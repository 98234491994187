import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ListItemRendererComponent } from 'gung-list';
import { Order } from '../../models/order';

@Component({
  selector: 'lib-order-card-list',
  templateUrl: './order-card-list.component.html',
  styleUrls: ['./order-card-list.component.scss']
})
export class OrderCardListComponent extends ListItemRendererComponent<Order> implements AfterViewInit {
  constructor() {
    super();
  }

  ngAfterViewInit(): void {
    this.renderFinished?.emit();
  }
}
