<div class="row">
  <div class="col-12 text-right">
    <button class="btn btn-primary" *ngIf="showAddToBasketBtn" [disabled]="addedToBasket" (click)="addToBasket()">
      <span *ngIf="addedToBasket" translate>ADDED_TO_BASKET</span>
      <span *ngIf="!addedToBasket" translate>ADD_TO_BASKET</span>
    </button>
  </div>
</div>
<lib-filter-list
  #productListByOrder
  (loaded)="(showAddToBasketBtn)"
  [configService]="productListConfigService"
></lib-filter-list>
