import { Component, OnInit } from '@angular/core';
import { HlDisplayOpenOrdersListConfigService } from '../../../services/hl-display-open-orders-list-config.service';
import { HlDisplayQuotesListConfigService } from '../../../services/hl-display-quotes-list-config.service';

@Component({
  selector: 'app-hl-display-quotes',
  templateUrl: './hl-display-quotes.component.html',
  styleUrls: ['./hl-display-quotes.component.css']
})
export class HlDisplayQuotesComponent implements OnInit {
  constructor(public config: HlDisplayQuotesListConfigService) {}

  ngOnInit() {}
}
