<div class="checkout-error" *ngIf="error; else steps">
  {{ error | json }}
</div>

<ng-template #steps>
  <ng-template #indicator></ng-template>

  <div class="gung-stepper-indicator-offset"></div>
  <div class="border-gray" [hidden]="showLoading">
    <span *ngIf="isCreditHardBlock" class="credit-blocked-warning bg-danger" translate>CREDIT_BLOCKED</span>
    <span *ngIf="isCreditSoftBlock" class="credit-blocked-warning bg-warning" translate>CREDIT_SOFT_BLOCKED</span>
    <ng-template #step libCustomHost></ng-template>
    <ng-template #btns></ng-template>
  </div>

  @if (!!freightCost || !!minOrderValue || !!adminFee) {
  <div class="row mt-4" [hidden]="showLoading">
    <div class="col">
      <div class="border-gray mx-15">
        <p class="mx-15" [innerHTML]="'ADVANCE_DELIVERY_INFORMATION' | translate"></p>

        @if (freightCostMin > 0) {
        <p class="mx-15" [innerHTML]="
                'NEW_FREIGHT_INFORMATION'
                  | translate
                    : {
                        value: freightCost,
                        min: freightCostMin,
                        currency: currencySymbol
                      }
              "></p>
        }

        @if (minOrderValue > 0) {
        <p class="mx-15" [innerHTML]="
                'NEW_MINIMUM_ORDER_INFORMATION'
                  | translate
                    : {
                        value: minOrderValue,
                        currency: currencySymbol
                      }
              "></p>
        }

        @if (adminChargeMin > 0) {
        <p class="mx-15" [innerHTML]="
                'NEW_ADMIN_FEE_INFORMATION'
                  | translate
                    : {
                        value: adminCharge,
                        min: adminChargeMin,
                        currency: currencySymbol
                      }
              "></p>
        }
      </div>
    </div>
  </div>
  }

  <!-- @if (minOrderValue > 0) {
  <div class="row mt-4" [hidden]="showLoading">
    <div class="col">
      <div class="border-gray mx-15">
        <p class="mx-15" [innerHTML]="
              'MIN_ORDER_VALUE'
                | translate
                  : {
                      value: minOrderValue,
                      currency: checkoutSettingsGroup
                    }
            "></p>
      </div>
    </div>
  </div>
  } -->

  <div>
    <div *ngIf="showLoading" class="progress mt-10">
      <div class="progress-bar progress-bar-striped progress-bar-animated bg-primary" role="progressbar"
        aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style="width: 75%"></div>
    </div>
    <div *ngIf="showLoading && isSubmitting">
      <h2 translate>SUBMITTING_ORDER</h2>
    </div>
  </div>
</ng-template>