<div class="main-menu-wrapper">
  <div class="scroll-menu">
    <div class="user-info d-flex align-items-center" *ngIf="userInfo">
      <div class="user-icon mr-3 text-primary">{{ userInfo.inicials }}</div>
      <div class="user-info-strings">
        <div class="user-name text-primary">{{ userInfo.name }}</div>
        <div class="role">{{ userInfo.role }}</div>
      </div>
    </div>
  
    <div class="m-menu">
      <div *ngFor="let menu of mainMenu" class="menu">
        <div
          class="menu-with-sub"
          *ngIf="menu.links && menu.links.length > 0; else singleMenu"
          routerLinkActive="active-submenu"
          [routerLinkActiveOptions]="{ exact: false }"
        >
          <div class="d-flex" (click)="parentMenuClick(menu); menu.action ? menu.action(): null">
            <div class="icon"><i class="{{ menu.icon }}"></i></div>
            <div>{{ menu.heading | translate }}</div>
            <div class="ml-auto">
              <i class="fa-solid fa-chevron-down" [hidden]="menu.open"></i>
              <i class="fa-solid fa-chevron-up" [hidden]="!menu.open"></i>
            </div>
          </div>
  
          <div class="sub-menu" [hidden]="!menu.open">
            <a
              [routerLink]="subMenu.link ? [subMenu.link] : null"
              (click)="menuClick(subMenu)"
              routerLinkActive="active"
              *ngFor="let subMenu of menu.links"
            >
              <div class="sub-menu-link d-flex align-items-center">
                <div class="icon"><i class="{{ subMenu.icon }}"></i></div>
                <div>{{ subMenu.heading | translate }}</div>
              </div>
            </a>
          </div>
        </div>
        <ng-template #singleMenu>
          <a [routerLink]="menu.link ? [menu.link] : null" (click)="menuClick(menu)" routerLinkActive="active">
            <div class="single-menu d-flex align-items-center">
              <div class="icon"><i class="{{ menu.icon }}"></i></div>
              <div>{{ menu.heading | translate }}</div>
            </div>
          </a>
        </ng-template>
        <div class="separator"></div>
      </div>
    </div>
  </div>
  

  <div class="logout bg-primary" (click)="logout()">
    <div class="single-menu d-flex">
      <div class="icon"><i class="fa-solid fa-right-to-bracket"></i></div>
      <div>{{ 'LOGOUT' | translate }}</div>
    </div>
  </div>
</div>
