import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DateUtilService } from 'gung-common';
import { ListItemRendererComponent } from 'gung-list';
import { AuthService, CustomerService, OrderRow } from 'gung-standard';
import { first } from 'rxjs';

@Component({
  selector: 'app-hl-display-order-lines-table',
  templateUrl: './hl-display-order-lines-table.component.html',
  styleUrls: ['./hl-display-order-lines-table.component.css']
})
export class HlDisplayOrderLinesTableComponent extends ListItemRendererComponent<OrderRow[]> {
  isLoading: boolean = true;
  currency: string;

  constructor(
    public dateUtilService: DateUtilService,
    protected customerService: CustomerService,
    protected route: ActivatedRoute,
    protected authService: AuthService
  ) {
    super();
  }

  ngOnInit(): void {
    const routeParams = this.route.snapshot.params;
    this.customerService.getCustomer(routeParams.customerId).pipe(first()).subscribe(customer => {
      this.currency = customer.extra.kus.valkod;
      this.isLoading = false;
    });
  }
}
