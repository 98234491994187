import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ExportDragProductService } from '../../services/export-drag-product.service';

@Component({
  selector: 'lib-pdf-export-edit-note-modal',
  templateUrl: './pdf-export-edit-note-modal.component.html',
  styleUrls: ['./pdf-export-edit-note-modal.component.css']
})
export class PdfExportEditNoteModalComponent implements OnInit {
  @Input() note;

  @Input() page;

  heading: string;

  noteText: string;
  constructor(protected exportDragProductService: ExportDragProductService, public activeModal: NgbActiveModal) {}

  ngOnInit(): void {
    this.noteText = this.note.data?.note;
    this.heading = this.note.data?.heading;
  }

  editNote() {
    this.note.data.note = this.noteText;
    this.note.data.heading = this.heading;
    this.exportDragProductService.editNote(this.note, this.page);
    this.activeModal.close();
  }
}
