import {
  Customer,
  ActivityService,
  Activity,
  TableRecord,
  MetadataService,
  CustomerGungContactsService,
  CustomerGungContact,
  AuthService
} from 'gung-standard';
import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { format } from 'date-fns';
import { environment } from './../../../environments/environment';
import { Observable, OperatorFunction } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs';
import { DateUtilService } from 'gung-common';
import { TranslateService } from '@ngx-translate/core';
interface Alert {
  type: string;
  message: string;
}

@Component({
  selector: 'app-hl-display-customer-meeting-modal',
  templateUrl: './hl-display-customer-meeting-modal.component.html',
  styleUrls: ['./hl-display-customer-meeting-modal.component.css']
})
export class HlDisplayCustomerMeetingModalComponent implements OnInit {
  isSales: boolean = false;
  alertMessage: Alert;
  form: FormGroup;
  isEdit = false;

  now = new Date();

  @Input()
  customer: Customer;

  @Input()
  meeting: Activity;

  allowedSalesCycleIds = ['3', '2'];
  allowedVGIds = ['4010', '4020'];

  allowedXAIds = ['12', '20', '21', '22'];

  allowedIds = [
    'AdLite',
    'FreshAccessories',
    'Multivo',
    'Next',
    'RollerTrack',
    'RotoShelf',
    'Sigma',
    'SoftPushTrays',
    'Turnloader',
    '3eBin',
    '4eBin',
    'Facer',
    'DatastripCarrierRails',
    'DatastripForPaperLabels'
  ];
  qSystemFilteredMapped: { id: string; name: string }[];
  qSalesCyclesFilteredMapped: { id: string; name: string }[];
  qVGFilteredMapped: { id: string; name: string }[];
  xaFilteredMapped: { id: string; name: string }[];

  durations = [0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8];

  ediTextContacts: string[] = [];

  protected contactsCustomer: CustomerGungContact[];
  contactEmailField = (contact: CustomerGungContact) => contact?.email || (contact as any);

  public modelSearch: any;
  formatter = (item: CustomerGungContact) => this.contactEmailField(item);
  resultFormatter = (item: CustomerGungContact) => item.name + ' - ' + this.contactEmailField(item);

  search: OperatorFunction<string, readonly CustomerGungContact[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      filter(term => term.length >= 2),
      map(term =>
        this.contactsCustomer
          ?.filter(contact => new RegExp(term, 'mi').test(this.contactEmailField(contact)))
          .slice(0, 10)
      )
    );

  constructor(
    protected activityService: ActivityService,
    protected metadataService: MetadataService,
    protected formBuilder: FormBuilder,
    public activeModal: NgbActiveModal,
    protected customerGungContactsService: CustomerGungContactsService,
    protected dateUtilService: DateUtilService,
    protected translateService: TranslateService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.customerGungContactsService
      .getContactsByCustomerId(this.customer?.id || this.meeting.customerId)
      .subscribe(data => (this.contactsCustomer = data));

    this.authService.getRoles().subscribe(roles => {
      this.isSales = roles.includes('SALES');
    });

    const xa = this.metadataService.getMetadataTableList('xa');

    const xaFiltered = xa.filter(element => {
      const id = element.eventkod1 + '';
      return this.allowedXAIds.indexOf(id) >= 0;
    });
    this.xaFilteredMapped = xaFiltered
      .map(element => ({ id: element.eventkod1 + '', name: element.eventbeskr }))
      .sort((a, b) => a.name.localeCompare(b.name));

    const qSystem = this.metadataService.getMetadataTableList('q_system');
    const qSystemFiltered = qSystem.filter(theSystem => {
      const id = theSystem.q_systemid;
      return this.allowedIds.indexOf(id) >= 0;
    });
    this.qSystemFilteredMapped = qSystemFiltered
      .map(theSystem => ({ id: theSystem.q_systemid, name: theSystem.q_systemdescription }))
      .sort((a, b) => a.name.localeCompare(b.name));

    const qSalesCycle = this.metadataService.getMetadataTableList('q_hl_salescycle');
    const qSalesCycleFiltered = qSalesCycle.filter(theCycle => {
      const id = theCycle.q_hl_salescycleid;
      return this.allowedSalesCycleIds.indexOf('' + id) >= 0;
    });

    this.qSalesCyclesFilteredMapped = qSalesCycleFiltered
      .map(theCycle => ({ id: '' + theCycle.q_hl_salescycleid, name: theCycle.q_hl_salescyclebeskr }))
      // .sort((a, b) => a.name.localeCompare(b.name));
      .sort((a, b) => this.allowedSalesCycleIds.indexOf('' + a.id) - this.allowedSalesCycleIds.indexOf('' + b.id)); // Sort by array position

    const vg = this.metadataService.getMetadataTableList('vg');
    const vgFiltered = vg.filter(element => {
      const id = element.varugruppkod;
      return this.allowedVGIds.indexOf('' + id) >= 0;
    });

    this.qVGFilteredMapped = vgFiltered
      .map(theCycle => ({ id: theCycle.varugruppkod, name: theCycle.varugruppbeskr }))
      .sort((a, b) => a.name.localeCompare(b.name));

    //Move 'Multivo Max' field to system section (only for sales portal env.).
    if (this.isSales) {
      const multivoMax = this.qVGFilteredMapped.find(i => i.id === '4010');
      const shelfAutomation = { id: '4', name: this.translateService.instant('SHELF_AUTOMATION') };
      const mayamField = { id: 'mayam', name: 'Mayam' };
      this.qSystemFilteredMapped.push(multivoMax, mayamField);
      this.qSalesCyclesFilteredMapped.push(shelfAutomation);
    }

    //   this.salesCyclesFilteredMapped = [{ id: '10', name: 'Beverages' }];
    if (!!this.meeting) {
      this.isEdit = true;
    }
    this.initForm(this.meeting);
  }

  selectItem({ item: itemObject, preventDefault }, target) {
    // const item: CustomerGungContact = itemObject;
    // const inputItem: FormArray = this.form.get('contacts') as FormArray;
    // if (this.ediTextContacts.indexOf(this.contactEmailField(item)) !== -1) {
    //   preventDefault();
    //   this.modelSearch = '';
    //   target.value = '';
    //   return;
    // }
    // this.addContact(this.contactEmailField(item));
    // preventDefault();
    // this.modelSearch = '';
    // target.value = '';
  }

  ngModelChange(itemObject, target) {
    // const item: CustomerGungContact = itemObject;
    // const inputItem = this.form.get('contacts');
    // if (!item) {
    //   inputItem.patchValue(this.formBuilder.array([]));
    // }
  }

  focusout({ target }, modelSearch: CustomerGungContact) {
    // if (!modelSearch) {
    //   target.value = '';
    // }
  }

  toSystemsArray(meeting?: Activity) {
    let systemCodes = [];
    if (meeting) {
      systemCodes = meeting.extra.systems;
    }

    const arr = this.qSystemFilteredMapped.map(system => {
      return this.formBuilder.control(systemCodes.indexOf(system.id) >= 0);
    });
    return this.formBuilder.array(arr);
  }

  toSalesCyclesArray(meeting?: Activity) {
    let salesCyclesCodes = [];
    if (meeting && meeting.extra.salesCycles) {
      salesCyclesCodes = meeting.extra.salesCycles;
    }

    const arr = this.qSalesCyclesFilteredMapped.map(system => {
      return this.formBuilder.control(salesCyclesCodes.indexOf(system.id) >= 0);
    });
    return this.formBuilder.array(arr);
  }

  toProductGroupArray(meeting?: Activity) {
    let productGroupCodes = [];
    if (meeting && meeting.extra.productGroups) {
      productGroupCodes = meeting.extra.productGroups;
    }

    const arr = this.qVGFilteredMapped.map(system => {
      return this.formBuilder.control(productGroupCodes.indexOf(system.id) >= 0);
    });
    return this.formBuilder.array(arr);
  }

  initForm(meeting?: Activity) {
    const systemsArray = this.toSystemsArray(meeting);
    const salesCyclesArray = this.toSalesCyclesArray(meeting);
    const productGroupArray = this.toProductGroupArray(meeting);

    let theDate = meeting ? meeting.extra.hr.eventpldat : new Date();
    if (!theDate) {
      theDate = new Date();
    } else {
      theDate = new Date(theDate);
    }
    let theTime = meeting ? meeting.extra.hr.eventpltime : new Date();
    if (!theTime) {
      theTime = new Date();
    } else {
      const theTimeDate = new Date(theTime);
      if (theTimeDate instanceof Date && !isNaN(theTimeDate as any)) {
        theTime = theTimeDate;
      } else if (theTime.length === 5) {
        theTime = this.dateUtilService.parseDate(theTime, 'HH:mm');
      }
    }
    let theReminderDate = meeting ? meeting.extra.hr.kvittdatum : null;
    if (!theReminderDate) {
      theReminderDate = null;
    } else {
      theReminderDate = new Date(theReminderDate);
    }
    const theDateFormatted = format(theDate, 'yyyy-MM-dd');
    const theTimeFormatted = format(theTime, 'HH:mm');
    const theReminderDateFormatted = theReminderDate ? format(theReminderDate, 'yyyy-MM-dd') : null;

    this.form = this.formBuilder.group({
      extra: this.formBuilder.group({
        hr: this.formBuilder.group({
          eventkod1: meeting ? meeting.extra.hr.eventkod1 : '20', // meeting type
          // eventnote: meeting ? meeting.extra.hr.eventnote : '', // meeting heading
          assetid: meeting ? meeting.extra.hr.assetid : '', // sales cycle
          editext: meeting ? meeting.extra.hr.editext : '', // comma separated string contact person name
          editint: meeting ? meeting.extra.hr.editint : '', // comma separated string with product group codes
          edit: meeting ? meeting.extra.hr.edit : '', // note
          // eventpltime: '', // planned time (full date, only HH:mm:ss matters for us)
          eventpldat: [theDateFormatted], // planned date
          eventpltime: [theTimeFormatted], // planned time
          kvittdatum: theReminderDate ? [theReminderDateFormatted] : '', // reminder date
          q_hl_sync2outlook: meeting ? meeting.extra.hr.q_hl_sync2outlook : false,
          eventtimant2: meeting?.extra?.hr?.eventtimant2 || undefined
        })
        //      ,systems: '', // comma separated string with systems
      }),
      systems2: systemsArray, // comma separated string with systems
      salesCycles2: salesCyclesArray, // comma separated string with systems
      productGroups2: productGroupArray // comma separated string with systems
    });

    this.ediTextContacts = (this.form.get('extra').get('hr').get('editext').value || '').split(';');
  }

  outputSelectedDate(date) {
    this.form.patchValue({ date: date.date });
  }

  addContact(email?: string) {
    this.ediTextContacts.push(email || '');
  }

  removeContact(i) {
    this.ediTextContacts.splice(i, 1);
  }

  private setEditTextFromControl() {
    // set edit text contacts in form extra hr
    this.form
      .get('extra')
      .get('hr')
      .get('editext')
      .setValue(
        this.ediTextContacts
          .map(editText => this.contactEmailField(editText as any) || editText)
          .filter(editText => !!editText)
          .join(';')
      );
  }

  onSave() {
    if (this.isEdit) {
      // Theres another way
      alert('USE ONEDIT IF EDITING');
    } else {
      this.setEditTextFromControl();
      const form = this.form.getRawValue();

      this.meeting = {
        customerId: this.customer?.id || this.meeting.customerId,
        name: '',
        id: '',
        extra: {
          ...form.extra
        }
      };

      //     this.meeting.extra.hr.assetid = '10';
      if (!this.meeting.extra.hr.eventpltime) {
        this.meeting.extra.hr.eventpltime = format(new Date(), 'HH:mm');
      }
      this.updateMeeting(form);

      // Set boolean value to update in Jeeves
      if (!this.meeting.extra.hr.q_hl_sync2outlook) {
        this.meeting.extra.hr.q_hl_sync2outlook = 0;
      } else {
        this.meeting.extra.hr.q_hl_sync2outlook = 1;
      }

      this.activityService.createActivity(this.meeting.customerId, this.meeting).subscribe(
        (value: Activity) => {
          this.activeModal.close(true);
        },
        (error: any) => {
          console.log('error: ', error);
          this.alertMessage = {
            message: 'SAVE_MEETING_ERROR',
            type: 'danger'
          };
        }
      );
    }
  }

  updateMeeting(form: any) {
    const systems = [];

    form.systems2.forEach((element, index) => {
      if (element) {
        systems.push('' + this.qSystemFilteredMapped[index].id);
      }
    });

    const salesCycles = [];
    form.salesCycles2.forEach((element, index) => {
      if (element) {
        salesCycles.push('' + this.qSalesCyclesFilteredMapped[index].id);
      }
    });

    const productGroups = [];
    form.productGroups2.forEach((element, index) => {
      if (element) {
        productGroups.push('' + this.qVGFilteredMapped[index].id);
      }
    });

    this.meeting.extra.systems = systems;
    this.meeting.extra.salesCycles = salesCycles;
    this.meeting.extra.productGroups = productGroups;
    this.meeting.extra.hr = {
      ...this.meeting.extra.hr,
      ...form.extra.hr
    };

    // Jeeves does not like null values for this field so explicitly remove it.
    if (!this.meeting?.extra?.hr?.eventtimant2) {
      delete this.meeting.extra.hr.eventtimant2;
    }

    if (!this.meeting.extra.hr.assetid) {
      this.meeting.extra.hr.assetid = null;
    }
  }

  onRemove() {
    const customerId = this.meeting.customerId;

    this.activityService.removeActivity(customerId, this.meeting).subscribe(
      (value: boolean) => {
        this.activeModal.close(true);
      },
      (error: any) => {
        console.log('error: ', error);
        this.alertMessage = {
          message: 'REMOVE_MEETING_ERROR',
          type: 'danger'
        };
      }
    );
  }

  onEdit() {
    this.setEditTextFromControl();
    const form = this.form.getRawValue();
    this.updateMeeting(form);
    const customerId = this.meeting.customerId;

    this.activityService.updateActivity(customerId, this.meeting).subscribe(
      (value: Activity) => {
        this.activeModal.close(true);
      },
      (error: any) => {
        console.log('error: ', error);
        this.alertMessage = {
          message: 'EDIT_MEETING_ERROR',
          type: 'danger'
        };
      }
    );
  }

  trackByFn(index: any, item: any) {
    return index;
  }
}
