import { Inject, Injectable, Optional } from '@angular/core';
import { AppState, AuthService, BackendInterceptor } from 'gung-standard';
import { Store } from '@ngrx/store';
import { OAuthService } from 'angular-oauth2-oidc';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HlDisplayAuthService extends AuthService {
  constructor(
    protected store: Store<AppState>,
    protected backendInterceptor: BackendInterceptor,
    @Optional()
    protected oauthService: OAuthService,
    @Optional()
    @Inject('environment')
    protected environment: { [s: string]: any }
  ) {
    super(
      store,
      backendInterceptor,
      oauthService,
      environment
    );
  }

  logout() {
    if (environment.oauth2Enabled) {
      this.oauthService.logOut();
    }
    super.logout();
  }
}
