import { ChangeDetectorRef, Component, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  AuthService,
  ButtonsComponent,
  CartService,
  CartTotalsService,
  CheckoutConfigService,
  GungModalService,
  NavbarCartService,
  SaveCartModalConfigService,
  SelectedCustomerService
} from 'gung-standard';
import { combineLatest, first, forkJoin, map, of, switchMap, takeUntil, tap } from 'rxjs';
import {
  HlDisplayPortalFeaturesService,
  CheckoutSettingsGroup,
  PriceSettingsGroup
} from '../../../services/hl-display-portal-features.service';
import { HlDisplayAdditionalCostsService } from '../../../services/hl-display-additional-costs.service';
import { add } from 'date-fns';

@Component({
  selector: 'app-hl-checkout-buttons-portal',
  templateUrl: './hl-checkout-buttons-portal.component.html',
  styleUrl: './hl-checkout-buttons-portal.component.css'
})
export class HlCheckoutButtonsPortalComponent extends ButtonsComponent implements OnInit {
  public showApprovalQuote: boolean;
  public showCreateOrder: boolean;
  public showCreateSampleOrder: boolean;
  isApprovalQuote: boolean;
  isQuote: boolean;
  isSampleOrder: boolean;
  loading: boolean = true;
  minOrderValue: number;
  isManager: boolean = false;
  freightCost: number = 0;
  freightCostMin: number = 0;
  adminCharge: number = 0;
  adminChargeMin: number = 0;
  orderTotalValue: number = 0;

  additionalCosts: any;

  constructor(
    protected navbarCartService: NavbarCartService,
    protected gungModalService: GungModalService,
    protected saveCartModalConfigService: SaveCartModalConfigService,
    protected cartService: CartService,
    protected translationService: TranslateService,
    protected authService: AuthService,
    protected checkoutConfig: CheckoutConfigService,
    protected hlDisplayPortalFeaturesService: HlDisplayPortalFeaturesService,
    protected changeDetectorRef: ChangeDetectorRef,
    protected hlDisplayAdditionalCostsService: HlDisplayAdditionalCostsService,
    protected selectedCustomerService: SelectedCustomerService,
    private cartTotalsService: CartTotalsService,
  ) {
    super(
      navbarCartService,
      gungModalService,
      saveCartModalConfigService,
      cartService,
      translationService,
      authService,
      checkoutConfig
    );
  }

  ngOnInit() {
    this.updateData();

    this.authService
      .getRoles()
      .pipe(first())
      .subscribe(roles => {
        this.isSales = roles.indexOf('SALES') > -1;
        this.isAdmin = roles.indexOf('ADMIN') > -1;
        this.isUser = roles.indexOf('USER') > -1 && !this.isSales && !this.isAdmin;
        this.isManager = roles.indexOf('MANAGER') > -1;
        this.rolesLoaded();
      });

    this.hlDisplayPortalFeaturesService.getPortalFeatureGroup('priceSettingsGroup').pipe(first(), map(priceSettingsGroup => (priceSettingsGroup as PriceSettingsGroup)))
      .pipe(
        takeUntil(this.unsubscribe),
        switchMap(priceSettings => {
          return combineLatest({
            priceSettings: of(priceSettings),
            contents: this.navbarCartService.getContents()
          })
        })
      ).subscribe(({ priceSettings, contents }) => {
        if (contents.currency !== '') {
          this.cartCurrency = contents.currency;
        }
        else {
          this.cartCurrency = priceSettings.defaultCurrencyCode;
        }
        this.cartContents = contents;

        this.cartTotalRows = contents.rows.length;
        this.cartTotalItems = contents.rows.map(row => row.cartRow.qty).reduce((acc, curr) => acc + curr, 0);
      });

    forkJoin({
      checkoutSettingsGroup: this.hlDisplayPortalFeaturesService.getPortalFeatureGroup('orderCheckoutSettingsGroup').pipe(first(), map(checkoutSettingsGroup => (checkoutSettingsGroup as CheckoutSettingsGroup))),
      selectedCustomer: this.selectedCustomerService.getSelectedCustomer().pipe(first()),
    }).pipe(
      first(),
      switchMap(({ checkoutSettingsGroup, selectedCustomer }) => {
        return combineLatest({
          checkoutSettingsGroup: of(checkoutSettingsGroup).pipe(first()),
          additionalCostTerms: checkoutSettingsGroup.useTradePolicy ? this.hlDisplayAdditionalCostsService.getAdditionalCostTerms(selectedCustomer).pipe(first()) : of(null),
          totals: this.cartTotalsService.getTotals()
        }).pipe(takeUntil(this.unsubscribe));
      })).subscribe(({ checkoutSettingsGroup, additionalCostTerms, totals }) => {
        this.minOrderValue = checkoutSettingsGroup.minimumOrderValue;

        this.cartTotalValue = totals.totalValue;
        this.orderTotalValue = totals.totalValue;

        if (additionalCostTerms != null) {

          const freightCostItem = this.hlDisplayAdditionalCostsService.getFreightCharge(
            this.orderTotalValue,
            additionalCostTerms,
          )

          if (freightCostItem) {
            this.freightCost = freightCostItem.value;
            this.freightCostMin = freightCostItem.freightMin;
          }

          const adminFeeItem = this.hlDisplayAdditionalCostsService.getAdminFee(
            this.orderTotalValue,
            additionalCostTerms,
          )

          if (adminFeeItem) {
            this.adminCharge = adminFeeItem.value;
            this.adminChargeMin = adminFeeItem.adminChargeMin;
          }

          const minOrderItem = this.hlDisplayAdditionalCostsService.getMinOrder(
            additionalCostTerms,
          )

          if (minOrderItem) {
            this.minOrderValue = minOrderItem.value;
          }

          this.cartTotalValue = totals.totalValue;

          if (this.cartTotalValue < this.adminChargeMin) {
            this.orderTotalValue += this.adminCharge;
          }

          if (this.cartTotalValue < this.freightCostMin) {
            this.orderTotalValue += this.freightCost;
          }

          this.applyDiscountToTotalValue(null, this._discountCode);
        }

        this.changeDetectorRef.detectChanges();
        this.loading = false;
      });



    if (!!this.disableNextButtonObservable) {
      this.disableNextButtonObservable
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(disable => (this.disableNextButton = disable));
    }
  }

  rolesLoaded() {
    this.hlDisplayPortalFeaturesService
      .getPortalFeatureGroup('orderCheckoutSettingsGroup')
      .pipe(first())
      .subscribe(orderCheckoutSettingsGroup => {
        let orderCheckoutGroup = orderCheckoutSettingsGroup as CheckoutSettingsGroup;
        this.showApprovalQuote = orderCheckoutGroup.showApprovalQuote && (this.isManager || this.isSales);
        this.showCreateOrder = !this.isSales || orderCheckoutGroup.showCreateOrder;
        this.showCreateSampleOrder = orderCheckoutGroup.showCreateSampleOrder && (this.isManager || this.isSales);
      });
  }

  sendApprovalQuote() {
    this.isApprovalQuote = true;
    this.nextClicked.emit({ isApprovalQuote: true });
  }

  createQuote() {
    this.isQuote = true;
    this.nextClicked.emit({ isQuote: true });
  }

  createSampleOrder() {
    this.isSampleOrder = true;
    this.nextClicked.emit({ isSampleOrder: true });
  }

  createOrder() {
    this.nextClicked.emit({});
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    /* if (changes.additionalCosts) {
      this.additionalCosts = changes.additionalCosts.currentValue;
   
      if (this.additionalCosts?.freightInfo) {
        this.freightCost = this.additionalCosts.freightInfo.value;
        this.freightCostMin = this.additionalCosts.freightInfo.freightMin;
      }
   
      if (this.additionalCosts?.adminFeeInfo) {
        this.adminCharge = this.additionalCosts.adminFeeInfo.value;
        this.adminChargeMin = this.additionalCosts.adminFeeInfo.adminChargeMin;
      }
   
      if (this.additionalCosts?.minOrder) {
        this.minOrderValue = this.additionalCosts.minOrder.value;
      }
   
      if (this.orderTotalValue < this.adminChargeMin) {
        this.orderTotalValue += this.adminCharge;
      }
   
      if (this.orderTotalValue < this.freightCostMin) {
        this.orderTotalValue += this.freightCost;
      }
    } */
  }
}
