import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Customer } from 'gung-standard/lib/models';

@Component({
  selector: 'app-hl-display-order-modal',
  templateUrl: './hl-display-order-modal.component.html',
  styleUrls: ['./hl-display-order-modal.component.css']
})
export class HlDisplayOrderModalComponent implements OnInit {
  constructor(public activeModal: NgbActiveModal) {}

  currentCustomer: Customer;
  nextCustomer: Customer;

  ngOnInit() {}
}
