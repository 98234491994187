import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Customer } from '../../models';
import { SelectedCustomerService } from '../../services/selected-customer/selected-customer.service';
import { FooterService, FooterText, FooterImage, FooterLink } from '../../services/footer/footer.service';
import { Observable, Subject, first } from 'rxjs';
import { takeUntil } from 'rxjs';
import { GungCompanyService } from '../../services/gung-company/gung-company.service';
import { MyCompanyFooter, MyCompanyFooterColumn } from '../my-company/my-company-footer/my-company-footer.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomPageHtmlEditorComponent } from '../custom-page/custom-page-html-editor/custom-page-html-editor.component';
import { TemplateElement } from '../custom-page/custom-page-interface/template-component';
@Component({
  selector: 'lib-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  public currentLang = this.translateService.currentLang || 'se';
  leftContent: (FooterText | FooterImage | FooterLink)[];
  centerContent: (FooterText | FooterImage | FooterLink)[];
  rightContent: (FooterText | FooterImage | FooterLink)[];
  @Input()
  public myCompanyFooter: MyCompanyFooter;
  @Input()
  public edit = false;

  protected unsubscribe: Subject<void> = new Subject();
  constructor(
    protected footerService: FooterService,
    protected companyService: GungCompanyService,
    protected modalService: NgbModal,
    protected translateService: TranslateService
  ) {}

  ngOnInit() {
    if (!this.myCompanyFooter) {
      this.companyService.getPublicGungCompany().pipe(first()).subscribe(company => {
        if (company?.extra?.footer?.enabled) {
          this.myCompanyFooter = company.extra.footer
        } else {
          this.leftContent = this.footerService.getLeftColumnContent();
          this.centerContent = this.footerService.getCenterColumnContent();
          this.rightContent = this.footerService.getRightColumnContent();
        }
      });
    }
  }

  addColumn() {
    this.myCompanyFooter.columns.push({
      html: '',
      i18n: {},
      class: ''
    });
  }

  public invertHex(hex) {
    return (Number(`0x1${hex}`) ^ 0xFFFFFF).toString(16).substr(1).toUpperCase()
  }

  public openHtmlModal(column: MyCompanyFooterColumn) {
    const modalRef = this.modalService.open(CustomPageHtmlEditorComponent, { 
      size: 'xl',
      backdrop: 'static',
      keyboard: true
    });

    const data: TemplateElement[] = [
      {
        link: undefined,
        image: undefined,
        text: {
          enabled: true,
          text: column.html,
          i18n: {},
        }
      }
    ];
    for (const [key, value] of Object.entries(column.i18n)) {
      data[0].text.i18n[key] = {
        text: value
      };
    }
    
    modalRef.componentInstance.data = JSON.parse(JSON.stringify(data));
    modalRef.result.then(
      result => {
        const newElement = JSON.parse(result);
        for (const [key, value] of Object.entries(newElement[0].text.i18n)) {
          column.i18n[key] = (value as any).text;
        }
        // column.i18n = newElement[0].text.i18n;
        column.html = newElement[0].text.text;
      },
      reason => {}
    );
  }

  public getHtmlText(htmlText: MyCompanyFooterColumn) {
    if (htmlText && (htmlText.html || htmlText?.i18n?.[this.currentLang])) {
      return htmlText?.i18n?.[this.currentLang] || htmlText.html;
    }
    return '';
  }

  public deleteColumn(i: number) {
    this.myCompanyFooter.columns.splice(i, 1);
  }
}
