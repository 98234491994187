<div class="row justify-content-center">
  <div [ngClass]="!!customerSalesPerson ? 'col col-md-10' : 'col-6'">
    <div class="row mb-3">
      <div class="col">
        <h4 class="mt-4">
          <span translate>CONTACT_US</span>
        </h4>
        <hr />
      </div>
    </div>

    <div class="row mb-4">
      <div class="col service-client">
        <h3 class="mb-3">
          <span translate>SERVICE_CLIENT</span>
        </h3>
        <div class="row">

          <div class="col" *ngIf="servicePortal">
            <h4>{{ servicePortal.name }}</h4>
            <p class="mb-4">
              <span>{{'PHONE' | translate}}: {{ servicePortal.phone }}</span>
              <br />
              {{'EMAIL' | translate}}: <a href="mailto:{{ servicePortal.email }}">{{ servicePortal.email }}</a>
            </p>
            <h4 translate>ADDRESS</h4>
            <div *ngIf="contactInformation">
              <div *ngIf="contactInformation.address">
                {{contactInformation.address}}
              </div>
              <!-- <br /> -->
              <div *ngIf="contactInformation.address2">
                {{contactInformation.address2}}
              </div>
              <!-- <br /> -->
              <div *ngIf="contactInformation.city">
                {{contactInformation.city}}
              </div>
              <!-- <br /> -->
              <div *ngIf="contactInformation.zipCode">
                {{contactInformation.zipCode}}
              </div>
              <div *ngIf="contactInformation.country">
                {{contactInformation.country}}
              </div>
              <h4>Opening hours</h4>
              <div *ngIf="contactInformation.daysOpen">
                {{contactInformation.daysOpen}}
              </div>
              <div *ngIf="contactInformation.hoursOpen">
                {{ contactInformation.hoursOpen }}
              </div>
              <div *ngIf="contactInformation.openingExtraInfo">
                {{ contactInformation.openingExtraInfo }}
              </div>
            </div>
          </div>
          <div class="col-4">
            <img class="img-fluid" src="assets/Contact.png" alt="" />
          </div>
        </div>
      </div>
      <div class="col vhr" *ngIf="customerSalesPerson">
        <h3 class="mb-3">
          <span translate>ACCOUNT_MANAGER</span>
        </h3>
        <div class="row">
          <div class="col">
            <span *ngIf="customerSalesPerson.name">
              <h4>
                <span translate>CONTACT_NAME</span>
              </h4>
              <p>{{ customerSalesPerson.name }}</p>
            </span>
            <span *ngIf="customerSalesPerson.phone">
              <h4>
                <span translate>PHONE</span>
              </h4>
              <p>{{ customerSalesPerson.phone }}</p>
            </span>
            <span *ngIf="customerSalesPerson.email">
              <h4>
                <span translate>EMAIL</span>
              </h4>
              <p>{{ customerSalesPerson.email }}</p>
            </span>
          </div>
          <div class="col-4">
            <img class="img-fluid" src="assets/Customer.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>