@if(order){
  <div>
    <div class="d-flex flex-row justify-content-between align-items-center mb-4 mt-4">
      <div>
        <h2>{{ 'ORDER' | translate }} {{ order.id }}</h2>
      </div>
      <div>
        <!--  <lib-action-btn [item]="order" [config]="configService"></lib-action-btn> -->
      </div>
    </div>
  
    <div class="row mb-3">
      <div class="col">
        <lib-gung-accordion>
          <lib-gung-accordion-item [collapsed]="false">
            <ng-template libGungAccordionHeader>{{ 'DETAILS' | translate }}</ng-template>
  
            <ng-template libGungAccordionBody>
              <div class="row">
                <div class="col col-xs-12 col-md-6">
                  <h5 translate>DETAILS</h5>
                  <lib-gung-text-input-horizontal
                    renderStatic="true"
                    gungTranslate="ORDER_NUMBER"
                    [(ngModel)]="order.extra.gme.ourReference"
                  />
  
                  <lib-gung-text-input-horizontal
                    renderStatic="true"
                    gungTranslate="ORDER_STATUS"
                    [ngModel]="order.extra.gme.status | translate"
                  />
                  @if (order.extra.gme.orderDate) {
                    <lib-gung-date-output
                      [horizontal]="true"
                      [gungTranslate]="'ORDER_DATE'"
                      [value]="order.extra.gme.orderDate"
                    />
                  }
  
                  @if (order.extra.gme.deliveryDate) {
                    <lib-gung-date-output
                      [horizontal]="true"
                      [gungTranslate]="'ESTIMATED_DELIVERY_DATE'"
                      [value]="order.extra.gme.deliveryDate"
                    />
                  }
                </div>
  
                <div class="col col-xs-12 col-md-6">
                  <h5 translate>TOTAL</h5>
                  <lib-gung-text-input-horizontal
                    renderStatic="true"
                    gungTranslate="TOTAL"
                    [ngModel]="
                      priceConfigService.getPrice(
                        order.extra.gme.totalPriceIncludingVat,
                        undefined,
                        undefined,
                        order.extra.gme.currencyCode
                      )
                    "
                  />
  
                  <lib-gung-text-input-horizontal
                    renderStatic="true"
                    gungTranslate="ORDER_VALUE"
                    [ngModel]="
                      priceConfigService.getPrice(
                        order.extra.gme.totalPriceExcludingVat,
                        undefined,
                        undefined,
                        order.extra.gme.currencyCode
                      )
                    "
                  />
                </div>
              </div>
            </ng-template>
          </lib-gung-accordion-item>
  
          <lib-gung-accordion-item>
            <ng-template libGungAccordionHeader>{{ 'SHIPPING_INFORMATION' | translate }}</ng-template>
            <ng-template libGungAccordionBody>
              <div class="row">
                @if (order.extra.gme.deliveryAddress) {
                  <div class="col col-xs-12 col-md-6">
                    <h5 translate>DELIVERY_ADDRESS</h5>
                    <lib-gung-text-input-horizontal
                      renderStatic="true"
                      gungTranslate="ID"
                      [ngModel]="order.extra.deliveryCustomer.id"
                    />
  
                    <lib-gung-text-input-horizontal
                      renderStatic="true"
                      gungTranslate="NAME"
                      [ngModel]="order.extra.gme.deliveryAddress.name"
                    />
  
                    <lib-gung-text-input-horizontal
                      renderStatic="true"
                      gungTranslate="ADDRESS"
                      [ngModel]="order.extra.gme.deliveryAddress.address1"
                    />
  
                    <lib-gung-text-input-horizontal
                      renderStatic="true"
                      gungTranslate="ADDRESS_2"
                      [ngModel]="order.extra.gme.deliveryAddress.address2"
                    />
  
                    <lib-gung-text-input-horizontal
                      renderStatic="true"
                      gungTranslate="ZIP"
                      [(ngModel)]="order.extra.gme.deliveryAddress.zipCode"
                    />
  
                    <lib-gung-text-input-horizontal
                      renderStatic="true"
                      gungTranslate="CITY"
                      [(ngModel)]="order.extra.gme.deliveryAddress.city"
                    >
                    </lib-gung-text-input-horizontal>
  
                    <lib-gung-text-input-horizontal
                      renderStatic="true"
                      gungTranslate="COUNTRY"
                      [(ngModel)]="order.extra.gme.deliveryAddress.countryCode"
                    />
                  </div>
                }
  
                @if (order.extra.gme.billingAddress) {
                  <div class="col col-xs-12 col-md-6">
                    <h5 translate>BILLING_ADDRESS</h5>
                    <lib-gung-text-input-horizontal
                      renderStatic="true"
                      gungTranslate="ID"
                      [ngModel]="order.extra.billingCustomer?.id"
                    />
  
                    <lib-gung-text-input-horizontal
                      renderStatic="true"
                      gungTranslate="NAME"
                      [(ngModel)]="order.extra.gme.billingAddress.name"
                    />
  
                    <lib-gung-text-input-horizontal
                      renderStatic="true"
                      gungTranslate="ADDRESS"
                      [ngModel]="order.extra.gme.billingAddress.address1"
                    />
  
                    <lib-gung-text-input-horizontal
                      renderStatic="true"
                      gungTranslate="ADDRESS_2"
                      [ngModel]="order.extra.gme.billingAddress.address2"
                    />
  
                    <lib-gung-text-input-horizontal
                      renderStatic="true"
                      gungTranslate="ZIP"
                      [(ngModel)]="order.extra.gme.billingAddress.zipCode"
                    />
  
                    <lib-gung-text-input-horizontal
                      renderStatic="true"
                      gungTranslate="CITY"
                      [(ngModel)]="order.extra.gme.billingAddress.city"
                    >
                    </lib-gung-text-input-horizontal>
  
                    <lib-gung-text-input-horizontal
                      renderStatic="true"
                      gungTranslate="COUNTRY"
                      [(ngModel)]="order.extra.gme.billingAddress.countryCode"
                    />
                  </div>
                }
              </div>
            </ng-template>
          </lib-gung-accordion-item>
        </lib-gung-accordion>
      </div>
    </div>
  
    <div class="row">
      <h2 translate>ROWS</h2>
      @if (isDataReady) {
        <div class="col-12 table-responsive gung-responsive">
          <table class="table table-striped">
            <thead>
              <tr>
                <th translate>DESCRIPTION</th>
                <th translate>QUANTITY</th>
                <th translate>UNIT_PRICE</th>
                <th translate>DISCOUNT</th>
                <th translate>TOTAL</th>
              </tr>
            </thead>
            <tbody>
              @for (orderedProduct of standardOrderedProductsSummary; track orderedProduct.productId) {
                <tr>
                  <td [attr.data-label]="'DESCRIPTION' | translate">
                    <div class="row">
                      <div class="col-auto col-md-3 col-lg-2">
                        <div style="max-width: 100px">
                          @if (orderedProduct.image) {
                            <img
                              [src]="
                                orderedProduct.image.s3Uri
                                  | gungImageUrl: 'type:thumbnail' : 'etag:' + orderedProduct.image.s3ETag
                              "
                              alt="{{ orderedProduct.image.description }}"
                              class="img-fluid"
                            />
                          }
                        </div>
                      </div>
  
                      <div class="col-auto col-md-3 col-lg-3">
                        <div class="table-product-name">
                          <strong>{{ orderedProduct.name }}</strong>
                        </div>
                        <div class="table-product-name-id">
                          @if (orderedProduct.isMultiDimension) {
                            <div>{{ orderedProduct.primaryDimensionName }}</div>
                          }
                          <div>{{ orderedProduct.productId }}</div>
                        </div>
                      </div>
                      @if (orderedProduct.isMultiDimension) {
                        <div class="col-auto">
                          <table class="table table-borderless table-condensed table-sm table-summary-size-qty">
                            <tbody>
                              <tr class="small">
                                <td>
                                  <b>{{ 'SIZE' | translate }}</b>
                                </td>
                                @for (
                                  secondaryDimension of orderedProduct.secondaryDimension;
                                  track secondaryDimension.size
                                ) {
                                  <td class="text-center">
                                    {{ secondaryDimension.size }}
                                  </td>
                                }
                              </tr>
                              <tr class="small">
                                <td>
                                  <b>{{ 'QUANTITY' | translate }}</b>
                                </td>
                                @for (
                                  secondaryDimension of orderedProduct.secondaryDimension;
                                  track secondaryDimension.size
                                ) {
                                  <td class="text-center">
                                    {{ secondaryDimension.qty }}
                                  </td>
                                }
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      }
                    </div>
                  </td>
  
                  <td [attr.data-label]="'QUANTITY' | translate">
                    {{ orderedProduct.totals.qty }}
                  </td>
  
                  <td [attr.data-label]="'UNIT_PRICE' | translate">
                    <lib-price
                      [price]="orderedProduct.netPrice.value"
                      [currency]="orderedProduct.netPrice.currencyCode "
                    ></lib-price>
                  </td>
  
                  <td [attr.data-label]="'DISCOUNT' | translate"></td>
  
                  <td [attr.data-label]="'TOTAL' | translate">
                    <lib-price
                      [price]="orderedProduct.totals.price.value"
                      [currency]="orderedProduct.totals.price.currencyCode "
                    ></lib-price>
                  </td>
                </tr>
              }
            </tbody>
          </table>
        </div>
      } @else {
        <div class="col-12 text-center"><i class="fa fa-spinner fa-spin fa-7x"></i></div>
      }
    </div>
  </div>
}@else{
  <div class="col-12 text-center"><i class="fa fa-spinner fa-spin fa-7x"></i></div>
}
