<div class="gung-app-navbar">
  <div class="logo mr-3">
    <div class="nav-brand-wrapper d-flex justify-content-center">
      <a class="navbar-brand" routerLink="/home">
        <img src="{{ logoUrl }}" width="auto" height="36px" alt="Logotype" />
      </a>
    </div>
  </div>
  <div class="shop-menu d-flex align-items-center">
    @for (menu of shopMenu; track menu) {
      <div class="s-menu flex-row d-flex align-items-center">
        <div class="menu-name" translate>
          <a [routerLink]="[menu.link]" routerLinkActive="active">{{ menu.heading }}</a>
        </div>
        @if (menu.links && menu.links.length > 0) {
          <i class="fa-solid fa-chevron-down"></i>
          <div class="sub-menu" [class.d-none]="isClosingShowMenu">
            <lib-gung-expand-menu
              (closeExpandMenu)="closeExpandMenu($event)"
              [menu]="menu.links"
              [levels]="gungNavbar.assortmentMenuLevel"
              top="40"
              left="0"
            />
          </div>
        }
      </div>
    }
  </div>
  <div class="navbar-right d-flex align-items-center">
    <div class="global-search">
      <lib-global-search [roundedCorners]="true"></lib-global-search>
    </div>
    <div class="customer ml-2">{{ customerString }}</div>
    @if (!isAnonymous && authenticated) {
      <div class="icons d-flex align-items-center">
        <div class="mobile-search icon-action">
          <i class="fa-solid fa-magnifying-glass" (click)="isGlobalSearchOpen = !isGlobalSearchOpen"></i>
          @if (isGlobalSearchOpen) {
            <div class="mobile-search-container">
              <lib-global-search [roundedCorners]="true"></lib-global-search>
            </div>
          }
        </div>
        <div class="icon-action" *ngIf="isSales">
          <a [routerLink]="['/customers']"><i class="fa-solid fa-users" title="{{ 'CUSTOMERS' | translate }}"></i></a>
        </div>
        <div class="icon-action">
          <a [routerLink]="['/favourites']"><i class="fa-solid fa-heart" title="{{ 'FAVOURITES' | translate }}"></i></a>
        </div>
        <div class="icon-action">
          <a [routerLink]="['/checkout']">
            <i class="fa-solid fa-cart-shopping"></i>
            @if (!!cartContents) {
              <span class="badge badge-primary rounded-circle cart-icon-badge">{{ cartContents.rows.length }}</span>
            }
          </a>
        </div>
        <div class="icon-action btn-open-menu" (click)="toggleMainMenu()"><i class="fa-solid fa-bars"></i></div>
      </div>
    }
  </div>
</div>
<div class="main-menu" [class.menu-open]="activeMainMenu">
  <lib-gung-main-menu (menuClickEvent)="menuClickEvent($event)"></lib-gung-main-menu>
</div>
<div class="content-margin"></div>
