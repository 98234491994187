import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Type,
  QueryList,
  ViewChildren,
  OnChanges
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable, Subscription, fromEventPattern, takeUntil, Subject, first, switchMap, forkJoin, of, take } from 'rxjs';
import { CustomHostDirective } from '../custom-host/custom-host.directive';
import { FilterListService } from '../services/filter-list.service';
import {
  ConfigService,
  ListItemRendererComponent,
  ListLayout,
  ListLayoutComponent,
  RenderFilter,
  FilterSelectionAction,
  PimTemplateProperties,
  ListSortOption,
  RenderFilterSubValueList,
  SubRenderFilter,
  SubFilterSelectionAction,
  Pagination
} from '../types';
import { SelectionAction } from './../types';
import { AbstractSelectionService } from '../services/selections/abstract-selection-service';
import { GungSlider } from '../slider/slider.component';
import { FilterListRoutingService } from '../services/filter-list-routing.service';
import { FilterListLocationConfigService } from '../services/filter-list-location-config.service';
import { GungListRouterEventsService } from '../services/gung-list-router-events.service';

const FILTER_PARAM_SPLIT_STRING1 = '__|__'; // BETWEEN FILTERS
const FILTER_PARAM_SPLIT_STRING2 = '__:__'; // BETWEEN FILTER NAME AND SELECTIONS
const FILTER_PARAM_SPLIT_STRING3 = '_____'; // BETWEEN FILTER SELECTIONS

const filterParamStrToObj = (paramMapStr: string | null): { [filterName: string]: string[] } => {
  if (!paramMapStr || paramMapStr === '') {
    return {};
  }
  const splittedByFilters = paramMapStr.split(FILTER_PARAM_SPLIT_STRING1);
  const selectedOptionsByFilter: { [filterName: string]: string[] } = splittedByFilters
    .map(filterStr => filterStr.split(FILTER_PARAM_SPLIT_STRING2))
    .filter(f => {
      const valid = f.length === 2;
      if (!valid) {
        console.error('Filter query param is not valid', f);
      }
      return valid;
    })
    .map(f => ({
      [f[0]]: f[1].split(FILTER_PARAM_SPLIT_STRING3)
    }))
    .reduce((acc, curr) => ({ ...acc, ...curr }), {});
  return selectedOptionsByFilter;
};

const filterParamObjToStr = (paramMapObj: { [filterName: string]: string[] }): string =>
  Object.keys(paramMapObj)
    .map(
      key =>
        `${key}${FILTER_PARAM_SPLIT_STRING2}${paramMapObj[key]
          .sort()
          .reduce((acc, opt) => (acc === '' ? opt : `${acc}${FILTER_PARAM_SPLIT_STRING3}${opt}`), '')}`
    )
    .reduce((acc, filter) => (acc === '' ? filter : `${acc}${FILTER_PARAM_SPLIT_STRING1}${filter}`), '');

const processAction = (currentObj: { [filterName: string]: string[] }, action: SubFilterSelectionAction): void => {
  const { filterName, selected, optionId, optionIdRange, subOptionId, subFilterName } = action;
  if (selected) {
    // init the filter key if not existing
    if (!currentObj[filterName]) {
      currentObj[filterName] = [];
    }
    // add all range
    if (optionIdRange && optionIdRange.length > 0) {
      currentObj[filterName] = optionIdRange;
    } else {
      // add the option if non-existent
      if (!currentObj[filterName].includes(optionId)) {
        currentObj[filterName].push(optionId);
      }
    }
  } else {
    // To clear just one filter
    if (optionId === 'CLEAR_FILTER') {
      delete currentObj[filterName];
      return;
    }
    // To clear sub filter of the selected filter
    if (subFilterName && currentObj[subFilterName]) {
      currentObj[subFilterName] = currentObj[subFilterName].filter(subOption => !subOption.startsWith(optionId + '_'));
      if (currentObj[subFilterName].length === 0) {
        delete currentObj[subFilterName];
      }
    }
    //
    if (optionIdRange && optionIdRange.length > 0) {
      currentObj[filterName] = [];
    } else {
      // remove the option if it exists
      if (currentObj[filterName] && currentObj[filterName].includes(optionId)) {
        // it exists, remove it
        currentObj[filterName] = currentObj[filterName].filter(option => option !== optionId);
      }
    }
    // if the filter is empty, remove it
    if (!!currentObj[filterName] && currentObj[filterName].length === 0) {
      delete currentObj[filterName];
    }
  }
};

const processSubAction = (currentObj: { [filterName: string]: string[] }, action: SubFilterSelectionAction): void => {
  const { filterName, selected, optionId, optionIdRange, subOptionId } = action;
  processAction(currentObj, { ...action, filterName: filterName, optionId: subOptionId, subOptionId: undefined });
}

const getNewFilterStr = (currentFilterStr: string | null, actions: SubFilterSelectionAction[]): string => {
  // parse out the filter string
  const currentObj = filterParamStrToObj(currentFilterStr);
  // process the actions
  actions.forEach(action => !action.subOptionId ? processAction(currentObj, action) : processSubAction(currentObj, action));
  // convert back to query string
  return filterParamObjToStr(currentObj);
};

@Component({
  selector: 'lib-filter-list[configService]',
  templateUrl: './filter-list.component.html',
  styleUrls: ['./filter-list.component.css']
})
export class FilterListComponent<T> implements AfterViewInit, OnInit, OnChanges, OnDestroy {
  @Input()
  protected configService: ConfigService<T>;

  @Input()
  topFilter?: boolean;

  @Input()
  paginationList?: boolean;

  pagination: Pagination = { totalPages: 0, currentPage: 1 };

  @Input()
  selectedFilterTop?: boolean;

  @Input()
  keepFilterOpen?: boolean;

  @ViewChildren(CustomHostDirective)
  protected viewChild: QueryList<CustomHostDirective>;

  @Output()
  loaded = new EventEmitter<boolean>();

  itemsPerRow: number;
  navbarHeight: number;
  // stores the layout rendered
  protected itemRendererComponent: Type<ListItemRendererComponent<T | T[]>>;
  public layouts: ListLayout<T>[];
  public defaultLayout: number;
  public currentLayoutIdx: number;

  public searches: Record<string, string> = {};
  public renderFilters: RenderFilter[];
  public renderFiltersForSelected: RenderFilter[];

  protected baseFilterClasses: { [className: string]: boolean } = {
    open: false
  };

  // This flag is needed when for TopFilters
  // The HTML is slightly different when in smaller or larger screens
  public isSmallScreen = false;

  public shouldDisplayOverlay: boolean;
  public batchSizes: number[];
  public batchSize: number;
  public limit: number;
  public selectionActions: SelectionAction<T>[];
  public selectionActionsButtonTitle = 'DOWNLOADS';

  public selectionMarkingActions: SelectionAction<T>[];

  public items: T[];
  public filteredItems: T[];
  public searchDisabled: boolean;
  public showHeaderBanner: boolean = true;

  protected getItemsSubscription: Subscription;
  protected getMediaQuerySubscription: Subscription;
  protected queryParamsSubscription: Subject<void> = new Subject<void>();

  public renderItemsSubject = new BehaviorSubject<T[]>([]);
  public renderItems: Observable<T[]> = this.renderItemsSubject.asObservable();

  private loadingVar = true;

  containerStyles: { [styleName: string]: any } = {};

  set loading(value: boolean) {
    this.loadingVar = value;
    this.loaded.emit(!value);
  }

  get loading() {
    return this.loadingVar;
  }

  protected dynamicColumns: PimTemplateProperties[];
  protected pimOptions: { [s: string]: any };

  public flowHtml: string;
  public flowSlider: GungSlider[];
  public backgroundImage: string;

  public sortOptions: ListSortOption<T>[];
  public bootstrapCols: number[];
  defaultSortIdx: T[];

  private itemsCopy: T[];

  public searchAboveFilters = false;

  protected unsubscribeRouterEvents = new Subject<void>();
  protected currenUrl: string;
  protected scrollToPosition: boolean = true;
  protected addQueryParameterLimitToUrl: boolean = false;

  maxScreenWidthValue = '991';

  public showFilterTitle: 'minimal' | 'group';

  public selectedSortOption: string = undefined;

  constructor(
    public filterListRoutingService: FilterListRoutingService,
    public route: ActivatedRoute,
    public changeDetectorRef: ChangeDetectorRef,
    protected filterListService: FilterListService<T>,
    protected gungListRouterEventsService: GungListRouterEventsService,
    protected filterListLocationConfigService: FilterListLocationConfigService,
    protected router: Router,
    @Optional() protected selectionService?: AbstractSelectionService<T>
  ) {
    this.navbarHeight =
      document.getElementsByClassName('navbar').length > 0 &&
      (document.getElementsByClassName('navbar')[0] as any).offsetHeight;
  }

  adjustBottomMargin(margin: number) {
    this.containerStyles['margin-bottom'] = margin + 10 + 'px';
  }

  ngOnInit(): void {
    this.currenUrl = this.router.url;
    this.gungListRouterEventsService.getRouterNavigationStart().pipe(takeUntil(this.unsubscribeRouterEvents)).subscribe(nav => {
      if (nav && nav.navigationTrigger !== 'popstate' && nav.url !== this.currenUrl) {
        const urlToUse: string = this.currenUrl?.split('?')?.[0] || '';
        // Debug Log //
        //console.log(`Save: ${urlToUse}, X: ${window.scrollX}, Y: ${window.scrollY}, Limit: ${this.limit}, Layout: ${this.currentLayoutIdx || this.defaultLayout}`);

        this.filterListLocationConfigService.setSavedLocation(urlToUse, window.scrollX, window.scrollY, this.limit, this.currentLayoutIdx || this.defaultLayout);
      }
    });

    this.filterListRoutingService.createSubscription();
    const routeSnapshot = this.route.snapshot;
    if (!!this.configService.getCurrentFlow) {
      this.configService.getCurrentFlow().subscribe(flow => {
        this.loading = true;
        this.flowHtml = flow && flow.extra.html;
        this.flowSlider = flow && flow.extra.slider;
        this.backgroundImage = flow && flow.extra.backgroundImage;
      });
    }
    this.getItemsSubscription = this.configService.getItems(routeSnapshot).subscribe(ps => {
      this.items = ps;
      // This only needs to be a shallow copy.
      // Doing it the other way will copy all data to a string and back
      // The product list breaks totally at 5000 k products
      this.defaultSortIdx = ps.slice(); // JSON.parse(JSON.stringify(ps));
      this.loading = false;
      this.shouldDisplayOverlay = false;
      this.searchDisabled = !!this.configService.searchDisabled;
      if (this.configService.showHeaderBanner === false) {
        this.showHeaderBanner = false;
      }
      this.renderFilters = this.filterListService.getInitializedFilters(this.items, this.configService, routeSnapshot);
      this.batchSizes = this.configService.getBatchSizes() ? this.configService.getBatchSizes(routeSnapshot) : [12];
      this.layouts = this.configService.getLayouts(routeSnapshot);
      this.defaultLayout =
        typeof this.configService.setPreviousLayout === 'function' ? this.configService.getPreviousLayout() : 0;
      this.pimOptions = this.configService.getPimOptions ? this.configService.getPimOptions(routeSnapshot) : undefined;
      // Sortoptions for the grid/table items
      this.sortOptions =
        (this.configService.getSortOptions && this.configService.getSortOptions(routeSnapshot).map(o => { return { ...o, label: o.getLabel() }; })) || undefined;
      this.bootstrapCols = this.configService.getBootstrapCols
        ? this.configService.getBootstrapCols(routeSnapshot)
        : undefined;
      this.dynamicColumns = this.configService.getDynamicColumns
        ? this.configService.getDynamicColumns(routeSnapshot)
        : [];
      if (!(this.dynamicColumns && this.dynamicColumns.find(c => c.sort))) {
        // If column sorted preserve search term
        this.searches = {};
      }
      if (this.configService.getSelectionActions) {
        this.configService.getSelectionActions().subscribe(selectionActions => {
          if (selectionActions && selectionActions.length > 0 && this.selectionService) {
            this.selectionActions = selectionActions;
            this.selectionService.setSelectionsEnabled(true);
            this.renderNewLayout(); // Display selection button
          }
        });
        if (this.configService.getSelectionActionsButtonTitle) {
          this.selectionActionsButtonTitle = this.configService.getSelectionActionsButtonTitle();
        }
      }
      if (this.configService.getSelectionMarkingActions) {
        this.configService.getSelectionMarkingActions().subscribe(actions => {
          if (actions && actions.length > 0 && this.selectionService) {
            this.selectionMarkingActions = actions;
          }
        });
      }

      if (!!this.configService.getAddQueryParameterLimitToUrl) {
        this.addQueryParameterLimitToUrl = this.configService.getAddQueryParameterLimitToUrl();
      } else {
        this.addQueryParameterLimitToUrl = true;
      }

      // in case limit is a queryParm in url then get value from there
      const qpMap = this.filterListRoutingService.queryParamsSnapshot();
      if (!!qpMap && qpMap.limit) {
        this.limit = qpMap.limit;
      }
      this.limit = this.limit || (!!this.configService.getLimit ? this.configService.getLimit() : this.batchSizes[0]);
      this.batchSize = this.batchSize || this.batchSizes[0];
      this.filterListRoutingService
        .getQueryParams(this.configService)
        .pipe(takeUntil(this.queryParamsSubscription))
        .subscribe(qp => {
          this.readQueryParams(qp);
          //this.filterListRoutingService.setQueryParams(qp); This solution breaks query params loading on popstate navigation
        });

      this.showFilterTitle = this.configService.showFilterTitle;

      // in case the item component layout rendered is of a type which does not exists in new layouts (in case in the subcription the layouts are updated)
      // then render new layout
      // this situation can happen for example when the getLayouts dependes on the assortment (some assortment products showAsList, others not)
      const layoutListItemComponents = this.layouts.map(layout => layout.getListItemComponent());
      if (
        (!!this.itemRendererComponent && !layoutListItemComponents.includes(this.itemRendererComponent)) ||
        this.defaultLayout
      ) {
        this.renderNewLayout();
      }
      if (!!this.configService.paginationList) {
        this.initializePaginationList();
      }
    });

    // Moved outside item subscribe to fix list reseting queryparams when items are updated
    this.route.queryParams.pipe(takeUntil(this.queryParamsSubscription)).subscribe(qpm => {
      // FIX when the filter-list-routing.service don't trigger the queryParams
      this.filterListRoutingService.setQueryParams(qpm);
    });
  }

  ngOnChanges(): void {
    this.initializeTopFilter();
    this.inicializeItemsPerRow();
    this.checkScreenChanges();
  }

  checkScreenChanges() {
    // After Initializing top filter
    // If Top Filter and not subcribed yet the media query for the screen width
    // then subcribe it
    if (this.topFilter) {
      const mediaQuery = matchMedia(`screen and (max-width: ${this.maxScreenWidthValue}px)`);
      this.isSmallScreen = mediaQuery.matches;

      if (!this.getMediaQuerySubscription) {
        const mediaObserver = fromEventPattern<MediaQueryListEvent>(
          handler => mediaQuery.addEventListener('change', handler),
          handler => mediaQuery.removeEventListener('change', handler)
        );
        this.getMediaQuerySubscription = mediaObserver
          .pipe()
          .subscribe(result => {
            this.isSmallScreen = result.matches;
            if (!this.isSmallScreen) {
              this.baseFilterClasses.open = false;
              this.shouldDisplayOverlay = false;
            }
          });
      }
    }
  }

  initializeTopFilter(): void {
    // Inputted value has highest priority.
    if (this.topFilter !== undefined) {
      return;
    } else if (this.configService.topFilter !== undefined) {
      this.topFilter = this.configService.topFilter;
    } else {
      this.topFilter = false;
    }

    if (this.selectedFilterTop !== undefined) {
      return;
    } else if (this.configService.selectedFilterTop !== undefined) {
      this.selectedFilterTop = this.configService.selectedFilterTop;
    } else {
      this.selectedFilterTop = false;
    }

    this.searchAboveFilters = this.configService.searchAboveFilters;
  }

  inicializeItemsPerRow(): void {
    if (this.configService.itemsPerRow) {
      this.itemsPerRow = this.configService.itemsPerRow;
    } else {
      if (this.configService.getBootstrapCols) {
        this.itemsPerRow = this.configService.getBootstrapCols()[0];
      }
    }
  }

  initializePaginationList(): void {
    this.paginationList = this.configService.paginationList;
    this.pagination.totalPages = Math.ceil(this.filteredItems.length / this.batchSize);
    this.pagination.currentPage = this.pagination.currentPage || 1;
  }

  readQueryParams(queryParams: Record<string, any>) {
    const filters = queryParams.filters;
    this.readFilterQueries(filters);
    const searchTerms = queryParams.search;
    this.readSearchQueries(searchTerms);
    this.limit = Number(queryParams.limit) ? Number(queryParams.limit) : this.limit; // Ensure limit is set when it comes in queryparams
    this.pagination.currentPage = Number(queryParams.page) ? Number(queryParams.page) : this.pagination.currentPage; // Ensure limit is set when it comes in queryparams
    this.selectedSortOption = queryParams.sort || undefined;
    this.filter();
  }

  readSearchQueries(searchParamsStr: string | null) {
    const parsedQuery = filterParamStrToObj(searchParamsStr);

    if (!this.searches || Object.keys(this.searches).length === 0) {
      this.searches = Object.fromEntries(Object.entries(parsedQuery).map(e => [e[0], e[1][0]]));
    }
  }

  readFilterQueries(filterParamsStr: string | null) {
    const selectedOptionsByFilter = filterParamStrToObj(filterParamsStr);
    // first deselect all filters
    this.renderFilters.forEach(f => f.valueList.forEach(v => {
      v.selected = false;
      if (f.type === 'SubSimpleConfigBaseFilter') {
        (v as RenderFilterSubValueList).subValueList.forEach(v => (v.selected = false));
      }
    }));
    this.renderFilters
      // all filters that exists
      .filter(rf => !!selectedOptionsByFilter[rf.name]
        || (rf.type === 'SubSimpleConfigBaseFilter' && !!selectedOptionsByFilter[(rf as SubRenderFilter).subName])
      ).forEach(rf =>
        rf.valueList
          // all values that are selected
          .filter(v => {
            const selectedValues = selectedOptionsByFilter[rf.name];
            if (rf.type === 'SubSimpleConfigBaseFilter' && !!selectedOptionsByFilter[(rf as SubRenderFilter).subName]) {
              // Check if sub value exists in the sub filter
              const selectedSubValues = selectedOptionsByFilter[(rf as SubRenderFilter).subName];
              const subValuesFilters = (v as RenderFilterSubValueList).subValueList
                .filter(subValue => selectedSubValues.includes(subValue.valueId))
                .map(subValue => subValue.selected = true);
              if (subValuesFilters.length > 0) {
                return selectedValues?.includes(v.valueId);
              }
              // return selectedValues?.includes(v.valueId) && subValuesFilters.length > 0;
            }
            if (rf.type !== 'RangeConfigBaseFilter' && rf.type !== 'dateRangeFilter') {
              return selectedValues.includes(v.valueId);
            }
            // Range Filter - Get all value inside the range (min/max)
            const selectedValuesSorted = selectedValues.map(i => parseFloat(i)).sort((a, b) => a - b);
            const min = selectedValuesSorted[0];
            const max = selectedValuesSorted[selectedValuesSorted.length - 1];

            rf.maxValue = max;
            rf.minValue = min;
            const valueId = parseFloat(v.valueId);
            return min <= valueId && valueId <= max;
          })
          .forEach(v => (v.selected = true))
      );
    if (!!this.keepFilterOpen && !this.topFilter) {
      // deep copy to detect changes in filters
      this.renderFilters = JSON.parse(JSON.stringify(this.renderFilters));
    }
  }

  ngAfterViewInit(): void {
    const layouts = this.configService.getLayouts();
    this.currentLayoutIdx = this.defaultLayout || 0;
    const toRender = layouts[this.currentLayoutIdx];
    this.inicializeItemsPerRow();
    this.dynamicColumns = this.configService.getDynamicColumns ? this.configService.getDynamicColumns() : [];

    // This set timeout is important and required to fix an issue when changing between lists on the same page
    // such as on the customer page. Without this it would set the other lists search the first time it loaded (only in
    // the searchbar, but not actually doing the filter). This will make the rendering done after so that it is not seen.
    setTimeout(() => {
      this.renderLayout(toRender.getListLayoutComponent(), toRender.getListItemComponent());
    }, 0);
  }

  ngOnDestroy(): void {
    if (!!this.getItemsSubscription) {
      this.getItemsSubscription.unsubscribe();
    }

    if (!!this.getMediaQuerySubscription) {
      this.getMediaQuerySubscription.unsubscribe();
    }

    if (!!this.queryParamsSubscription) {
      this.filterListRoutingService.unsubscribeToRoute();
      this.queryParamsSubscription.next();
      this.queryParamsSubscription.unsubscribe();
    }

    this.unsubscribeRouterEvents.next();
    this.unsubscribeRouterEvents.complete();
  }

  renderNewLayout() {
    const layouts = this.configService.getLayouts();
    this.currentLayoutIdx = this.defaultLayout || 0;
    const toRender = layouts[this.currentLayoutIdx];
    this.renderLayout(toRender.getListLayoutComponent(), toRender.getListItemComponent());
  }

  renderNewFilters() {
    this.renderFilters = this.filterListService.getInitializedFilters(this.items, this.configService);
  }

  changeLayout(index: number) {
    if (typeof this.configService.setPreviousLayout === 'function') {
      this.configService.setPreviousLayout(index);
      this.defaultLayout = index;
    }
    this.currentLayoutIdx = index;
    this.renderLayout(this.layouts[index].getListLayoutComponent(), this.layouts[index].getListItemComponent());
  }

  renderLayout(
    layoutComponent: Type<ListLayoutComponent<T>>,
    itemComponent: Type<ListItemRendererComponent<T | T[]>>
  ): void {
    if (!this.viewChild || this.viewChild.length === 0) {
      return;
    }

    this.itemRendererComponent = itemComponent;
    this.changeDetectorRef.detectChanges();
    const containerRef = this.viewChild.last.viewContainerRef;
    containerRef.clear();
    const componentRef = containerRef.createComponent(layoutComponent);
    const typedComponent = componentRef.instance as ListLayoutComponent<T>;

    typedComponent.listItemRenderer = itemComponent;
    typedComponent.renderItems = this.renderItems;
    typedComponent.pimOptions = this.pimOptions;
    typedComponent.itemsPerRow = this.itemsPerRow ? 12 / this.itemsPerRow : 3;
    typedComponent.dynamicColumns = this.dynamicColumns;
    typedComponent.parentClassCss = this.configService.getParentClass && this.configService.getParentClass();
    typedComponent.childColClassCss = this.configService.getChildColClassCss && this.configService.getChildColClassCss(typedComponent.parentClassCss);
    typedComponent.renderFinished = new EventEmitter<void>();
    typedComponent.renderFinished.pipe(takeUntil(this.unsubscribeRouterEvents)).subscribe(() => this.scrollFilterList());

    // draw search boxess
    const searchQueryParams = this.filterListRoutingService.queryParamsSnapshot().search;
    if (Object.keys(this.searches).length === 0 && searchQueryParams) {
      this.readSearchQueries(searchQueryParams);
    }
    const searchFieldContainerRef = this.viewChild.find(vc => vc.name === 'searchFields')?.viewContainerRef;
    if (searchFieldContainerRef) {
      searchFieldContainerRef.clear();
      for (const searchField of this.configService.getSearchFields?.() || [
        this.filterListService.getDefaultSearchField(this.configService)
      ]) {
        const searchCompRef = searchFieldContainerRef.createComponent(searchField.getComponent());
        searchCompRef.instance.searchUpdated.subscribe(evt => this.setSearch(evt, searchField.getKey()));
        searchCompRef.instance.placeholder = searchField.getPlaceHolder();
        searchCompRef.instance.initSearchTerm = this.searches[searchField.getKey()];
        searchCompRef.instance.classes =
          searchField.componentClass?.() ||
          this.filterListService.getDefaultSearchField(this.configService).componentClass?.();
      }
    }

    this.changeDetectorRef.detectChanges();
    if (this.items) {
      this.updateRenderItems();
    }
  }

  filter(): void {
    this.filteredItems = this.filterListService.getFilteredList(
      this.items,
      this.searches,
      this.configService,
      this.renderFilters
    );
    if (this.selectedSortOption) {
      const selectedOption = this.sortOptions.find(o => o.getLabel() === this.selectedSortOption);
      if (selectedOption && selectedOption.getComparator) {
        if (selectedOption.getComparator()) {
          this.filteredItems = this.filteredItems.sort(selectedOption.getComparator());
        } else {
          this.items = this.defaultSortIdx;
        }
      }
    }
    this.setFilteredItems(this.filteredItems);
    this.updateRenderItems();
    if (this.renderFilters) {
      const copiedFilters = JSON.parse(JSON.stringify(this.renderFilters));
      this.renderFiltersForSelected = copiedFilters;
    }
  }

  updateRenderItems(): void {
    this.renderItemsSubject.next(this.getSlicedItems());
  }

  protected getSlicedItems(): T[] {
    if (!this.filteredItems) {
      return [];
    }

    if (this.limit < 0) {
      return this.filteredItems;
    }
    const sliceSize = !!this.paginationList ? Math.min(this.pagination.currentPage * this.batchSize, this.filteredItems.length) : Math.min(this.limit, this.filteredItems.length);

    // use slice here to make a shallow copy of the elements required
    const slicedItems = !!this.paginationList ? this.filteredItems.slice((this.pagination.currentPage - 1) * this.batchSize, sliceSize) : this.filteredItems.slice(0, sliceSize);
    // create a deep copy of the slice

    return JSON.parse(JSON.stringify(slicedItems));
  }

  setFilteredItems(filteredItems: T[]): void {
    this.filteredItems = filteredItems;
    if (!!this.configService.paginationList) {
      this.pagination.totalPages = Math.ceil(this.filteredItems.length / this.batchSize);
    }
  }

  clearRangeFilter(actions) {
    for (const action of actions) {
      if (!action.selected) {
        const index = this.renderFilters.findIndex(f => f.name === action.filterName);
        if (index > -1 && this.renderFilters[index].type === 'dateRangeFilter') {
          this.renderFilters[index].maxValue = null;
          this.renderFilters[index].minValue = null;
        }
      }
    }
  }

  setMultipleSelections(actions: SubFilterSelectionAction[]): void {
    this.clearRangeFilter(actions);
    const urlParams = this.keepFilterOpen
      ? new URLSearchParams(window.location.search)
      : this.filterListRoutingService.queryParamsSnapshot();
    /* const filterStr: string | null = !!this.keepFilterOpen ? urlParams.get('filters') : urlParams.filters; */
    const filterStr: string | null = !!this.keepFilterOpen
      ? urlParams.get('filters')
      : typeof urlParams === 'object' && 'filters' in urlParams
        ? urlParams.filters
        : null;
    const newFilterStr = getNewFilterStr(filterStr, actions);
    this.clearPagination();
    if (!newFilterStr || newFilterStr === '') {
      // clear the filter param
      if (!!this.keepFilterOpen) {
        this.navigateWithoutReload({ filters: null });
        this.readFilterQueries(newFilterStr);
        this.filter();
        return;
      }
      this.filterListRoutingService.setQueryParams({ filters: null });
      return;
    }
    if (!!this.keepFilterOpen) {
      this.navigateWithoutReload({ filters: newFilterStr });
      this.readFilterQueries(newFilterStr);
      this.filter();
      return;
    }
    this.filterListRoutingService.setQueryParams({
      filters: newFilterStr
    });
  }

  setSelection(filterName: string, optionId: string, selected: boolean, optionIdRange?: string[], subFilterName?: string): void {
    if (!!subFilterName) {
      // Force clear sub filters
      this.setMultipleSelections([
        {
          filterName,
          subFilterName, // Send the name of the sub filter to remove children
          optionId,
          selected,
          optionIdRange,
        }
      ]);
      return;
    }
    this.setMultipleSelections([
      {
        filterName,
        optionId,
        selected,
        optionIdRange
      }
    ]);
  }

  setSubSelection(filterName: string, { option, subOption }: { option: string, subOption: string }, selected: boolean): void {
    this.setMultipleSelections([
      {
        filterName,
        optionId: option,
        selected,
        subOptionId: subOption
      }
    ]);
  }

  clearSelection(): void {
    this.clearPagination();
    if (!!this.keepFilterOpen) {
      this.navigateWithoutReload({ filters: null });
      this.readFilterQueries('');
      this.filter();
      return;
    }

    //Clear dateRangeFilter fitlers
    for (const filter of this.renderFilters) {
      if ((filter.type === 'dateRangeFilter')) {
        filter.maxValue = null;
        filter.minValue = null;
      }
    }

    this.filterListRoutingService.setQueryParams({
      filters: null
    });
  }

  setBatchSize(desiredSize: number) {
    const oldLimit = this.limit;

    this.limit = desiredSize;
    this.batchSize = desiredSize;
    if (!!this.configService.paginationList) {
      this.initializePaginationList();
    }
    this.updateRenderItems();

    if (this.addQueryParameterLimitToUrl) {
      this.addOrSetQueryParameterLimit();
    }
  }

  setSearch(searchString: string, searchKey?: string) {
    const parsedSearchKey = searchKey || this.filterListService.getDefaultSearchField(this.configService).getKey();
    if (!searchString) {
      delete this.searches[parsedSearchKey];
    } else {
      this.searches[parsedSearchKey] = searchString;
    }

    const mapped = Object.fromEntries(
      Object.entries(this.searches)
        .filter(e => e[1])
        .map(e => [e[0], [e[1]]])
    );
    const query = filterParamObjToStr(mapped);
    if (Object.keys(this.searches).length > 0) {
      this.navigateWithoutReload({ search: query });
    } else {
      this.navigateWithoutReload({ search: null });
    }
    this.clearPagination();
    this.filter();
  }

  navigateWithoutReload(queryParams: Record<string, any>) {
    this.filterListRoutingService.setQueryParams(queryParams);
  }

  loadMore(): void {
    const oldLimit = this.limit;

    this.limit = parseInt(this.limit as any, 10) + parseInt(this.batchSize as any, 10);
    this.updateRenderItems();

    if (this.addQueryParameterLimitToUrl) {
      this.addOrSetQueryParameterLimit();
    }
  }

  loadPage(page): void {
    this.pagination.currentPage = page;
    this.updateRenderItems();

    if (this.addQueryParameterLimitToUrl) {
      this.addOrSetQueryParameterLimit();
    }

    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  toggleFilters(): void {
    this.baseFilterClasses.open = !this.baseFilterClasses.open;
    this.shouldDisplayOverlay = !this.shouldDisplayOverlay;
  }

  getGungFilterClasses(): { [className: string]: boolean } {
    const defaultClasses = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'col-lg-3': this.renderFilters && this.renderFilters.length > 0 && !this.topFilter,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'd-none': !this.renderFilters || this.renderFilters.length === 0
    };

    return {
      ...this.baseFilterClasses,
      ...defaultClasses
    };
  }

  getLoadMoreCss(): string {
    if (!!this.configService.getLoadMoreCss) {
      return this.configService.getLoadMoreCss();
    } else {
      return 'btn-primary btn-block';
    }
  }

  getFilterGroupCss(): string {
    if (!!this.configService.getFilterGroupCss) {
      return this.configService.getFilterGroupCss();
    } else {
      return '';
    }
  }

  getNothingFoundTranslateTag(): string {
    if (!!this.configService.getNothingFoundTranslateTag) {
      return this.configService.getNothingFoundTranslateTag();
    } else {
      return 'NOTHING_FOUND_CONTACT_GUNG';
    }
  }

  getSearchGroupCss(): string {
    if (!!this.configService.getSearchGroupCss) {
      return this.configService.getSearchGroupCss();
    } else {
      return '';
    }
  }

  addOrSetQueryParameterLimit(): void {
    // it is just needed to add the query parameter limit to the url
    // in order to keep it in history for use when navigate back to the list
    if (!!this.paginationList) {
      this.navigateWithoutReload({
        page: this.pagination.currentPage
      });
    } else {
      this.navigateWithoutReload({
        limit: this.limit
      });
    }
    // const queryParametersKeys = Object.keys(this.route.snapshot.queryParams);

    // if (queryParametersKeys.length === 0) {
    //   this.location.replaceState(this.router.url, `limit=${this.limit}`);
    // } else {
    //   // check if contains limit
    //   if (queryParametersKeys.includes('limit')) {
    //     const existingLimit = this.route.snapshot.queryParams.limit;
    //     this.location.replaceState(this.router.url.replace(`limit=${existingLimit}`, `limit=${this.limit}`));
    //   } else {
    //     this.location.replaceState(this.router.url + `&limit=${this.limit}`);
    //   }
    // }
  }

  setSortOption(event) {
    const option = event?.srcElement?.value || this.sortOptions[0].getLabel();
    const selectedOption = this.sortOptions.find(o => o.getLabel() === option);
    if (selectedOption && selectedOption.getComparator) {
      if (selectedOption.getComparator()) {
        this.items = this.items.sort(selectedOption.getComparator());
      } else {
        this.items = this.defaultSortIdx;
      }
      this.clearPagination();
      this.filter();
      this.navigateWithoutReload({
        sort: selectedOption.getLabel()
      });
    }
  }

  onSelectedItemsToggle(selectedItemIds: string[]) {
    const idKey = 'id';
    if (selectedItemIds.length === 0) {
      this.items = this.itemsCopy;
    } else {
      if (!this.itemsCopy) {
        this.itemsCopy = JSON.parse(JSON.stringify(this.items));
      }
      this.items = this.itemsCopy.filter(item => selectedItemIds.includes(item[idKey]));
    }
    this.filter();
    this.renderNewFilters();
  }

  public trackByFn(renderFilter: RenderFilter) {
    return renderFilter.name;
  }

  setBootstrapCols(event) {
    this.renderNewLayout();
  }

  protected scrollFilterList(): void {
    if (this.filteredItems?.length > 0 && this.scrollToPosition) {
      this.gungListRouterEventsService.getRouterNavigationStart().pipe(
        first(),
        switchMap(nav => forkJoin({
          nav: of(nav),
          savedLocation: this.filterListLocationConfigService.getSavedLocation(nav?.url?.split('?')?.[0] || '').pipe(first())
        }))
      ).subscribe(({ nav, savedLocation }) => {
        if (nav?.navigationTrigger === 'popstate' && savedLocation) {
          if (this.layouts[savedLocation.layout] && this.currentLayoutIdx !== savedLocation.layout) {
            this.changeLayout(savedLocation.layout);
          }

          window.scrollTo({ top: savedLocation.scrollY, behavior: 'smooth' });
          this.scrollToPosition = false;
        }
      });
    }
  }

  clearPagination(): void {
    if (!!this.configService.paginationList && this.pagination.currentPage > 1) {
      this.pagination.currentPage = 1;
      this.addOrSetQueryParameterLimit();
    }
  }
}
