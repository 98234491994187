import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ListItemRendererComponent } from 'gung-list';
import { Invoice } from '../../models/invoice';
import { DateUtilService } from 'gung-common';

@Component({
  selector: 'lib-invoice-table',
  templateUrl: './invoice-table.component.html',
  styleUrls: ['./invoice-table.component.css']
})
export class InvoiceTableComponent extends ListItemRendererComponent<Invoice[]> implements OnInit, AfterViewInit {
  constructor(public dateUtilService: DateUtilService) {
    super();
  }

  ngOnInit() {}

  ngAfterViewInit(): void {
    this.renderFinished?.emit();
  }
}
