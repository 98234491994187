<div class="container" *ngIf="currentLang === 'fr'">
  <div class="mb-4">
    <h1>L’histoire d’HL Display</h1>
  </div>
  <div class="row">
    <div class="col-12 col-lg-8 col-xl-7">
      <div class="mb-5">
        <p>
          En 1954, Harry Lundvall, un commercial itinérant de Borlänge en Suède, s’aperçoit d’un problème : en magasin,
          les produits sont mal présentés et les étiquettes de prix sont très difficiles à trouver. Nous sommes en
          pleine évolution des épiceries vers le libre-service. Harry décide de prendre les choses en main. Armé de son
          fer à repasser et d’un enthousiasme à toute épreuve, il commence à fabriquer des porte-étiquettes et des
          présentoirs merchandising dans son sous-sol. Rapidement, son innovation prend de l’ampleur, ses produits faits
          à la main permettent de mettre en avant les produits et d’augmenter les ventes des magasins. C’est le début
          d’un beau succès.
        </p>
        <p>
          Dans les années 90, l’entreprise familiale créée par Harry devient mondiale lorsque sa petite-fille Lis et son
          mari Anders en prennent les commandes. Anders, Lis et leur équipe agrandissent l’entreprise en conquérant de
          nouveaux clients dans de nombreux pays. C’est cet esprit d’entreprenariat qui a fait d’HL Display la société
          qu’elle est aujourd’hui.
        </p>
        <p>
          Aujourd’hui, nous faisons entrer notre esprit novateur dans une nouvelle ère en célébrant des décennies
          d’innovation. Notre passion pour la distribution nous permet de nous concentrer sur l’évolution des besoins
          des acheteurs. Nous les anticipons et les adaptons en permanence pour inventer les solutions fonctionnelles et
          durables de demain. C’est de cette manière que nous pouvons inspirer et créer une meilleure expérience d’achat
          pour les consommateurs à travers le monde.
        </p>
      </div>
    </div>
    <div class="col d-none d-lg-block">
      <img class="img-fluid" alt="" src="./assets/history_image.jpg" />
    </div>
  </div>
</div>

<div class="container" *ngIf="currentLang === 'en'">
  <div class="mb-4">
    <h1>About HL Display</h1>
  </div>
  <div class="row">
    <div class="col-12 col-lg-8 col-xl-7">
      <div class="mb-5">
        <p>
          In 1954, a travelling salesman in Borlänge, Sweden saw a problem. Harry Lundvall observed that products were
          badly displayed and price labels were hard to find. During an era when groceries were evolving into
          self-service, Harry took matters into his own hands. Armed with an iron and sheer enthusiasm, he started
          manufacturing label holders and merchandising stands in his own basement. Before long, his innovation took off
          as his hand-made solutions created product exposure and increased store sales.
        </p>
        <p>
          In the 90’s, Harry’s family-owned business turned global when his granddaughter Lis and her husband Anders
          took over the company. Anders, Lis and their team grew the business by acquiring new customers and expanding
          to new locations around the world. The entrepreneurial spirit that made HL Display the leading company that it
          is today is still very much within the company culture.
        </p>
        <p>
          Today we bring our pioneering spirit into the next era by celebrating decades of innovations. Our passion for
          retail makes us focused on people’s changing needs. We always anticipate and adapt to them, to invent
          functional and sustainable solutions. That's how we can inspire, and create a better shopping experience for
          people all over the world.
        </p>
      </div>
    </div>
    <div class="col d-none d-lg-block">
      <img class="img-fluid" alt="" src="./assets/history_image.jpg" />
    </div>
  </div>
</div>

<div class="container" *ngIf="currentLang === 'no'">
  <div class="mb-4">
    <h1>Om HL Display</h1>
  </div>
  <div class="row">
    <div class="col-12 col-lg-8 col-xl-7">
      <div class="mb-5">
        <p>
          Fra et lite omreisende enmanns-orkester til en global leder i over 50 land. I 1954 oppdaget en omreisende
          selger i Borlänge, Sverige, et problem. Harry Lundvall observerte at produktene var dårlig presentert og at
          prislappene var vanskelig å finne. I en tid der matvarehandling utviklet seg mot selvbetjening, tok Harry
          skjeen i egen hånd. Utstyrt med strykejern og ren entusiasme begynte han å produsere etikettholdere og
          vareeksponeringsstativer i sin egen kjeller. På kort tid tok innovasjonen hans av etter hvert som hans
          håndlagede løsninger skapte produktsynlighet og økt butikksalg.
        </p>
        <p>
          På 90-tallet ble Harrys familieeide virksomhet global da barnebarnet Lis og hennes mann tok over selskapet.
          Anders, Lis og deres team fikk virksomheten til å vokse ved å skaffe nye kunder og etableres seg i nye
          markeder rundt om i verden. Entreprenørånden som gjorde HL Display til det ledende selskapet det er i dag,
          finnes det fremdeles veldig mye av i selskapskulturen.
        </p>
        <p>
          I dag bringer vi vår pionerånd inn i den neste tidsalderen ved å feire tiår med innovasjoner. Vår lidenskap
          for detaljhandel gjør oss fokuserte på folks endrede behov. Vi forutser og tilpasser oss alltid til endrede
          behov for å finne opp funksjonelle og bærekraftige løsninger. Slik kan vi inspirere og skape en bedre
          handleopplevelse for folk i hele verden.
        </p>
      </div>
    </div>
    <div class="col d-none d-lg-block">
      <img class="img-fluid" alt="" src="./assets/history_image.jpg" />
    </div>
  </div>
</div>

<div class="container" *ngIf="currentLang === 'nl'">
  <div class="mb-4">
    <h1>Het verhaal van HL Display</h1>
  </div>
  <div class="row">
    <div class="col-12 col-lg-8 col-xl-7">
      <div class="mb-5">
        <p>
          Van een kleine, reizende eenmansband tot een wereldleider in meer dan 70 landen, dit is het verhaal van HL Display.
        </p>
        <p>
          In 1954 zag een handelsreiziger in Borlänge, Zweden, een probleem. Harry Lundvall merkte op dat producten slecht werden uitgestald en prijsetiketten moeilijk te vinden waren. In een tijd waarin boodschappen evolueerden naar zelfbediening, nam Harry het heft in eigen handen. Gewapend met een ijzersterk en puur enthousiasme begon hij in zijn eigen kelder met het vervaardigen van etikethouders en merchandisingstands. Het duurde niet lang voordat zijn innovatie een vlucht nam toen zijn handgemaakte oplossingen productbekendheid kregen en de winkelomzet verhoogde.
        </p>
        <p>
          In de jaren 90 werd het familiebedrijf van Harry wereldwijd toen zijn kleindochter Lis en haar man Anders het bedrijf overnamen.  Anders, Lis en hun team lieten het bedrijf groeien door nieuwe klanten te verwerven en uit te breiden naar nieuwe locaties over de hele wereld. De ondernemersgeest die van HL Display het leidende bedrijf maakte dat het nu is, zit nog steeds in de bedrijfscultuur.
        </p>
        <p>
          Vandaag brengen we onze pioniersgeest naar het volgende tijdperk door decennia van innovaties te vieren. Onze passie voor retail zorgt ervoor dat we ons richten op de veranderende behoeften van mensen. We anticiperen er altijd op en passen ons eraan aan om functionele en duurzame oplossingen te bedenken. Zo kunnen we mensen over de hele wereld inspireren en een betere winkelervaring creëren.
        </p>
      </div>
    </div>
    <div class="col d-none d-lg-block">
      <img class="img-fluid" alt="" src="./assets/history_image.jpg" />
    </div>
  </div>
</div>