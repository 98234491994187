<div *ngIf="cartContents; then basketTemplate; else loadingTemplate"></div>

<ng-template #basketTemplate>
  <div class="dropdown">
    <a class="nav-link" routerLink="/checkout" routerLinkActive="active">
      <i class="fa fa-shopping-bag fa-lg"></i>
      <span class="badge badge-pill badge-primary badge-indicator">{{ cartContents.rows.length }}</span>
    </a>
    <div class="dropdown-menu">
      <div class="container">
        <a style="cursor: pointer" class="float-right" translate (click)="clearCart()">CLEAR_CART</a>
        <table class="table table-sm table-borderless">
          <tbody>
            <tr *ngFor="let row of cartContents.rows; let i = index">
              <th scope="row">
                <a routerLink="/product/{{ row.product.id }}">{{ row.product.id }}</a>
              </th>
              <td>
                {{ row.cartRow.qty }}
              </td>
              <td class="text-right">
                <div *ngIf="!dummyArticleService.isDummyRow(row.cartRow); else dummyPrice">
                  {{
                    row.price.cartRowTotalPriceInclRowDiscount.value
                      | gungCurrency
                        : currencyCode
                        : ('symbol-narrow')
                        : '1.2-2' : ('sv_SE')
                  }}
                </div>
                <ng-template #dummyPrice>
                  {{
                    (row.cartRow.extra.orp.vb_pris || 0) * row.cartRow.extra.orp.ordantal
                      | gungCurrency
                        : currencyCode
                        : ('symbol-narrow')
                        : '1.2-2' : ('sv_SE')
                  }}
                </ng-template>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="row">
          <div class="col-12 text-right">
            <div class="totals-container">
              {{
                cartContentsTotalValue
                  | gungCurrency: currencyCode:('symbol-narrow'):'1.2-2': ('sv_SE')
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="snackbar" [ngClass]="{ active: highlight }">
    <span translate>PRODUCT_ADDED_TO_CART</span>
  </div>
</ng-template>

<ng-template #loadingTemplate> Loading spinner.. </ng-template>
