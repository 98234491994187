<app-hl-display-navbar></app-hl-display-navbar>
<!-- temporary comment: https://trello.com/c/LU1UBise/544-customer-portal-all-slider-as-a-header-for-promotions-should-look-exactly-like-this-https-wwwposcentrecouk -->
<div class="container-sales" [style.height.px]="isOnCategoriesPage() ? 430 : 0" [ngClass]="{
    'logged-out': !isSignedIn(),    
  }">
  <app-hl-display-slider-promotion *ngIf="isOnCategoriesPage()"></app-hl-display-slider-promotion>
</div>

<div [ngClass]="{ 'gray-bg': grayBg() }">
  <div class="main" [ngClass]="{
      'on-category-page': isOnCategoriesPage(),
      'container-customer-signed-out': customerPortalSignedOut,
      'container': !onHomeUser,
      'container-fluid p-0': onHomeUser
    }">
    <router-outlet> </router-outlet>
  </div>
</div>
<app-footer></app-footer>