import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Activity, ActivityService, MetadataService } from 'gung-standard';
import { Observable } from 'rxjs';
import { tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HlDisplayActivityService extends ActivityService {
  constructor(protected http: HttpClient, protected metadataService: MetadataService) {
    super(http, metadataService);
  }

  createActivity(customerId: string, activity: Activity): Observable<Activity | void> {
    const url = `json/hl-display/customer/${customerId}/activities`;
    return this.http.post<Activity | void>(url, activity).pipe(
      tap(
        // Log the result or error
        data => {
          if (this.activitySubjects[customerId]) {
            this.updateActivitysSubject(customerId);
          }
        },
        error => console.log('ERROR', error)
      )
    );
  }

  updateActivity(customerId: string, activity: Activity): Observable<Activity | void> {
    const url = `json/hl-display/customer/${customerId}/activities`;
    return this.http.put<Activity | void>(url, activity).pipe(
      tap(
        // Log the result or error
        data => this.updateActivitysSubject(customerId),
        error => console.log('ERROR', error)
      )
    );
  }

  removeActivity(customerId: string, activity: Activity): Observable<boolean | void> {
    const url = `json/hl-display/customer/${customerId}/activities/${activity.id}`;
    return this.http.delete<boolean | void>(url).pipe(
      tap(
        // Log the result or error
        data => this.updateActivitysSubject(customerId),
        error => console.log('ERROR', error)
      )
    );
  }
}
