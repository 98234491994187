import { Component, OnInit } from '@angular/core';
import { StepIndicatorComponent } from 'gung-standard';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-hl-step-indicator',
  templateUrl: './hl-step-indicator.component.html',
  styleUrls: ['./hl-step-indicator.component.css']
})
export class HlStepIndicatorComponent extends StepIndicatorComponent {
  constructor(public translateService: TranslateService) {
    super();
  }
}
