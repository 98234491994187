import { Inject, Injectable, Optional } from '@angular/core';
import { PipeService, SelectedCustomerService } from 'gung-standard';

import { map, skipWhile } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class HlPipeService extends PipeService {
  constructor(protected translateService: TranslateService) {
    super();
  }

  transformCurrency(
    transformed: string | null,
    input: any,
    currencyCode?: string,
    display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean,
    digitsInfo?: string,
    locale?: string
  ): string | null {
    if (this.translateService.currentLang !== 'fr') {
      return transformed;
    }
    if (display !== '') {
      return transformed + ' HT';
    } else {
      return transformed;
    }
  }
}
