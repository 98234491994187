import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { map } from 'rxjs';
import { S3Image } from '../../models/s3';
import { AuthService } from '../../services/auth/auth.service';
import { GungCompany, GungCompanyService } from '../../services/gung-company/gung-company.service';
import { gungColorToHsl } from '../../utils/gung-utils';
import { FeatureActivatedGuardWrapper } from '../../guards/feature-activated/feature-activated.guard';

@Component({
  selector: 'lib-my-company',
  templateUrl: './my-company.component.html',
  styleUrls: ['./my-company.component.css']
})
export class MyCompanyComponent implements OnInit {
  public form: FormGroup;
  myCompany: GungCompany;

  public objectFileImage: { [s: string]: File } = {};
  public objectFileImagePreview: { [s: string]: S3Image } = {};

  selectedNavItem: string;

  primary: string;
  secondary: string;
  hover: string;
  contrasting: string;

  constructor(
    protected formBuilder: FormBuilder,
    protected companyService: GungCompanyService,
    protected authService: AuthService,
    protected http: HttpClient,
    protected featureActivatedGuardWrapper: FeatureActivatedGuardWrapper
  ) { }

  ngOnInit(): void {
    this.authService.getCurrentUser().subscribe(user => { });
    this.companyService.getGungCompany().subscribe(company => {
      this.myCompany = company;
      this.initForm();
      this.objectFileImagePreview = {
        loginBackgroundImage: this.myCompany?.loginBackgroundImage?.s3Uri && this.myCompany?.loginBackgroundImage,
        logo: this.myCompany?.logo?.s3Uri && this.myCompany?.logo,
        logoLogin: this.myCompany?.logoLogin?.s3Uri && this.myCompany?.logoLogin,
        logoOrderConfirm: this.myCompany?.logoOrderConfirm?.s3Uri && this.myCompany?.logoOrderConfirm,
        'extra.favicon': this.myCompany?.extra?.favicon
      };
    });
    this.primary = document.documentElement.style.getPropertyValue('--dynamic-colour-primary');
    this.secondary = document.documentElement.style.getPropertyValue('--dynamic-colour-secondary');
    this.hover = document.documentElement.style.getPropertyValue('--dynamic-colour-hover');
    this.contrasting = document.documentElement.style.getPropertyValue('--dynamic-colour-contrasting');
  }

  initForm() {
    this.form = this.formBuilder.group({
      name: this.formBuilder.control({ value: this.myCompany?.name || undefined, disabled: false }, [
        Validators.required
      ]),
      address1: this.formBuilder.control({ value: this.myCompany?.address1 || undefined, disabled: false }),
      address2: this.formBuilder.control({ value: this.myCompany?.address2 || undefined, disabled: false }),
      zipCode: this.formBuilder.control({ value: this.myCompany?.zipCode || undefined, disabled: false }),
      city: this.formBuilder.control({ value: this.myCompany?.city || undefined, disabled: false }),
      organizationNumber: this.formBuilder.control({
        value: this.myCompany?.organizationNumber || undefined,
        disabled: false
      }),
      email: this.formBuilder.control({ value: this.myCompany?.email || undefined, disabled: false }),
      telephone: this.formBuilder.control({ value: this.myCompany?.telephone || undefined, disabled: false }),
      webAddress: this.formBuilder.control({ value: this.myCompany?.webAddress || undefined, disabled: false }),
      // logo: this.formBuilder.group({ value: this.myCompany?.logo, disabled: true })
      logo: this.formBuilder.group({
        category: [undefined],
        description: [undefined],
        i18n: [undefined],
        index: [undefined],
        pimDimensionIds: [undefined],
        s3ETag: [undefined],
        s3Uri: [undefined],
        s3lastModifiedDate: [undefined],
        id: [undefined],
        name: [undefined],
        extra: [undefined]
      }),
      loginBackgroundImage: this.formBuilder.group({
        category: [undefined],
        description: [undefined],
        i18n: [undefined],
        index: [undefined],
        pimDimensionIds: [undefined],
        s3ETag: [undefined],
        s3Uri: [undefined],
        s3lastModifiedDate: [undefined],
        id: [undefined],
        name: [undefined],
        extra: [undefined]
      }),
      logoLogin: this.formBuilder.group({
        category: [undefined],
        description: [undefined],
        i18n: [undefined],
        index: [undefined],
        pimDimensionIds: [undefined],
        s3ETag: [undefined],
        s3Uri: [undefined],
        s3lastModifiedDate: [undefined],
        id: [undefined],
        name: [undefined],
        extra: [undefined]
      }),
      logoOrderConfirm: this.formBuilder.group({
        category: [undefined],
        description: [undefined],
        i18n: [undefined],
        index: [undefined],
        pimDimensionIds: [undefined],
        s3ETag: [undefined],
        s3Uri: [undefined],
        s3lastModifiedDate: [undefined],
        id: [undefined],
        name: [undefined],
        extra: [undefined]
      }),
      colors: this.formBuilder.group({
        primary: this.formBuilder.control({ value: this.myCompany?.colors?.primary || undefined, disabled: false }),
        secondary: this.formBuilder.control({ value: this.myCompany?.colors?.secondary || undefined, disabled: false }),
        hover: this.formBuilder.control({ value: this.myCompany?.colors?.hover || undefined, disabled: false }),
        contrasting: this.formBuilder.control({ value: this.myCompany?.colors?.contrasting || undefined, disabled: false })
      }),
      extra: this.formBuilder.group({
        customization: this.formBuilder.group({
          indexHead: this.formBuilder.control({
            value: this.myCompany?.extra?.customization?.indexHead || undefined,
            disabled: false
          })
        }),
        favicon: this.formBuilder.group({
          category: [undefined],
          description: [undefined],
          i18n: [undefined],
          index: [undefined],
          pimDimensionIds: [undefined],
          s3ETag: [undefined],
          s3Uri: [undefined],
          s3lastModifiedDate: [undefined],
          id: [undefined],
          name: [undefined],
          extra: [undefined]
        })
      })
    });

    this.form.patchValue({
      logo: {
        ...this.myCompany?.logo
      }
    });

    this.form.patchValue({
      logoLogin: {
        ...this.myCompany?.logoLogin
      }
    });

    //loginBackgroundImage
    if (this.myCompany?.loginBackgroundImage) {
      this.form.patchValue({
        loginBackgroundImage: {
          ...this.myCompany?.loginBackgroundImage
        }
      });
    }

    if (this.myCompany?.logoOrderConfirm) {
      this.form.patchValue({
        logoOrderConfirm: {
          ...this.myCompany?.logoOrderConfirm
        }
      });
    }
  }

  onSubmit(form?: FormGroup) {
    form.disable();

    if (form.invalid) {
      form.markAllAsTouched();
      form.enable();
      return;
    }

    const gungCompany: GungCompany = {
      ...(this.myCompany || {}),
      ...form.getRawValue(),
      extra: {
        ...(this.myCompany?.extra || {}),
        ...form.getRawValue().extra,
      }
    };

    if (!gungCompany.logo?.s3Uri) {
      gungCompany.logo = undefined;
    }

    if (!gungCompany.logoLogin?.s3Uri) {
      gungCompany.logoLogin = undefined;
    }

    if (gungCompany.extra?.customization?.indexHead) {
      // gungCompany.extra.customization.indexHead = encodeURIComponent(gungCompany.extra.customization.indexHead);
    }

    this.companyService.postGungCompany(gungCompany).subscribe(
      result => {
        this.myCompany = result;
        form.enable();
        this.initForm();
        window.location.reload();
      },
      error => {
        console.log('MyCompanyComponent - onSubmit: ', error);
        form.enable();
      }
    );
  }

  save() {
    if (this.objectFileImage && Object.keys(this.objectFileImage).length > 0) {
      const images: File[] = [];
      const request: { [s: string]: Observable<any> } = {};
      for (const key of Object.keys(this.objectFileImage)) {
        if (this.objectFileImage[key]) {
          images.push(this.objectFileImage[key]);
          request[key] = this.uploadImages([this.objectFileImage[key]], key);
        }
      }
      forkJoin(request).subscribe(objectFileImage => {
        for (const key of Object.keys(objectFileImage)) {
          this.form.patchValue({
            key: objectFileImage[key]
          });
          this.form.get(key).patchValue(objectFileImage[key]);
        }
        this.onSubmit(this.form);
      });
      return;
    }
    this.onSubmit(this.form);
  }

  onDragOver(event /* : DragEvent */) {
    // console.log(`Drag Over`, event);
    event.target.style.opacity = 0.5;
    event.preventDefault();
    event.stopPropagation();
  }

  onDragLeave(event /* : DragEvent */) {
    // console.log('Drag Leave', event);
    event.target.style.opacity = 1;
    event.preventDefault();
    event.stopPropagation();
  }

  onDrop(files: File[], imgTag?: string) {
    // console.log('Drop', event);
    // event.preventDefault();
    // event.stopPropagation();
    // event.target.style.opacity = 1;
    // const files: File[] = event.target.files || event.dataTransfer.files;
    if (files.length > 0) {
      if (imgTag) {
        this.objectFileImage[imgTag] = files[0];
      }
      this.previewImage(files, imgTag);
      // event.target.value = '';
    }
  }

  previewImage(files: File[], imgTag?: string) {
    if (!files || files.length === 0) {
      return;
    }
    for (const file of files) {
      const mimeType = file.type;
      if (mimeType.match(/image\/*/) == null) {
        console.log('Only images are supported.');
        return;
      }

      const reader = new FileReader();
      const imagePath = file;
      reader.readAsDataURL(file);
      reader.onload = _event => {
        const fileDA: S3Image = {
          category: 'Product Image',
          description: null,
          i18n: null,
          index: null,
          pimDimensionIds: null,
          s3ETag: null,
          s3Uri: reader.result as string,
          s3lastModifiedDate: new Date(file.lastModified),
          id: null,
          name: file.name,
          extra: null
        };
        if (imgTag) {
          this.objectFileImagePreview[imgTag] = fileDA;
        }
      };
    }
  }

  uploadImages(files: File[], imgTag?: string): Observable<any> {
    const imageObject = {
      index: 0,
      description: (imgTag || 'my company logo - ') + new Date().getTime(),
      category: imgTag || 'my company logo',
      s3Uri: 'images/no-image.jpg'
    };
    const formData = new FormData();
    formData.append('file', files[0]);
    formData.append('productImage', JSON.stringify(imageObject));
    return this.http.post<any>(`import/s3-image`, formData).pipe(
      map(image => {
        return image;
      })
    );
  }

  private uploadImagesLogo(images) {
    const timeStamp = new Date();
    if (!images || !images.length) {
      return of([]);
    }

    const promises = [];
    images.forEach(img => {
      if (!!img.file.$error) {
        return;
      }

      const formData = new FormData();
      formData.append('file', img.file);
      promises.push(this.http.post('json/logo-applicator/image', formData));
    });

    return forkJoin(promises).pipe(
      map(responseImages => {
        return responseImages.map(({ id, s3Uri }) => {
          return {
            id,
            uri: s3Uri
          };
        });
      })
    );
  }

  public setupCustomization() {
    const primary = this.form.get('colors').get('primary').value;
    if (!!primary) {
      let hsl = gungColorToHsl(primary);
      document.documentElement.style.setProperty('--dynamic-colour-primary', primary);
      document.documentElement.style.setProperty('--dynamic-colour-primary-h', hsl[0]);
      document.documentElement.style.setProperty('--dynamic-colour-primary-s', `${hsl[1]}%`);
      document.documentElement.style.setProperty('--dynamic-colour-primary-l', `${hsl[2]}%`);
    } else {
      document.documentElement.style.removeProperty('--dynamic-colour-primary');
      document.documentElement.style.removeProperty('--dynamic-colour-primary-h');
      document.documentElement.style.removeProperty('--dynamic-colour-primary-s');
      document.documentElement.style.removeProperty('--dynamic-colour-primary-l');
      if (this.primary) {
        const hsl = gungColorToHsl(primary);
        document.documentElement.style.setProperty('--dynamic-colour-primary', primary);
        document.documentElement.style.setProperty('--dynamic-colour-primary-h', hsl[0]);
        document.documentElement.style.setProperty('--dynamic-colour-primary-s', `${hsl[1]}%`);
        document.documentElement.style.setProperty('--dynamic-colour-primary-l', `${hsl[2]}%`);
      }
    }

    const secondary = this.form.get('colors').get('secondary').value;
    if (!!secondary) {
      const hsl = gungColorToHsl(secondary);
      document.documentElement.style.setProperty('--dynamic-colour-secondary', secondary);
      document.documentElement.style.setProperty('--dynamic-colour-secondary-h', hsl[0]);
      document.documentElement.style.setProperty('--dynamic-colour-secondary-s', `${hsl[1]}%`);
      document.documentElement.style.setProperty('--dynamic-colour-secondary-l', `${hsl[2]}%`);
    } else {
      document.documentElement.style.removeProperty('--dynamic-colour-secondary');
      document.documentElement.style.removeProperty('--dynamic-colour-secondary-h');
      document.documentElement.style.removeProperty('--dynamic-colour-secondary-s');
      document.documentElement.style.removeProperty('--dynamic-colour-secondary-l');
      if (this.secondary) {
        const hsl = gungColorToHsl(secondary);
        document.documentElement.style.setProperty('--dynamic-colour-secondary', secondary);
        document.documentElement.style.setProperty('--dynamic-colour-secondary-h', hsl[0]);
        document.documentElement.style.setProperty('--dynamic-colour-secondary-s', `${hsl[1]}%`);
        document.documentElement.style.setProperty('--dynamic-colour-secondary-l', `${hsl[2]}%`);
      }
    }

    const hover = this.form.get('colors').get('hover').value;
    if (!!hover) {
      const hsl = gungColorToHsl(hover);
      document.documentElement.style.setProperty('--dynamic-colour-hover', hover);
      document.documentElement.style.setProperty('--dynamic-colour-hover-h', hsl[0]);
      document.documentElement.style.setProperty('--dynamic-colour-hover-s', `${hsl[1]}%`);
      document.documentElement.style.setProperty('--dynamic-colour-hover-l', `${hsl[2]}%`);
    } else {
      document.documentElement.style.removeProperty('--dynamic-colour-hover');
      document.documentElement.style.removeProperty('--dynamic-colour-hover-h');
      document.documentElement.style.removeProperty('--dynamic-colour-hover-s');
      document.documentElement.style.removeProperty('--dynamic-colour-hover-l');
      if (this.hover) {
        const hsl = gungColorToHsl(hover);
        document.documentElement.style.setProperty('--dynamic-colour-hover', hover);
        document.documentElement.style.setProperty('--dynamic-colour-hover-h', hsl[0]);
        document.documentElement.style.setProperty('--dynamic-colour-hover-s', `${hsl[1]}%`);
        document.documentElement.style.setProperty('--dynamic-colour-hover-l', `${hsl[2]}%`);
      }
    }

    const contrasting = this.form.get('colors').get('contrasting').value;
    if (!!contrasting) {
      const hsl = gungColorToHsl(contrasting);
      document.documentElement.style.setProperty('--dynamic-colour-contrasting', contrasting);
      document.documentElement.style.setProperty('--dynamic-colour-contrasting-h', hsl[0]);
      document.documentElement.style.setProperty('--dynamic-colour-contrasting-s', `${hsl[1]}%`);
      document.documentElement.style.setProperty('--dynamic-colour-contrasting-l', `${hsl[2]}%`);
    } else {
      document.documentElement.style.removeProperty('--dynamic-colour-contrasting');
      document.documentElement.style.removeProperty('--dynamic-colour-contrasting-h');
      document.documentElement.style.removeProperty('--dynamic-colour-contrasting-s');
      document.documentElement.style.removeProperty('--dynamic-colour-contrasting-l');
      if (this.contrasting) {
        const hsl = gungColorToHsl(contrasting);
        document.documentElement.style.setProperty('--dynamic-colour-contrasting', contrasting);
        document.documentElement.style.setProperty('--dynamic-colour-contrasting-h', hsl[0]);
        document.documentElement.style.setProperty('--dynamic-colour-contrasting-s', `${hsl[1]}%`);
        document.documentElement.style.setProperty('--dynamic-colour-contrasting-l', `${hsl[2]}%`);
      }
    }
  }

  shouldDisplayButtons(): boolean {
    // We don't want to display the buttons when we are on the configuration wrapper.
    // The reason for this is that it is composed of smaller, more specific components that
    // are managed on their own. Saving on this top level will not be able to save changes done there
    // so removing it entirely makes more sense in a UX perspective.
    if (!!this.selectedNavItem && this.selectedNavItem === 'adminConfigurationWrapper') {
      return false;
    }
    if (!!this.selectedNavItem && this.selectedNavItem === 'adminCompanyTemplates') {
      return false;
    }
    return true;
  }
}
