<span [innerHTML]="priceHTML" *ngIf="!hidePrice"></span>
<!--
We need to use *ngIf in order to not accidentally leak prices.
If we only hide through css visibility the element will still exist in the DOM
which can be picked up through page inspection and search engine crawlers.
 -->
<!-- This below is kept in order to keep the span element to avoid breaking layouts -->
<span *ngIf="hidePrice"></span>
<span *ngIf="assortmentType != ''"> {{ assortmentType }}</span>
<hl-display-price-levels
  *ngIf="displayPriceLevels && !hidePrice && priceLevelsDisplayType !== 'HIDDEN'"
  [price]="price"
  [useStaffedPrice]="useStaffedPrice"
  [priceLevelsDisplayType]="priceLevelsDisplayType"
  [priceLevelsCurrentQty]="currentQty"
></hl-display-price-levels>
