import { Component, OnInit } from '@angular/core';
import { HlDisplayMeetingListService } from '../../../services/hl-display-meeting-list.service';

@Component({
  selector: 'app-meetings',
  templateUrl: './meetings.component.html',
  styleUrls: ['./meetings.component.css']
})
export class MeetingsComponent implements OnInit {
  constructor(public listConfigService: HlDisplayMeetingListService) {}

  ngOnInit() {}
}
