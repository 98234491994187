<div class="row my-2 px-5">
  <div class="col-12 col-sm-6 col-md-3 px-4 py-3 d-flex flex-column product-card" *ngFor="let product of mappedData">
    <div class="fav-product p-1">
      <lib-favourite-product-icon [id]="product.id"></lib-favourite-product-icon>
    </div>

    <div class="product-image d-flex justify-content-center" *ngIf="showImage">
      <ng-template
        ngTemplateOutlet
        *ngIf="!product.extra.oldProduct; then productImg; else oldProductImg"
      ></ng-template>
      <ng-template #productImg>
        <a [routerLink]="['/product/', product.id]">
          <img
            class="img-fluid"
            *ngIf="!!product.extra.images && !!product.extra.images[0]"
            src="https://d27ahaa1qqlr90.cloudfront.net/fit-in/250x250/filters:fill(white):background_color(white):etag({{
              product.extra.images[0].s3ETag
            }}):format(jpeg)/{{ product.extra.images[0].s3Uri }}"
            alt=""
          />
        </a>
      </ng-template>
      <ng-template #oldProductImg>
        <div class="old-product-image">
          <img src="assets/old-product.png" class="img-fluid" />
          <div class="text text-center" translate>OLD_PRODUCT_IMAGE</div>
        </div>
      </ng-template>
    </div>
    <!-- <span class="d-block mt-2 overflow small">
      {{ product.name.length > 70 ? (product.name | slice : 0 : 70) + '...' : product.name }}
    </span> -->
    <div class="product-name mb-1 h5 font-weight-bold">
      <a class="product-link" routerLink="/product/{{ product.id }}">
        @if (product.extra.i18n?.[currentLang]?.artbeskrspec) {
          {{ product.extra.i18n[currentLang].artbeskrspec }}
        } @else if (product.extra.ar.artbeskrspec) {
          {{ product.extra.ar.artbeskrspec }}
        } @else {
          {{ product.extra.ar.artbeskr }}
        }
      </a>
    </div>
    @if (isSales) {
      <div class="product-text">({{ product.extra.ar.artbeskr }})</div>
    }
    <!-- Per request by Magnus on 20241023: Only first and second row should be vissible in Grid view -->
    <!-- @if (product.extra.arb?.itemSpecWeb) {
    <div class="product-text">
      {{product.extra.arb.itemSpecWeb}}
      <span translate>PRODUCT_GROUP</span>:
    </div>
    } -->
    <div class="d-block">
      @if (displayStaffedPrices && product.price) {
        <hl-display-price
          [price]="product.price"
          priceLevelsDisplayType="FULL"
          displayOnlyPrice="true"
          type="STAFFED"
          [assortmentType]="getAssortmentType(product.extra)"
        ></hl-display-price>
      } @else {
        <span class="h5 font-weight-bold text-nowrap text-primary" *ngIf="product.price">
          {{
            product.price.customerGrossPrice.value
              | gungCurrency: product.price.customerGrossPrice.currencyCode : 'symbol-narrow' : '1.2-2' : 'sv_SE'
          }}
        </span>
        @if (displayDiscountFromPriceList && product.price?.backendPrice?.levels?.[0]?.extra?.prl?.proc1 > 0) {
          <span class="text-disc">(-{{ product.price.backendPrice.levels[0].extra.prl.proc1 }}%)</span>
        }
        <span>{{ getAssortmentType(product.extra) }}</span>
      }
    </div>

    <span class="d-block">
      <span translate>ID</span>: {{ product.id }}
      @if (!!currentCustomer && currentCustomer.extra.kuarMapped?.[product.id]?.artnrkund) {
        &nbsp;({{ currentCustomer.extra.kuarMapped[product.id]?.artnrkund }})
      }
    </span>
    <div class="mt-auto">
      <span *ngIf="productSize && productSize[product.id]" class="d-block"
        ><span translate>SIZE</span>: {{ productSize[product.id] }}
      </span>

      <span
        *ngIf="
          isSales &&
          product.price &&
          getAssortmentType(product.extra) === '(W)' &&
          product.extra.ar.q_basecost_start &&
          product.extra.ar.q_basecost_unit
        "
        class="d-block"
      >
        <span translate>BASECOST_S_U</span>:
        {{
          product.extra.ar.q_basecost_start
            | gungCurrency: product.price.customerNetPrice.currencyCode : 'symbol-narrow' : '1.2-2' : 'sv_SE'
        }}
        /
        {{
          product.extra.ar.q_basecost_unit
            | gungCurrency: product.price.customerNetPrice.currencyCode : 'symbol-narrow' : '1.2-2' : 'sv_SE'
        }}
      </span>

      <div class="d-block mt-3">
        <span translate>AVAILABILITY</span>:
        <app-hl-display-availability
          [availability]="
            product.extra.availabilities['0'] ||
            product.extra.availabilities['*'] ||
            (stockId && product.extra.availabilities[stockId])
          "
        ></app-hl-display-availability>
      </div>

      <app-hl-display-buy-btn
        class="mt-3 w-100"
        [product]="product"
        *ngIf="!product.extra.oldProduct"
        [id]="product.id"
      ></app-hl-display-buy-btn>
      <a class="product-link" routerLink="/product/{{ product.id }}">
        <button type="button" class="btn btn-outline-primary mt-2 w-100" style="white-space: nowrap" translate>
          MORE_INFO
        </button>
      </a>
    </div>
  </div>
</div>