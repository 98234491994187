import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OrderService, SelectedCustomerService, Order, MetadataService, CustomerService } from 'gung-standard';
import { ListLayout, ListLayoutMultipleComponent } from 'gung-list';

import { OpenOrderTableComponent } from 'gung-standard';
import { JeevesOpenOrdersListTableConfigService } from '../jeeves-open-orders-list-table-config/jeeves-open-orders-list-table-config.service';

@Injectable({
  providedIn: 'root'
})
export class JeevesOpenOrdersListConfigService extends JeevesOpenOrdersListTableConfigService {
  constructor(
    protected translationService: TranslateService,
    protected orderService: OrderService,
    protected selectedCustomerService: SelectedCustomerService,
    protected metadataService: MetadataService,
    protected customerService: CustomerService
  ) {
    super(translationService, orderService, selectedCustomerService, metadataService, customerService);
  }

  getLayouts(): ListLayout<Order>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => OpenOrderTableComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'Product grid'
      }
    ];
  }
}
