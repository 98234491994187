import { environment } from '../../../environments/environment';
import { Component, OnInit, OnChanges } from '@angular/core';
import { ListItemRendererComponent } from 'gung-list';
import {
  Product,
  CustomerProductPrice,
  PriceService,
  AuthService,
  AvailabilityService,
  Availability
} from 'gung-standard';
import { forkJoin, of } from 'rxjs';
import { first, switchMap } from 'rxjs';
import { HlDisplayPurchasedProduct } from '../../models/purchased-product';
import { langExists } from '../../services/hl-product.service';

@Component({
  selector: 'app-hl-display-customer-ordered-products-table',
  templateUrl: './hl-display-customer-ordered-products-table.component.html',
  styleUrls: ['./hl-display-customer-ordered-products-table.component.css']
})
export class HlDisplayCustomerOrderedProductsTableComponent
  extends ListItemRendererComponent<Product[]>
  implements OnInit, OnChanges {
  customerPurchasedProducts: {
    purchasedProduct: HlDisplayPurchasedProduct;
    price: CustomerProductPrice;
    availability: Availability;
  }[] = [];

  isSales = false;

  constructor(
    private priceService: PriceService,
    private authService: AuthService,
    private availabilityService: AvailabilityService
  ) {
    super();
  }

  public ngOnInit() {
    this.subscribeToProducts();
    this.authService.getRoles().pipe(first()).subscribe(roles => {
      this.isSales = roles.includes('SALES');
    });
  }

  ngOnChanges() {
    this.subscribeToProducts();
  }

  private subscribeToProducts() {
    const productIds = this.data.map(product => product.id);

    if (productIds.length === 0) {
      this.customerPurchasedProducts = [];
      return;
    }

    this.authService
      .getCurrentUser()
      .pipe(
        first(),
        switchMap(user =>
          forkJoin({
            products: of(this.data),
            prices: this.priceService.getCurrentCustomerPrices(productIds).pipe(first()),
            availabilities: forkJoin([
              ...productIds.map(id => this.availabilityService.getAvailability(id, user.managedMultistockIds[0]))
            ]).pipe(first())
          })
        )
      )
      .pipe(first())
      .subscribe(results => {
        this.customerPurchasedProducts = results.products.map(purchasedProduct => {
          return {
            purchasedProduct: purchasedProduct as HlDisplayPurchasedProduct,
            price: results.prices.find(price => price.productId === purchasedProduct.id),
            availability: results.availabilities.find(availability => availability.productId === purchasedProduct.id)
          };
        });
      });
  }

  getName(product: Product): string {
    if (!this.isSales) {
      return product.name;
    }

    let itemDesc = product.extra.ar.artbeskr;
    return `${itemDesc}`;
  }

  getDescription(product: Product): string {
    if (!this.isSales) {
      return product.description;
    }

    const desc1 = product.description;
    const desc2 = product.extra.ar.artbeskrspec;
    if (desc2) {
      return `${desc1} (${desc2})`;
    }
    return `${desc1}`;
  }

}
