import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { HlDisplayPortalFeaturesService, PortalFeatures } from '../../../services/hl-display-portal-features.service';
import { HlDisplayGungLanguageConfigService } from '../../../services/hl-display-gung-language-config.service';
import { GungNotificationService, Language, gungAddRemoveSpinner } from 'gung-common';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-hl-features-management',
  templateUrl: './hl-features-management.component.html',
  styleUrl: './hl-features-management.component.css'
})
export class HlFeaturesManagementComponent {
  currentPortal: string;
  features: PortalFeatures;
  selectedSettingsGroup: string;
  enabledLanguages: Language[];
  constructor(
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    protected portalFeaturesService: HlDisplayPortalFeaturesService,
    protected languageConfigService: HlDisplayGungLanguageConfigService,
    private location: Location,
    private gungNotificationService: GungNotificationService,
    private translateService: TranslateService
  ) {
    this.currentPortal = this.activatedRoute.snapshot.params.portal;
    if (this.currentPortal) {
      this.portalFeaturesService.getPortalFeatures(this.currentPortal).subscribe(features => {
        this.features = features;
      });
    } else {
      this.location.back();
    }

    this.enabledLanguages = this.languageConfigService.getLanguages().filter(l => l.enabled);
  }

  updateArrayValues(input: any, featureKey: string) {
    if (typeof input === 'string') {
      input = input.split(',').map((item: string) => item.trim());
    }
    this.features[this.selectedSettingsGroup][featureKey] = input;
  }

  save({ target }) {
    gungAddRemoveSpinner(target);
    this.portalFeaturesService.putPortalFeatures(this.features, this.currentPortal).subscribe(
      features => {
        this.features = features;
        this.gungNotificationService.notify(
          this.translateService.instant('SAVED'),
          this.translateService.instant('CHANGE_SAVED_SUCCESS'),
          'success'
        );
        gungAddRemoveSpinner(target);
      },
      error => {
        this.gungNotificationService.notify(
          this.translateService.instant('ERROR'),
          this.translateService.instant('CHANGE_SAVED_FAIL'),
          'warning'
        );
        gungAddRemoveSpinner(target);
      }
    );
  }
}
