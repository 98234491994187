import { HlDisplayCreditBlockedService } from './../../services/hl-display-credit-blocked.service';
import { Component, OnInit } from '@angular/core';
import { CustomerListCardComponent, SelectedCustomerService, Customer, GungModalService, AuthService } from 'gung-standard';
import { environment } from '../../../environments/environment';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { HlDisplayCustomerMeetingModalComponent } from '../hl-display-customer-meeting-modal/hl-display-customer-meeting-modal.component';
import { Router } from '@angular/router';
import { first } from 'rxjs';

@Component({
  selector: 'app-hl-customer-list-card',
  templateUrl: './hl-customer-list-card.component.html',
  styleUrls: ['./hl-customer-list-card.component.scss']
})
export class HlCustomerListCardComponent extends CustomerListCardComponent {
  isSales: boolean = false;
  isManager: boolean = false;

  constructor(
    selectedCustomerService: SelectedCustomerService,
    protected router: Router,
    protected modalService: NgbModal,
    protected gungModalService: GungModalService,
    protected creditBlockedService: HlDisplayCreditBlockedService,
    protected authService: AuthService
  ) {
    super(selectedCustomerService, router);
    this.authService.getRoles().pipe(first()).subscribe(roles => {
      this.isSales = roles.includes('SALES');
      this.isManager = roles.includes('MANAGER');
    })
  }

  selectCustomer(customer: Customer) {
    this.creditBlockedService.checkCreditBlocked(customer).pipe(first()).subscribe(result => {
      if (result.status === 'CREDIT_HARD_BLOCK') {
        const options: NgbModalOptions = {
          backdrop: 'static',
          size: 'sm',
          keyboard: true
        };
        const translation = this.creditBlockedService.getPortalTranslationCustomer(customer);
        this.gungModalService.openBasicModal(translation.title, translation.body, options).then(
          result => {},
          reason => {}
        );
      }
    });
    this.selectCustomerService.selectCustomer(customer.id);
  }

  openMeeting(customer: Customer) {
    const modalReference = this.modalService.open(HlDisplayCustomerMeetingModalComponent, {
      backdrop: true || 'static',
      size: 'lg',
      keyboard: true
    });

    modalReference.componentInstance.customer = customer;

    modalReference.result.then(
      result => {
        console.log('result: ', result);
      },
      error => {
        console.log('error: ', error);
      }
    );
  }
}
