<div class="row">
  <div class="col">
    <div class="image-container">
      <h1 class="page-label text-center">
        {{ getTitle() }}
      </h1>
    </div>
  </div>
</div>
<div class="row my-5">
  <div *ngFor="let item of items; let i = index" class="col-12 px-5">
    <div class="row">
      <div class="align-items-center col-12 col-sm-2 col-md-1 d-flex justify-content-center">
        <div class="top-product-ranking-circle d-flex justify-content-center align-items-center">
          {{ i + 1 }}
        </div>
      </div>
      <div class="col-12 col-sm-10 col-md-11">
        <ng-template libCustomHost></ng-template>
      </div>
    </div>
  </div>
</div>
