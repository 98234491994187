import { Injectable, OnInit } from '@angular/core';
import { AuthService, Product } from 'gung-standard';
import { ListLayout, ListSortOption, ConfigService, ConfigBaseFilter, ListLayoutMultipleComponent } from 'gung-list';
import { first, Observable } from 'rxjs';
import { HlDisplayCustomerOrderedProductsTableComponent } from '../components/hl-display-customer-ordered-products-table/hl-display-customer-ordered-products-table.component';
import { HlProductService, langExists } from './hl-product.service';
import { HlDisplayPurchasedProduct } from '../models/purchased-product';
import { HlDisplayPortalFeaturesService, OpenOrdersSettingsGroup } from './hl-display-portal-features.service';

@Injectable({
  providedIn: 'root'
})
export class HlDisplayCustomerOrderedProductsLastXMonthsListService implements ConfigService<Product>, OnInit {
  isSales = false;
  mainCountry = '';

  constructor(
    protected hlProductService: HlProductService,
    protected authService: AuthService,
    protected hlDisplayPortalFeaturesService: HlDisplayPortalFeaturesService
  ) {}

  ngOnInit(): void {
    this.authService.getRoles().pipe(first()).subscribe(roles => {
        this.isSales = roles.includes('SALES');
      });
    this.hlDisplayPortalFeaturesService.getPortalFeatureGroup('openOrdersSettingsGroup').subscribe(openOrdersSettingsGroup => {
      const openOrdersGroup = openOrdersSettingsGroup as OpenOrdersSettingsGroup;
      this.mainCountry = openOrdersGroup.mainCountry;
    });
  }

  getItems(): Observable<HlDisplayPurchasedProduct[]> {
    return this.hlProductService.getCustomerPurchasedProducts();
  }

  getFilters(): ConfigBaseFilter<Product>[] {
    return [];
  }

  getSortOptions(): ListSortOption<Product>[] {
    return [];
  }

  getBatchSizes(): number[] {
    return [20];
  }

  getLayouts(): ListLayout<Product>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => HlDisplayCustomerOrderedProductsTableComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'ProductTable'
      }
    ];
  }

  getSearchTerms(item: Product): string[] {
    return [item.id, item.name, this.getDescription(item)];
  }

  getDescription(product: Product): string {
    if (!this.isSales) {
      return product.description;
    }

    let desc1 = '';
    if (langExists(this.mainCountry)(product)) {
      desc1 = product.extra.i18n[this.mainCountry].artbeskrspec || desc1;
    }
    const desc2 = product.extra.ar.artbeskrspec;
    if (desc2) {
      return `${desc1} (${desc2})`;
    }
    return `${desc1}`;
  }

  getItemId(item: Product): string {
    return item.id;
  }
}
