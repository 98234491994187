import { Injectable } from '@angular/core';
import { TranslationLocalService } from 'gung-standard';
import seTranslations from '../i18n/se.json';
import frTranslations from '../i18n/fr.json';
import frChTranslations from '../i18n/fr-ch.json';
import enTranslations from '../i18n/en.json';
import deTranslations from '../i18n/de.json';
import deChTranslations from '../i18n/de-ch.json';
import noTranslations from '../i18n/no.json';
import dkTranslations from '../i18n/dk.json';
import nlTranslations from '../i18n/nl.json';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { Observable, from } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HlTranslationLocalService extends TranslationLocalService {
  constructor(protected http: HttpClient, protected handler: HttpBackend) {
    super(http, handler);
  }

  protected async getCustTranslations(lang: string): Promise<any> {
    switch (lang) {
      case 'se':
        return seTranslations;
      case 'en':
        return enTranslations;
      case 'de':
        return deTranslations;
      case 'fr':
        return frTranslations;
      case 'no':
        return noTranslations;
      case 'dk':
        return dkTranslations;
      case 'nl':
        return nlTranslations;
    }
    return {};
  }
}
