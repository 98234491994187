import { Component, Inject, Optional } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { CommonModalService } from 'gung-common';
import {
  AuthService,
  BaseViewConfigService,
  CustomerService,
  gungAddRemoveSpinner,
  GungModalService,
  Order,
  OrderDetailsComponent,
  OrderRow,
  PriceConfigService,
  PriceService,
  ProductAssortmentParentService,
  ProductService
} from 'gung-standard';
import { first, forkJoin, map } from 'rxjs';
import { HlBackendInterceptorService } from '../../services/hl-backend-interceptor/hl-backend-interceptor.service';
import { HlDisplayDateUtilService } from '../../services/hl-display-date-util.service';
import { HlDisplayOrderActionConfigService } from '../../services/hl-display-order-action-config.service';
import { HlDisplayOrderService } from '../../services/hl-display-order.service';
import { AdressSettingsGroup, DeliverySettingsGroup, HlDisplayPortalFeaturesService } from '../../services/hl-display-portal-features.service';
import { HlDisplayOrderAddRowModalComponent } from '../hl-display-order-add-row-modal/hl-display-order-add-row-modal.component';

export enum AddressInfoVersion {
  Default = 'Default',
  PointOfDelivery = 'POD',
  Delivery = 'Delivery',
}

export interface DeliveryAddress {
  deliveryAddress1: string;
  deliveryAddress2: string;
  deliveryAddressCity: string;
  deliveryAddressZipcode: string;
  deliveryAddressCountry: string;
}

@Component({
  selector: 'app-hl-display-order-details',
  templateUrl: './hl-display-order-details.component.html',
  styleUrls: ['./hl-display-order-details.component.scss']
})
export class HlDisplayOrderDetailsComponent extends OrderDetailsComponent {
  public isSales: boolean;
  public isManager: boolean;
  public dateFormat: string = 'yyyy-MM-dd'; //Fallback
  public fetchDeliveryCustomerFromOrdLevPlats: boolean = false;
  deliveryAddress: DeliveryAddress = undefined;
  deliveryAddress1: string;
  deliveryAddress2: string;
  deliveryAddressCity: string;
  deliveryAddressZipcode: string;

  activeIds = ['panel-1', 'panel-3'];

  addressInfoDisplayVersion: AddressInfoVersion = AddressInfoVersion.Default;

  constructor(
    protected route: ActivatedRoute,
    protected orderService: HlDisplayOrderService,
    protected authService: AuthService,
    protected hlBackendInterceptor: HlBackendInterceptorService,
    protected modalService: NgbModal,
    public configService: HlDisplayOrderActionConfigService,
    protected productService: ProductService,
    public dateUtilService: HlDisplayDateUtilService,
    protected baseViewConfigService: BaseViewConfigService,
    protected productAssortmentParentService: ProductAssortmentParentService,
    protected translateService: TranslateService,
    protected gungModalService: GungModalService,
    protected customerService: CustomerService,
    protected commonModalService: CommonModalService,
    protected hldDisplayPortalFeaturesService: HlDisplayPortalFeaturesService,
    public priceConfigService: PriceConfigService,
    @Optional()
    @Inject('environment')
    protected environment: { [s: string]: any }
  ) {
    super(
      route,
      orderService,
      authService,
      hlBackendInterceptor,
      productService,
      dateUtilService,
      baseViewConfigService,
      productAssortmentParentService,
      commonModalService,
      priceConfigService
    );
  }

  ngOnInit(): void {
    super.ngOnInit();
    forkJoin([
      this.authService.getRoles().pipe(first()),
      this.hldDisplayPortalFeaturesService.getPortalFeatureGroup('deliverySettingsGroup').pipe(first(), map(deliverySettingsGroup => (deliverySettingsGroup as DeliverySettingsGroup))),
      this.hldDisplayPortalFeaturesService.getPortalFeatureGroup('addressSettingsGroup').pipe(first(), map(addressSettingsGroup => (addressSettingsGroup as AdressSettingsGroup))),
      this.dateUtilService.getLangSettingsObservable().pipe(first())
    ]).pipe(first()).subscribe(([roles, deliverySettingsGroup, addressSettingsGroup, langSettings]) => {
      this.isSales = roles.indexOf('SALES') > -1;
      this.isManager = roles.indexOf('MANAGER') > -1;

      this.dateFormat = langSettings.dateFormat;

      this.fetchDeliveryCustomerFromOrdLevPlats = deliverySettingsGroup.fetchDeliveryCustomerFromOrdLevPlats;

      this.addressInfoDisplayVersion = addressSettingsGroup.addressInfoDisplayVersion;
    });
  }

  protected mapOrder(order: Order): Order {
    order.rows = order.rows.filter(row => row.extra.orp.ordradnrstrpos === 0);
    if (this.fetchDeliveryCustomerFromOrdLevPlats) {
      this.customerService
        .getCustomer(order.deliveryCustomerId + '@' + order.extra.oh.ordlevplats1)
        .pipe(first())
        .subscribe(customer => {
          order.extra._deliveryCustomerName = customer?.name;
        });
    }

    this.mapDeliveryPoint(order);

    return order;
  }

  mapDeliveryPoint(order: Order) {
    if (order.extra.pod != null) {
      this.deliveryAddress = {
        deliveryAddress1: order.extra.pod.ftgnamn || '',
        deliveryAddress2: order.extra.pod.ftgpostadr2 || '',
        deliveryAddressCity: order.extra.pod.ftgpostadr3 || '',
        deliveryAddressZipcode: order.extra.pod.ftgpostnr || '',
        deliveryAddressCountry: order.extra.pod.landskod || ''
      }

    } else if (order.extra.oh.ordlevplats1 != null) {
      const deliveryPoint = order.extra?.deliveryCustomer?.extra?.lp
        .filter(lp => lp.ordlevplats1 === order.extra.oh.ordlevplats1)

      if (deliveryPoint.length > 0) {
        this.deliveryAddress = {
          deliveryAddress1: deliveryPoint[0]?._fr.ftgnamn || '',
          deliveryAddress2: deliveryPoint[0]?._fr.ftgpostadr2 || '',
          deliveryAddressCity: deliveryPoint[0]?._fr.ftgpostadr3 || '',
          deliveryAddressZipcode: deliveryPoint[0]?._fr.ftgpostnr || '',
          deliveryAddressCountry: deliveryPoint[0]?._fr.landskod || ''
        }
      }
    }
    else {
      this.deliveryAddress = {
        deliveryAddress1: order.extra.deliveryCustomer?.extra?.fr?.ftgnamn || '',
        deliveryAddress2: order.extra.deliveryCustomer?.extra?.fr?.ftgpostadr2 || '',
        deliveryAddressCity: order.extra.deliveryCustomer?.extra?.fr?.ftgpostadr3 || '',
        deliveryAddressZipcode: order.extra.deliveryCustomer?.extra?.fr?.ftgpostnr || '',
        deliveryAddressCountry: order.extra.deliveryCustomer?.extra?.fr?.landskod || ''
      }
    }
  }

  getTotal(rows: OrderRow[]): number {
    const valueArray = rows.map(row => this.getCustomerMappedRowPrice(row) as number);
    const sum = valueArray.reduce((acc, next) => acc + next, 0);
    return sum;
  }

  addRow() {
    const modalRef = this.modalService.open(HlDisplayOrderAddRowModalComponent, { size: 'lg', backdrop: 'static' });
    modalRef.componentInstance.order = this.order;
  }

  saveOrderMessage(element) {
    gungAddRemoveSpinner(element.target);
    let oh = { oh: { editext: this.order.extra.oh.editext } };
    if (this.isSales) {
      oh = { oh: { edit: this.order.extra.oh.edit } } as any;
    }

    this.orderService.updateOrderHeader({ id: this.order.id, extra: oh } as any).subscribe(
      result => {
        this.gungModalService
          .openConfirmYesNoModal(
            undefined,
            this.translateService.instant('CHANGE_SAVED_SUCCESS'),
            { size: 'sm' },
            'OK',
            null
          )
          .then(_ => { });
      },
      error => {
        gungAddRemoveSpinner(element.target);
        this.gungModalService
          .openConfirmYesNoModal(
            undefined,
            this.translateService.instant('AN_ERROR_HAS_OCCURED'),
            { size: 'sm' },
            'OK',
            null
          )
          .then(_ => { });
      },
      () => {
        gungAddRemoveSpinner(element.target);
      }
    );
  }

  getCustomerMappedProductPrice(orderRow: OrderRow) {
    const financialGrpCode = this.order.extra.billingCustomer?.extra?.kus?.q_financialgrpcode;

    if (!financialGrpCode || !this.environment.discountCustomerFinancialGroup[financialGrpCode]) {
      return orderRow.extra.orp.vb_pris;
    }

    return orderRow.extra.orp.vb_pris * (1 + this.environment.discountCustomerFinancialGroup[financialGrpCode])

  }

  getCustomerMappedRowPrice(orderRow: OrderRow) {
    const financialGrpCode = this.order.extra.billingCustomer?.extra?.kus?.q_financialgrpcode;

    if (!financialGrpCode || !this.environment.discountCustomerFinancialGroup[financialGrpCode]) {
      return orderRow.extra.orp.vbordradsum;
    }

    return orderRow.extra.orp.vbordradsum * (1 + this.environment.discountCustomerFinancialGroup[financialGrpCode])

  }
}
