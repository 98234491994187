<div *ngIf="loading || (loading && (!filteredItems || filteredItems.length <= 0))" class="progress mt-10">
  <div
    class="progress-bar progress-bar-striped progress-bar-animated bg-primary"
    role="progressbar"
    aria-valuenow="75"
    aria-valuemin="0"
    aria-valuemax="100"
    style="width: 75%"
  ></div>
</div>
<div class="filter-list">
  <div class="filter-list-wrapper">
    <ng-container *ngTemplateOutlet="topFilter ? upFilter : sideFilter"></ng-container>
    <div class="row" *ngIf="selectionActions">
      <lib-selection-bar
        [selectionActions]="selectionActions"
        [selectionMarkingActions]="selectionMarkingActions"
        [filteredItems]="filteredItems"
        [allItems]="items"
        [actionButtonTitle]="selectionActionsButtonTitle"
        (barHeight)="adjustBottomMargin($event)"
        (selectedItemsToggle)="onSelectedItemsToggle($event)"
      ></lib-selection-bar>
    </div>
  </div>
</div>

<div (click)="toggleFilters()" [ngClass]="shouldDisplayOverlay ? 'overlay-visible' : 'overlay-hidden'"></div>

<ng-template #sideFilter>
  <div
    class="row filter-list-background"
    [hidden]="loading || (loading && (!filteredItems || filteredItems.length <= 0))"
    [ngStyle]="containerStyles"
  >
    <div
      #gungFilters
      [ngClass]="getGungFilterClasses()"
      [class]="getFilterGroupCss()"
      class="col-12 sticky gung-filters d-flex flex-column"
    >
      <div class="row" [ngClass]="getSearchGroupCss()" *ngIf="searchAboveFilters">
        <div class="mb-2 col-md col-12" *ngIf="!searchDisabled">
          <div class="row">
            <ng-template name="searchFields" libCustomHost></ng-template>
          </div>
        </div>
      </div>
      <div class="gung-filters-inner d-flex flex-column p-0">
        <div class="filter-header px-4 py-3" [hidden]="!renderFilters || renderFilters.length == 0">
          <i class="fas fa-sliders-h pr-2"></i>
          <span class="font-weight-bold" translate>FILTERS</span>
        </div>
        <ng-container *ngTemplateOutlet="selectedFilterTop ? selectedFilters : filterOptions"></ng-container>
        <ng-container *ngTemplateOutlet="!selectedFilterTop ? selectedFilters : filterOptions"></ng-container>
        <ng-template #filterOptions>
          <lib-filter-list-side-filter
            *ngFor="let renderFilter of renderFilters"
            [renderFilter]="renderFilter"
            [class]="renderFilter.customClass"
            (selectValue)="setSelection(renderFilter.name, $event, true)"
            (deselectValue)="setSelection(renderFilter.name, $event, false, renderFilter.subName)"
            (selectValueRange)="setSelection(renderFilter.name, null, true, $event)"
            (selectSubValue)="setSubSelection(renderFilter.subName, $event, true)"
            (deselectSubValue)="setSubSelection(renderFilter.subName, $event, false)"
          ></lib-filter-list-side-filter>
        </ng-template>
        <ng-template #selectedFilters>
          <lib-selected-filter
            [renderFilters]="renderFiltersForSelected"
            (deselectAll)="clearSelection()"
            (deselectValue)="setSelection($event.filterName, $event.valueId, false)"
            (deselectValueRange)="setSelection($event.filterName, null, false, $event.valueIdRange)"
            [showFilterTitle]="showFilterTitle"
          >
          </lib-selected-filter>
        </ng-template>
      </div>
    </div>
    <div class="gung-products" [ngClass]="renderFilters && renderFilters.length > 0 ? 'col-12 col-lg-9' : 'col-12'">
      <div class="product-list-search-group pt-2">
        <div class="row" [ngClass]="getSearchGroupCss()">
          <div class="mb-2 col-md col-12" *ngIf="!searchAboveFilters && !searchDisabled">
            <div class="row">
              <ng-template name="searchFields" libCustomHost></ng-template>
            </div>
          </div>
          <div class="col col-md-3" *ngIf="sortOptions && sortOptions.length > 0">
            <div class="form-group">
              <select
                name="sortOptions"
                id="sortOptions"
                class="form-control form-select ml-1"
                [(ngModel)]="selectedSortOption"
                (change)="setSortOption($event)"
              >
                <option value="undefined" translate disabled>SORT_OPTIONS</option>
                <option *ngFor="let option of sortOptions" [value]="option.label" translate>
                  {{ option.label }}
                </option>
              </select>
            </div>
          </div>
          <div class="col col-md-3" *ngIf="batchSizes && batchSizes.length > 1">
            <lib-filter-list-batch (batchSizeUpdated)="setBatchSize($event)" [batchSizes]="batchSizes">
            </lib-filter-list-batch>
          </div>
          <div class="col-auto" *ngIf="layouts && layouts.length > 1">
            <button
              class="btn btn-light layout-btn {{layout.getButtonClass && layout.getButtonClass()}}"
              [ngClass]="{ active: layoutIndex === currentLayoutIdx }"
              *ngFor="let layout of layouts; let layoutIndex = index"
              (click)="changeLayout(layoutIndex)"
            >
              <i [ngClass]="layout.getIconClass()"></i>
            </button>
          </div>
          <div class="col col-md-2" *ngIf="bootstrapCols && bootstrapCols.length > 0">
            <div class="row">
              <div class="col-6 text-right"><label for="bootstrapCols" translate>ITEMS_PER_ROW</label></div>
              <div class="col-6">
                <div class="form-group product-per-row">
                  <select
                    name="bootstrapCols"
                    id="bootstrapCols"
                    class="form-control ml-1"
                    [(ngModel)]="itemsPerRow"
                    (change)="setBootstrapCols($event)"
                  >
                    <option *ngFor="let bootstrapCol of bootstrapCols" [value]="bootstrapCol">
                      {{ bootstrapCol }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row header-banner" *ngIf="showHeaderBanner">
          <div class="col-12 my-2 flow-slider" *ngIf="flowSlider?.length > 0" style="max-height: 400px">
            <lib-slider [sliderContent]="flowSlider"></lib-slider>
          </div>
          <div class="col-12 my-2 flow-html" *ngIf="flowHtml">
            <div
              class="flow-html ql-editor"
              style="background-image: url('{{ backgroundImage }}');"
              [innerHTML]="flowHtml | safeHtml"
            ></div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <ng-template libCustomHost></ng-template>
            <p *ngIf="filteredItems && filteredItems.length === 0" class="nothing-found">
              <span [innerHTML]="getNothingFoundTranslateTag() | translate"></span>
            </p>
          </div>
        </div>
        <ng-container *ngTemplateOutlet="loadMoreItems"></ng-container>
      </div>
    </div>
    <div *ngIf="filteredItems && filteredItems.length > 0 && renderFilters && renderFilters.length > 0">
      <a
        [ngClass]="{ 'hide-filter-toggle': getGungFilterClasses().open }"
        class="gung-filters-toggle"
        (click)="toggleFilters()"
      >
        <i class="fa fa-filter"></i>
      </a>

      <a
        [ngClass]="{ 'hide-filter-toggle': !getGungFilterClasses().open }"
        class="gung-filters-toggle close-filters"
        (click)="toggleFilters()"
      >
        <i class="fa fa-times"></i>
      </a>
    </div>
  </div>
</ng-template>
<!-- upFilter -->
<ng-template #upFilter>
  <div
    class="row filter-list-background"
    [hidden]="loading || (loading && (!filteredItems || filteredItems.length <= 0))"
    [ngStyle]="containerStyles"
  >
    <!-- style="margin-top: -30px;" -->
    <div class="col-12">
      <div class="row" *ngIf="showHeaderBanner">
        <div class="col-12 my-2 flow-slider" *ngIf="flowSlider" style="max-height: 400px">
          <lib-slider [sliderContent]="flowSlider"></lib-slider>
        </div>
        <div class="col-12 flow-html" *ngIf="flowHtml">
          <div
            class="flow-html ql-editor"
            style="background-image: url('{{ backgroundImage }}');"
            [innerHTML]="flowHtml | safeHtml"
          ></div>
        </div>
      </div>
      <div class="fixed-search" [ngClass]="getSearchGroupCss()" [ngStyle]="{ 'top.px': navbarHeight }">
        <div class="row search-container">
          <div class="col-12 col-md" *ngIf="!searchDisabled">
            <div class="row">
              <ng-template name="searchFields" libCustomHost></ng-template>
            </div>
          </div>
          <div class="col col-md-2" *ngIf="batchSizes && batchSizes.length > 1">
            <lib-filter-list-batch (batchSizeUpdated)="setBatchSize($event)" [batchSizes]="batchSizes">
            </lib-filter-list-batch>
          </div>
          <div class="col col-md-3" *ngIf="sortOptions && sortOptions.length > 0">
            <div class="form-group">
              <select
                name="sortOptions"
                id="sortOptions"
                class="form-control form-select ml-1"
                [(ngModel)]="selectedSortOption"
                (change)="setSortOption($event)"
              >
                <option value="undefined" translate disabled>SORT_OPTIONS</option>
                <option *ngFor="let option of sortOptions" [value]="option.label" translate>
                  {{ option.label }}
                </option>
              </select>
            </div>
          </div>
          <div class="col col-md-2" *ngIf="bootstrapCols && bootstrapCols.length > 0">
            <div class="row">
              <div class="col-6 text-right"><label for="bootstrapCols" translate>ITEMS_PER_ROW</label></div>
              <div class="col-6">
                <div class="form-group product-per-row">
                  <select
                    name="bootstrapCols"
                    id="bootstrapCols"
                    class="form-control ml-1"
                    [(ngModel)]="itemsPerRow"
                    (change)="setBootstrapCols($event)"
                  >
                    <option *ngFor="let bootstrapCol of bootstrapCols" [value]="bootstrapCol">
                      {{ bootstrapCol }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="col-auto" *ngIf="layouts && layouts.length > 1">
            <button
              class="btn btn-light layout-btn {{layout.getButtonClass && layout.getButtonClass()}}"
              [ngClass]="{ active: layoutIndex === currentLayoutIdx }"
              *ngFor="let layout of layouts; let layoutIndex = index"
              (click)="changeLayout(layoutIndex)"
            >
              <i [ngClass]="layout.getIconClass()"></i>
            </button>
          </div>
        </div>
        <div class="row sticky margin-top-when-fixed-top-filters top-filter-wrapper filter-container">
          <div
            *ngIf="!isSmallScreen"
            #gungFilters
            [ngClass]="getGungFilterClasses()"
            [class]="getFilterGroupCss()"
            class="col-12 gung-filters d-flex flex-column flex-wrap"
          >
            <div class="d-flex flex-wrap bg-white-custom w-100">
              <div
                #gungFilters
                *ngFor="let renderFilter of renderFilters"
                class="mb-1"
                [ngClass]="getGungFilterClasses()"
                [class]="getFilterGroupCss()"
              >
                <lib-filter-list-top-filter
                  [class]="renderFilter.customClass"
                  [renderFilter]="renderFilter"
                  (selectValue)="setSelection(renderFilter.name, $event, true)"
                  (deselectValue)="setSelection(renderFilter.name, $event, false, undefined, renderFilter.subName)"
                  (selectValueRange)="setSelection(renderFilter.name, null, true, $event)"
                  (selectSubValue)="setSubSelection(renderFilter.subName, $event, true)"
                  (deselectSubValue)="setSubSelection(renderFilter.subName, $event, false)"
                ></lib-filter-list-top-filter>
              </div>
            </div>
          </div>
          <!-- <ng-template #isNotSmallScreen>
            <div class="col-12 gung-filters">
              <div class="d-flex flex-wrap bg-white-custom w-100">
                <div
                  #gungFilters
                  *ngFor="let renderFilter of renderFilters"
                  class="mb-1"
                  [ngClass]="getGungFilterClasses()"
                  [class]="getFilterGroupCss()"
                >
                  <lib-filter-list-top-filter
                    [renderFilter]="renderFilter"
                    (selectValue)="setSelection(renderFilter.name, $event, true)"
                    (deselectValue)="setSelection(renderFilter.name, $event, false)"
                    (selectValueRange)="setSelection(renderFilter.name, null, true, $event)"
                  ></lib-filter-list-top-filter>
                </div>
              </div>
            </div>
          </ng-template> -->
          <div class="col-12">
            <div class="m-0 mt-2 text-results-top-filters">
              <span
                style="padding-top: 1px"
                class="mt-1 mr-3"
                *ngIf="limit > 0 && renderItems && items && filteredItems && filteredItems.length === items.length"
              >
                {{ 'PRODUCT_LIST_TOTAL' | translate: { render: (renderItems | async).length, total: items.length } }}
              </span>
              <span
                style="padding-top: 1px"
                class="mt-1 mr-3"
                *ngIf="limit > 0 && renderItems && items && filteredItems && filteredItems.length !== items.length"
              >
                {{
                  'PRODUCT_LIST_FILTER_TOTAL'
                    | translate
                      : { render: (renderItems | async).length, filter: filteredItems.length, total: items.length }
                }}
              </span>
              <lib-selected-filter
                [topFilter]="true"
                [renderFilters]="renderFiltersForSelected"
                (deselectAll)="clearSelection()"
                (deselectValue)="setSelection($event.filterName, $event.valueId, false)"
                (deselectValueRange)="setSelection($event.filterName, null, false, $event.valueIdRange)"
              >
              </lib-selected-filter>
            </div>
          </div>
        </div>
      </div>

      <div
        *ngIf="isSmallScreen"
        #gungFilters
        [ngClass]="getGungFilterClasses()"
        [class]="getFilterGroupCss()"
        class="col-12 gung-filters d-flex flex-column flex-wrap"
      >
        <div class="filter-header px-3 py-3" [hidden]="!renderFilters || renderFilters.length == 0">
          <i class="fas fa-sliders-h pr-2"></i>
          <span class="font-weight-bold" translate>FILTERS</span>
        </div>
        <div class="d-flex flex-column">
          <lib-filter-list-top-filter
            *ngFor="let renderFilter of renderFilters"
            [renderFilter]="renderFilter"
            (selectValue)="setSelection(renderFilter.name, $event, true)"
            (deselectValue)="setSelection(renderFilter.name, $event, false, renderFilter.subName)"
            (selectValueRange)="setSelection(renderFilter.name, null, true, $event)"
            (selectSubValue)="setSubSelection(renderFilter.subName, $event, true)"
            (deselectSubValue)="setSubSelection(renderFilter.subName, $event, false)"
            [class]="renderFilter.customClass"
          ></lib-filter-list-top-filter>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <ng-template libCustomHost></ng-template>
          <p *ngIf="filteredItems && filteredItems.length === 0" class="nothing-found">
            <span [innerHTML]="getNothingFoundTranslateTag() | translate"></span>
          </p>
        </div>
      </div>
      <ng-container *ngTemplateOutlet="loadMoreItems"></ng-container>
    </div>
    <div *ngIf="filteredItems && filteredItems.length > 0 && renderFilters && renderFilters.length > 0">
      <a
        [ngClass]="{ 'hide-filter-toggle': getGungFilterClasses().open }"
        class="gung-filters-toggle"
        (click)="toggleFilters()"
      >
        <i class="fa fa-filter"></i>
      </a>

      <a
        [ngClass]="{ 'hide-filter-toggle': !getGungFilterClasses().open }"
        class="gung-filters-toggle close-filters"
        (click)="toggleFilters()"
      >
        <i class="fa fa-times"></i>
      </a>
    </div>
  </div>
</ng-template>

<ng-template #loadMoreItems>
  <div *ngIf="!paginationList" class="d-flex justify-content-center align-items-center btn-load-more">
    <button
      [ngClass]="getLoadMoreCss()"
      *ngIf="limit > 0 && !!filteredItems && filteredItems.length > limit"
      class="btn"
      (click)="loadMore()"
      style="overflow-anchor: none"
    >
      <span translate [translateParams]="{ limit: limit, total: filteredItems.length, batchSize: batchSize }"
        >GUNG_ITEM_LIST_LOAD_MORE</span
      >
    </button>
  </div>
  <div *ngIf="!!paginationList" class="d-flex justify-content-center align-items-center btn-load-more">
    <nav *ngIf="!!filteredItems && filteredItems.length > batchSize" aria-label="Page navigation example">
      <ul class="pagination">
        <li class="page-item">
          <button
            class="page-link"
            [disabled]="pagination.currentPage === 1"
            (click)="loadPage(pagination.currentPage - 1)"
            translate
          >
            PREVIOUS
          </button>
        </li>
        <li *ngIf="pagination.currentPage >= 4" class="page-item">
          <button class="page-link" (click)="loadPage(1)">{{ 1 }}</button>
        </li>
        <li *ngIf="pagination.currentPage >= 5" class="page-item align-self-end">...</li>
        <li *ngIf="pagination.currentPage - 2 > 0" class="page-item">
          <button class="page-link" (click)="loadPage(pagination.currentPage - 2)">
            {{ pagination.currentPage - 2 }}
          </button>
        </li>
        <li *ngIf="pagination.currentPage - 1 > 0" class="page-item">
          <button class="page-link" (click)="loadPage(pagination.currentPage - 1)">
            {{ pagination.currentPage - 1 }}
          </button>
        </li>
        <li class="page-item active">
          <span class="page-link">{{ pagination.currentPage }}</span>
        </li>
        <li *ngIf="pagination.currentPage + 1 <= pagination.totalPages" class="page-item">
          <button class="page-link" (click)="loadPage(pagination.currentPage + 1)">
            {{ pagination.currentPage + 1 }}
          </button>
        </li>
        <li *ngIf="pagination.currentPage + 2 <= pagination.totalPages" class="page-item">
          <button class="page-link" (click)="loadPage(pagination.currentPage + 2)">
            {{ pagination.currentPage + 2 }}
          </button>
        </li>
        <li *ngIf="pagination.currentPage <= pagination.totalPages - 4" class="page-item align-self-end">...</li>
        <li *ngIf="pagination.currentPage <= pagination.totalPages - 3" class="page-item">
          <button class="page-link" (click)="loadPage(pagination.totalPages)">{{ pagination.totalPages }}</button>
        </li>
        <li class="page-item">
          <button
            class="page-link"
            [disabled]="pagination.currentPage === pagination.totalPages"
            (click)="loadPage(pagination.currentPage + 1)"
            translate
          >
            NEXT
          </button>
        </li>
      </ul>
    </nav>
  </div>
</ng-template>
