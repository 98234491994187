import { Component, Input } from '@angular/core';
import { PriceComponent } from 'gung-standard';

@Component({
  selector: 'hl-display-price',
  templateUrl: './hl-display-price.component.html',
  styleUrls: ['./hl-display-price.component.css']
})
export class HlDisplayPriceComponent extends PriceComponent {
  @Input() assortmentType?: string;

  ngOnInit() {
    this.initValue();
  }
}
