import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hl-display-customer-service',
  templateUrl: './hl-display-customer-service.component.html',
  styleUrls: ['./hl-display-customer-service.component.css']
})
export class HlDisplayCustomerServiceComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
