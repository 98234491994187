import { Injectable } from '@angular/core';
import { EdiOrder, EdiOrderListConfigService } from 'gung-standard';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HlDisplayEdiOrderListConfigService extends EdiOrderListConfigService {

  getItems(): Observable<EdiOrder[]> {
    return super.getItems().pipe(
      map(edis =>
        edis.filter(edi => !!edi.buyerGln)
      )
    );
  }
}
