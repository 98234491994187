import { Inject, Injectable, Optional, Type } from '@angular/core';
import { Router } from '@angular/router';
import {
  AuthService,
  CartSalesStepComponent,
  CartTotalStepComponent,
  CheckoutStepComponent,
  GungFlowService,
  NavigationConfig,
  StripeCheckoutService
} from 'gung-standard';
import {
  DeliveryLocationsJeevesComponent,
  JeevesCheckoutConfigService,
  JeevesCustomerConfigService,
  OrderTermsJeevesComponent,
  SummarizeOrderJeevesComponent
} from 'gung-standard-jeeves';
import { first, forkJoin, map, Observable, of, switchMap } from 'rxjs';
import { HlAssignDefaultsComponent } from '../components/checkout/hl-assign-defaults/hl-assign-defaults.component';
import { HlCartListComponent } from '../components/checkout/hl-cart-list/hl-cart-list.component';
import { HlCheckoutButtonsPortalComponent } from '../components/checkout/hl-checkout-buttons-portal/hl-checkout-buttons-portal.component';
import { HlShippingStepPortalComponent } from '../components/checkout/hl-shipping-step-portal/hl-shipping-step-portal.component';
import { HlStepIndicatorComponent } from '../components/checkout/hl-step-indicator/hl-step-indicator.component';
import { HlSummarizeOrderStepPortalComponent } from '../components/checkout/hl-summarize-order-step-portal/hl-summarize-order-step-portal.component';
import { HlCartPrevalidationStepComponent } from '../components/checkout/steps/hl-cart-prevalidation-step/hl-cart-prevalidation-step.component';
import { HlDisplayAdditionalCostsStepComponent } from '../components/checkout/steps/hl-display-additional-costs-step/hl-display-additional-costs-step.component';
import { CheckoutSettingsGroup, HlDisplayPortalFeaturesService } from './hl-display-portal-features.service';
import { HlDisplayStripePaymentStepComponent } from '../components/checkout/steps/hl-display-stripe-payment-step/hl-display-stripe-payment-step.component';

@Injectable({
  providedIn: 'root'
})
export class HlCheckoutConfigService extends JeevesCheckoutConfigService {
  constructor(
    router: Router,
    protected authService: AuthService,
    protected gungFlowService: GungFlowService,
    protected jeevesCustomerConfigService: JeevesCustomerConfigService,
    @Optional()
    @Inject('environment')
    protected environment: NavigationConfig,
    protected hlDisplayPortalFeaturesService: HlDisplayPortalFeaturesService,
    protected stripeCheckoutService: StripeCheckoutService
  ) {
    super(router, authService, gungFlowService, jeevesCustomerConfigService, environment);
  }

  getPreProcessSteps() {
    return super.getPreProcessSteps().pipe(
      switchMap(steps =>
        forkJoin({
          steps: of(steps),
          roles: this.authService.getRoles().pipe(first())
        })
      ),
      map(({ steps, roles }) => {
        const toReturn: Type<CheckoutStepComponent>[] = steps;

        if (roles.includes('SALES')) {
          toReturn.push(HlCartPrevalidationStepComponent);
        }

        return toReturn;
      })
    );
  }

  getMainProcessSteps() {
    return super.getMainProcessSteps().pipe(
      switchMap(steps =>
        forkJoin({
          steps: of(steps),
          roles: this.authService.getRoles().pipe(first()),
          checkoutSettingsGroup: this.hlDisplayPortalFeaturesService
            .getPortalFeatureGroup('orderCheckoutSettingsGroup')
            .pipe(
              first(),
              map(settingsGroup => settingsGroup as CheckoutSettingsGroup)
            )
        })
      ),
      map(({ steps, roles, checkoutSettingsGroup }) => {
        const toReturn: Type<CheckoutStepComponent>[] = steps;

        // replace delivery locations
        let idx = steps.indexOf(DeliveryLocationsJeevesComponent);
        if (idx >= 0) {
          toReturn[idx] = HlShippingStepPortalComponent;
        }

        // replace order terms
        idx = toReturn.indexOf(OrderTermsJeevesComponent);
        if (idx >= 0) {
          toReturn.splice(toReturn.indexOf(OrderTermsJeevesComponent), 1);
        }

        idx = toReturn.indexOf(SummarizeOrderJeevesComponent);
        if (idx >= 0) {
          toReturn[idx] = HlSummarizeOrderStepPortalComponent;
        }

        idx = toReturn.indexOf(CartSalesStepComponent);
        if (idx >= 0) {
          toReturn[idx] = HlCartListComponent;
        } else {
          idx = toReturn.indexOf(CartTotalStepComponent);
          if (idx >= 0) {
            toReturn[idx] = HlCartListComponent;
          }
        }

        // READ THIS BEFORE CHANGIN THE ORDER OF THE STEPS BELOW!

        // This step ensures that freight, admin fee and small order fee gets correctly applied to the order
        // This type of step should generally be used as a post step, as it expects the checkout to not be modified after this step in the process.
        // The only reason that it is used as a main step here is because it is a requirement for the checkout to be modified with the additional costs before the payment step.
        // since the payment step redirects the user away from the checkout process. - Adam
        if (checkoutSettingsGroup.useTradePolicy) {
          toReturn.push(HlDisplayAdditionalCostsStepComponent);
        }

        if (this.stripeCheckoutService.isEnabled()) {
          toReturn.push(HlDisplayStripePaymentStepComponent);
        }

        return toReturn;
      })
    );
  }

  getInternalPreProcessSteps(): Observable<Type<CheckoutStepComponent>[]> {
    const result = [HlAssignDefaultsComponent] as Type<CheckoutStepComponent>[];

    return of(result);
  }

  getStepIndicator() {
    return HlStepIndicatorComponent;
  }

  getButtonsComponent() {
    return HlCheckoutButtonsPortalComponent;
  }
}
