import { Injectable } from '@angular/core';
import { SimpleConfigBaseFilter } from 'gung-list';
import { MetadataService, Product } from 'gung-standard';
import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductStoreCategoryFilterService extends SimpleConfigBaseFilter<Product> {
  private externalCategoriesCache: any = {};

  constructor(protected metadataService: MetadataService) {
    super();
  }

  SEGMENT: string = 'ProductStoreCategory';
  SEGMENT_META: string = 'StoreCategory';

  getName(): string {
    return 'STORE_CATEGORY';
  }

  getOptionIds(item: Product): string[] {
    if (!!item.extra.hlPimProduct && !!item.extra.hlPimProduct[this.SEGMENT]) {
      const value = item.extra.hlPimProduct[this.SEGMENT];

      let productExternalCategoryIds: string[] = [];
      if (Array.isArray(value)) {
        productExternalCategoryIds = value;
      } else {
        productExternalCategoryIds = [value];
      }

      const result = productExternalCategoryIds.map(key => {
        if (this.externalCategoriesCache[key]) return this.externalCategoriesCache[key];

        const productExternalCategoryValue = this.getI18nProductExternalCategory(item, key);

        if (!!productExternalCategoryValue) {
          this.externalCategoriesCache[key] = productExternalCategoryValue;
          return productExternalCategoryValue;
        }
      });

      return result;
    }

    return [];
  }

  getOptionName(key: string): string {
    return key;
  }

  private getI18nProductExternalCategory(item: Product, key: string): string {
    // in case not find a product external category for the selected language then use the default

    const selectedLanguage = localStorage.getItem('selectedLanguage') || 'en';
    if (!!item.extra.i18n) {
      if (
        !!item.extra.i18n[selectedLanguage] &&
        !!item.extra.i18n[selectedLanguage][this.SEGMENT_META] &&
        !!item.extra.i18n[selectedLanguage][this.SEGMENT_META][key]
      ) {
        return item.extra.i18n[selectedLanguage][this.SEGMENT_META][key];
      }

      const defaultLanguage = 'en';

      if (
        !!item.extra.i18n[defaultLanguage] &&
        !!item.extra.i18n[defaultLanguage][this.SEGMENT_META] &&
        !!item.extra.i18n[defaultLanguage][this.SEGMENT_META][key]
      ) {
        return item.extra.i18n[defaultLanguage][this.SEGMENT_META][key];
      }

      return key;
    }

    return null;
  }
}
