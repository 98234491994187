<div class="modal-body">
  <form [formGroup]="form" *ngIf="form">
    <div class="form-group row" *ngIf="customer">
      <label for="customer" class="col-form-label col-md-4 col-12 text-right" translate>CUSTOMER</label>
      <div class="col-md-8 col-12">
        <input type="text" name="customer" id="customer" class="form-control" readonly [value]="customer.name" />
      </div>
    </div>

    <!-- <div class="form-group row" formGroupName="extra">
      <label for="contact" class="col-form-label col-md-4 col-12 text-right" translate>CONTACT</label>
      <div class="col-md-8 col-12" formGroupName="hr">
        <input type="text" name="contact" id="contact" class="form-control" formControlName="editext">
      </div>
    </div> -->

    <div class="form-group row">
      <label for="contact" class="col-form-label col-md-4 col-12 text-right" translate>CONTACT</label>
      <div class="col-md-8 col-12">
        <div *ngFor="let ediTextContact of ediTextContacts; let i = index; trackBy: trackByFn" class="d-flex mb-2">
          <!-- <input class="form-control" type="text" [(ngModel)]="ediTextContacts[i]"
            [ngModelOptions]="{standalone: true}"> -->
          <ng-container *ngTemplateOutlet="searchElement; context: { $implicit: ediTextContacts[i], index: i }">
          </ng-container>
          <button type="button" class="btn btn-danger align-self-end" (click)="removeContact(i)">
            <i class="fas fa-times-circle"></i>
          </button>
        </div>
        <button type="button" class="btn btn-primary" (click)="addContact()">
          <span translate>ADD_CONTACT</span><i class="fas fa-plus-circle ml-1"></i>
        </button>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4 col-12">
        <div class="form-group row">
          <label for="date" class="col-12 col-form-label text-right" translate>DATE</label>
        </div>
      </div>
      <div class="col-6 col-md-5 col-lg-3">
        <div class="form-group row" formGroupName="extra">
          <div class="col-12" formGroupName="hr">
            <input type="date" name="date" id="date" class="form-control" formControlName="eventpldat" />
          </div>
        </div>
      </div>
      <div class="col-6 col-md-3 col-lg-2">
        <div class="form-group row" formGroupName="extra">
          <div class="col-12" formGroupName="hr">
            <input type="time" name="time" id="date" class="form-control" formControlName="eventpltime" />
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-3" formGroupName="extra">
        <div class="form-check" formGroupName="hr">
          <input
            class="form-check-input"
            type="checkbox"
            id="SYNC_OUTLOOK"
            name="SYNC_OUTLOOK"
            formControlName="q_hl_sync2outlook"
          />
          <label class="form-check-label" for="SYNC_OUTLOOK" translate>SYNC_OUTLOOK</label>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 col-12">
        <div class="form-group row">
          <label for="duration" class="col-12 col-form-label text-right" translate>DURATION</label>
        </div>
      </div>
      <div class="col-12 col-md-8 col-lg-8" formGroupName="extra">
        <div class="d-flex align-items-center justify-content-between" formGroupName="hr">
          <select style="width: 35%;" name="duration" id="duration" class="form-control" formControlName="eventtimant2">
            <option *ngFor="let duration of durations" [ngValue]="duration">{{ duration }}</option>
          </select>
          <div style="width: 30%;" class="d-flex align-items-center justify-content-between">
            <span class="ml-2" translate>HOURS</span>

            <span class="mr-2" translate>REMINDER</span>
          </div>
          <input type="date" name="reminderdate" id="reminderdate" class="form-control" formControlName="kvittdatum" style="width: 35%;" />
        </div>
      </div>
    </div>
    <!--
    <div class="form-group row" style="margin-bottom: 0px;" formGroupName="extra">
      <label class="col-form-label col-md-4 col-12 text-right" translate>MEETING_TYPE</label>
      <div class="col-md-8 col-12" formGroupName="hr">
        <lib-meta-select-input suppressLabel="true" formControlName="eventkod1" table="xa" metaId="eventkod1"
          metaDisplay="eventbeskr">
        </lib-meta-select-input>
      </div>
    </div>
-->
    <div class="form-group row" formGroupName="extra">
      <label class="col-form-label col-md-4 col-12 text-right" translate>MEETING_TYPE</label>
      <div class="col-md-8 col-12" formGroupName="hr">
        <select class="form-control" formControlName="eventkod1">
          <option *ngFor="let xa of xaFilteredMapped" [value]="xa.id">{{ xa.name }}</option>
        </select>
      </div>
    </div>
    <div class="form-group row">
      <label for="system" class="col-form-label col-md-4 col-12 text-right" translate>SYSTEM</label>

      <div class="col-md-8 col-12" formGroupName="extra">
        <div class="row">
          <div class="col-md-6 col-12" *ngFor="let system of form.controls.systems2.controls; let i = index">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" [formControl]="system" />
              <label class="form-check-label" for="system">{{ qSystemFilteredMapped[i].name }}</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <div class="form-group row" *ngIf="!isSalesPortal">
      <label for="system" class="col-form-label col-md-4 col-12 text-right" translate>PRODUCT_GROUP</label>

      <div class="col-md-8 col-12" formGroupName="extra">
        <div class="row">
          <div
            class="col-md-6 col-12"
            *ngFor="let productGroup of form.controls.productGroups2.controls; let i = index"
          >
            <div class="form-check">
              <input class="form-check-input" type="checkbox" [formControl]="productGroup" />
              <label class="form-check-label" for="productGroup">{{ qVGFilteredMapped[i].name }}</label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label for="system" class="col-form-label col-md-4 col-12 text-right" translate>SALES_CYCLE</label>

      <div class="col-md-8 col-12" formGroupName="extra">
        <div class="row">
          <div class="col-md-6 col-12" *ngFor="let salesCycle of form.controls.salesCycles2.controls; let i = index">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" [formControl]="salesCycle" />
              <label class="form-check-label" for="salesCycle">{{ qSalesCyclesFilteredMapped[i].name }}</label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--
    <div class="form-group row" style="margin-bottom: 0px;" formGroupName="extra">
      <label for="system" class="col-form-label col-md-4 col-12 text-right" translate>SALES_CYCLE</label>
      <div class="col-md-8 col-12">
        <div class="form-group" formGroupName="hr">
          <select class="form-control" formControlName="assetid" name="assetid" id="assetid">
            <option></option>
            <option [value]="10">Beverages</option>
          </select>
        </div>
      </div>
    </div>
-->
    <div class="form-group row" formGroupName="extra">
      <label for="note" class="col-form-label col-md-4 col-12 text-right" translate>EMAIL_TEXT</label>
      <div class="col-md-8 col-12" formGroupName="hr">
        <textarea rows="3" type="text" name="note" id="note" class="form-control" formControlName="editint"></textarea>
      </div>
    </div>
    <div class="form-group row" formGroupName="extra">
      <label for="note" class="col-form-label col-md-4 col-12 text-right" translate>NOTE</label>
      <div class="col-md-8 col-12" formGroupName="hr">
        <textarea rows="3" type="text" name="note" id="note" class="form-control" formControlName="edit"></textarea>
      </div>
    </div>
  </form>
  <div class="row mx-5">
    <div class="col-12">
      <div
        *ngIf="alertMessage"
        class="alert mt-2 text-center"
        role="alert"
        [ngClass]="{ 'alert-success': alertMessage.type === 'success', 'alert-danger': alertMessage.type === 'danger' }"
        (click)="alertMessage = null"
      >
        <span>{{ alertMessage.message | translate }}</span>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary px-5" (click)="activeModal.dismiss('cancel click')" translate>
    CANCEL
  </button>
  <button *ngIf="!isEdit" type="button" class="btn btn-outline-primary px-5" (click)="onSave()" translate>SAVE</button>
  <button *ngIf="isEdit" type="button" class="btn btn-outline-primary px-5" (click)="onRemove()" translate>
    REMOVE
  </button>
  <button *ngIf="isEdit" type="button" class="btn btn-primary px-5" (click)="onEdit()" translate>SAVE</button>
</div>

<ng-template #searchElement let-searchmodel let-index="index">
  <input
    #searchInput
    id="typeahead-prevent-manual-entry"
    type="text"
    class="form-control"
    [(ngModel)]="ediTextContacts[index]"
    [ngbTypeahead]="search"
    [inputFormatter]="formatter"
    [resultFormatter]="resultFormatter"
    (selectItem)="selectItem($event, searchInput)"
    placeholder="{{ 'CONTACT' | translate }}"
    (focusout)="focusout($event, modelSearch)"
    (ngModelChange)="ngModelChange($event, ediTextContacts[index])"
    [ngModelOptions]="{ standalone: true }"
    [showHint]="false"
    [editable]="true"
  />
</ng-template>
<!--

<div class="modal-body" [formGroup]="form">
    <div class="row mt-3">
      <div class="col-12 col-md-4 d-flex justify-content-md-end align-items-center">
        <label class="m-0" for="customer" translate>CUSTOMER</label>
      </div>
      <div class="col-12 col-md-8">
        <input type="text" class="form-control" id="customer" name="customer" readonly
          formControlName="customerId">
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12 col-md-4 d-flex justify-content-md-end align-items-center">
        <label class="m-0" for="contact" translate>CONTACT</label>
      </div>
      <div class="col-12 col-md-8">
        <input type="text" class="form-control" id="contact" name="contact"
          formControlName="name">
      </div>
    </div>
    <div class="row mt-3" formGroupName="extra">
      <div class="col-12 col-md-4 d-flex justify-content-md-end align-items-center">
        <label class="m-0" for="contact" translate>DATE</label>
      </div>
      <div class="col-6 col-md-5">
        <app-date-picker class="meeting" [initialDate]="form.get('extra.hr.eventpldat').value" (selectedDate)="outputSelectedDate($event)"></app-date-picker>
      </div>
      <div *ngIf="!isEdit" formGroupName="hr" class="col-6 col-md-3 d-flex justify-content-center align-items-center">
        <label class="m-0" for="sync" translate>SYNC_OUTLOOK</label>:
        <input type="checkbox" class="ml-1" id="sync" name="sync" formControlName="autoconnect">
      </div>
    </div>
    <div class="row mt-3" formGroupName="extra">
      <div class="col-12 col-md-4 d-flex justify-content-md-end align-items-center">
        <label class="m-0" for="type" translate>MEETING_TYPE</label>
      </div>
      <div class="col-12 col-md-8" formGroupName="hr">
        <select class="form-control" id="type" name="type" formControlName="ftgnr">
          <option>Scheduled sales meeting, pre populated - dtop down</option>
          <option *ngFor="let type of listMeetingType" [value]="type.id">{{type.name}}</option>
        </select>
      </div>
    </div>
    <div class="row mt-3" formGroupName="extra">
      <div class="col-12 col-md-4 d-flex justify-content-md-end align-items-start">
        <label class="m-0" for="system" translate>SYSTEM</label>
      </div>
      <div class="col-12 col-md-8" formGroupName="hr">
        <div *ngFor="let type of listSystem" class="d-flex align-items-center mb-1">
          <input #checkbox type="checkbox" class="mr-1" id="sync" name="sync" [value]="type.q_systemid"
          (change)="checkValue(type.q_systemid, $event.currentTarget.checked)">
          <label class="m-0" for="sync" translate>{{type.q_systemdescription}}</label>
        </div>
      </div>
    </div>
    <div class="row mt-3" formGroupName="extra">
      <div class="col-12 col-md-4 d-flex justify-content-md-end align-items-center">
        <label class="m-0" for="product" translate>PRODUCT_GROUP</label>
      </div>
      <div class="col-12 col-md-8" formGroupName="hr">
        <select class="form-control" id="product" name="product" formControlName="eventkod1">
          <option>Drop down, from Jeeves, multi select, depende on above</option>
          <option *ngFor="let type of listItemGroup" [value]="type.id">{{type.name}}</option>
        </select>
      </div>
    </div>
    <div class="row mt-3" formGroupName="extra">
      <div class="col-12 col-md-4 d-flex justify-content-md-end align-items-center">
        <label class="m-0" for="sales" translate>SALES_CYCLE</label>
      </div>
      <div class="col-12 col-md-8" formGroupName="hr">
        <select class="form-control" id="sales" name="sales" x="foretagkod">
          <option>Drop down, from Gung</option>
          <option *ngFor="let type of listSalesCycle" [value]="type.id">{{type.name}}</option>
        </select>
      </div>
    </div>
    <div class="row mt-3" formGroupName="extra">
      <div class="col-12 col-md-4 d-flex justify-content-md-end align-items-top">
        <label class="m-0" for="note" translate>NOTE</label>
      </div>
      <div class="col-12 col-md-8" formGroupName="hr">
        <textarea class="form-control" id="note" name="note" style="resize: none;" formControlName="eventnote"></textarea>
      </div>
    </div>
  </div>

  <div class="row mx-5">
    <div class="col-12">
        <div *ngIf="alertMessage" class="alert mt-2 text-center" role="alert"
            [ngClass]="{'alert-success':alertMessage.type === 'success','alert-danger':alertMessage.type === 'danger'}"
            (click)="alertMessage = null">
            <span>{{alertMessage.message | translate}}</span>
        </div>
    </div>
  </div>

  <div class="modal-footer ">
    <button type="button" class="btn btn-outline-secondary px-5" (click)="activeModal.dismiss('cancel click');" translate>CANCEL</button>
    <button *ngIf="!isEdit" type="button" class="btn btn-outline-primary px-5" (click)="onSave()" translate>SAVE</button>
    <button *ngIf="isEdit" type="button" class="btn btn-outline-primary px-5" (click)="onRemove()" translate>REMOVE</button>
    <button *ngIf="isEdit" type="button" class="btn btn-primary px-5" (click)="onEdit()" translate>SAVE</button>
  </div>

-->
