import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Scheduler, JobKey } from '../../models/scheduler/scheduler';
import { first, map } from 'rxjs';
import { DateUtilService } from '../date-util/date-util.service';

@Injectable({
  providedIn: 'root'
})
export class SchedulersService {
  protected baseUrl = `json/quartz-schedulers`;

  public intervaleSeconds = 5;

  constructor(protected http: HttpClient, public dateUtilService: DateUtilService) {}

  public getSchedulers(nocache?: boolean): Observable<Scheduler[]> {
    const url = `${this.baseUrl}`;
    const headers = {
      maxAge: nocache ? '-1' : '300'
    };

    return this.http.get<Scheduler[]>(url, { headers }).pipe(
      first(),
      map(schedulers =>
        schedulers.map((scheduler: Scheduler) => ({
          ...scheduler,
          nextFireTime: this.dateUtilService.parseDate(scheduler.nextFireTime, 'yyyy-MM-dd')
        }))
      )
    );
  }

  public triggerJob(jobKey: JobKey) {
    const url = `${this.baseUrl}/trigger-job-immediately`;
    return this.http.post<{ jobKey: JobKey }>(url, jobKey);
  }

  public clearAllErrors() {
    const url = `${this.baseUrl}/clear-all-errors`;
    return this.http.post<{ status: string }>(url, undefined);
  }

  parseCronExpressionToText(cronExpression: string): string {
    const cronParser: CronParser = new CronParser();
    return cronParser.parseCronExpression(cronExpression);
  }
}
class CronParser {
  requiredFields: number = 6;
  maximumFields: number = 7;

  parseCronExpression(cronExpression: string): string {
    cronExpression = cronExpression.trim();
    const fields = cronExpression.split(' ');

    if (!this.isValidCronLength(fields)) {
      throw new Error(
        `Invalid number of fields. Expected ${this.requiredFields} - ${this.maximumFields}, but got ${fields.length}.`
      );
    }

    const [seconds, minutes, hours, dayOfMonth, month, dayOfWeek, year] = fields;
    
    
    const cronFields: StandardCronField[] = [
      new SecondsField(seconds),
      new MinutesField(minutes),
      new HoursField(hours),
      new DayOfMonthField(dayOfMonth),
      new DayOfWeekField(dayOfWeek),
      new MonthField(month),
      new YearField(year)
    ];
    
    let descriptions: string[] = [];
    for (const cronField of cronFields) {
      const parsedField = cronField.parseField();
      if (parsedField.length > 0) {
        descriptions.push(parsedField);
      }
      if (cronField.name === 'Year' && cronField.value === '2099') {
        return 'Must be started manually';
      }
    }
    return 'Runs ' + descriptions.join(', ') + '.';
  }

  private isValidCronLength(fields: string[]): boolean {
    return fields.length === this.requiredFields || fields.length === this.maximumFields;
  }
}

interface CronField {
  value: string;
  name: string;
  allowedValues: string[];
  allowedSpecialCharacters: string;

  parseField(value: string): string;
}

const onlyNumbers = /^[0-9]+$/;
const onlyLetters = /^[A-Z]+$/;

class StandardCronField implements CronField {
  value: string;
  name!: string;
  allowedValues: string[];
  allowedSpecialCharacters: string = ',-*/';

  constructor(value: string) {
    if (value == undefined) {
      this.value = '';
    } else {
      this.value = value;
    }
  }

  parseField(): string {
    const parts = this.value?.split(',');

    return this.parseParts(parts).join(', ');
  }

  private parseParts(parts: string[]): string[] {
    let result: string[] = [];
    for (let i = 0; i < parts.length; i++) {
      const part = parts[i];

      if (part.includes('/')) {
        let [value, increment] = part.split('/');
        if(value.length > 1){
          value = value.replace(/^0+/, '');
        }
        if(increment.length > 1){
        increment = increment.replace(/^0+/, '');
        }
        if ((!this.isValid(value) && value != '*') || !this.isValid(increment)) {
          result.push(this.errorParse())
        }else{
          result.push(this.parseIncrement(value, increment));
        }       
      } else if (part.includes('*')) {
        if (parts.length > 1) {
          result.push(this.errorParse())         
        }else{
          result.push('every ' + this.name.toLowerCase());
        }        
      } else if (part.includes('?')) {
        if (parts.length > 1) {
          result.push(this.errorParse())   
        }
      } else if (part.includes('-')) {
        const [start, end] = part.split('-');
        if (!this.isValid(start) || !this.isValid(end)) {
          result.push(this.errorParse())   
        }else{
          result.push(this.parseRange(start, end));
        }

        
      } else if (part.toLowerCase() === 'l') {
        if (!this.isValid(part)) {
          result.push(this.errorParse())   
        }else{
          result.push(this.parseLastDayOfMonth());
        }

        
      } else if (part.toLowerCase() === 'w') {
        if (!this.isValid(part)) {
          result.push(this.errorParse())   
        }else{
          result.push(this.parseWeekdayOfMonth());
        }
      } else if (part.includes('#')) {
        const [dayOfWeek, occurrence] = part.split('#');
        if (!this.isValid(dayOfWeek) || !this.isValid(occurrence)) {
          result.push(this.errorParse())   
        }else{
          result.push(this.parseNthDayOfWeek(dayOfWeek, occurrence));
        }
        
      } else if (onlyNumbers.test(part)) {
        if (!this.isValid(part)) {
          result.push(this.errorParse())   
        }
        if (part != '0') {
          if (i == 0) {
            result.push(`at ${this.name}: ${part}`.toLowerCase());
          } else {
            result.push(part);
          }
        }
      } else if (onlyLetters.test(part)) {
        result.push(this.parseLetters(part));
      }
    }

    return result;
  }

  errorParse() {
    return 'Cannot parse Cron';
  }

  private parseLetters(part: string): string {
    if (this.name === 'Day of Week') {
      return 'every ' + this.getWeekDay(part);
    } else if (this.name === 'Month') {
      return 'every ' + this.getMonth(part);
    } else {
      throw new Error(`Invalid use of ${part} in ${this.name} field.`);
    }
  }
  private isValid(value: string): boolean {
    if(value.length > 1){
    value = value.replace(/^0+/, '');
    }
    return this.allowedValues.map(item => item.toLowerCase()).includes(value.toLowerCase());
  }

  private parseRange(start: string, end: string): string {
    return `between ${this.name.toLowerCase()}s ${start} and ${end}`;
  }

  private parseIncrement(value: string, increment: string): string {
    if (value === '*') {
      return ' every ' + increment + ' ' + this.name.toLowerCase() + (parseInt(increment) > 1 ? 's' : '');
    }
    return ` every ${increment} ${this.name.toLowerCase()}${
      parseInt(increment) > 1 ? 's' : ''
    } starting at ${this.name.toLowerCase()}: ${value}`;
  }

  private parseLastDayOfMonth(): string {
    if (this.name === 'Day of Week') {
      return 'the last ' + this.allowedValues[6].toLowerCase();
    } else if (this.name === 'Day of Month') {
      return 'the last day of the month';
    } else {
      throw new Error(`Invalid use of "L" character in ${this.name} field.`);
    }
  }

  private parseWeekdayOfMonth(): string {
    if (this.name === 'Day of Month') {
      return 'the weekday nearest the given day';
    } else {
      throw new Error(`Invalid use of "W" character in ${this.name} field.`);
    }
  }

  private parseNthDayOfWeek(dayOfWeek: string, occurrence: string): string {
    return `the ${occurrence + this.getOrdinalSuffix(occurrence)} ${this.getWeekDay(
      dayOfWeek.toLowerCase()
    )} of the month`;
  }

  private getWeekDay(dayOfWeek: string): string {
    if (onlyLetters.test(dayOfWeek)) {
      dayOfWeek = this.allowedValues[this.allowedValues.indexOf(dayOfWeek) - 7];
    }
    switch (dayOfWeek) {
      case '1':
        return 'Sunday';

      case '2':
        return 'Monday';

      case '3':
        return 'Tuesday';

      case '4':
        return 'Wednesday';

      case '5':
        return 'Thursday';

      case '6':
        return 'Friday';

      case '7':
        return 'Saturday';

      default:
        return this.capitalizeFirstLetter(dayOfWeek);
    }
  }

  private getMonth(month: string): string {
    if (onlyLetters.test(month)) {
      month = this.allowedValues[this.allowedValues.indexOf(month) - 12];
    }
    switch (month) {
      case '1':
        return 'January';

      case '2':
        return 'February';

      case '3':
        return 'March';

      case '4':
        return 'April';

      case '5':
        return 'May';

      case '6':
        return 'June';

      case '7':
        return 'July';

      case '8':
        return 'August';

      case '9':
        return 'September';

      case '10':
        return 'October';

      case '11':
        return 'November';
      case '12':
        return 'December';

      default:
        return this.capitalizeFirstLetter(month);
    }
  }

  private capitalizeFirstLetter(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  private getOrdinalSuffix(n: string): string {
    const number = parseInt(n, 10);
    const suffixes = ['th', 'st', 'nd', 'rd'];
    const suffixIndex = number % 100 > 10 && number % 100 < 20 ? 0 : number % 10;
    return number.toString() + suffixes[suffixIndex];
  }
}

class SecondsField extends StandardCronField {
  name: string = 'Second';
  allowedValues: string[] = Array.from(Array(60).keys()).map(String);
  allowedSpecialCharacters: string = ',-*/';
}

class MinutesField extends SecondsField {
  name: string = 'Minute';
}

class HoursField extends StandardCronField {
  name: string = 'Hour';
  allowedValues: string[] = Array.from(Array(24).keys()).map(String);
}

class DayOfMonthField extends StandardCronField {
  name: string = 'Day of Month';
  allowedValues: string[] = Array.from(Array(31).keys()).map(i => (i + 1).toString());
  allowedSpecialCharacters: string = ',-*/?LW#';
}

class MonthField extends StandardCronField {
  name: string = 'Month';
  allowedValues: string[] = Array.from(Array(12).keys())
    .map(i => (i + 1).toString())
    .concat(['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']);
}

class DayOfWeekField extends StandardCronField {
  name: string = 'Day of Week';
  allowedValues: string[] = Array.from(Array(7).keys())
    .map(i => (i + 1).toString())
    .concat(['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT']);
  allowedSpecialCharacters: string = ',-*/?L#';
}

class YearField extends StandardCronField {
  name: string = 'Year';
  allowedValues: string[] = ['empty'].concat(Array.from(Array(2099 - 1970 + 1).keys()).map(i => (i + 1970).toString()));
}
