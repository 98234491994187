import { Injectable } from '@angular/core';
import { SimpleConfigBaseFilter } from 'gung-list';
import { Product } from 'gung-standard';

@Injectable({
  providedIn: 'root'
})
export class ProductPimFilterService extends SimpleConfigBaseFilter<Product> {
  constructor(
    protected filterLabel: string,
    protected segment: string,
    protected field: string,
    protected addToName = '',
    protected isList = false
  ) {
    super();
  }

  getName(): string {
    return this.filterLabel;
  }
  getOptionIds(item: Product): string[] {
    let options = [];
    if (this.isList) {
      options = item.extra[this.segment][this.field] || [];
    } else {
      options = [item.extra[this.segment][this.field] || ''];
    }
    return options;
  }
  getOptionName(key: string): string {
    return key + this.addToName;
  }
}
