import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, switchMap, take, mergeMap, first, debounce, filter } from 'rxjs';
import { Observable, forkJoin, of, timer } from 'rxjs';
import {
  ProductPrice,
  CustomerProductPrice,
  BackendPrice,
  BackendPriceLevel,
  CartRowPrice,
  Pricelist
} from '../../models/price';
import { SelectedCustomerService } from '../selected-customer/selected-customer.service';
import { ProductService } from '../products/product.service';
import { Customer } from '../../models';
import { GungStringConverterService } from '../gung-string-converter.service';
import { CartRow } from '../../state/cart/types';
import { GungFlowService } from '../gung-flow/gung-flow.service';
import { gungComparatorHelper } from '../../utils/gung-utils';

@Injectable({
  providedIn: 'root'
})
export class PriceService {
  protected cachedCustomerPrices: { [productId: string]: BackendPrice } = {};
  public qtyGroupingLevel: 'MODEL' | 'VARIANT' | 'SKU';

  constructor(
    protected http: HttpClient,
    protected customerService: SelectedCustomerService,
    protected productService: ProductService,
    protected gungFlowService: GungFlowService
  ) {
    customerService.getSelectedCustomer().subscribe(() => (this.cachedCustomerPrices = {}));

    gungFlowService.getSelectedFlow().subscribe(() => (this.cachedCustomerPrices = {}));
  }

  getCurrentCustomerPrice(productId: string): Observable<CustomerProductPrice> {
    return this.customerService
      .getSelectedCustomer()
      .pipe(switchMap(cust => this.getCustomerPrice(productId, cust.id)));
  }

  getCurrentCustomerPrices(productIds: string[]): Observable<CustomerProductPrice[]> {
    return this.customerService
      .getSelectedCustomer()
      .pipe(switchMap(cust => this.getCustomerPrices(productIds, cust.id)));
  }

  getCurrentCustomerPriceWithQty(input: { [productId: string]: number }): Observable<CustomerProductPrice[]> {
    return this.customerService
      .getSelectedCustomer()
      .pipe(filter(cust => !!cust), switchMap(cust => this.getCustomerPricesWithQtys(input, cust.id)));
  }

  getPrice(productId: string): Observable<ProductPrice> {
    // TODO
    return null;
  }

  getCustomerPrice(productId: string, customerId: string): Observable<CustomerProductPrice> {
    return this.internalHttpGetCustomerPrice(productId, customerId).pipe(
      mergeMap(x => forkJoin([of(x), this.customerService.getSelectedCustomer().pipe(first())])),
      map(([backendPrice, customer]) => {
        return this.mapCustomerProductPrice(backendPrice, customer);
      })
    );
  }

  getCustomerPrices(productIds: string[], customerId: string): Observable<CustomerProductPrice[]> {
    return this.internalHttpGetCustomerPrices(productIds, customerId).pipe(
      switchMap(x => forkJoin([of(x), this.customerService.getSelectedCustomer().pipe(first())])),
      map(([backendPrices, customer]) => {
        return Object.values(backendPrices).map(backendPrice => {
          return this.mapCustomerProductPrice(backendPrice, customer);
        });
      })
    );
  }

  getCustomerPricesWithQtys(
    input: { [productId: string]: number },
    customerId: string
  ): Observable<CustomerProductPrice[]> {
    return this.internalHttpGetCustomerPrices(Object.keys(input), customerId).pipe(
      switchMap(resp =>
        forkJoin({
          prices: of(resp),
          customer: this.customerService.getSelectedCustomer().pipe(first())
        })
      ),
      map(({ prices, customer }) => {
        return Object.values(prices).map(price =>
          this.mapCustomerProductPrice(price, customer, input[price.productId])
        );
      })
    );
  }

  public getPriceLevel(backendPrice: BackendPrice, qty?: number): BackendPriceLevel {
    let level = backendPrice.levels[0];

    if (!qty) {
      return level;
    }
    // Don't modify the array!
    const allSortedLevels = [...backendPrice.levels].sort((a, b) => gungComparatorHelper(a.quantity, b.quantity, 1));
    // loop till I am at the level which is right below too much
    for (let i = 0; i < allSortedLevels.length; i++) {
      const thisLevel = allSortedLevels[i];
      if (thisLevel.quantity > qty) {
        break;
      }
      level = thisLevel;
    }
    if (level && level.discount) {
      level.discount = Math.abs(level.discount);
    }
    return level;
  }

  protected mapCustomerProductPrice(backendPrice: BackendPrice, customer: Customer, qty?: number) {
    const priceLevel = this.getPriceLevel(backendPrice, qty);

    // TODO - rename? not necessarily customerDiscount, can be staffed price discount
    const customerDiscountPercent = this.getCustomerDiscountPercent(priceLevel, backendPrice);

    const customerGrossPrice = priceLevel.price / ((100 - customerDiscountPercent) / 100);
    const customerNetPrice = priceLevel.price;

    const customerProductPrice: CustomerProductPrice = {
      productId: backendPrice.productId,
      customerId: customer.id,
      customerDiscountPercent,
      customerGrossPrice: {
        value: customerGrossPrice,
        currencyCode: ''
      },
      customerNetPrice: {
        value: customerNetPrice,
        currencyCode: ''
      },
      customerDiscountAmount: {
        value: customerGrossPrice - customerNetPrice,
        currencyCode: ''
      },
      backendPriceLevel: priceLevel,
      priceFactor: backendPrice.priceFactor,
      backendPrice: backendPrice
    };

    return customerProductPrice;
  }

  getCustomerDiscountPercent(priceLevel: BackendPriceLevel, backendPrice: BackendPrice) {
    return priceLevel.discount;
  }

  protected internalHttpGetCustomerPrice(productId: string, customerId: string): Observable<BackendPrice> {
    const convertedId = GungStringConverterService.toGungString(productId);

    return this.gungFlowService.getSelectedFlow().pipe(
      first(),
      switchMap(selectedFlow =>
        this.http.get<BackendPrice>(`json/product-price/${customerId}/${convertedId}?flowId=${selectedFlow.id}`, {
          headers: { maxAge: '86400' }
        })
      )
    );
  }

  protected internalHttpGetCustomerPrices(
    productIds: string[],
    customerId: string
  ): Observable<{ [productId: string]: BackendPrice }> {
    const newListProductsIds = productIds.filter(productId => !this.cachedCustomerPrices[productId]);

    return this.gungFlowService.getSelectedFlow().pipe(
      first(),
      switchMap(selectedFlow =>
        newListProductsIds.length > 0
          ? this.http.post<{ [productId: string]: BackendPrice }>(
              `json/product-price-customer/${customerId}?flowId=${selectedFlow.id}`,
              newListProductsIds
            )
          : of(null)
      ),
      map((customerPrices: { [productId: string]: BackendPrice }) => {
        // in case any data from backend
        // then store it in cache
        if (!!customerPrices) {
          for (const customerPriceKey of Object.keys(customerPrices)) {
            this.cachedCustomerPrices[customerPriceKey] = customerPrices[customerPriceKey];
          }
        }

        // then return the data for requested productIds
        const toReturn: { [productId: string]: BackendPrice } = {};
        for (const productId of productIds) {
          toReturn[productId] = this.cachedCustomerPrices[productId];
        }

        return toReturn;
      })
    );
  }

  public getCartRowPriceObservable(cartRow: CartRow): Observable<CartRowPrice> {
    return this.getCurrentCustomerPriceWithQty({ [cartRow.productId]: cartRow.qty }).pipe(
      first(),
      map(price => {
        return this.getCartRowPrice(price[0], cartRow);
      })
    );
  }

  public getCartRowPricesObservable(cartRows: CartRow[]): Observable<CartRowPrice[]> {
    const productIdQuantityMap: { [productId: string]: number } = {};
    const productIdPromises: Observable<string>[] = [];
    cartRows.forEach(cartRow => {
      switch (this.qtyGroupingLevel) {
        case 'MODEL': {
          productIdPromises.push(this.productService.getModelId(cartRow.productId));
          break;
        }
        case 'VARIANT': {
          productIdPromises.push(this.productService.getVariantProductId(cartRow.productId));
          break;
        }
        default: {
          productIdQuantityMap[cartRow.productId] = cartRow.qty;
          break;
        }
      }
    });

    if (productIdPromises.length) {
      return forkJoin(productIdPromises).pipe(
        switchMap(productIds => {
          this.fillGroupedProductIdQuantityMap(
            cartRows,
            productIdQuantityMap,
            Object.assign({}, ...cartRows.map((cartRow, index) => ({ [cartRow.productId]: productIds[index] })))
          );
          return this.getCartRowsPricesFromProductIdQuantityMap(productIdQuantityMap, cartRows);
        })
      );
    } else {
      return this.getCartRowsPricesFromProductIdQuantityMap(productIdQuantityMap, cartRows);
    }
  }

  protected getCartRowsPricesFromProductIdQuantityMap(
    productIdQuantityMap: { [productId: string]: number },
    cartRows: CartRow[]
  ): Observable<CartRowPrice[]> {
    return this.getCurrentCustomerPriceWithQty(productIdQuantityMap).pipe(
      first(),
      map(prices => {
        return this.getCartRowPrices(prices, cartRows);
      })
    );
  }

  protected fillGroupedProductIdQuantityMap(
    cartRows: CartRow[],
    productIdQuantityMap: { [productId: string]: number },
    productIdsMapped
  ) {
    const groupedQuantities = {};
    cartRows.forEach(cartRow => {
      let groupedId = productIdsMapped[cartRow.productId];
      groupedQuantities[groupedId] = groupedQuantities[groupedId] ?? 0;
      groupedQuantities[groupedId] += cartRow.qty;
    });
    cartRows.forEach(cartRow => {
      productIdQuantityMap[cartRow.productId] = groupedQuantities[productIdsMapped[cartRow.productId]];
    });
  }

  public getCartRowPrices(customerPrices: CustomerProductPrice[], cartRows: CartRow[]): CartRowPrice[] {
    return cartRows.map(cartRow => {
      const customerPrice = customerPrices.find(price => price.productId === cartRow.productId);
      return this.getCartRowPrice(customerPrice, cartRow);
    });
  }

  public getCartRowPrice(customerPrice: CustomerProductPrice, cartRow: CartRow): CartRowPrice {
    const baseCurrencyCode = customerPrice.customerNetPrice.currencyCode;

    const res: CartRowPrice = {
      ...customerPrice,
      productPartialId: cartRow.productPartialId,
      cartRowUnitPrice: customerPrice.customerGrossPrice,
      cartRowUnitPriceInclRowDiscount: customerPrice.customerNetPrice,
      quantity: cartRow.qty,
      cartRowTotalPrice: { value: 0, currencyCode: baseCurrencyCode },
      cartRowTotalPriceInclRowDiscount: { value: 0, currencyCode: baseCurrencyCode },
      cartRowDiscountPercent: customerPrice.customerDiscountPercent,
      cartRowDiscountAmountPerUnit: {
        value: customerPrice.customerDiscountAmount.value,
        currencyCode: baseCurrencyCode
      },
      cartRowDiscountAmountTotal: {
        value: customerPrice.customerDiscountAmount.value * cartRow.qty,
        currencyCode: baseCurrencyCode
      }
    };

    res.cartRowTotalPrice = {
      value: res.cartRowUnitPrice.value * res.quantity,
      currencyCode: res.cartRowUnitPrice.currencyCode
    };

    res.cartRowTotalPriceInclRowDiscount = {
      value: res.cartRowUnitPriceInclRowDiscount.value * res.quantity,
      currencyCode: res.cartRowTotalPriceInclRowDiscount.currencyCode
    };
    return res;
  }

  isStaffedPrice(price: CustomerProductPrice): boolean {
    return !!(price?.backendPrice?.levels?.length > 1);
  }

  getPriceListOptions(): Observable<Pricelist[]> {
    const url = '/json/pricelist/options';
    return this.http.get<Pricelist[]>(url);
  }

  getCartTotal(cart: CartRow[], checkout) {
    return this.getCartRowPricesObservable(cart); // This can be changed to use the OrderTotalService in the backend
  }
}
