import { Component } from '@angular/core';
import { HlDisplayPortalFeaturesService } from '../../services/hl-display-portal-features.service';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, NavigationStart, Router, UrlSegment } from '@angular/router';
import { Observable, Subscription, filter, map, startWith } from 'rxjs';

@Component({
  selector: 'app-hl-features-portals',
  templateUrl: './hl-features-portals.component.html',
  styleUrl: './hl-features-portals.component.css'
})
export class HlFeaturesPortalsComponent {
  public portals: string[];

  routerSubscription: Subscription;
  breadcrumbs$: Observable<any>;
  breadcrumbs: Array<{ label: string; url: string }>;
  public deliminator: string = ">";

  constructor(
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    protected portalFeaturesService: HlDisplayPortalFeaturesService
  ) {
    this.buildBreadcrumbs(this.activatedRoute);
    this.router.events
      .pipe(
        filter(event => (event instanceof NavigationEnd /* || event instanceof NavigationStart */))).subscribe(event => {
          this.buildBreadcrumbs(this.activatedRoute);
        });

    this.getPortals();
  }

  buildBreadcrumbs(data: ActivatedRoute) {
    this.breadcrumbs = [];
    let currentRoute = data.root;
    let url = "";
    do {
      const childrenRoutes = currentRoute.children;
      if (!childrenRoutes.length) {
        break;
      }
      childrenRoutes.forEach(route => {

        if (route.outlet === "primary") {
          const routeSnapshot = route.snapshot;
          url += "/" + routeSnapshot.url.map(segment => segment.path).join("/");
          this.breadcrumbs.push({
            label: route.snapshot.data.breadCrum || route.snapshot.params.portal || route.snapshot.routeConfig.path.slice(route.snapshot.routeConfig.path.lastIndexOf('/') + 1),
            url: url
          });
          currentRoute = route;
        }
      });
    } while (currentRoute);
  }


  getPortals() {
    this.portalFeaturesService.getAllPortalsWithFeatures().subscribe(portals => {
      this.portals = Object.keys(portals.portals);
    });
  }
}
