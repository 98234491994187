import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import {
  ProductDetailComponent,
  AuthService,
  AvailabilityService,
  PriceService,
  MetadataService,
  Product,
  SelectedCustomerService,
  AssortmentService,
  GungFlowService,
  TableRecord,
  Customer
} from 'gung-standard';
import { NgbCarousel } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { map, mergeMap, debounce, first } from 'rxjs';
import { forkJoin, of, timer } from 'rxjs';
import { HlProductService, langExists } from '../../services/hl-product.service';
import { environment } from '../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { HlDisplayPortalFeaturesService, PriceSettingsGroup } from '../../services/hl-display-portal-features.service';

@Component({
  selector: 'app-hl-display-product-detail',
  templateUrl: './hl-display-product-detail.component.html',
  styleUrls: ['./hl-display-product-detail.component.scss']
})
export class HlDisplayProductDetailComponent extends ProductDetailComponent implements OnInit {
  public displayStaffedPrices: boolean;
  public displayDiscountFromPriceList: boolean;

  constructor(
    protected route: ActivatedRoute,
    protected productService: HlProductService,
    protected availabilityService: AvailabilityService,
    protected authService: AuthService,
    protected priceService: PriceService,
    protected metadataService: MetadataService,
    protected customerService: SelectedCustomerService,
    translateService: TranslateService,
    assortmentService: AssortmentService,
    protected gungFlowService: GungFlowService,
    protected hldisplayPortalFeaturesService: HlDisplayPortalFeaturesService
  ) {
    super(
      route,
      productService,
      availabilityService,
      authService,
      priceService,
      metadataService,
      translateService,
      assortmentService,
      gungFlowService
    );
  }

  @ViewChild(NgbCarousel)
  private myCarousel: NgbCarousel;
  @ViewChild('scrollContainer')
  scrollingImages: ElementRef;

  public imageIdString = 'productImage';
  private customerId: string;
  private selectedCustomer: Customer;


  currentLang = this.translateService.currentLang;

  ngOnInit(): void {
    this.route.params
      .pipe(
        map(params => {
          return params.productId as string;
        }),
        mergeMap(productId =>
          forkJoin([
            of(productId),
            this.authService.getCurrentUser().pipe(
              debounce(() => timer(100)),
              first()
            )
          ])
        ),
        mergeMap(([productId, currentUser]) =>
          forkJoin([
            this.productService.getProduct(productId),
            this.availabilityService.getAvailability(productId, currentUser.managedMultistockIds[0]),
            this.priceService.getCurrentCustomerPrice(productId).pipe(first())
          ])
        )
      )
      .subscribe(([product, availability, price]) => {
        this.product = product;
        this.availability = availability;
        if (availability.extra._componentsAvailable) {
          const componentIds = Object.keys(availability.extra._componentsAvailable);
          componentIds.forEach(id => {
            this.componentAmounts[id] = availability.extra._componentsRequired[id];
          });

          this.productService
            .getComponentsByIds(componentIds)
            .pipe(first())
            .subscribe(products => (this.components = products));
        } else {
          this.components = [];
        }
        this.price = price;
      });
    this.customerService.getSelectedCustomer().subscribe(cust => {
      this.customerId = cust.id;
      this.selectedCustomer = cust;
    });
    this.hldisplayPortalFeaturesService
      .getPortalFeatureGroup('priceSettingsGroup')
      .pipe(first())
      .subscribe(priceSettingsGroup => {
        this.displayStaffedPrices = (priceSettingsGroup as PriceSettingsGroup).displayStaffedPrices;
      });
    this.hldisplayPortalFeaturesService
      .getPortalFeatureGroup('priceSettingsGroup')
      .pipe(first())
      .subscribe(priceSettingsGroup => {
        this.displayDiscountFromPriceList = (priceSettingsGroup as PriceSettingsGroup).displayDiscountFromPriceList;
      });
  }

  select(index: number): void {
    this.myCarousel.select(this.imageIdString + index);
  }

  scrollImagesRight(): void {
    const scroller = this.scrollingImages.nativeElement as HTMLElement;
    const scrollWidth = Math.min(
      scroller.scrollLeft + scroller.clientWidth,
      scroller.scrollWidth - scroller.clientWidth
    );
    scroller.scroll({ left: scrollWidth, top: 0, behavior: 'smooth' });
  }

  scrollImagesLeft(): void {
    const scroller = this.scrollingImages.nativeElement as HTMLElement;
    const scrollWidth = Math.max(scroller.scrollLeft - scroller.clientWidth, 0);
    scroller.scroll({ left: scrollWidth, top: 0, behavior: 'smooth' });
  }

  getColorTranslated(name: string) {
    let metadataTableList = this.metadataService.getMetadataTableList('ColourName');

    const finalMetadata = metadataTableList.filter(element => {
      if (Array.isArray(name)) {
        return name.includes(element.Id);
      }
      return element.Id === name;
    });
    if (finalMetadata?.length > 0) {
      return (finalMetadata as any).map(colorTranslated => colorTranslated.Values[this.currentLang]);
    }
    return '';
  }

  getName(): string {
    if (!this.isSales) {
      return this.product.name;
    }

    const id = this.product.id;

    // const itemGroup = this.metadataService.getMetadataValue('vg', 'varugruppbeskr', this.product.extra.ar.varugruppkod);
    const itemProductSystem = this.product.extra.hlPimProduct?.ProductSystem;
    let itemDesc = this.product.extra.ar.artbeskr;
    return `${id}${itemProductSystem ? ' - ' + itemProductSystem : ''} - ${itemDesc}`;
  }

  getKuarDesc(): string {
    const hasKuar = !!this.selectedCustomer.extra.kuarMapped?.[this.product.id];
    if (!hasKuar) {
      return '';
    }

    const artbeskr2 = this.selectedCustomer.extra.kuarMapped?.[this.product.id]?.artbeskr2 || '';
    const artnrkund = this.selectedCustomer.extra.kuarMapped?.[this.product.id]?.artnrkund || '';
    return `${artbeskr2} (${artnrkund})`;
  }

  getDescription(): string {

    if (!this.isSales) {
      return this.product.description;
    }

    let desc1 = '';
    const desc2 = this.product.extra.ar.artbeskrspec;
    return `${desc1} (${desc2})`;
  }

}
