import { Component, OnInit } from '@angular/core';
import { CustomerDetailsComponent, AuthService } from 'gung-standard';
import { ActivatedRoute } from '@angular/router';
import { CustomerService } from 'gung-standard';
import { Observable, of } from 'rxjs';
import { first, map, tap } from 'rxjs';
import { Customer } from 'gung-standard';
import { SelectedCustomerService } from 'gung-standard';
import { CustomerInformationSettingsGroup, HlDisplayPortalFeaturesService } from '../../services/hl-display-portal-features.service';

@Component({
  selector: 'app-hl-display-customer-details',
  templateUrl: './hl-display-customer-details.component.html',
  styleUrls: ['./hl-display-customer-details.component.scss']
})
export class HlDisplayCustomerDetailsComponent implements OnInit {
  isSales: boolean;
  customer: Customer;
  alertMessageTrackingEmail: {
    type: string;
    message: string;
  };
  meetingTabEnabled: boolean;
  productsTabEnabled: boolean;
  constructor(
    private selectedCustomerService: SelectedCustomerService,
    private authService: AuthService,
    private customerService: CustomerService,
    protected hlDisplayPortalFeaturesService: HlDisplayPortalFeaturesService
  ) { }

  ngOnInit() {
    this.authService.getHasRoles('SALES').subscribe(response => {
      this.isSales = response;
    });
    this.selectedCustomerService.getSelectedCustomer().subscribe(response => {
      this.customer = response;
    });
    this.hlDisplayPortalFeaturesService.getPortalFeatureGroup('customerInformationSettingsGroup')
      .pipe(
        first(),
        map(customerInformationSettingsGroup => customerInformationSettingsGroup as CustomerInformationSettingsGroup)
      ).subscribe(customerInformationSettingsGroup => {
        this.meetingTabEnabled = customerInformationSettingsGroup.meetingTabEnabled;
        this.productsTabEnabled = customerInformationSettingsGroup.productsTabEnabled;
      })
  }

  public updateTrackingEmail(customer: Customer, value: any) {
    if ((customer.extra.kus.q_hl_emailtt || '') === (value || '')) {
      return;
    }

    customer.extra.kus.q_hl_emailtt = value;
    customer.extra.kus.q_hl_sendttlinkemail = '1';

    this.customerService
      .updateCustomer(customer)
      .pipe(first())
      .subscribe(
        response => {
          this.alertMessageTrackingEmail = {
            type: 'success',
            message: 'CUSTOMER_TRACKING_EMAIL_UPDATE_SUCCESS'
          };
        },
        error => {
          this.alertMessageTrackingEmail = {
            type: 'danger',
            message: 'CUSTOMER_TRACKING_EMAIL_UPDATE_ERROR'
          };
        }
      );
  }
}
