import { Component } from '@angular/core';
import { HlProductCardListGridViewComponent } from '../hl-product-card-list-grid-view/hl-product-card-list-grid-view.component';

@Component({
  selector: 'app-hl-product-compressed-list-view',
  templateUrl: './hl-product-compressed-list-view.component.html',
  styleUrl: './hl-product-compressed-list-view.component.css'
})
export class HlProductCompressedListViewComponent extends HlProductCardListGridViewComponent {

}
