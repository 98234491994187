import { Component, OnInit } from '@angular/core';
import { HlDisplayOpenOrdersListConfigService } from '../../../services/hl-display-open-orders-list-config.service';

@Component({
  selector: 'app-hl-display-open-orders',
  templateUrl: './hl-display-open-orders.component.html',
  styleUrls: ['./hl-display-open-orders.component.css']
})
export class HlDisplayOpenOrdersComponent implements OnInit {
  constructor(public config: HlDisplayOpenOrdersListConfigService) {}

  ngOnInit() {}
}
