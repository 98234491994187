import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OrderService } from '../../services/orders/order.service';
import { Order } from '../../models/order';
import { catchError, throwError, first, forkJoin, mergeMap, Observable, of, switchMap } from 'rxjs';
import { AuthService } from '../../services/auth/auth.service';
import { BackendInterceptor } from '../../services/backend-interceptor/backend-interceptor.service';
import { ProductService } from '../../services/products/product.service';
import { Product, ProductMultiDimension } from '../../models';
import { CanDeactivateComponent } from '../can-deactivate.component';
import { CommonModalService, DateUtilService } from 'gung-common';
import { BaseViewConfigService, ProductViewType } from '../../services/base-view-config/base-view-config.service';
import { ProductAssortmentParentPathTreeMap, ProductAssortmentParentService } from '../../services/product-assortment-parent/product-assortment-parent.service';
import { HttpErrorResponse } from '@angular/common/http';
import { GungError } from '../../models/gung-error';
import { PriceConfigService } from '../../services/price-config/price-config.service';
import { OrderRow } from '../../models/orderRow';
import { Price } from '../../models/price';

export interface StandardOrderedProductsSummary {
  isMultiDimension: boolean;
  productId: string;
  image: any;
  name: string;
  deliveryDate: string;
  unitPrice: Price;
  netPrice?: Price;
  discountPercent?: number;
  totals: { qty: number; price: Price };
  primaryDimensionName?: string;
  secondaryDimension?: {
    size: string;
    qty: number;
    delQty?: number;
    remainingQty?: number;
    estDeliveryDate?: string;
  }[];
  // this is an array because for multidimension products
  // every entry in the table is built by a collection of products which have the same modelId+primaryDimensionId (several orderRows)
  // for singleDimension products this collection will contains one orderRow
  rows: OrderRow[];
  shipmentDate?: string;
}
@Component({
  selector: 'lib-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.css']
})
export class OrderDetailsComponent extends CanDeactivateComponent implements OnInit {
  order: Order;
  isSales: boolean;
  products: { [productId: string]: Product };

  isDataReady = false;
  standardOrderedProductsSummary: StandardOrderedProductsSummary[] = [];

  constructor(
    protected route: ActivatedRoute,
    protected orderService: OrderService,
    protected authService: AuthService,
    protected backendInterceptor: BackendInterceptor,
    protected productService: ProductService,
    public dateUtilService: DateUtilService,
    protected baseViewConfigService: BaseViewConfigService,
    protected productAssortmentParentService: ProductAssortmentParentService,
    protected commonModalService: CommonModalService,
    public priceConfigService: PriceConfigService
  ) {
    super();
  }

  ngOnInit() {
    const routeParams = this.route.snapshot.params;
    if (routeParams.orderId != null) {
      this.orderService
        .getOrder(routeParams.orderId, true)
        .pipe(
          first(),
          catchError((err: HttpErrorResponse) => {
            const message = err.error?.gungMessage || err.error?.message || 'ERROR';
            const gungError: GungError = {
              ...new Error(message),
              i18nKeyMessage: message
            };
            
            this.commonModalService.openGungErrorHandler(gungError);

            return throwError(() => new Error(message));
          }),
          mergeMap(order => {
            let request: Observable<any> = of(null);
            if (this.baseViewConfigService.productViewType === ProductViewType.assortmentTreeView) {
              request = this.productAssortmentParentService.postProductAssortmentParentByProductIds(order.rows.map(r => r.productId)).pipe(catchError(error => of(null)))
            }
            return forkJoin([
              of(order),
              request
            ])
          }),
          switchMap(([order, parentMap]: [Order, ProductAssortmentParentPathTreeMap]) => {
            if (parentMap) {
              for (const row of order.rows) {
                if (parentMap?.[row.productId]) {
                  row.extra._path = parentMap[row.productId].map(p => p.id);
                  row.extra._path.shift();
                  row.extra._path.unshift('/articles');
                  row.extra._path.push(row.productId);
                }
              }
            }
            return of(order);
          })
        )
        .subscribe((order) => {
          const mappedOrder = this.mapOrder(order);
          this.order = mappedOrder;
          const productIds = mappedOrder.rows.map(row => row.productId || row.id);
          this.productService.getProductsByIds(productIds).subscribe(products => {
            this.products = {};
            products.forEach(product => {
              this.products[product.id] = product;
            });
            this.buildStandardOrderRowsSummaryTable();
          });
        });
    }

    this.authService.getRoles().subscribe(roles => {
      const filteredRoles = roles.filter(role => role.toUpperCase() === 'SALES');

      if (filteredRoles.length === 0) {
        return;
      }
      this.isSales = true;
    });
  }

  canDeactivate(): boolean {
    return true;
  }

  orderUpdated(order: Order) {
    this.order = order;
  }

  protected mapOrder(order: Order): Order {
    return order;
  }

  reroute(url: string) {
    window.location.href = this.backendInterceptor.getBaseUrl() + url;
  }

  buildStandardOrderRowsSummaryTable(): void {
    const productTypeKey = 'productType';

    this.standardOrderedProductsSummary = [];

    // go through all the order rows and build data summary to display in the order
    this.order.rows.forEach(orderRow => {
      const product = this.products[orderRow.productId];
      // orderRow may not be found, case when the order is edited are rows are removed
      if (!!orderRow) {
        if (!!product && product[productTypeKey] === 'multiDimension') {
          const multiDimensionProduct = product as ProductMultiDimension;
          const primaryDimensionId = multiDimensionProduct.modelId + multiDimensionProduct.primaryDimension[0].id;

          // check if already exits an entry for primaryDimensionId
          const primaryDimensionEntry = this.standardOrderedProductsSummary.find(item => item.productId === primaryDimensionId);
          if (!!primaryDimensionEntry) {
            // add secondaryDimension
            primaryDimensionEntry.secondaryDimension.push({
              size: multiDimensionProduct.secondaryDimension[0].name,
              qty: orderRow.quantity
            });

            // set totals
            primaryDimensionEntry.totals.qty += orderRow.quantity;
            primaryDimensionEntry.totals.price.value += orderRow.extra.gme.totalPriceExcludingVat;

            // add orderRow to collection of order rows for this primaryDimension entry
            primaryDimensionEntry.rows.push(orderRow);
          } else {
            // create a new entry for primaryDimension

            let unitPrice = orderRow.extra.gme.unitPriceExcludingVat;
            let lineAmount = orderRow.extra.gme.totalPriceExcludingVat / orderRow.quantity;
            
            

            this.standardOrderedProductsSummary.push({
              isMultiDimension: true,
              productId: primaryDimensionId,
              image: !!product.extra.images ? product.extra.images[0] : null,
              name: product.name,
              primaryDimensionName: multiDimensionProduct.primaryDimension[0].name,
              deliveryDate: orderRow.extra.gme.requestedDeliveryDate,
              // assume that all secondary dimension have the same unit price
              unitPrice: {
                value: unitPrice,
                currencyCode: this.order.extra.gme.currencyCode
              },
              netPrice: {
                value: lineAmount,
                currencyCode: this.order.extra.gme.currencyCode
              } /* this.order.extra.billingCustomer.extra.customers.currencyCode */,
              totals: {
                qty: orderRow.quantity,
                price: {
                  value: orderRow.extra.gme.totalPriceExcludingVat,
                  currencyCode: this.order.extra.gme.currencyCode
                }
              },
              secondaryDimension: [{ size: multiDimensionProduct.secondaryDimension[0].name, qty: orderRow.quantity }],
              shipmentDate: this.order.extra.shipmentDate,
              rows: [orderRow]
            });
          }
        } else {
          // singleDimension

          let unitPrice = orderRow.extra.gme.unitPriceExcludingVat;
          let lineAmount = orderRow.extra.gme.totalPriceExcludingVat / (orderRow.quantity == 0 ? 1 : orderRow.quantity);
          

          this.standardOrderedProductsSummary.push({
            isMultiDimension: false,
            productId: orderRow.productId,
            image:
              !!product && !!product.extra && !!product.extra.images
                ? product.extra.images[0]
                : { s3Uri: 'images/no-image.jpg' },
            name: !!product ? product.name : '',
            deliveryDate: orderRow.extra.gme.deliveryDate,
            unitPrice: {
              value: unitPrice,
              currencyCode: this.order.extra.gme.currencyCode
            },
            netPrice: {
              value: lineAmount,
              currencyCode: this.order.extra.gme.currencyCode
            },
            totals: {
              qty: orderRow.quantity,
              price: {
                value: orderRow.extra.gme.totalPriceExcludingVat,
                currencyCode: this.order.extra.gme.currencyCode
              }
            },
            rows: [orderRow]
          });
        }
      }
    });

    // show singleDimension entries at the bottom
    this.standardOrderedProductsSummary.sort((x, y) => (x === y ? 0 : x.isMultiDimension ? -1 : 1));
    this.isDataReady = true;
  }
}
