import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { first, takeUntil, Subject } from 'rxjs';
import { AssortmentService } from '../../services/assortment.service';
import { CurrentAssortmentService } from '../../services/base-view-config/base-view-config.service';
import { ProductListV2ConfigService } from '../../services/product-list-v2-config/product-list-v2-config.service';

@Component({
  selector: 'lib-product-list-v2',
  template:
    `<lib-product-list-banner *ngIf="productListConfigService.assortmentBannerEnabled" [assortment]="currentAssortmentSerivce.currentAssortment"></lib-product-list-banner>
    <lib-filter-list *ngIf="assortmentLoaded; else loading" #productList [configService]="productListConfigService"></lib-filter-list>
    <ng-template #loading>
      <i class="fa fa-spinner fa-spin fa-7x"></i>
    </ng-template>`,
  styleUrls: ['./product-list-v2.component.css']
})
export class ProductListV2Component implements OnInit, OnDestroy {
  /**
   * This component is made only for customers using Cloud PIM and therefore fetches assortment data whithout children.
   * Backend function only implemented for Mongo Assortments.
   * Uses assortment data for dynamic product card data and filters from Cloud PIM.
   */
  unsubscribe: Subject<void> = new Subject();
  assortmentLoaded = false;

  constructor(
    public productListConfigService: ProductListV2ConfigService,
    public route: ActivatedRoute,
    protected assortmentService: AssortmentService,
    public currentAssortmentSerivce: CurrentAssortmentService
  ) {}

  ngOnInit(): void {
    this.route.paramMap.pipe(takeUntil(this.unsubscribe)).subscribe(params => {
      const assortmentId = params.get('assortmentId');
      if (assortmentId != null) {
        this.assortmentService
          .getPimAssortmentData(assortmentId)
          .pipe(first())
          .subscribe(assortment => {
            this.currentAssortmentSerivce.currentAssortment = assortment;
            this.productListConfigService.assortmentSubject.next(assortment);
            this.assortmentLoaded = true;
          });
      } else {
        this.productListConfigService.assortmentSubject.next(null);
        this.assortmentLoaded = true;
      }
    });
  }

  ngOnDestroy(): void {
    this.productListConfigService.assortmentSubject.next(null);
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
