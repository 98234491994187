<div class="table-responsive">
  <table class="table table-borderless table-striped table-hover">
    <thead>
      <tr>
        <th scope="col" translate>DATE</th>
        <th scope="col" translate>CUSTOMER</th>
        <th scope="col" translate>SYSTEM</th>
        <th scope="col"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let meeting of data">
        <td>
          <span class="d-block">
            {{ meeting.extra.hr.eventpldat | date: 'yyyy-MM-dd' }} {{ meeting.extra.hr.eventpltime | date: 'HH:mm' }}
          </span>
        </td>
        <td class="hidden-ipad-down">
          {{ meeting.extra.customerName }}
        </td>
        <td class="hidden-ipad-down">
          {{ meeting.extra.systems?.join(', ') }}
        </td>
        <td class="btn-buy d-table-cell">
          <button type="button" class="btn btn-primary btn-block" (click)="openEditMeetingModal(meeting)" translate>
            UPDATE_MEETING
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</div>
