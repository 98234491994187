import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { first, map, switchMap } from 'rxjs';
import { Assortment, AssortmentService, ProductService, SelectedCustomerService } from 'gung-standard';
import { HlDisplayHelperService } from './hl-display-helper.service';

@Injectable({
  providedIn: 'root'
})
export class HlDisplayBlockedProductsHelperService {
  constructor(
    protected productService: ProductService,
    private assortmentService: AssortmentService,
    private selectedCustomerService: SelectedCustomerService,
    private helperService: HlDisplayHelperService
  ) {}

  checkBlockedProducts(
    productsIds: string[],
    checkPriceList: boolean = true
  ): Observable<{ [productId: string]: boolean }> {
    return this.selectedCustomerService.getSelectedCustomer().pipe(
      first(),
      switchMap(customer =>
        forkJoin({
          blockedProducts: this.productService.getBlockedProducts(productsIds).pipe(first()),
          priceListAssortment: checkPriceList
            ? this.assortmentService
                .getAssortment(
                  this.helperService.checkRequestPricelistAssortmentWithWebCondition(customer.extra.kus.prislista)
                )
                .pipe(first())
            : of(null as Assortment)
        })
      ),
      map(({ blockedProducts, priceListAssortment }) => {
        const blocked: { [productId: string]: boolean } = {};

        for (const productId of productsIds) {
          blocked[productId] = blockedProducts[productId];

          // mark as blocked also if it does not exist in assortment
          if (checkPriceList && priceListAssortment.children.findIndex(product => product.id === productId) < 0) {
            blocked[productId] = true;
          }
        }

        return blocked;
      })
    );
  }
}
