<div class="table-responsive gung-responsive">
  <table class="table table-borderless table-striped table-hover">
    <thead>
      <tr>
        <th scope="col" translate>ORDER_ID</th>
        <th scope="col" translate>ORDER_STATUS</th>
        <th scope="col" translate>CUSTOMER_NAME</th>
        <th scope="col" translate>ORDER_DATE</th>
        <th scope="col" translate>TOTAL</th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="let order of data"
        (click)="goToDetail(order)"
        class="selectableRow"
      >
        <td [attr.data-label]="'ORDER_ID' | translate">{{ order.id }}</td>
        <td [attr.data-label]="'ORDER_STATUS' | translate" translate></td>
        <td [attr.data-label]="'CUSTOMER_NAME' | translate"></td>
        <td [attr.data-label]="'ORDER_DATE' | translate">
         
        </td>
        <td [attr.data-label]="'TOTAL' | translate">
         
        </td>
      </tr>
    </tbody>
  </table>
</div>
