import { Injectable } from '@angular/core';
import { AuthService, CustomerProductPrice, Product, ProductInputQuantityConfigService } from 'gung-standard';
import { environment } from '../../environments/environment';
import { CheckoutSettingsGroup, HlDisplayPortalFeaturesService } from './hl-display-portal-features.service';
import { first, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HlProductInputQuantityConfigService extends ProductInputQuantityConfigService {
  isAnyStepAmount: boolean = false;
  isSales: boolean = false;

  constructor(protected hlDisplayPortalFeaturesService: HlDisplayPortalFeaturesService, protected authService: AuthService) {
    super();

    this.authService.getRoles().pipe(first()).subscribe(roles => {
      this.isSales = roles.includes('SALES');
    })
    this.hlDisplayPortalFeaturesService.getPortalFeatureGroup('orderCheckoutSettingsGroup').pipe(first(), map(settingsGroup => settingsGroup as CheckoutSettingsGroup)).subscribe(orderCheckoutSettingsGroup => {
      this.isAnyStepAmount = orderCheckoutSettingsGroup.changeToAnyQtyAsUser;
    })
  }

  getMinimumOrderQuantity(productId: string, product?: Product): number {
    if (!product) return 1;

    if (!this.isSales) {
      return product.extra.stepAmount;
    }

    return product.extra.minimumOrderQuantity || 1;
  }

  getStepAmount(productId: string, product?: Product): number {
    if (!!product?.extra?.stepAmount) {
      if (typeof product?.extra?.stepAmount === "string") {
        return Number(product.extra.stepAmount.trim());
      }
      else if (typeof product?.extra?.stepAmount === "number") {
        return product.extra.stepAmount;
      }
    }
    return 1;
  }

  getAllowManualQtyInputOutsideStepSizes(productId: string, product?: Product, price?: CustomerProductPrice, isAnonymous?: boolean, isUser?: boolean, isSales?: boolean, isAdmin?: boolean, isActuator?: boolean): boolean {
    if (this.isAnyStepAmount || this.isSales || isAdmin) {
      return true;
    }

    return false;
  }
}
