import { Injectable } from '@angular/core';
import {
  CustomerListConfigService,
  CustomerService,
  Customer,
  MetadataService,
  UsersService,
  AuthService,
  CustomerSelectionActionConfigService
} from 'gung-standard';
import { ListLayout, ListLayoutSingleComponent, SimpleConfigBaseFilter } from 'gung-list';
import { HlCustomerListCardComponent } from '../components/hl-customer-list-card/hl-customer-list-card.component';
import { first, forkJoin, map, Observable, tap } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../environments/environment';
import { CustomerListSettingsGroup, HlDisplayPortalFeaturesService } from './hl-display-portal-features.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerFinancialGroupFilter extends SimpleConfigBaseFilter<Customer> {
  constructor(protected metadataService: MetadataService) {
    super();
  }

  getName(): string {
    return 'FINANCIAL_GROUP';
  }

  getOptionIds(item: Customer): string[] {
    const metadataValue = this.metadataService.getMetadataValue(
      'q_financialgrp',
      'q_financialgrpdesc',
      item.extra.kus.q_financialgrpcode
    );
    if (metadataValue == null) {
      return [];
    } else {
      return [metadataValue];
    }
  }

  getOptionName(key: string): string {
    return key;
  }
}

@Injectable({
  providedIn: 'root'
})
export class CustomerSuperGroupFilter extends SimpleConfigBaseFilter<Customer> {
  constructor(protected metadataService: MetadataService) {
    super();
  }

  getName(): string {
    return 'SUPER_GROUP';
  }

  getOptionIds(item: Customer): string[] {
    const metadataValue = this.metadataService.getMetadataValue(
      'q_supergrp',
      'q_supergrpdesc',
      item.extra.kus.q_supergrpcode
    );
    if (metadataValue == null) {
      return [];
    } else {
      return [metadataValue];
    }
  }

  getOptionName(key: string): string {
    return key;
  }
}

@Injectable({
  providedIn: 'root'
})
export class CustomerMeetingFilter extends SimpleConfigBaseFilter<Customer> {
  getName(): string {
    return 'MEETING';
  }

  getOptionIds(item: Customer): string[] {
    if (item.extra.meetings?.meetingLastTwelveMonths) {
      return ['MEETING_LAST_12_MONTHS'];
    } else if (item.extra.meetings?.meetingOlderThanTwelveMonths) {
      return ['MEETING_OLDER_THAN_12_MONTHS'];
    } else {
      return ['NO_MEETING'];
    }
  }

  getOptionName(key: string): string {
    return key;
  }
}

@Injectable({
  providedIn: 'root'
})
export class CustomerCityFilter extends SimpleConfigBaseFilter<Customer> {
  getName(): string {
    return 'CITY';
  }

  getOptionIds(item: Customer): string[] {
    return [item.extra.fr.ftgpostadr3];
  }

  getOptionName(key: string): string {
    return key.trim();
  }
}

@Injectable({
  providedIn: 'root'
})
export class HlCustomerListConfigService extends CustomerListConfigService {
  constructor(
    customerService: CustomerService,
    protected usersService: UsersService,
    protected authService: AuthService,
    protected customerSelectionActionConfigService: CustomerSelectionActionConfigService,
    protected translateService: TranslateService,
    protected customerSuperGroupFilter: CustomerSuperGroupFilter,
    protected financialGroupFilter: CustomerFinancialGroupFilter,
    protected customerMeetingFilter: CustomerMeetingFilter,
    protected customerCityFilter: CustomerCityFilter,
    protected hlDisplayPortalFeaturesService: HlDisplayPortalFeaturesService
  ) {
    super(customerService, usersService, authService, customerSelectionActionConfigService, translateService);
  }

  getFilters() {
    let customerFiltersEnabled = false;
    this.hlDisplayPortalFeaturesService
      .getPortalFeatureGroup('customerListSettingsGroup')
      .pipe(
        first(),
        map(customerFilterGroup => customerFilterGroup as CustomerListSettingsGroup)
      )
      .subscribe(customerFilterGroup => {
        customerFiltersEnabled = customerFilterGroup.customerFiltersEnabled;
      });

    return customerFiltersEnabled
      ? [this.financialGroupFilter, this.customerSuperGroupFilter, this.customerCityFilter, this.customerMeetingFilter]
      : [];
  }

  getLayouts(): ListLayout<Customer>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => HlCustomerListCardComponent,
        getListLayoutComponent: () => ListLayoutSingleComponent,
        getName: () => 'Customer list'
      }
    ];
  }

  getSearchTerms(item: Customer): string[] {
    const terms = [];
    if (item.id) {
      terms.push(item.id);
    }
    if (item.name) {
      terms.push(item.name);
    }
    if (item.extra.fr.ftgpostadr2) {
      terms.push(item.extra.fr.ftgpostadr2);
    }
    if (item.extra.fr.ftgpostadr3) {
      terms.push(item.extra.fr.ftgpostadr3);
    }
    if (item.extra.fr.ftgpostnr) {
      terms.push(item.extra.fr.ftgpostnr);
    }
    if (item.extra?.fr?.q_siren) {
      terms.push(item.extra.fr.q_siren);
    }
    return terms;
  }

  hideZeroOptions() {
    return true;
  }

  getItems(): Observable<Customer[]> {
    return forkJoin({
      customers: super.getItems().pipe(first()),
      customerListSettings: this.hlDisplayPortalFeaturesService.getPortalFeatureGroup('customerListSettingsGroup').pipe(
        first(),
        map(group => group as CustomerListSettingsGroup)
      )
    }).pipe(
      map(({ customers, customerListSettings }) => {
        if (customerListSettings.podShowOnlyDeliveryCustomers) {
          return customers.filter(c => c.id.includes('@'));
        }
        return customers;
      })
    );
  }
}
