import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { JeevesInvoiceListConfigService } from 'gung-standard-jeeves';
import { Invoice, InvoiceService, SelectedCustomerService } from 'gung-standard';
import { Observable } from 'rxjs';
import { map } from 'rxjs';
import { HlDisplayInvoiceCardListComponent } from '../components/hl-display-invoice-card-list/hl-display-invoice-card-list.component';
import { ListLayout, ListLayoutSingleComponent } from 'gung-list';

@Injectable({
  providedIn: 'root'
})
export class HlDisplayInvoicesListConfigService extends JeevesInvoiceListConfigService {
  constructor(
    protected translationService: TranslateService,
    protected invoiceService: InvoiceService,
    protected selectedCustomerService: SelectedCustomerService
  ) {
    super(translationService, invoiceService, selectedCustomerService);
  }

  getItems(): Observable<Invoice[]> {
    return this.invoiceService.getOpenInvoices().pipe(
      map(results =>
        results.sort((a, b) => {
          return a.extra.fh.faktdat < b.extra.fh.faktdat ? 1 : -1;
        })
      ),
      map(results => {
        // filter status
        const filterStatus = results.filter(result => result.extra.fh.faktstkod === 0);
        // filter type
        const filtertype = filterStatus.filter(result => result.extra.fh.facturatyp !== 120);

        return filtertype;
      })
    );
  }

  getLayouts(): ListLayout<Invoice>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => HlDisplayInvoiceCardListComponent,
        getListLayoutComponent: () => ListLayoutSingleComponent,
        getName: () => 'Invoice grid'
      }
    ];
  }
}
