import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { JeevesOrderListConfigService } from './../jeeves-order-list-config/jeeves-order-list-config.service';
import { SelectedCustomerService, MetadataService, CustomerService } from 'gung-standard';
import { Observable, forkJoin, of } from 'rxjs';
import { Order } from 'gung-standard';
import { mergeMap, first, map } from 'rxjs';
import { JeevesOrderService } from '../jeeves-order.service';
import { ListLayout, ListLayoutMultipleComponent } from 'gung-list';
import { JeevesOpenOrderTableListComponent } from '../../components/jeeves-open-order-table-list/jeeves-open-order-table-list.component';

@Injectable({
  providedIn: 'root'
})
export class JeevesOpenOrdersConfigService extends JeevesOrderListConfigService {
  constructor(
    protected translationService: TranslateService,
    protected orderService: JeevesOrderService,
    protected selectedCustomerService: SelectedCustomerService,
    protected metadataService: MetadataService,
    protected customerService: CustomerService
  ) {
    super(translationService, orderService, selectedCustomerService, metadataService, customerService);
  }

  getItems(): Observable<Order[]> {
    return this.orderService.getOpenOrders().pipe(
      mergeMap(items => forkJoin([of(items), this.customerService.getCustomers().pipe(first())])),
      map(([orders, customers]) => {
        const result = orders.map(order => {
          return {
            ...order,
            extra: {
              ...order.extra
            }
          };
        });

        result.map(resOrder => {
          const cust = customers.find(customer => customer.id === resOrder.deliveryCustomerId);
          resOrder.extra.customerName = cust ? cust.name : '';
          return resOrder;
        });

        const sorted = result.sort((a, b) => {
          if (a.extra.oh.orddatum > b.extra.oh.orddatum) {
            return 1;
          }
          if (a.extra.oh.orddatum < b.extra.oh.orddatum) {
            return -1;
          }
          if (a.id > b.id) {
            return 1;
          }
          if (a.id < b.id) {
            return -1;
          }
          return 0;
        });

        return sorted.reverse();
      })
    );
  }

  getSearchTerms(item: Order): string[] {
    const res = [...super.getSearchTerms(item)];

    if (item.extra.customerName) {
      res.push(item.extra.customerName);
    }
    return res;
  }

  getLayouts(): ListLayout<Order>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => JeevesOpenOrderTableListComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'Order list'
      }
    ];
  }
}
