import { Injectable, Optional } from '@angular/core';
import {
  CartService,
  AvailabilityService,
  Availability,
  AuthService,
  GungGoogleTagManagerService
} from 'gung-standard';
import { Store } from '@ngrx/store';
import { AppState } from 'gung-standard';
import { mergeMap, map, tap, first, switchMap } from 'rxjs';
import { forkJoin, of, Observable, Subject } from 'rxjs';
import { CartRow } from 'gung-standard';

export interface CartRowValidationData {
  productId: string;
  firstAvailableDate: Date | null;
  availabilityData: any;
}

@Injectable({
  providedIn: 'root'
})
export class HlDisplayCartService extends CartService {
  private orderLineItemNotInJeevesProductId = '155035';

  constructor(
    store: Store<AppState>,
    private availabilityService: AvailabilityService,
    authService: AuthService,
    @Optional() protected gungGoogleTagManagerService: GungGoogleTagManagerService
  ) {
    super(store, authService, gungGoogleTagManagerService);
  }

  getCartRowsValidationData(rows: CartRow[]): Observable<CartRowValidationData[]> {
    // TODO cleanup
    return of(rows).pipe(
      mergeMap(cartRows =>
        forkJoin([
          of(cartRows),
          cartRows.length > 0
            ? forkJoin(
                cartRows.map(row =>
                  this.availabilityService.getAvailability(row.productId, row.targetStockId || '0', true, 60)
                )
              )
            : of([])
        ])
      ),
      map(([cartRows, availabilityData]) => {
        if (cartRows.length <= 0) {
          return [];
        }
        return cartRows.map(row => {
          const obj = {
            productId: row.productId,
            availabilityData: availabilityData.find(data => data.productId === row.productId),
            //firstAvailableDate: null
            firstAvailableDate: new Date()
          };

          let quantityToCheck = row.qty;
          if (row.productId === this.orderLineItemNotInJeevesProductId) {
            quantityToCheck = 0;
          }

          const filteredDates = Object.keys(obj.availabilityData.extra.availabilities).filter(
            key => obj.availabilityData.extra.availabilities[key] >= quantityToCheck
          );
          if (filteredDates && filteredDates.length > 0) {
            obj.firstAvailableDate = new Date(this.getStandardizedDateString(filteredDates[0]));
          }
          return obj;
        });
      })
    );
  }

  getCartValidationData(): Observable<any[]> {
    return this.getCurrentCart().pipe(switchMap(rows => this.getCartRowsValidationData(rows)));
  }

  // Require format yyMMdd.
  public getStandardizedDateString(date: string): string {
    const res = '20' + date.substring(0, 2) + '-' + date.substring(2, 4) + '-' + date.substring(4);
    return res;
  }

  public getFormattedDateString(date: Date): string {
    let dateString = '';
    dateString = dateString + date.getFullYear();

    const month = '' + (date.getMonth() + 1);
    if (month.length === 1) {
      dateString = dateString + '-' + '0' + month;
    } else {
      dateString = dateString + '-' + month;
    }

    const day = '' + date.getDate();
    if (day.length === 1) {
      dateString = dateString + '-' + '0' + day;
    } else {
      dateString = dateString + '-' + day;
    }

    return dateString;
  }
}
