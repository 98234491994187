import { Component, OnInit } from '@angular/core';
import { LangSelectComponent } from 'gung-standard';
import { TranslateService } from '@ngx-translate/core';
import { environment } from './../../../environments/environment';
import { GungLanguageConfigService } from 'gung-common';
import { HlDisplayGungLanguageConfigService } from '../../services/hl-display-gung-language-config.service';
import { HlDisplayPortalFeaturesService } from '../../services/hl-display-portal-features.service';

@Component({
  selector: 'app-hl-lang-select',
  templateUrl: './hl-lang-select.component.html',
  styleUrls: ['./hl-lang-select.component.css']
})
export class HlLangSelectComponent extends LangSelectComponent implements OnInit {
  currentIso639LangCode = '';
  languagesEnabled: { [lang: string]: boolean } = {};

  constructor(
    translateService: TranslateService,
    protected gungLanguageConfigService: HlDisplayGungLanguageConfigService
  ) {
    super(translateService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.checkLanguagesEnabled();
    this.setLangCode();
  }

  changeLanguage(langCode: string): void {
    super.changeLanguage(langCode);
  }

  setLangCode() {
    const defaultLang = this.gungLanguageConfigService.getDefaultLanguage();

    const lang = this.translateService.currentLang.toUpperCase() || defaultLang.toUpperCase();
    if (lang === 'SE') {
      this.currentIso639LangCode = 'SV';
    } else {
      this.currentIso639LangCode = lang;
    }
  }

  updateData() {
    this.currentLang = this.translateService.currentLang;
    this.setLangCode();
  }

  checkLanguagesEnabled() {
    this.gungLanguageConfigService.languageListObservable.subscribe(langList => {
      if (!!langList && langList.length > 0) {
        this.languagesEnabled = langList.reduce((a, lang) => ({ ...a, [lang]: true }), {});
      }
    });
    this.gungLanguageConfigService.selectLanguage(true);
  }
}
