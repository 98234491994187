import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StandardStatisticsData } from '../../models/statistics';

@Injectable({
  providedIn: 'root'
})
export class CustomerStatisticsService {
  constructor(private http: HttpClient) {}

  getStatistics(): Observable<StandardStatisticsData[]> {
    const url = `json/company-statistics`;
    return this.http.get<StandardStatisticsData[]>(url);
  }

  getStatisticsByCustomer(customerId: string): Observable<StandardStatisticsData[]> {
    const url = `json/company-statistics/customer-statistics/${customerId}`;
    return this.http.get<StandardStatisticsData[]>(url);
  }
}
