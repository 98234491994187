import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { SelectedCustomerService } from 'gung-standard';
import { switchMap, tap, catchError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomerSalesPersonService {
  constructor(protected http: HttpClient, protected selectedCustomerService: SelectedCustomerService) {}

  public getCurrentCustomerSalesPerson(): Observable<CustomerSalesPerson | null> {
    return this.selectedCustomerService.getSelectedCustomer().pipe(
      switchMap(cust => this.http.get<CustomerSalesPerson>(`json/customer/${cust.id}/sales-person`)),
      catchError(() => {
        return of(null);
      })
    );
    // return of({
    //   name: 'Melker Veltman',
    //   phone: '+4673000000',
    //   email: 'melker.veltman@gung.se'
    // });
  }
}

export interface CustomerSalesPerson {
  name: string;
  phone: string;
  email: string;
}
