import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomPageTemplateComponent } from '../../custom-page-class/custom-page-template-component';
import { AuthService } from '../../../../services/auth/auth.service';
import { Assortment, AssortmentRecursive, AssortmentService } from '../../../../services/assortment.service';
import { filter, map, switchMap } from 'rxjs';
import { TemplateComponent } from '../../custom-page-interface/template-component';
import { TranslateService } from '@ngx-translate/core';
import { GungFlowService } from '../../../../services/gung-flow/gung-flow.service';
import { CustomPageConfigService } from '../../../../services/custom-page/custom-page-config.service';
import { GungImageUrlService, GungNoImageUrl } from 'gung-common';

// DEFAULT DATA EXAMPLE USED WHEN NO DATA
const dataDefault: TemplateComponent = {
  templateName: null,
  elements: {
    topImage: {
      link: {
        enabled: false,
        internal: undefined,
        url: undefined
      },
      image: {
        enabled: true,
        sourceUrl: GungNoImageUrl // 1400x1400
      },
      text: {
        enabled: true,
        text: ''
      }
    },
    topButton: {
      link: {
        enabled: true,
        internal: true,
        url: '/products'
      },
      image: {
        enabled: false,
        sourceUrl: undefined
      },
      text: {
        enabled: true,
        text: ''
      }
    },
    prefixUrl: {
      link: {
        enabled: true,
        internal: true,
        url: '/categories'
      },
      image: null,
      text: null
    },
    assortment: {
      link: {
        enabled: false,
        internal: undefined,
        url: undefined
      },
      image: {
        enabled: false,
        sourceUrl: undefined
      },
      text: {
        enabled: true,
        text: 'userAssortment'
      }
    },
  }
};

@Component({
  selector: 'lib-home-assortment-new-template',
  templateUrl: './home-assortment-new-template.component.html',
  styleUrls: ['./home-assortment-new-template.component.css']
})
export class HomeAssortmentNewTemplateComponent extends CustomPageTemplateComponent implements OnInit {
  rootAssortment: Assortment;
  selectedAssortment: Assortment;
  prefixUrl: string;

  constructor(
    protected router: Router,
    protected modalService: NgbModal,
    protected gungFlowService: GungFlowService,
    protected assortmentService: AssortmentService,
    protected authService: AuthService,
    protected translateService: TranslateService,
    protected customPageConfigService: CustomPageConfigService,
    protected gungImageUrlService: GungImageUrlService
  ) {
    super(router, modalService, gungFlowService, assortmentService, translateService, customPageConfigService);
  }

  getDefaultData(): TemplateComponent {
    const data = dataDefault;
    if (this.rootAssortment) {
      const sourceUrl = this.getAssortmentImage(this.rootAssortment, '1000x800');
      data.elements.topImage.image.sourceUrl = sourceUrl.substr(5, sourceUrl.length - 7);
      data.elements.topImage.text.text = this.rootAssortment.name;
      data.elements.topButton.text.text = 'PRODUCTS';
      data.elements.topButton.link.url = this.prefixUrl + '/' + this.rootAssortment.id;
    }
    return data;
  }

  ngOnInit() {
    const currentLang = this.translateService.currentLang;
    return this.authService
      .getCurrentUser()
      .pipe(
        filter(user => !!user),
        switchMap(user => {
          const assortText = this.data.elements?.assortment?.text.text;
          if (assortText !== 'userAssortment' && assortText !== '' && assortText !== undefined  && assortText !== null) {
            return this.assortmentService.getAssortmentRecursive(assortText);
          }
          return this.assortmentService.getAssortmentRecursive(user.assortment);
        }),
        map(assortment => {
          assortment.children = assortment.children.map((a: AssortmentRecursive) => {
            if (a?.extra?.i18?.[currentLang]?.assortmentName) {
              a.name = a.extra.i18[currentLang].assortmentName;
            }
            return a;
          });
          return assortment;
        })
      )
      .subscribe(assortment => {
        this.rootAssortment = assortment;
        this.selectedAssortment = this.rootAssortment;
        this.prefixUrl = this.data.elements.prefixUrl.link.url;
        if (this.initialSetup) {
          const defaultData = this.getDefaultData();
          this.data.elements = defaultData.elements || this.data.elements;
          this.initialSetup = false;
        }
      });
  }

  getStyle(assortment: Assortment, size?: string): any {
    return {
      'background-image': this.getAssortmentImage(assortment, size)
    };
  }

  getAssortmentImage(assortment: Assortment, size?: string): string {
    const assortmentVar = assortment as any;
    const fitInSize = size || '500x400';
    if (
      assortmentVar &&
      assortmentVar.extra &&
      assortmentVar.extra &&
      assortmentVar.extra.images &&
      assortmentVar.extra.images.length > 0
    ) {
      return `url('${this.gungImageUrlService.getUrl(encodeURIComponent(assortmentVar.extra.images[0].s3Uri),{size: fitInSize, etag: assortmentVar.extra.images[0].s3ETag, quality: '100'})}')`;
    } else {
      return `url('${GungNoImageUrl}')`;
    }
  }

  refreshData(): void {
    this.ngOnInit();
  };
}
