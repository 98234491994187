import { Injectable } from '@angular/core';
import { Product } from '../../models';

@Injectable({
  providedIn: 'root'
})
export class ProductSortService {
  constructor() {}

  sortProducts(products: Product[]): Product[] {
    return [...products];
  }
}
