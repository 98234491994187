import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  OrderToBasketService,
  OrderRow,
  CartService,
  OrderService,
  ProductService,
  GungModalService,
  GungStringConverterService,
  AuthService
} from 'gung-standard';
import { forkJoin, of } from 'rxjs';
import { first, map, switchMap } from 'rxjs';
import { environment } from '../../environments/environment';
import { HlDisplayBlockedProductsHelperService } from './hl-display-blocked-products-helper.service';

@Injectable({
  providedIn: 'root'
})
export class HlOrderToBasketService extends OrderToBasketService {
  private isSales = false;
  private orderLineItemNotInJeevesProductId = '155035';

  constructor(
    protected cartService: CartService,
    protected orderService: OrderService,
    protected productService: ProductService,
    protected modalService: GungModalService,
    protected translationService: TranslateService,
    private hlDisplayBlockedProductsHelperService: HlDisplayBlockedProductsHelperService,
    protected authService: AuthService
  ) {
    super(cartService, orderService, productService, modalService, translationService);

    this.authService
      .getRoles()
      .pipe(first())
      .subscribe(roles => {
        this.isSales = roles.includes('SALES');
      });
  }

  public getExcludedProductIds(): string[] {
    return environment.copyToCartExcludedProductIds;
  }

  protected addRowHL(row: OrderRow, counter: any): void {
    console.log('HlOrderToBasketService.addRowHL', counter, row);

    if (row.extra.orp.ordradnrstrpos !== 0) {
      return;
    }
    if (!counter[row.productId]) {
      counter[row.productId] = 0;
    }
    const productPartialId = counter[row.productId] + '';

    counter[row.productId] = counter[row.productId] + 1;
    console.log('HlOrderToBasketService.addRowHL [2]', counter, row);

    this.cartService.addToCart(row.productId, row.quantity, null, productPartialId);

    if (row.productId === this.orderLineItemNotInJeevesProductId) {
      const theOrp = {
        vb_pris: row.extra.orp.vb_pris,
        ordantal: row.quantity,
        rabatt1: row.extra.orp.rabatt1,
        artbeskr: row.extra.orp.artbeskr
      };
      this.cartService.setExtra(
        row.productId,
        {
          orp: theOrp
        },
        undefined,
        productPartialId
      );
    }
  }

  public async overRiddenAddOrderToBasket(orderId: string): Promise<void> {
    const excluded = this.getExcludedProductIds();
    console.log('addOrderToBasket [excluded]', excluded);
    // check if each product in the order is blocked
    // add the product to the cart only when is not blocked
    this.orderService
      .getOrder(orderId)
      .pipe(
        first(),
        switchMap(order =>
          forkJoin({
            order: of(order),
            blockedProducts: this.productService.getBlockedProducts(order.rows.map(row => row.productId)).pipe(first())
          })
        ),
        map(({ order, blockedProducts }) => {
          this.modalService
            .openConfirmYesNoModal(undefined, this.translationService.instant('LOAD_ORDER_CART_CONFIRMATION'), {
              size: 'sm'
            })
            .then(result => {
              const blocked: string[] = [];
              const orderRows: OrderRow[] = [];
              if (result) {
                this.cartService.clearCart();

                for (const orderRow of order.rows) {
                  const realProductId = GungStringConverterService.toGungString(orderRow.productId);
                  // Enable exclusion of specific product ids, such as freight products
                  if (excluded.includes(realProductId)) {
                    continue;
                  }
                  if (blockedProducts[realProductId]) {
                    blocked.push(orderRow.productId);
                    continue;
                  }
                  orderRows.push(orderRow);
                }
              }
              const counter = {};

              if (blocked.length > 0) {
                this.modalService
                  .openConfirmYesNoModal(
                    this.translationService.instant('EXCLUDED_PRODUCTS'),
                    this.translationService.instant('EXCLUDED_PRODUCTS_MESSAGE', { products: blocked.join(', ') }),
                    null,
                    'OK',
                    null
                  )
                  .then(proceed => {
                    if (proceed) {
                      orderRows.forEach(orderRow => this.addRowHL(orderRow, counter));
                    }
                  });
              } else {
                orderRows.forEach(orderRow => {
                  this.addRowHL(orderRow, counter);
                });
              }
            });
        })
      )
      .toPromise();
  }

  public async addOrderToBasket(orderId: string): Promise<void> {
    // We need special handling of dummy products (name and index har to be created)
    return this.overRiddenAddOrderToBasket(orderId);

    /**
     * Not used since 30/10-24 
     * Magnus: Customers shoule be able to copy the orders as the sales person can.
    */

    /* const excluded = this.getExcludedProductIds();

    this.orderService
      .getOrder(orderId)
      .pipe(
        first(),
        switchMap(order =>
          forkJoin({
            order: of(order),
            blockedProducts: this.hlDisplayBlockedProductsHelperService
              .checkBlockedProducts(order.rows.map(row => row.productId))
              .pipe(first())
          })
        ),
        map(({ order, blockedProducts }) => {
          for (const productId of Object.keys(blockedProducts)) {
            // Enable exclusion of specific product ids, such as freight products
            if (excluded.includes(productId)) {
              console.log('addOrderToBasket [excluded]', productId, excluded.includes(productId));
              continue;
            }

            if (!blockedProducts[productId]) {
              const orderRow = order.rows.find(row => row.productId === productId);

              if (!!orderRow) {
                this.addRow(orderRow);
              }
            }
          }
        })
      )
      .toPromise(); */
  }
}
