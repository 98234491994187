import { Inject, Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { NavigationConfig } from 'gung-standard';

@Injectable({
  providedIn: 'root'
})
export class HlDisplayMsflowArgumentService {
  constructor(@Inject('environment') private environment: NavigationConfig) { }


  getArgumentKey(): string {
    return '_msFlowArguments';
  }

  createMsFlowArguments(actionType: ActionType, isSales: boolean): MsFlowArguments {
    return {
      Portal_type: this.#getPortalType(isSales),
      Portal_name: this.#getPortalName(),
      Action_type: actionType.toString()
    };
  }

  #getPortalType(isSales: boolean): string {
    if (isSales) {
      return 'Sales Portal';
    } else {
      return 'Customer Portal';
    }
  }

  #getPortalName(): string {
    // Hostname gives us for example: sales-fr.hl-display.com
    // We want to return the first part i.e. sales-fr
    let name = window.location.hostname;
    if (name.includes('localhost')) {
      name = this.environment['portalName'];
    }
    return name.split('.')[0];
  }
}

export interface MsFlowArguments {
  Portal_type: string;
  Portal_name: string;
  Action_type: string;
}

export enum ActionType {
  Order = 'Order',
  Sample = 'Sample',
  Offer = 'Offer',
  OfferToOrder = 'OfferToOrder'
}
