<table class="table table-borderless table-striped table-hover">
  <thead>
    <tr>
      <th scope="col" translate>INVOICE_ID</th>
      <th scope="col" translate>ORDER_ID</th>
      <th scope="col" translate>CUSTOMER_NAME</th>
      <th scope="col" translate>INVOICE_DATE</th>
      <th scope="col" translate>DUE_DATE</th>
      <th scope="col" translate>INVOICE_AMOUNT</th>
      <th scope="col" translate>RESIDUAL_AMOUNT</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let invoice of data">
      <td>{{ invoice.id }}</td>
      <td>{{ invoice.extra.fh.ordernr }}</td>
      <td>{{ invoice.extra.fh.kundref2 }}</td>
      <td>{{ invoice.extra.fh.faktdat | date: dateUtilService.dateFormat }}</td>
      <td>{{ invoice.extra.fh.faktffdat | date: dateUtilService.dateFormat }}</td>
      <td>{{ invoice.extra.fh.vb_faktsum | gungCurrency: invoice.extra.fh.valkod:'symbol-narrow':'1.2-2' }}</td>
      <td>{{ invoice.extra.fh.vbfaktrest | gungCurrency: invoice.extra.fh.valkod:'symbol-narrow':'1.2-2' }}</td>
    </tr>
  </tbody>
</table>
