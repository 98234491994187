import { Injectable } from '@angular/core';
import { SimpleConfigBaseFilter } from 'gung-list';
import { Product } from 'gung-standard';
@Injectable({
  providedIn: 'root'
})
export class ProductWidthFilterService extends SimpleConfigBaseFilter<Product> {

  getName(): string {
    return 'WIDTH_MM';
  }
  getOptionIds(item: Product): string[] {
    return [item.extra.hlPimItem.Widthmm];
  }
  getOptionName(key: string): string {
    return key + ' mm';
  }
}
