import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-responsibility-page',
  templateUrl: './responsibility-page.component.html',
  styleUrls: ['./responsibility-page.component.scss']
})
export class ResponsibilityPageComponent implements OnInit {
  currentLang: string;

  constructor(protected translate: TranslateService) {}

  ngOnInit() {
    if (!['en', 'fr', 'no'].includes(this.translate.currentLang)) {
      this.currentLang = 'en';
    } else {
      this.currentLang = this.translate.currentLang;
    }
  }
}
