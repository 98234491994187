import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfigBaseFilter, ListLayout, ListLayoutMultipleComponent, ListSortOption, SimpleConfigBaseFilter } from 'gung-list';
import { CustomerStatisticsService, MetadataService, OrderFinderConfigService, OrderRow, SelectedCustomerService } from 'gung-standard';
import { HlDisplayOrderLinesTableComponent } from '../components/hl-display-order-lines-table/hl-display-order-lines-table.component';
import { HlDisplayOrderService } from './hl-display-order.service';
import { Observable, mergeMap, of } from 'rxjs';

export class DeliveryStatusFilter extends SimpleConfigBaseFilter<OrderRow> {
  constructor(protected metadataService: MetadataService) {
    super();
  }

  getOptionIds(item: OrderRow): string[] {
    return [item?.extra.orp?.ordradst];
  }
  getOptionName(key: string): string {
    return this.metadataService.getMetadataValue('x7', 'ordstatbeskr', key) || key;
  }
  getName(): string {
    return 'DELIVERY_STATUS';
  }
}

@Injectable({
  providedIn: 'root'
})
export class HlDisplayOrderFinderListConfigService {
  customerId: string;

  setCustomerId(customerId: string) {
    this.customerId = customerId;
  }

  constructor(
    protected customerStatisticsService: CustomerStatisticsService,
    protected selectedCustomerService: SelectedCustomerService,
    protected orderlinesService: HlDisplayOrderService,
    protected metadataService: MetadataService
  ) {}

  getItems(): Observable<OrderRow[]> {
    if (this.customerId == null) {
      return this.selectedCustomerService.getSelectedCustomer().pipe(
        mergeMap(customer => {
          if (customer != null) {
            return this.orderlinesService.getOrderRows(customer.id, false);
          } else {
            return of([]);
          }
        })
      );
    } else {
      return this.orderlinesService.getOrderRows(this.customerId, false);
    }
  }

  getFilters(): ConfigBaseFilter<OrderRow>[] {
    return [
      new DeliveryStatusFilter(this.metadataService)
    ];
  }

  getSortOptions(): ListSortOption<OrderRow>[] {
    return [];
  }

  getBatchSizes(): number[] {
    return [24];
  }

  getLayouts(): ListLayout<OrderRow>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => HlDisplayOrderLinesTableComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'Order finder list'
      }
    ];
  }

  getSearchTerms(item: OrderRow): string[] {
    return [item.id, item.orderId, item.productId, item.extra.orp.artbeskr, item.extra.orp.kundbestnr,item.extra.orp.ordlevadr5];
  }

  getItemId(item: OrderRow): string {
    return item.orderId;
  }

  getNothingFoundTranslateTag() {
    return 'NOTHING_FOUND';
  }
}
