import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { NavbarSearchViewMoreListConfigService } from '../../services/navbar-search-view-more-list-config.service';
import { ProductService } from '../../services/products/product.service';
import { AuthService } from '../../services/auth/auth.service';
import { Subject, debounce, first, forkJoin, from, interval, mergeMap, of, takeUntil } from 'rxjs';
import { SearchRequest } from 'gung-list';
import { User } from '../../state/auth/types';
import { ActivatedRoute } from '@angular/router';
import { FormControl } from '@angular/forms';
import { ProductInlineSearchListConfigService } from '../../services/product-inline-search-list-config/product-inline-search-list-config.service';

@Component({
  selector: 'lib-navbar-search-view-more-list',
  templateUrl: './navbar-search-view-more-list.component.html',
  styleUrls: ['./navbar-search-view-more-list.component.css']
})
export class NavbarSearchViewMoreListComponent implements OnInit, OnDestroy {
  loading: boolean = true;
  user: User;

  searchTerm: string = '';
  skip: number;
  batch: number;
  skuLevel: boolean;
  requireAvailability: boolean = this.productInlineSearchListConfigService.requireAvailability;
  totalItems: number = 0;

  protected unsubscribe: Subject<boolean> = new Subject<boolean>();

  searchTermForm: FormControl;

  scrollPosition = 0;

  disableViewMoreSearch: boolean = false;

  constructor(
    public navbarSearchViewMoreListConfigService: NavbarSearchViewMoreListConfigService,
    protected productService: ProductService,
    protected authService: AuthService,
    protected route: ActivatedRoute,
    protected changeDetectorRef: ChangeDetectorRef,
    protected productInlineSearchListConfigService: ProductInlineSearchListConfigService
  ) {}

  ngOnInit(): void {
    this.authService.getCurrentUser().subscribe(user => {this.user = user})
    this.disableViewMoreSearch = this.navbarSearchViewMoreListConfigService.disableViewMoreSearch;
    this.searchTermForm = new FormControl('');

    this.route.queryParams.pipe(takeUntil(this.unsubscribe)).subscribe(params => {
      this.skip = 0;
      this.navbarSearchViewMoreListConfigService.productsSubject.next([]);
      const search: string = params?.search?.split('__:__').pop() || this.searchTerm;
      this.searchTermForm.setValue(search);
      this.setDefaults(params);
      this.search();
    });

    this.searchTermForm.valueChanges.pipe(debounce(() => interval(250))).subscribe(_ => {
      this.skip = 0;
      this.navbarSearchViewMoreListConfigService.productsSubject.next([]);
      this.search();
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(false);
    this.unsubscribe.complete();
  }

  setDefaults(params) {
    // Set Defaults
    this.skip = 0; // Number of products to skip
    this.batch = 24;  // Maximum number of products to show
    this.skuLevel = params?.skuLevel === 'true'; // True - Products | False - Concepts
  }

  showMore() {
    // Save scroll position
    this.scrollPosition = window.scrollY;

    // Add batch size to current skip
    this.skip = this.skip + this.batch;
    this.search();
  }

  search(
    terms: string[] = this.searchTermForm.value.split(' '),
    skip: number = this.skip,
    limit: number = this.batch,
    skuLevel: boolean = this.skuLevel,
    requireAvailability: boolean = this.requireAvailability
  ) {
    this.loading = true;
    if (!this.user) {
      this.authService.getCurrentUser().pipe(
        first(),
        mergeMap(user => {
          this.user = user;
          skip = 0; // On the first time skip is always 0
          const searchRequest: SearchRequest = {
            terms,
            skip,
            limit,
            assortment: user.assortment,
            skuLevel,
            requireAvailability
          };
          return this.productService.getPagedProducts(searchRequest);
        }),
      ).subscribe(result => {
        this.navbarSearchViewMoreListConfigService.productsSubject.next(result.items);
        this.navbarSearchViewMoreListConfigService.getBatchSizes = () => { return [this.batch]; };
        this.totalItems = result.totalItems;
        this.loading = false;
        this.changeDetectorRef.detectChanges();
      });
    } else {
      from(of(this.user)).pipe(
        first(),
        mergeMap(user => {
          const searchRequest: SearchRequest = {
            terms,
            skip,
            limit,
            assortment: user.assortment,
            skuLevel,
            requireAvailability
          };
          return forkJoin({
            result: this.productService.getPagedProducts(searchRequest).pipe(first()),
            cache: this.navbarSearchViewMoreListConfigService.productsSubject.asObservable().pipe(first())
          });
        })
      ).subscribe(({result, cache}) => {
        this.navbarSearchViewMoreListConfigService.productsSubject.next([...cache, ...result.items]);
        this.navbarSearchViewMoreListConfigService.getBatchSizes = () => { return [this.batch + this.skip]; };
        this.totalItems = result.totalItems;
        this.loading = false;
        this.changeDetectorRef.detectChanges();
        setTimeout(() => {
          window.scrollTo(0, this.scrollPosition);
        }, 500);
      });
    }
  }
}
