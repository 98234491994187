import { Component, LOCALE_ID, OnDestroy } from '@angular/core';
import { ActivatedRoute, ActivationEnd, NavigationEnd, Router } from '@angular/router';
import { filter, first, takeUntil, Subject } from 'rxjs';
import { HlDisplayCustomerDetailsComponent } from './components/hl-display-customer-details/hl-display-customer-details.component';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { ProductListByOrderComponent } from './components/product-list-by-order/product-list-by-order.component';
import { HlDisplayProductDetailComponent } from './components/hl-display-product-detail/hl-display-product-detail.component';
import {
  AdminGuard,
  AppState,
  AuthGuard,
  AuthService,
  UserGet,
  SelectedCustomerService,
  GungModalService
} from 'gung-standard';
import { HistoryPageComponent } from './components/history-page/history-page.component';
import { FaqPageComponent } from './components/faq-page/faq-page.component';
import { ResponsibilityPageComponent } from './components/responsibility-page/responsibility-page.component';
import { ContactUsPageComponent } from './components/contact-us-page/contact-us-page.component';
import { ProductListByTopProductsComponent } from './components/product-list-by-top-products/product-list-by-top-products.component';
import { registerLocaleData } from '@angular/common';
import localeSv from '@angular/common/locales/sv';
import localeFr from '@angular/common/locales/fr';
import localeDe from '@angular/common/locales/de';
import localeNl from '@angular/common/locales/nl';
import { HomeComponent } from './components/home/home.component';
import { HlDisplayOrderDetailsComponent } from './components/hl-display-order-details/hl-display-order-details.component';
import { HlDisplayCustomerServiceComponent } from './components/hl-display-customer-service/hl-display-customer-service.component';
import { environment } from '../environments/environment';
import { HlDisplayOpenOrdersQuotesComponent } from './components/hl-display-open-orders-quotes/hl-display-open-orders-quotes.component';
import { HlDisplayOrderListWrapperComponent } from './components/hl-display-order-list-wrapper/hl-display-order-list-wrapper.component';
import { HlProductListWrapperComponent } from './components/hl-product-list-wrapper/hl-product-list-wrapper.component';
import { HlCheckoutWrapperComponent } from './components/hl-checkout-wrapper/hl-checkout-wrapper.component';
import { HlDisplayLoginComponent } from './components/hl-display-login/hl-display-login.component';
import { OAuthService } from 'angular-oauth2-oidc';
import { Store } from '@ngrx/store';
import { authCodeFlowConfig } from './kesko-sso.config';
import { HlDisplayUserDetailsComponent } from './components/hl-display-user-details/hl-display-user-details.component';
import { HlDisplayGungLanguageConfigService } from './services/hl-display-gung-language-config.service';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { HlDisplayCreditBlockedService } from './services/hl-display-credit-blocked.service';
import { HlHomeUserComponent } from './components/hl-home-user/hl-home-user.component';
import { HlFeaturesManagementComponent } from './components/hl-features-portals/hl-features-management/hl-features-management.component';
import { HlFeaturesPortalsComponent } from './components/hl-features-portals/hl-features-portals.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnDestroy {
  title = 'hl-display';
  protected unsubscribe: Subject<void> = new Subject();
  previousUrl = '';
  currentUrl = '';
  containerFull = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    translate: TranslateService,
    private oauthService: OAuthService,
    protected store: Store<AppState>,
    protected authService: AuthService,
    protected gungLanguageConfigService: HlDisplayGungLanguageConfigService,
    protected selectedCustomerService: SelectedCustomerService,
    protected creditBlockedService: HlDisplayCreditBlockedService,
    protected gungModalService: GungModalService
  ) {
    router.events.subscribe(val => {
      if (val instanceof ActivationEnd) {
        this.containerFull = false;
        if ((val.snapshot.component as any).name === 'HlDisplayProductCardListGridViewComponent') {
          this.containerFull = true;
        }
        if (router.url.includes('/products') && !router.url.includes('/products-export')) {
          this.containerFull = true;
        }
        if (router.url.includes('/favourites')) {
          this.containerFull = true;
        }
      }
    });

    if (environment.oauth2Enabled) {
      this.oauthService.configure(authCodeFlowConfig);
      // This makes it so that when we are redirected back from the oauth2 login, we are fetching access tokens and
      // starts to use them.
      this.oauthService.loadDiscoveryDocumentAndTryLogin().then(_ => {
        this.oauthService.setupAutomaticSilentRefresh();
        this.store.dispatch(new UserGet());
      });

      // This check is basically for when the access token has expired but we have not made a silent refresh.
      // Should only happen when we have closed the browser, and therefor having it here in the main component should
      // be good enough.
      if (this.oauthService.getAccessToken() && !this.oauthService.hasValidAccessToken()) {
        this.authService.logout();
      }
    }

    this.selectedCustomerService
      .getSelectedCustomer()
      .pipe(
        filter(customer => !!customer),
        first()
      )
      .subscribe(customer => {
        this.creditBlockedService
          .checkCreditBlocked(customer)
          .pipe(first())
          .subscribe(result => {
            if (result.status === 'CREDIT_HARD_BLOCK') {
              const options: NgbModalOptions = {
                backdrop: 'static',
                size: 'sm',
                keyboard: true
              };
              const translation = this.creditBlockedService.getPortalTranslationCustomer(customer);
              this.gungModalService.openBasicModal(translation.title, translation.body, options).then(
                result => { },
                reason => { }
              );
            }
          });
      });

    const config = router.config;
    router.resetConfig([
      {
        path: '',
        component: HomeComponent,
        canActivate: [AuthGuard],
        pathMatch: 'full'
      },
      {
        path: 'customers/:customerId',
        component: HlDisplayCustomerDetailsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'orders/:orderId/products',
        component: ProductListByOrderComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'product/:productId',
        component: HlDisplayProductDetailComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'products',
        canActivate: [AuthGuard],
        component: HlProductListWrapperComponent
      },
      {
        path: 'most-purchased',
        component: ProductListByTopProductsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'history',
        component: HistoryPageComponent
      },
      {
        path: 'faq',
        component: FaqPageComponent
      },
      {
        path: 'contact',
        component: ContactUsPageComponent
      },
      {
        path: 'responsibilities',
        component: ResponsibilityPageComponent
      },
      {
        path: 'checkout',
        component: HlCheckoutWrapperComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'open-orders',
        component: HlDisplayOpenOrdersQuotesComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'orders',
        canActivate: [AuthGuard],
        component: HlDisplayOrderListWrapperComponent
      },
      {
        path: 'orders/:orderId',
        canActivate: [AuthGuard],
        component: HlDisplayOrderDetailsComponent
      },
      {
        path: 'customer-service',
        canActivate: [AuthGuard],
        component: HlDisplayCustomerServiceComponent
      },
      // {
      //   path: 'login',
      //   component: HlDisplayLoginComponent
      // },
      {
        path: 'admin/users-details/:username',
        component: HlDisplayUserDetailsComponent,
        canActivate: [AuthGuard, AdminGuard]
      },
      {
        path: 'home-user',
        component: HlHomeUserComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'admin/portal-settings',
        component: HlFeaturesPortalsComponent,
        canActivate: [AuthGuard],
        data: {
          breadCrum: 'PORTALS'
        },
        children: [
          {
            path: ':portal/features',
            component: HlFeaturesManagementComponent,
            canActivate: [AuthGuard]
          }
        ]
      },

      ...config
    ]);

    let lang = localStorage.getItem('selectedLanguage');
    // let defaultLang = this.gungLanguageConfigService.getDefaultLanguage();

    translate.use(lang || this.gungLanguageConfigService.getDefaultLanguage());

    registerLocaleData(localeSv);
    registerLocaleData(localeFr);
    registerLocaleData(localeDe);
    registerLocaleData(localeNl);

    translate.onLangChange.subscribe((event: LangChangeEvent) => {
      console.log('language changed', event.lang);
      localStorage.setItem('selectedLanguage', event.lang);
    });

  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}
