import { Injectable } from '@angular/core';
import { OrderListConfigService } from '../order-list-config/order-list-config.service';
import { TranslateService } from '@ngx-translate/core';
import { OrderService } from '../orders/order.service';
import { SelectedCustomerService } from '../selected-customer/selected-customer.service';
import { MetadataService } from '../metadata/metadata.service';
import { Order } from '../../models/order';
import { QuotesCardListComponent } from '../../components/quotes-card-list/quotes-card-list.component';
import { ListLayoutSingleComponent, ListLayout, ConfigBaseFilter, ListLayoutMultipleComponent } from 'gung-list';
import { mergeMap, first, map } from 'rxjs';
import { forkJoin, of } from 'rxjs';
import { CustomerService } from '../customers/customer.service';
import { QuotesTableComponent } from '../../components/quotes-table/quotes-table.component';

@Injectable({
  providedIn: 'root'
})
export class QuotesConfigService extends OrderListConfigService {
  constructor(
    protected translationService: TranslateService,
    protected orderService: OrderService,
    protected selectedCustomerService: SelectedCustomerService,
    protected metadataService: MetadataService,
    protected customerService: CustomerService
  ) {
    super(orderService, selectedCustomerService);
  }

  getLayouts(): ListLayout<Order>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => QuotesTableComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'Order list'
      }
    ];
  }

  getItems() {
    return this.orderService.getQuotes().pipe(
      mergeMap(items => forkJoin([of(items), this.customerService.getCustomers().pipe(first())])),
      map(([orders, customers]) => {
        const result = orders.map(order => {
          return {
            ...order,
            extra: {
              ...order.extra
            }
          };
        });
        result.map(resOrder => {
          const cust = customers.find(customer => customer.id === resOrder.deliveryCustomerId);
          resOrder.extra.customerName = cust ? cust.name : '';
          return resOrder;
        });

        const sorted = result.sort((a, b) => {
          if (a.extra.oh.orddatum > b.extra.oh.orddatum) {
            return 1;
          }
          if (a.extra.oh.orddatum < b.extra.oh.orddatum) {
            return -1;
          }
          return 0;
        });

        return sorted.reverse();
      })
    );
  }

  /*
  getFilters(): ConfigBaseFilter<Order>[] {
    return [
      new OrderDateFilter(this.translationService),
      new OrderExpectedDeliveryFilter(this.translationService),
      new OrderStatusFilter(this.metadataService),
    ];
  } */
}
