import { Component, OnInit } from '@angular/core';
import { HlDisplayCustomerOrderedProductsLastXMonthsListService } from '../../../services/hl-display-customer-ordered-products-last-x-months-list.service';

@Component({
  selector: 'app-customer-ordered-products-last-x-months',
  templateUrl: './customer-ordered-products-last-x-months.component.html',
  styleUrls: ['./customer-ordered-products-last-x-months.component.css']
})
export class CustomerOrderedProductsLastXMonthsComponent implements OnInit {
  constructor(public listConfigService: HlDisplayCustomerOrderedProductsLastXMonthsListService) {}

  ngOnInit() {}
}
