import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { differenceInDays, parse } from 'date-fns';
import {
  ConfigBaseFilter,
  ConfigService,
  ListLayout,
  ListLayoutMultipleComponent,
  ListSortOption,
  SimpleConfigBaseFilter
} from 'gung-list';
import {
  Activity,
  ActivityService,
  Customer,
  CustomerService,
  MetadataService,
  SelectedCustomerService
} from 'gung-standard';
import { forkJoin, Observable, of } from 'rxjs';
import { first, map, mergeMap, switchMap } from 'rxjs';
import { HlDisplayMeetingCardListComponent } from '../components/hl-display-meetings/hl-display-meeting-card-list/hl-display-meeting-card-list.component';

export class MeetingDateFilter extends SimpleConfigBaseFilter<Activity> {
  customers: { [s: string]: Customer };
  constructor(protected translateService: TranslateService) {
    super();
  }

  getName(): string {
    return 'MEETING_DATE';
  }

  getOptionIds(item: Activity): string[] {
    const dayDifferense = differenceInDays(new Date(), new Date(item.extra.hr.eventpldat));

    if (dayDifferense <= 1) {
      return ['TODAY'];
    }
    if (dayDifferense <= 7) {
      return ['THIS_WEEK'];
    }
    if (dayDifferense <= 30) {
      return ['THIS_MONTH'];
    }
    if (dayDifferense <= 90) {
      return ['LAST_THREE_MONTHS'];
    }
    return ['OVER_THREE_MONTHS'];
  }

  getOptionName(key: string): string {
    return this.translateService.instant(key);
  }
}

export class MeetingFinancialGroupFilter extends SimpleConfigBaseFilter<Activity> {
  constructor(protected metadataService: MetadataService) {
    super();
  }

  getName(): string {
    return 'FINANCIAL_GROUP';
  }

  getOptionIds(item: Activity): string[] {
    const metadataValue = this.metadataService.getMetadataValue(
      'q_financialgrp',
      'q_financialgrpdesc',
      item.extra._customer?.extra.kus?.q_financialgrpcode
    );
    if (metadataValue == null) {
      return ['UNDEFINED'];
    } else {
      return [metadataValue];
    }
  }

  getOptionName(key: string): string {
    return key;
  }
}

@Injectable({
  providedIn: 'root'
})
export class HlDisplayMeetingsListConfigService implements ConfigService<Activity> {
  constructor(
    protected activityService: ActivityService,
    protected translateService: TranslateService,
    protected customerService: CustomerService,
    protected metadataService: MetadataService,
    protected selectedCustomerService: SelectedCustomerService
  ) {}

  getItems(): Observable<Activity[]> {
    return this.activityService.getAllActivitys().pipe(
      map(activities => {
        return activities.sort((a, b) => {
          const aDate = new Date(
            (a.extra.hr.eventpldat || a.extra.hr.regdat).substr(0, 11) +
              (a.extra.hr.eventpltime || a.extra.hr.regdat).substr(11)
          ).getTime();
          const bDate = new Date(
            (b.extra.hr.eventpldat || b.extra.hr.regdat).substr(0, 11) +
              (b.extra.hr.eventpltime || b.extra.hr.regdat).substr(11)
          ).getTime();

          return bDate - aDate;
        });
      })
    );
  }

  getFilters(): ConfigBaseFilter<Activity>[] {
    return [new MeetingDateFilter(this.translateService), new MeetingFinancialGroupFilter(this.metadataService)];
  }

  getSortOptions(): ListSortOption<Activity>[] {
    // TODO: implement
    return [];
  }

  getBatchSizes(): number[] {
    return [24];
  }

  getLayouts(): ListLayout<Activity>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => HlDisplayMeetingCardListComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'Order list'
      }
    ];
  }

  getSearchTerms(item: Activity): string[] {
    return [item.id];
  }

  getItemId(item: Activity): string {
    return item.id;
  }

  getNothingFoundTranslateTag() {
    return 'NOTHING_FOUND';
  }
}
