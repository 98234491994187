import { Component, OnInit } from '@angular/core';
import { HlDisplayMeetingsListConfigService } from '../../../services/hl-display-meetings-list-config.service';

@Component({
  selector: 'app-hl-display-meetings',
  template: '<lib-filter-list [configService]="config"></lib-filter-list>',
  styleUrls: ['./hl-display-meetings.component.css']
})
export class HlDisplayMeetingsComponent implements OnInit {
  constructor(public config: HlDisplayMeetingsListConfigService) {}

  ngOnInit(): void {}
}
