import { Injectable } from '@angular/core';
import { SimpleConfigBaseFilter } from 'gung-list';
import { Product } from 'gung-standard';

@Injectable({
  providedIn: 'root'
})
export class ProductEtiquetteFilterService extends SimpleConfigBaseFilter<Product> {
  getName(): string {
    return 'ETIQUETTE_TYPE';
  }
  getOptionIds(item: Product): string[] {
    return item.extra.hlPimProduct.ExtProdSys || [];
  }
  getOptionName(key: string): string {
    return key;
  }
}
