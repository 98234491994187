import { Injectable, OnInit } from '@angular/core';
import {
  DeliveryDateService,
  CalendarDate,
  SelectedCustomerService,
  CartRow,
  MetadataService,
  GungFlowService,
  AuthService
} from 'gung-standard';
import { DateUtilService } from 'gung-common';
import { HlDisplayCartService, CartRowValidationData } from './hl-display-cart.service';
import { BehaviorSubject, Observable, forkJoin, of, timer } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { mergeMap, first, map, debounce, tap, switchMap } from 'rxjs';
import { isAfter, isEqual } from 'date-fns';
import { environment } from '../../environments/environment';
import { AvailabilitySettingsGroup, HlDisplayPortalFeaturesService } from './hl-display-portal-features.service';

@Injectable({
  providedIn: 'root'
})
export class HlDeliveryDateService extends DeliveryDateService {
  public isSales: boolean;
  public availabilityBufferDays: number;
  protected triggered = false;

  private datesSubject: BehaviorSubject<CalendarDate[]> = new BehaviorSubject<CalendarDate[]>(undefined);

  constructor(
    http: HttpClient,
    protected gungFlowService: GungFlowService,
    private hldisplayCartService: HlDisplayCartService,
    private selectedCustomerService: SelectedCustomerService,
    private dateUtil: DateUtilService,
    private metadataService: MetadataService,
    protected authService: AuthService,
    private hlDisplayPortalFeaturesService: HlDisplayPortalFeaturesService
  ) {
    super(http, gungFlowService);
    this.authService
      .getHasRoles('SALES')
      .pipe(first())
      .subscribe(result => (this.isSales = result));
    this.hlDisplayPortalFeaturesService
      .getPortalFeatureGroup('availabilitySettingsGroup')
      .pipe(first())
      .subscribe(availabilitySettingsGroup => {
        this.availabilityBufferDays = (availabilitySettingsGroup as AvailabilitySettingsGroup).availabilityBufferDays;
      });
  }

  public getDeliveryDates(deliveryMethod: string, rows?: CartRow[]): Observable<CalendarDate[]> {
    return rows ? this.getDeliveryDatesFromRows(deliveryMethod, rows) : this.getDeliveryDatesForCart(deliveryMethod);
  }

  public getDeliveryDatesFromRows(deliveryMethod: string, rows: CartRow[]) {
    return this.authService.getRoles().pipe(
      first(),
      switchMap(roles => {
        if (roles.includes('SALES')) {
          return this.getDispatchDates().pipe(
            mergeMap(calendarDates =>
              forkJoin([of(calendarDates), this.hldisplayCartService.getCartRowsValidationData(rows).pipe(first())])
            ),
            switchMap(([calenderDates, validationData]) =>
              this.evaluateDeliveryDatesSales(calenderDates, validationData, deliveryMethod)
            )
          );
        } else {
          return super.getDeliveryDates(deliveryMethod).pipe(
            mergeMap(calendarDates =>
              forkJoin([of(calendarDates), this.hldisplayCartService.getCartRowsValidationData(rows).pipe(first())])
            ),
            switchMap(([calenderDates, validationData]) => this.evaluateDeliveryDates(calenderDates, validationData))
          );
        }
      })
    );
  }

  public getDeliveryDatesForCart(deliveryMethod: string): Observable<CalendarDate[]> {
    if (!this.triggered && !this.datesSubject.value) {
      this.triggered = true;

      this.hldisplayCartService.getCurrentCart().pipe(
        first(),
        mergeMap((cartRows: CartRow[]) => {
          return this.getDeliveryDatesFromRows(deliveryMethod, cartRows);
        }),
        tap(deliveryDates => {
          this.datesSubject.next(deliveryDates);
          this.triggered = false
        })
      ).subscribe()
    }

    return this.datesSubject.asObservable();
  }

  private evaluateDeliveryDatesSales(
    calendarDates: CalendarDate[],
    validationData: CartRowValidationData[],
    deliveryMethod: string
  ): Observable<CalendarDate[]> {
    const firstAvailable = this.getFirstAvailableDate(validationData);
    const inStock = firstAvailable ? firstAvailable.availabilityData.currentAvailability > 0 : true;
    const firstAvailableDate: Date = firstAvailable ? firstAvailable.firstAvailableDate : new Date();
    const availableDeliveryDates = this.evaluateAvailableDeliveryDates(calendarDates, firstAvailableDate, inStock);

    let padding = 0;
    const paddingString = this.metadataService.getMetadataValue('x2f', 'internledtid', deliveryMethod);
    if (paddingString) {
      try {
        padding = parseInt(paddingString, 10);
      } catch (error) {
        console.error(error);
      }
    }
    return of(this.evaluateExpectedDeliveryDates(availableDeliveryDates, firstAvailableDate, padding, inStock));
  }

  private evaluateDeliveryDates(
    calendarDates: CalendarDate[],
    validationData: CartRowValidationData[]
  ): Observable<CalendarDate[]> {
    const firstAvailable = this.getFirstAvailableDate(validationData);
    const inStock = firstAvailable ? firstAvailable.availabilityData.currentAvailability > 0 : true;
    const firstAvailableDate: Date = firstAvailable ? firstAvailable.firstAvailableDate : new Date();
    return this.getTransportTimeForSelectedCustomer().pipe(
      first(),
      map(transportElement => {
        let padding = transportElement ? transportElement.transporttime : 1;
        if (this.isSales) {
          padding = 0;
        }
        const availableDeliveryDates = this.evaluateAvailableDeliveryDates(calendarDates, firstAvailableDate, inStock);
        //       console.log('PADDING [availableDeliveryDates]', availableDeliveryDates);
        const res = this.evaluateExpectedDeliveryDates(availableDeliveryDates, firstAvailableDate, padding, inStock);
        //       console.log('PADDING [res]', res);
        return res;
      })
    );
  }

  private getFirstAvailableDate(data: CartRowValidationData[]): CartRowValidationData | undefined {
    if (!data || data.length === 0) {
      return undefined;
    }
    return data.sort((a, b) => {
      const date1 = this.dateUtil.getFormattedDateString(a.firstAvailableDate as Date);
      const date2 = this.dateUtil.getFormattedDateString(b.firstAvailableDate as Date);
      return date2.localeCompare(date1);
    })[0];
  }

  findFirstAvailableDate(calendarDates: CalendarDate[], fromDate?: Date): CalendarDate {
    return calendarDates.find(calendarDate => {
      if (!calendarDate.valid) {
        return false;
      }

      if (fromDate) {
        const hlDate = calendarDate as HlCalendarDate;
        if (isAfter(hlDate.estimatedShippingDate, fromDate) || isEqual(hlDate.estimatedShippingDate, fromDate)) {
          return true;
        } else {
          return false;
        }
      }
      return true;
    });
  }

  private evaluateAvailableDeliveryDates(
    calenderDates: CalendarDate[],
    firstAvailableDate: Date,
    inStock: boolean
  ): CalendarDate[] {
    let comparable = this.dateUtil.getFormattedDateString(firstAvailableDate);
    if (this.availabilityBufferDays > 0) {
      // console.log('USING CONFIG TRANSFORM@evaluateAvailableDeliveryDates [environment.availability.useConfigTransform]', environment.availability.useConfigTransform);
      return calenderDates.map(calendarDate => {
        if (this.dateUtil.getFormattedDateString(calendarDate.date).localeCompare(comparable) < 0) {
          return {
            ...calendarDate,
            valid: false
          };
        }
        return calendarDate;
      });
    }


    const availableDates = calenderDates.map(calendarDate => {
      if (!this.isSales) {
        if (this.dateUtil.getFormattedDateString(calendarDate.date).localeCompare(comparable) <= 0) {
          return {
            ...calendarDate,
            valid: false
          };
        }
      } else {
        const todayFormatted = this.dateUtil.getFormattedDateString(new Date());
        if (todayFormatted.localeCompare(comparable) === 0) {
          return calendarDate;
        }
        if (this.dateUtil.getFormattedDateString(calendarDate.date).localeCompare(comparable) <= 0) {
          return {
            ...calendarDate,
            valid: false
          };
        }
      }
      return calendarDate;
    });
    return availableDates;
  }

  private evaluateExpectedDeliveryDates(
    availableDates: CalendarDate[],
    firstAvailableDate: Date,
    padding: number,
    inStock: boolean
  ): CalendarDate[] {
    const now = new Date();
    // No padding for dates in the nordics, just use dates from backend
    if (this.availabilityBufferDays > 0) {
      // console.log('USING CONFIG TRANSFORM@evaluateExpectedDeliveryDates [environment.availability.useConfigTransform]', environment.availability.useConfigTransform);
      // console.log('USING CONFIG TRANSFORM@evaluateExpectedDeliveryDates [environment.availability.bufferDays]', environment.availability.bufferDays);
      /**
       *
       * Apply buffer days based on configuration
       *
       */
      const expectedDates1210 = availableDates.map((calendarDate, i, calendarDates) => {
        // early return if date is not valid
        if (!calendarDate.valid) {
          return calendarDate;
        }
        // The buffer days from config
        const bufferDays = this.availabilityBufferDays;
        const shippingDate = calendarDate;
        const estDeliveryDates = calendarDates
          .filter(d => d.valid)
          .filter(d => d.date.getTime() <= shippingDate.date.getTime());
        // Take the buffer into account. All delivery dates before the buffer threshold has been passed is invalid
        if (estDeliveryDates.length <= bufferDays) {
          return {
            ...calendarDate,
            valid: false
          };
        }

        return {
          ...calendarDate,
          estimatedShippingDate: calendarDate.date
        };
      });
      return expectedDates1210;
    }

    // Legacy for other countries
    const expectedDates = availableDates.map((calendarDate, i, calendarDates) => {
      // early return if date is not valid
      if (!calendarDate.valid) {
        return calendarDate;
      }
      const shippingDate = calendarDate;
      let bufferDays = padding;
      const estDeliveryDates = calendarDates
        .filter(d => d.valid)
        .filter(d => d.date.getTime() <= shippingDate.date.getTime());
      const diffInDays = (shippingDate.date.getTime() - firstAvailableDate.getTime()) / 1000 / 60 / 60 / 24;

      if (!this.isSales) {
        if (diffInDays <= 7 && now.getHours() >= 12) {
          if (estDeliveryDates.length <= 7) {
            return {
              ...calendarDate,
              valid: false
            };
          }
        } else if (diffInDays <= 7 && now.getHours() < 12) {
          if (estDeliveryDates.length <= 6) {
            return {
              ...calendarDate,
              valid: false
            };
          }
        }
      }
      // Take the buffer into account. All delivery dates before the buffer threshold has been passed is invalid
      if (estDeliveryDates.length <= bufferDays) {
        return {
          ...calendarDate,
          valid: false
        };
      }
      if (!this.isSales) {
        const estDeliveryDate = estDeliveryDates[estDeliveryDates.length - bufferDays - 1];
        return {
          ...calendarDate,
          estimatedShippingDate: estDeliveryDate.date
        } as HlCalendarDate;
      } else {
        return {
          ...calendarDate,
          estimatedShippingDate: calendarDate.date
        } as HlCalendarDate;
      }
    });
    return expectedDates;
  }

  public getTransportTimeForSelectedCustomer(): Observable<TransportMatrixElement> {
    return this.selectedCustomerService.getSelectedCustomer().pipe(
      first(),
      mergeMap(customer => {
        let postalCode = customer.extra.fr.ftgpostnr;

        if (customer.extra.kus.ordlevplats1) {
          if (customer.extra.lp && customer.extra.lp.length > 0) {
            const deliveryLocation = customer.extra.lp.find(location => {
              return location.ordlevplats1 === customer.extra.kus.ordlevplats1;
            });

            if (deliveryLocation) {
              if (deliveryLocation._fr) {
                postalCode = deliveryLocation._fr.ftgpostnr;
              }
            }
          }
        }

        const deliveryMethodCode = customer.extra.kus.levsattkod;
        const stockNumber = '0';

        let postalCodePrefix = postalCode;

        const countryCode = customer.extra.fr.landskod;
        const transporterCode = customer.extra.kus.transportorskod;

        return this.getTransportMatrix(deliveryMethodCode, stockNumber, countryCode, transporterCode).pipe(
          first(),
          map(transportMatrix => {
            return transportMatrix.find(element => {
              return element.postalcode === postalCodePrefix;
            });
          })
        );
      })
    );
  }

  private getTransportMatrix(
    deliveryMethodCode: string,
    stockNumber: string,
    countryCode: string,
    transporterCode: string
  ): Observable<TransportMatrixElement[]> {
    const urlString = `json/hl-display/transport-times?levsattkod=${deliveryMethodCode}&lagstalle=${stockNumber}&landskod=${countryCode}&transportorskod=${transporterCode}`;
    return this.http.get<TransportMatrixElement[]>(urlString);
  }

  public getDispatchDates(deliveryMethod?: string): Observable<CalendarDate[]> {
    const url = 'json/hl-display/calendar-dates';
    return this.http
      .get<CalendarDate[]>(url, {
        params: {
          deliveryMethodCode: deliveryMethod || ''
        }
      })
      .pipe(
        map(datesResp =>
          datesResp.map(date => ({
            valid: date.valid,
            invalidReason: date.invalidReason,
            date: new Date(date.date)
          }))
        )
      );
  }
}

export interface TransportMatrixElement {
  postalcode: string;
  transporttime: number;
}

export interface HlCalendarDate extends CalendarDate {
  estimatedShippingDate: Date;
}
