import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GungLanguageConfigService, LanguageSelectionComponent } from 'gung-common';
import { HlDisplayGungLanguageConfigService } from '../../services/hl-display-gung-language-config.service';
import { first } from 'rxjs';

@Component({
  selector: 'app-hl-display-language-selection',
  templateUrl: './hl-display-language-selection.component.html',
  styleUrls: ['./hl-display-language-selection.component.css']
})
export class HLDisplayLanguageSelectionComponent extends LanguageSelectionComponent {
  constructor(
    protected translateService: TranslateService,
    public languageConfigService: HlDisplayGungLanguageConfigService
  ) {
    super(translateService, languageConfigService);
  }

  initLanguages() {
    this.languageConfigService
      .initLanguageConfig()
      .pipe(first())
      .subscribe(() => super.initLanguages());
  }
}
