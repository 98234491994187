import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HlDisplayCustomerKuarService {
  constructor(protected http: HttpClient) {}

  getCustomerKuar(customerId: string): Observable<{ [key: string]: Object }> {
    const url = 'json/hl-display/kuar/' + customerId.replace('/', '_GUNGSLASH_');
    return this.http.get<{ [key: string]: Object }>(url).pipe(
      catchError((error, caught) => {
        // Handle issue where the backend is old enough that the endpoint does not exist.
        return of({});
      })
    );
  }
}
