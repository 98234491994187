import { Injectable, Inject, Optional } from '@angular/core';
import {
  PriceService,
  BackendPrice,
  Customer,
  CustomerProductPrice,
  SelectedCustomerService,
  ProductService,
  BackendPriceLevel,
  GungFlowService,
  GungStringConverterService,
  CartRow,
  CartRowPrice
} from 'gung-standard';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin, of } from 'rxjs';
import { mergeMap, first, map, switchMap } from 'rxjs';
import { JeevesPriceService } from 'gung-standard-jeeves';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HlPriceService extends JeevesPriceService {
  constructor(
    http: HttpClient,
    customerService: SelectedCustomerService,
    productService: ProductService,
    gungFlowService: GungFlowService,
    @Optional()
    @Inject('environment')
    protected environment: { [s: string]: any }
  ) {
    super(http, customerService, productService, gungFlowService);
  }

  protected mapCustomerProductPrice(
    backendPrice: BackendPrice,
    customer: Customer,
    qty?: number
  ): CustomerProductPrice {
    let price = super.mapCustomerProductPrice(backendPrice, customer, qty);

    // This snippet is for adding additional visual cost for special customers with specific financial group codes. The main example is ICA Group which has
    // a 3.8% admin fee which they want to display in the portal, but should not be added onto the order directly. This then gets added to the invoice later in Jeeves, outside of Gung. - Adam
    const customerFinancialGroup = customer.extra.kus.q_financialgrpcode;
    const discountFactor = environment.discountCustomerFinancialGroup[customerFinancialGroup]
      ? environment.discountCustomerFinancialGroup[customerFinancialGroup]
      : 0;
    if (discountFactor > 0) {
      // Round to 2 decimals
      price.customerGrossPrice.value = Number((price.customerGrossPrice.value * (1 + discountFactor)).toFixed(2));
      price.customerNetPrice.value = Number((price.customerNetPrice.value * (1 + discountFactor)).toFixed(2));
    }

    price = {
      ...price,
      priceList: this.getPriceLevel(backendPrice, qty).extra.PriceList
    } as HlDisplayCustomerProductPrice;

    return price;
  }

  public getPriceLevel(backendPrice: BackendPrice, qty?: number): BackendPriceLevel {
    let level = super.getPriceLevel(backendPrice, qty);
    let i = 1;
    while (level.price === 0 && i < backendPrice.levels.length) {
      level = backendPrice.levels[i];
      i++;
    }
    return level;
  }

  getCustomerPrice(productId: string, customerId: string): Observable<CustomerProductPrice> {
    return this.internalHttpGetCustomerPrice(productId, customerId).pipe(
      mergeMap(x =>
        forkJoin([
          of(x),
          this.customerService.getSelectedCustomer().pipe(first()),
          this.productService.getProduct(productId).pipe(first())
        ])
      ),
      map(([backendPrice, customer, product]) => {
        return this.mapCustomerProductPrice(backendPrice, customer, product.extra.stepAmount);
      })
    );
  }

  getCustomerPrices(productIds: string[], customerId: string): Observable<CustomerProductPrice[]> {
    return this.internalHttpGetCustomerPrices(productIds, customerId).pipe(
      switchMap(x =>
        forkJoin([
          of(x),
          this.customerService.getSelectedCustomer().pipe(first()),
          this.productService.getProductsByIds(productIds).pipe(first())
        ])
      ),
      map(([backendPrices, customer, products]) => {
        return Object.values(backendPrices).map(backendPrice => {
          const product = products.find(prod => prod.id === backendPrice.productId);
          let stepAmount = 0;
          if (!!product) {
            stepAmount = product.extra.stepAmount;
          }
          return this.mapCustomerProductPrice(backendPrice, customer, stepAmount);
        });
      })
    );
  }

  getCurrentCustomerPrice(productId: string): Observable<CustomerProductPrice> {
    return super.getCurrentCustomerPrice(productId).pipe(
      map(price => {
        price.backendPrice.levels = price.backendPrice.levels.filter(l => l.price > 0);
        return price;
      })
    );
  }

  getPriceByPricelist(productId: string, pricelistId: string): Observable<CustomerProductPrice> {
    return this.internalHttpGetPriceByPricelist(productId, pricelistId).pipe(
      mergeMap(x => forkJoin([of(x), this.customerService.getSelectedCustomer().pipe(first())])),
      map(([backendPrice, customer]) => {
        return this.mapCustomerProductPrice(backendPrice, customer);
      })
    );
  }

  protected internalHttpGetPriceByPricelist(productId: string, pricelistId: string): Observable<BackendPrice> {
    const convertedId = GungStringConverterService.toGungString(productId);

    return this.gungFlowService.getSelectedFlow().pipe(
      first(),
      switchMap(selectedFlow =>
        this.http.get<BackendPrice>(`json/product-price-pricelist/${pricelistId}/${convertedId}`, {
          headers: { maxAge: '86400' }
        })
      )
    );
  }

  getCustomerDiscountPercent(priceLevel: BackendPriceLevel, backendPrice: BackendPrice) {
    if (priceLevel.discount === 0) {
      // HL sometimes do not include discounts on their level but rather use "flat" staffed prices in Jeeves
      const basePrice = this.getPriceLevel(backendPrice);
      const testDiscount = (basePrice.price - priceLevel.price) / basePrice.price;
      return testDiscount * 100;
    }
    return priceLevel.discount;
  }

  getCartRowPrice(customerPrice: CustomerProductPrice, cartRow: CartRow): CartRowPrice {
    const cartRowPrice = super.getCartRowPrice(customerPrice, cartRow);

    // If the user has manually modified the price in the UI, we want to override the discount percent and row total.
    // If we do not do this, we will keep applying the discount even though the user has manually set the price. - Adam
    if (cartRow.extra?.modifiedPrice) {
      cartRowPrice.cartRowDiscountPercent = 0;
      cartRowPrice.cartRowTotalPriceInclRowDiscount.value = cartRow.extra.modifiedPrice * cartRow.qty;
    }

    return cartRowPrice;
  }
}

export interface HlDisplayCustomerProductPrice extends CustomerProductPrice {
  priceList: string | undefined;
  complementaryPricelist?: boolean;
}
