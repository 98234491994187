import { Component } from '@angular/core';
import { AuthService, BackendFeatureService, PasswordPolicyService, UserDetailsUserComponent, UsersService } from 'gung-standard';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-hl-display-user-details-user',
  templateUrl: './hl-display-user-details-user.component.html',
  styleUrls: ['./hl-display-user-details-user.component.css']
})
export class HlDisplayUserDetailsUserComponent extends UserDetailsUserComponent {
  
  public isManager = false;

  constructor(
    protected usersService: UsersService,
    protected http: HttpClient,
    protected backendFeatureService: BackendFeatureService,
    protected passwordPolicyService: PasswordPolicyService, 
    protected authService: AuthService,
  ) {
    super(usersService, http, backendFeatureService, passwordPolicyService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.authService.getRoles().subscribe(roles => {
      this.isManager = roles.includes('MANAGER');
    });
  }
}
