import { Component, OnInit, ViewChild } from '@angular/core';
import { HlDisplayOrderFinderListConfigService } from '../../services/hl-display-order-finder-list-config.service';
import { ActivatedRoute } from '@angular/router';
import { FilterListComponent } from 'gung-list/public-api';
import { OrderRow } from 'gung-standard';

@Component({
  selector: 'app-hl-display-order-finder',
  template: `<lib-filter-list #orderlinesList [configService]="hlDisplayOrderlinesConfigService"></lib-filter-list>`,
  styleUrls: ['./hl-display-order-finder.component.css']
})
export class HlDisplayOrderFinderComponent  {

  constructor(public hlDisplayOrderlinesConfigService: HlDisplayOrderFinderListConfigService, route: ActivatedRoute) {
    const customerId = route.snapshot.params.customerId;

    if (customerId != null) {
      hlDisplayOrderlinesConfigService.setCustomerId(customerId);
    } else {
      hlDisplayOrderlinesConfigService.setCustomerId(null);
    }
  }
  @ViewChild('orderlinesList') orderlinesList: FilterListComponent<OrderRow>;
}
