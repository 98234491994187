import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AuthService } from 'gung-standard';
import { first, map } from 'rxjs';
import { HlDisplayPortalFeaturesService, OpenOrdersSettingsGroup } from '../../services/hl-display-portal-features.service';

@Component({
  selector: 'app-hl-display-open-orders-quotes',
  template: `
    <lib-gung-tabset justify="fill">
      <lib-gung-tab [title]="'ORDERS' | translate">
        <ng-template libGungTabContent>
          <div class="pb-4"></div>
          <app-hl-display-open-orders></app-hl-display-open-orders>
        </ng-template>
      </lib-gung-tab>
      <lib-gung-tab [title]="'INVOICES' | translate" *ngIf="showInvoiceTab && isSales">
        <ng-template libGungTabContent>
          <div class="pb-4"></div>
          <app-hl-display-invoices></app-hl-display-invoices>
        </ng-template>
      </lib-gung-tab>
      <lib-gung-tab [title]="'OFFERS' | translate" *ngIf="showOffersTab && !isManager">
        <ng-template libGungTabContent>
          <div class="pb-4"></div>
          <app-hl-display-quotes></app-hl-display-quotes>
        </ng-template>
      </lib-gung-tab>
      <lib-gung-tab [title]="'MEETINGS' | translate" *ngIf="showMeetingsTab && !isManager">
        <ng-template libGungTabContent>
          <div class="pb-4"></div>
          <app-hl-display-meetings></app-hl-display-meetings>
        </ng-template>
      </lib-gung-tab>
    </lib-gung-tabset>
  `,
  styleUrls: ['./hl-display-open-orders-quotes.component.css']
})
export class HlDisplayOpenOrdersQuotesComponent implements OnInit {

  public isManager = false;
  isSales = false;

  showInvoiceTab = false;
  showMeetingsTab = false;
  showOffersTab = false;

  constructor(
    protected authService: AuthService,
    protected hlDisplayPortalFeaturesService: HlDisplayPortalFeaturesService
  ) { }

  ngOnInit() {
    this.authService.getRoles().pipe(first()).subscribe(roles => {
      this.isManager = roles.includes('MANAGER');
      this.isSales = roles.includes('SALES');
    });
    this.hlDisplayPortalFeaturesService.getPortalFeatureGroup('openOrdersSettingsGroup')
      .pipe(
        first(),
        map(openOrdersSettings => openOrdersSettings as OpenOrdersSettingsGroup)
      )
      .subscribe(openOrdersSettingsGroup => {
        this.showInvoiceTab = openOrdersSettingsGroup.showInvoiceTab;
        this.showMeetingsTab = openOrdersSettingsGroup.showMeetingsTab;
        this.showOffersTab = openOrdersSettingsGroup.showOffersTab;
      })
  }
}
