<div ngbDropdown>
  <button class="btn dorp-down" ngbDropdownToggle>
    <b>{{ currentIso639LangCode }}</b>
  </button>
  <div ngbDropdownMenu class="lang-dropdown">
    <button
      class="btn"
      ngbDropdownItem
      (click)="changeLanguage('fr')"
      *ngIf="languagesEnabled['fr']"
    >
      <span><b>FR</b> - Français</span>
    </button>
    <button
      class="btn"
      ngbDropdownItem
      (click)="changeLanguage('nl')"
      *ngIf="languagesEnabled['nl']"
    >
      <span><b>NL</b> - Dutch</span>
    </button>
    <button *ngIf="languagesEnabled['de']" class="btn" ngbDropdownItem (click)="changeLanguage('de')">
      <span><b>DE</b> - Deutsch</span>
    </button>
    <button class="btn" ngbDropdownItem (click)="changeLanguage('no')" *ngIf="languagesEnabled['no']">
      <span><b>NO</b> - Norsk</span>
    </button>
    <button
      class="btn"
      ngbDropdownItem
      (click)="changeLanguage('se')"
      *ngIf="languagesEnabled['se']"
    >
      <span><b>SV</b> - Svenska</span>
    </button>
    <button
      class="btn"
      ngbDropdownItem
      (click)="changeLanguage('fi')"
      *ngIf="languagesEnabled['fi']"
    >
      <span><b>FI</b> - Finnish</span>
    </button>
    <button class="btn" ngbDropdownItem (click)="changeLanguage('dk')" *ngIf="languagesEnabled['dk']">
      <span><b>DK</b> - Dansk</span>
    </button>
    <button
      class="btn"
      ngbDropdownItem
      (click)="changeLanguage('en')"
      *ngIf="languagesEnabled['en']"
    >
      <span><b>EN</b> - English</span>
    </button>
    <button
      class="btn"
      ngbDropdownItem
      (click)="changeLanguage('en-gb')"
      *ngIf="languagesEnabled['en-gb']"
    >
      <span><b>EN-GB</b> - English (UK)</span>
    </button>
  </div>
</div>
