<div class="row" *ngIf="!!product">
  <div class="col-12 col-md-7">
    <div class="border shadow-sm bg-white p-5">
      <div class="row">
        <div class="col-12 mb-2" *ngIf="!!product.extra.images">
          <ngb-carousel #myCarousel [interval]="-1" [showNavigationIndicators]="false" [showNavigationArrows]="false">
            <ng-template ngbSlide *ngFor="let image of product.extra.images; let i = index" [id]="imageIdString + i">
              <div class="square">
                <div class="content">
                  <div class="d-flex justify-content-center align-items-flex-start">
                    <img class="img-fluid" src="https://cdn1.gung.io/fit-in/320x320/filters:background_color(white):etag({{
                        image.s3ETag
                      }}):format(jpeg)/{{ image.s3Uri }}" alt="{{ image.description }}" />
                  </div>
                </div>
              </div>
            </ng-template>
          </ngb-carousel>
        </div>
        <div class="col-12">
          <div class="scolling-images">
            <a class="scroll-left" (click)="scrollImagesLeft()">
              <i class="fa fa-2x fa-caret-left"></i>
            </a>
            <div class="scroll-container row" #scrollContainer>
              <div class="col-4 d-flex justify-content-center align-items-center"
                [ngClass]="{ 'pr-0': index == product.extra.images.length - 1 }"
                *ngFor="let image of product.extra.images; let i = index">
                <img (click)="select(i)" class="img-fluid" src="https://cdn1.gung.io/fit-in/150x150/filters:fill(white):background_color(white):etag({{
                    image.s3ETag
                  }}):format(jpeg)/{{ image.s3Uri }}" alt="{{ image.description }}" />
              </div>
            </div>
            <a class="scroll-right" (click)="scrollImagesRight()">
              <i class="fa fa-2x fa-caret-right"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-12 col-md-5" *ngIf="!!product">
    <div class="border shadow-sm bg-white p-5 h-100">
      <div class="row">
        <div class="col">
          <h2 class="mb-1 pb-3 border-bottom gray">
            <b>{{ getName() }}</b>
          </h2>
          @if (displayStaffedPrices && price) {
          <hl-display-price [price]="price" priceLevelsDisplayType="FULL" displayOnlyPrice="true"
            type="STAFFED"></hl-display-price>
          } @else {
            <span *ngIf="price" class="text-nowrap d-block h3 m-0 mt-4 blue font-weight-bold">
              {{
                price.customerGrossPrice.value
                  | gungCurrency: price.customerGrossPrice.currencyCode : 'symbol-narrow' : '1.2-2'
              }}
              <span
                *ngIf="displayDiscountFromPriceList && price?.backendPrice?.levels?.[0]?.extra?.prl?.proc1 > 0"
                class="text-disc"
                >(-{{ price.backendPrice.levels[0].extra.prl.proc1 }}%)</span
              >
            </span>
          }
          <span *ngIf="availability" class="d-flex align-items-center light-gray">
            <!-- <i class="fas fa-circle fa-xs mr-3"></i> -->
            <app-hl-display-availability [availability]="availability"></app-hl-display-availability>
          </span>
          <app-hl-display-buy-btn class="mt-4" [product]="product" [id]="product.id"></app-hl-display-buy-btn>
          <h3 class="mt-4 blue" translate>DESCRIPTION</h3>
          <p class="mt-2 light-gray">{{ getDescription() }}</p>
          <ul class="component-list light-gray" *ngIf="components && components.length > 0">
            <li *ngFor="let component of components">
              {{ componentAmounts[component.id] }} {{ component.name }} ({{ component.id }})
            </li>
          </ul>
          <h3 class="mt-4 blue" translate>SPECIFICATION</h3>
          <div class="product-info-table mt-2 light-gray">
            <div class="table-responsive">
              <table class="table table-sm table-striped">
                <tbody>
                  <tr *ngIf="!!product.extra.hlPimItem.Lengthmm">
                    <th class="w-auto">
                      <span translate>LENGTH</span>
                    </th>
                    <td class="w-75">
                      {{ product.extra.hlPimItem.Lengthmm }}
                    </td>
                  </tr>
                  <tr *ngIf="!!product.extra.hlPimItem.Depthmm">
                    <th class="w-auto">
                      <span translate>DEPTH</span>
                    </th>
                    <td class="w-75">
                      {{ product.extra.hlPimItem.Depthmm }}
                    </td>
                  </tr>
                  <tr *ngIf="!!product.extra.hlPimItem.ItemColourName">
                    <th class="w-auto">
                      <span translate>COLOUR</span>
                    </th>
                    <td class="w-75">
                      {{ getColorTranslated(product.extra.hlPimItem.ItemColourName) }}
                    </td>
                  </tr>
                  <tr *ngIf="!!product.extra.stepAmount">
                    <th class="w-auto">
                      <span translate>PACKAGE_SIZE</span>
                    </th>
                    <td class="w-75">
                      {{ product.extra.stepAmount }}
                    </td>
                  </tr>
                  <tr *ngIf="!!product.extra.hlPimItem?.NumberPerBox && !product.extra.stepAmount">
                    <th class="w-auto">
                      <span translate>PACK_SIZE</span>
                    </th>
                    <td class="w-75">
                      {{ product.extra.hlPimItem.NumberPerBox }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>