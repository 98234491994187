import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Customer, MetadataService, SelectedCustomerService } from 'gung-standard';
import { first, map } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CheckoutSettingsGroup, HlDisplayPortalFeaturesService } from '../../services/hl-display-portal-features.service';

@Component({
  selector: 'app-faq-page',
  templateUrl: './faq-page.component.html',
  styleUrls: ['./faq-page.component.scss']
})
export class FaqPageComponent implements OnInit {
  public minimumFreight: number;
  public freightCost: number;
  public minimumOrder: number;
  public adminCost: number;

  isSampleEnabled = false;

  currentLang: string;

  constructor(
    protected selectedCustomer: SelectedCustomerService,
    protected metadataService: MetadataService,
    protected translate: TranslateService,
    protected hlDisplayPortalFeaturesService: HlDisplayPortalFeaturesService
  ) { }

  customerId: string;

  ngOnInit() {
    this.selectedCustomer
      .getSelectedCustomer()
      .pipe(first(customer => customer != null))
      .subscribe(customer => {
        this.customerId = customer.id;
        this.getValuesFromQHlTpSalesmarket(customer);
      });

    this.hlDisplayPortalFeaturesService.getPortalFeatureGroup('checkoutSettingsGroup')
      .pipe(
        first(),
        map(checkoutSettingsGroup => checkoutSettingsGroup as CheckoutSettingsGroup)
      ).subscribe(checkoutSettingsGroup => {
        this.isSampleEnabled = checkoutSettingsGroup.showCreateSampleOrder;
      })

    this.currentLang = this.translate.currentLang;
  }

  getValuesFromQHlTpSalesmarket(customer: Customer) {
    const qHlTpSalesmarket = this.metadataService.getMetadataTableList('q_hl_tp_salesmarket');
    if (qHlTpSalesmarket) {
      const salesmarket = qHlTpSalesmarket.find(s => s.q_salesmarket_code === (customer.extra.fr.landskod || 'FR'));
      this.minimumFreight = Number(salesmarket?.q_hl_fc_amount || 0);
      this.minimumOrder = Number(salesmarket?.q_hl_minordervalue || 0);
      this.adminCost = Number(salesmarket?.q_hl_sof_charged_amount || 0);
      this.freightCost = Number(salesmarket?.q_hl_fc_threshold_amount || 0);
    }
  }
}
