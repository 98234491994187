import { Component, ViewChild } from '@angular/core';
import { ProductListConfigService, Product, OrderService, OrderToBasketService } from 'gung-standard';
import { HlDisplayProductListConfigByOrderService } from '../../services/hl-display-product-list-config-by-order.service';
import { FilterListComponent } from 'gung-list';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-product-list-by-order',
  templateUrl: './product-list-by-order.component.html',
  styleUrls: ['./product-list-by-order.component.css'],
  providers: [
    {
      provide: ProductListConfigService,
      useClass: HlDisplayProductListConfigByOrderService
    }
  ]
})
export class ProductListByOrderComponent {
  @ViewChild('productListByOrder')
  productList: FilterListComponent<Product>;
  public addedToBasket = false;
  public showAddToBasketBtn = false;

  public addToBasket() {
    const routeParams = this.route.snapshot.params;
    const orderId = routeParams.orderId;
    this.orderToBasketService.addOrderToBasket(orderId).then(() => (this.addedToBasket = true));
  }

  constructor(
    protected orderService: OrderService,
    public productListConfigService: ProductListConfigService,
    private route: ActivatedRoute,
    private orderToBasketService: OrderToBasketService
  ) {
    const routeParams = this.route.snapshot.params;
    const orderId = routeParams.orderId;
    orderService
      .getOrder(orderId)
      .toPromise()
      .then(_ => (this.showAddToBasketBtn = true));
  }
}
