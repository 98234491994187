import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}
/*
if (!environment.sales) {
  document.write(`<script type="text/javascript">
  (function(d, src, c) { var t=d.scripts[d.scripts.length - 1],s=d.createElement('script');
  s.id='la_x2s6df8d';s.async=true;s.src=src;s.onload=s.onreadystatechange=function(){var rs=this.readyState;
    if(rs&&(rs!='complete')&&(rs!='loaded')){return;}c(this);};t.parentElement.insertBefore(s,t.nextSibling);})(document,
  'https://hl-display.liveagent.se/scripts/track.js',
  function(e){ LiveAgent.createButton('ipw9ec2j', e); });
  </script>`);
} */

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
