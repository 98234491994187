import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OrderStripe, StripeService } from 'gung-standard';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HlDisplayStripeService extends StripeService {
  constructor(private httpClient: HttpClient) {
    super(httpClient);
  }

  public getOrderStripe(storageId: string): Observable<OrderStripe> {
    const urlString = `json/order-stripe/${storageId}`;
    return this.httpClient.get<OrderStripe>(urlString);
  }
}
