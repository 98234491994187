import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, UrlSegment } from '@angular/router';
import { first, mergeMap, filter, switchMap, Subject, takeUntil } from 'rxjs';
import { forkJoin, of } from 'rxjs';
import { AssortmentService, Assortment } from '../../services/assortment.service';
import { AuthService } from '../../services/auth/auth.service';

interface Breadcrumb {
  label: string;
  url: string;
  recursiveUrl?: string[];
}

@Component({
  selector: 'lib-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.css']
})
export class BreadcrumbComponent implements OnInit, OnDestroy {
  breadcrumbLinks: Breadcrumb[] = [];
  unsubscribe: Subject<void> = new Subject<void>();

  @Input()
  parentUrl = 'categories';

  @Input()
  recursiveUrl = false; // TODO: WIP

  constructor(
    protected route: ActivatedRoute,
    protected activatedRoute: ActivatedRoute,
    protected assortmentService: AssortmentService,
    protected authService: AuthService
  ) { }

  ngOnInit() {
    this.authService
      .getCurrentUser()
      .pipe(
        filter(user => !!user),
        first(),
        switchMap(user => this.assortmentService.getAssortmentRecursive(user.assortment).pipe(first()))
      )
      .subscribe(assortment => {
        this.route.url.pipe(takeUntil(this.unsubscribe)).subscribe(segments => {
          const currentAssortment = segments?.[segments?.length - 1]?.path;
          this.breadcrumbLinks = this.createBreadcrumbs(currentAssortment, assortment);
          if (this.recursiveUrl) {
            const recursiveUrl: string[] = [];
            for (const link of this.breadcrumbLinks) {
              recursiveUrl.push(link.url)
              link.recursiveUrl = ['/' + this.parentUrl, ...recursiveUrl];
            }
          }
        });
      });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  protected createBreadcrumbs(
    currentAssortment: string,
    assortment: Assortment,
    url: string = '',
    breadcrumbLinks: Breadcrumb[] = []
  ): Breadcrumb[] {
    if (!currentAssortment || !assortment) {
      return [];
    }

    if (currentAssortment === assortment.id) {
      breadcrumbLinks.push({
        label: assortment.name,
        url: url + assortment.id
      });
      return breadcrumbLinks;
    }

    for (const child of assortment.children) {
      const childBreadcrumbs = this.createBreadcrumbs(currentAssortment, child, url, breadcrumbLinks);
      // if (childBreadcrumbs.length > 0 && childBreadcrumbs[childBreadcrumbs.length-1].url === child.id) {
      if (childBreadcrumbs.map(b => b.url).includes(child.id)) {
        // breadcrumbLinks.push(...childBreadcrumbs);
        breadcrumbLinks.unshift({
          label: assortment.name,
          url: url + assortment.id
        });
        break;
      }
    }

    return breadcrumbLinks;
  }
}
