import { Component, OnInit } from '@angular/core';
import { AuthService } from 'gung-standard';
import { CustomerSalesPersonService, CustomerSalesPerson } from '../../services/customer-sales-person.service';
import { filter, first, forkJoin, map, switchMap, tap } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
import { of } from 'rxjs';
import { CustomerServiceSettingsGroup, HlDisplayPortalFeaturesService, LanguageSettingsGroup } from '../../services/hl-display-portal-features.service';

@Component({
  selector: 'app-contact-us-page',
  templateUrl: './contact-us-page.component.html',
  styleUrls: ['./contact-us-page.component.scss']
})
export class ContactUsPageComponent implements OnInit {
  public authenticated = false;
  public currentLang: string;

  public contactInformation: ContactInformation | undefined;

  public servicePortal: CustomerSalesPerson | undefined;

  public customerSalesPerson: CustomerSalesPerson | undefined;
  

  constructor(
    protected authService: AuthService,
    protected customerSalesPersonService: CustomerSalesPersonService,
    protected translate: TranslateService,
    protected HlDisplayPortalFeaturesService: HlDisplayPortalFeaturesService
  ) { }

  ngOnInit() {
    forkJoin([
      this.HlDisplayPortalFeaturesService.getPortalFeatureGroup('languageSettingsGroup').pipe(first(), map(languageSettings => languageSettings as LanguageSettingsGroup)),
      this.HlDisplayPortalFeaturesService.getPortalFeatureGroup('customerServiceSettingsGroup').pipe(first(), map(customerServiceSettings => customerServiceSettings as CustomerServiceSettingsGroup)),
      this.customerSalesPersonService.getCurrentCustomerSalesPerson().pipe(first())
    ]).pipe(first()).subscribe(([languageSettings, customerServiceSettings, customerSalesPerson]) => {
      if(customerSalesPerson){
        this.customerSalesPerson = customerSalesPerson;
      } 
      this.servicePortal = {
        name: customerServiceSettings.contactName || '',
        email: customerServiceSettings.email || '',
        phone: customerServiceSettings.phoneNumber || ''
      };
      this.contactInformation = {
        address: customerServiceSettings.address || '',
        address2: customerServiceSettings.address2 || '',
        zipCode: customerServiceSettings.zipCode || '',
        country: customerServiceSettings.country || '',
        city: customerServiceSettings.city || '',
        daysOpen: customerServiceSettings.daysOpen || '',
        hoursOpen: customerServiceSettings.hoursOpen || '',
        openingExtraInfo: customerServiceSettings.openingExtraInfo || ''
      }

    })
    
    this.currentLang = this.translate.currentLang || '';
  }
}

export interface ContactInformation {
  address: string;
  address2: string;
  city: string;
  zipCode: string;
  country: string;

  hoursOpen: string;
  daysOpen: string;
  openingExtraInfo: string;
}
