import { Inject, Injectable, Optional } from '@angular/core';
import { BackendInterceptor } from 'gung-standard';
import { environment } from '../../../environments/environment';
import { HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { OAuthService } from 'angular-oauth2-oidc';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class HlBackendInterceptorService extends BackendInterceptor {
  constructor(
    protected modalService: NgbModal,
    @Optional()
    protected oauthService: OAuthService,
    @Optional()
    @Inject('environment')
    protected environment: { [s: string]: any }
  ) {
    super(
      modalService,
      oauthService,
      environment
    );
  }

  getBaseUrl(): string {
    return environment.backendUri;
  }

  getBaseUrlSSO(): string {
    return this.getBaseUrl();
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (environment.oauth2SkipRedirectUrls.find(u => req.url.startsWith(u))) {
      return next.handle(req).pipe(
        catchError(err => {
          return throwError(err);
        })
      );
    } else {
      if (this.oauthService.getAccessToken()) {
        const authToken = this.oauthService.getAccessToken();

        // Clone the request and replace the original headers with
        // cloned headers, updated with the authorization.
        const authReq = req.clone({
          url: `${this.getBaseUrlSSO()}${req.url}`,
          headers: req.headers.set('Authorization', 'Bearer ' + authToken).set('X-Requested-With', 'XMLHttpRequest')
        });

        return next.handle(authReq).pipe(
          catchError(err => {
            return throwError(err);
          })
        );
      } else {
        const apiReq = req.clone({ url: `${this.getBaseUrl()}${req.url}`, withCredentials: true });
        return next.handle(apiReq).pipe(
          catchError(err => {
            return throwError(err);
          })
        );
      }
    }
  }
}
