import { environment as defaultEnv } from './environment.portal-alignment';

export const environment = {
  ...defaultEnv,
  backendUri: 'https://sales-se-api.hl-display.com/',
  production: true,
  oauth2Enabled: true,
  oauth2SkipRedirectUrls: ['https://login.microsoftonline.com/ae6e7baa-e1bf-4ef0-92a1-4eb28ec805c0/'],
  discountCustomerFinancialGroup: {
    ICA: 0.038
  },
  defaultLanguage: 'en'
};
