import { Injectable } from '@angular/core';
import { gungComparatorHelper } from 'gung-common';

export interface CartListColumns{
  [columnName: string]: {
    name: string;
    styles: { [x: string]: string };
    show: boolean
    showToUser: boolean
  };
}

@Injectable({
  providedIn: 'root'
})
export class CartListCheckoutConfigService {

  enableGlobalDeliveryDate = false;
  public userCanChangeDeliveryDates = true;
  deliveryDateField = 'Order.date';
  saveDateFormat = 'yyyy-MM-dd'

  public enableSalesEditPrice = true;
  changePriceField = 'OrderRows.Price';

  public enableSalesEditDiscount = true;
  changeDiscountField = 'OrderRows.Discount';

 

  public userCanAddDescription = true;
  rowDescriptionField = 'extraDescription';

  unitField = "Article.Unit";

  public enableCopyRow = true;

  forceSingleDimentionCart = false;

  enableAddRow = false;


  /* width only work full screen desktop and it is for singledimention cart */
  public cartListColumns:CartListColumns = {
    image:{
      name: '',
      styles:{
        width:"10%"
      },
      show: true,
      showToUser: true
    },
    name:{
      name: 'name',
      styles:{
        width:"30%"
      },
      show:true,
      showToUser: true
    },
    qty:{
      name: 'QUANTITY',
      styles:{
        width:"10%",
        padding:"0 0.5% 0 0.5%"
      },
      showToUser: true,
      show:true 
    },
    unit:{
      name: 'UNIT',
      styles:{
        width:"3%",
        padding:"0 0.5% 0 0.5%"
      },
      show:false,
      showToUser: false
    },
    unitPrice:{
      name: 'UNIT_PRICE',
      styles:{
        width:"10%",
        padding:"0 0.5% 0 0.5%"
      },
      show:true,
      showToUser: true
    },
    discount:{
      name: 'DISCOUNT',
      styles:{
        width:"7%",
        padding:"0 0.5% 0 0.5%"
      },
      show:true,
      showToUser: true
    },
    total:{
      name: 'TOTAL_PRICE',
      styles:{
        width:"10%",
        padding:"0 0.5% 0 0.5%"
      },
      show:true,
      showToUser: true
    },
    deliveryDate:{
      name: 'DeliveryDate',
      styles:{
        width:"10%",
        padding:"0 0.5% 0 0.5%"
      },
      show:true,
      showToUser: true
    },
    actions:{
      name: '',
      styles:{
        width:"5%",
        padding:"0 0.5% 0 0.5%"
      },
      show:true,
      showToUser: true
    }
  }

  sortOptions: {name: string, value: any}[]; // Set this variable enable sort icon in cart table header;

  sortByAvailabilites = (a, b) => {
    const targetStockId = a?.cartRow?.targetStockId || b?.cartRow?.targetStockId;
    const aAvailabilities = a?.product?.extra?.availabilities;
    const bAvailabilities = b?.product?.extra?.availabilities;
    const aAvailability = aAvailabilities?.[targetStockId];
    const bAvailability = bAvailabilities?.[targetStockId];
    const aCurrentAvailability = aAvailability?.currentAvailability;
    const bCurrentAvailability = bAvailability?.currentAvailability;
    if (aCurrentAvailability || bCurrentAvailability) {
      // Have Stock
      return gungComparatorHelper(aCurrentAvailability, bCurrentAvailability, 1);
    }
    const aFutureAvailability = aAvailability?.extra?.availabilities;
    const bFutureAvailability = bAvailability?.extra?.availabilities;
    if (aFutureAvailability && bFutureAvailability) {
      // Have Future Stock
      const aFirstDate = Object.keys(aFutureAvailability).filter(key => {
        return aFutureAvailability[key] > 0;
      })[0];
      const bFirstDate = Object.keys(bFutureAvailability).filter(key => {
        return aFutureAvailability[key] > 0;
      })[0];
      // if (aFirstDate || bFirstDate) {
      //   return gungComparatorHelper(aFirstDate, bFirstDate, -1);
      // }
      if (!aFirstDate || !bFirstDate) {
        if (!aFirstDate) {
          return -1;
        }
        if (!bFirstDate) {
          return 1;
        }
        return 0
      }
      return aFirstDate < bFirstDate ? -1 : 1;
    }
    return 0;
  }

  sortByProductId = (a, b) => gungComparatorHelper(a.cartRow.productId, b.cartRow.productId, -1)

  sortByDefault = (a, b) => gungComparatorHelper(a.cartRow.timeAdded, b.cartRow.timeAdded, 1)

  transformPriceToERP(price: any): number | string {
    return parseFloat(price);
  }

  transformDiscountToERP(discount: any): number | string {
    return parseFloat(discount);
  }
}
