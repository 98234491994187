import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { CartTotalsService, CheckoutObject, Customer, MetadataService, TotalObject } from 'gung-standard';
import { Observable, Subject, first, takeUntil } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HlDisplayAdditionalCostsService {
  cartTotal: TotalObject;
  private unsubscribe: Subject<void> = new Subject();

  constructor(
    private metadataservice: MetadataService,
    private cartTotalsService: CartTotalsService,
    private http: HttpClient,
    @Optional()
    @Inject('environment')
    protected environment: { [s: string]: any }
  ) {
    this.cartTotalsService
      .getTotals()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(totalsObject => {
        this.cartTotal = totalsObject;
      });
  }

  getAdditionalCostTerms(customer: Customer): Observable<HlDisplayAdditionalCostTerms> {
    return this.http.get<HlDisplayAdditionalCostTerms>(`json/hl-display/get-trade-policy/${customer.id}`).pipe(first());
    /*const qHlTpSalesmarket = this.metadataservice.getMetadataTableList('q_hl_tp_salesmarket');
    if (qHlTpSalesmarket) {
      const salesmarket =
        qHlTpSalesmarket.find(
          s =>
            s.q_salesmarket_code === (customer.extra.kus.q_salesmarket_code || country) &&
            s.q_supergrpcode === (customer.extra.kus.q_supergrpcode || null)
        ) ||
        qHlTpSalesmarket.find(
          s =>
            s.q_salesmarket_code === (customer.extra.kus.q_salesmarket_code || country) &&
            s.q_financialgrpcode === (customer.extra.kus.q_financialgrpcode || null)
        );
      retValue.freight = Number(salesmarket?.q_hl_fc_amount || 0);
      retValue.freightMin = Number(salesmarket?.q_hl_fc_threshold_amount || 0);
      retValue.adminCharge = Number(salesmarket?.q_hl_sof_charged_amount || 0);
      retValue.adminChargeMin = Number(salesmarket?.q_hl_sof_threshold_amount || 0);
      retValue.minOrderQty = Number(salesmarket?.q_hl_minordvalue || 0);
      retValue.franco = Number(salesmarket?.q_hl_fc_threshold_amount || 0);
    }
    return retValue;*/
  }

  getFreightCharge(
    checkoutTotals: number,
    additionalCostTermsObject: HlDisplayAdditionalCostTerms,
  ) {
    let freightCostValue = null;

    if (!additionalCostTermsObject) {
      return freightCostValue
    }

    // Customer Portal Pharmacie: Freight charge (€25) should be placed on article: 900000 when the order is under €300
    freightCostValue = {
      productId: '900000',
      value: additionalCostTermsObject.freight || 25,
      quantity: 1,
      freightMin: additionalCostTermsObject.freightMin,
    };
    return freightCostValue;
  }

  getAdminFee(
    checkoutTotals: number,
    additionalCostTermsObject: HlDisplayAdditionalCostTerms,
  ) {
    let adminCostValue = null;

    if (!additionalCostTermsObject) {
      return adminCostValue
    }


    let adminChargeToUse = additionalCostTermsObject.adminCharge;

    // Usage of == vs === is important here. We need to check for both null and undefined which == handles.
    if (adminChargeToUse == null) {
      // Before this was the default, but we needed to be able to put 0 (without it defaulting to 17).
      // This was the solution to this.

      // Customer Portal Pharmacie: Admin (€17) should be placed on article: 527859 when the order is under €300
      adminChargeToUse = 17;
    }

    adminCostValue = {
      productId: '527859',
      value: adminChargeToUse,
      quantity: 1,
      adminChargeMin: additionalCostTermsObject.adminChargeMin,
    };

    return adminCostValue;
  }

  getMinOrder(
    additionalCostTermsObject: HlDisplayAdditionalCostTerms,
  ) {
    if (additionalCostTermsObject.minOrderQty <= 0) {
      return null;
    }
    const minOrder = {
      value: additionalCostTermsObject.minOrderQty,
    };

    return minOrder;
  }

  getCurrencySymbol(currencyCode: string) {
    return (0).toLocaleString(undefined, {
      style: 'currency',
      currency: currencyCode,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).replace(/\d/g, '').trim();
  }

  addFreightChargeToCheckout(
    checkoutObject: CheckoutObject,
    additionalCostTermsObject: HlDisplayAdditionalCostTerms,
    currencyCode: string
  ) {
    let freightCostValue = null;

    if (this.cartTotal.totalValue < additionalCostTermsObject.freightMin) {
      // Customer Portal Pharmacie: Freight charge (€25) should be placed on article: 900000 when the order is under €300
      freightCostValue = {
        productId: '900000',
        value: additionalCostTermsObject.freight || 25,
        currencyCode: currencyCode,
        quantity: 1,
        freightMin: additionalCostTermsObject.freightMin,
        currencySymbol: this.getCurrencySymbol(currencyCode)
      };

      checkoutObject.extra.additionalCosts = {
        ...checkoutObject.extra.additionalCosts,
        freight: freightCostValue
      };
    }
  }

  addAdminFeeToCheckout(
    checkoutObject: CheckoutObject,
    additionalCostTermsObject: HlDisplayAdditionalCostTerms,
    currencyCode: string
  ) {
    let adminCostValue = null;

    if (this.cartTotal.totalValue < additionalCostTermsObject.adminChargeMin) {
      let adminChargeToUse = additionalCostTermsObject.adminCharge;

      // Usage of == vs === is important here. We need to check for both null and undefined which == handles.
      if (adminChargeToUse == null) {
        // Before this was the default, but we needed to be able to put 0 (without it defaulting to 17).
        // This was the solution to this.

        // Customer Portal Pharmacie: Admin (€17) should be placed on article: 527859 when the order is under €300
        adminChargeToUse = 17;
      }

      adminCostValue = {
        productId: '527859',
        value: adminChargeToUse,
        currencyCode: currencyCode,
        quantity: 1,
        adminChargeMin: additionalCostTermsObject.adminChargeMin,
        currencySymbol: this.getCurrencySymbol(currencyCode)
      };

      checkoutObject.extra.additionalCosts = {
        ...checkoutObject.extra.additionalCosts,
        adminFee: adminCostValue
      };
    }
  }

  addMinOrderToCheckout(
    checkoutObject: CheckoutObject,
    additionalCostTermsObject: HlDisplayAdditionalCostTerms,
    currencyCode: string
  ): void {
    if (additionalCostTermsObject.minOrderQty > 0) {
      const minOrder = {
        value: additionalCostTermsObject.minOrderQty,
        currencyCode: currencyCode,
        currencySymbol: this.getCurrencySymbol(currencyCode)
      };

      checkoutObject.extra.additionalCosts = {
        ...checkoutObject.extra.additionalCosts,
        minOrder: minOrder
      };
    }
  }
}

export class HlDisplayAdditionalCostTerms {
  freight: number;
  minOrderQty: number;
  adminCharge: number;
  franco: number;
  freightMin: number;
  adminChargeMin: number;
}




