import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HlDisplayPortalFeaturesService, PriceSettingsGroup } from './hl-display-portal-features.service';
import { AuthService } from 'gung-standard';
import { first } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HlDisplayHelperService {
  requestPricelistAssortmentWithWebCondition: boolean;
  isSales = false;
  isManager = false;

  constructor(
    private hlDisplayPortalFeatureService: HlDisplayPortalFeaturesService,
    private authService: AuthService
  ) {
    this.hlDisplayPortalFeatureService.getPortalFeatureGroup('priceSettingsGroup').subscribe(portalFeatureGroup => {
      let priceSettingsGroup = portalFeatureGroup as PriceSettingsGroup;
      this.requestPricelistAssortmentWithWebCondition = priceSettingsGroup.requestPricelistAssortmentWithWebCondition;
    });

    this.authService
      .getRoles()
      .pipe(first())
      .subscribe(roles => {
        roles.indexOf('SALES') > -1 ? (this.isSales = true) : (this.isSales = false);
        roles.indexOf('MANAGER') > -1 ? (this.isManager = true) : (this.isManager = false);
      });
  }

  public checkRequestPricelistAssortmentWithWebCondition(prislista: string): string {
    let prislistaWithCondition = prislista;
    if (this.requestPricelistAssortmentWithWebCondition) {
      prislistaWithCondition += '_WEB';
    } else if (!this.isSales && !this.isManager) {
      prislistaWithCondition += '_CUSTOMER';
    }
    return prislistaWithCondition;
  }
}
