import { Component, OnInit, forwardRef, Output, EventEmitter, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DiscountCodesV2Service, DiscountCodeV2 } from '../../services/discount-codes-v2/discount-codes-v2.service';
import { DiscountCodesService } from '../../services/discount-codes.service';

@Component({
  selector: 'lib-discount-input',
  templateUrl: './discount-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DiscountInputComponent),
      multi: true
    }
  ]
})
export class DiscountInputComponent implements ControlValueAccessor, OnInit {
  @Input()
  public label = 'CAMPAIGN_CODE';
  protected _value: string;
  public disabled = false;
  public extraClasses = '';

  @Output()
  public discountDescription = new EventEmitter<string>();

  @Output()
  public discountCode = new EventEmitter<DiscountCodeV2>();

  public onChange: (val: string) => void = _ => { };

  constructor(protected discountCodesService: DiscountCodesV2Service) { }

  ngOnInit() { }

  public validateInput(value: string) {
    this.disabled = true;
    if (value === '') {
      this.disabled = false;
      this.value = '';
      this.discountDescription.emit('');
      this.discountCode.emit(null);
      this.extraClasses = '';
      return;
    }
    this.discountCodesService.getIsDiscountCodeValid(value).subscribe(valid => {
      if (!valid) {
        this.disabled = false;
        this.discountDescription.emit('');
        this.discountCode.emit(null);
        this.value = '';
        this.extraClasses = 'is-invalid';
        return;
      }
      this.handleValidDiscountCode(value);
    });
  }

  protected handleValidDiscountCode(discountCode: string): void {
    this.discountCodesService.getDiscountCode(discountCode).subscribe(code => {
      this.discountDescription.emit(code.description);
      this.discountCode.emit(code);
      this.extraClasses = 'is-valid';
      this.value = discountCode;
      this.disabled = false;
    });
  }

  get value() {
    return this._value;
  }

  set value(value: string) {
    this._value = value;
    this.onChange(value);
  }

  writeValue(obj: string): void {
    if (obj !== undefined && obj !== null) {
      this.value = obj;
    }
  }

  registerOnChange(fn: (val: string) => void): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void { }
  setDisabledState?(isDisabled: boolean): void { }
}
