<span *ngIf="loaded">
  <div *ngIf="categoryFilter">
    <p class="filter-header">{{ translateService.instant(categoryFilter.name) }}</p>
    <ul class="category-list">
      <li (click)="categoryClicked(option.valueId)" [ngClass]="{ selected: option.selected, 'd-none': !option.valueId }"
        *ngFor="let option of categoryFilter.valueList">
        {{ translateService.instant(option.valueName) }}
      </li>
    </ul>
  </div>
  <div *ngIf="categoryFilter" class="divider"></div>
  <div *ngFor="let specialSelectionFilter of specialSelectionFilters" class="form-check special-filter checkbox-selection"
    (click)="specialSelectionClicked(specialSelectionFilter)">
    <span class="form-check-label" for="{{ specialSelectionFilter.name }}" translate>
      {{ translateService.instant(specialSelectionFilter.name) }}
    </span>
    <span class="checkbox">
      <i *ngIf="specialSelectionFilter.valueList[0].selected" class="fas fa-check-square"></i>
      <i *ngIf="!specialSelectionFilter.valueList[0].selected" class="fas fa-square"></i>
    </span>
  </div>
  <div *ngIf="specialSelectionFilter" class="divider"></div>
  <p class="filter-header" translate>FILTERS</p>
  <lib-filter-list-side-filter [ngClass]="{ 'd-none': i >= showMoreLimit && !showMore }" [renderFilter]="renderFilter"
    *ngFor="let renderFilter of regularFilters; let i = index"
    (selectValue)="setSelection(renderFilter.name, $event, true)"
    (deselectValue)="setSelection(renderFilter.name, $event, false)"
    (selectValueRange)="setSelection(renderFilter.name, null, true, $event)"></lib-filter-list-side-filter>
  <div class="filter-expand" *ngIf="renderFilters.length > showMoreLimit">
    <button type="button" class="button btn btn-link" (click)="showMore = !showMore">
      {{ showMore ? translateService.instant('HIDE') : translateService.instant('SEE_MORE') }}
    </button>
  </div>
</span>
