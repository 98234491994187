<div *ngIf="loaded; else loading" class="contianer">
    <div class="row">
        <div class="col-2">
            <lib-gung-checkbox-input-horizontal gungTranslate="ENABLE" [(ngModel)]="myCompanyFooter.enabled">
            </lib-gung-checkbox-input-horizontal>
        </div>
    </div>
    <div class="row" *ngIf="myCompanyFooter.enabled">
        <div class="col-12 mb-2 d-flex align-items-end justify-content-between">
            <div class="d-flex align-items-end">
                <lib-gung-text-input gungTranslate="BACKGROUND" [(ngModel)]="myCompanyFooter.backgroundColor"></lib-gung-text-input>
                <i
                  class="fas fa-square fa-3x ml-2"
                  (click)="colorPicker1.click()"
                  [ngStyle]="{ color: myCompanyFooter.backgroundColor }"
                ></i>
                <input #colorPicker1 type="color" [(ngModel)]="myCompanyFooter.backgroundColor" style="height: 0px; width: 0px; visibility: hidden;" class="mt-5">
            </div>
            <div>
                <button type="button" class="btn btn-primary" (click)="addColumn()" translate>ADD_COLUMN</button>
            </div>
        </div>
        <div class="col-12">
            <lib-footer [myCompanyFooter]="myCompanyFooter" edit="true"></lib-footer>
        </div>
    </div>
</div>
<ng-template #loading>
    <div class="text-center w-100">
        <i class="fa fa-spinner fa-spin fa-3x"></i>
        <br>LOADING...
    </div>
</ng-template>