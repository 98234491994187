import { Inject, Injectable, Optional, inject } from '@angular/core';
import { GungLanguageConfigService, Language } from 'gung-common';
import {
  HlDisplayPortalFeaturesService,
  IFeatureGroup,
  LanguageSettingsGroup
} from './hl-display-portal-features.service';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subject, first, of, switchMap, tap } from 'rxjs';
import { init } from '@sentry/browser';

@Injectable({
  providedIn: 'root'
})
export class HlDisplayGungLanguageConfigService extends GungLanguageConfigService {
  private languageListSubject: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  public languageListObservable = this.languageListSubject.asObservable();

  private languageDefaultSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public defaultLanguageObservable = this.languageDefaultSubject.asObservable();

  constructor(
    protected translateService: TranslateService,
    protected hlDisplayPortalFeaturesService: HlDisplayPortalFeaturesService,
    @Optional()
    @Inject('environment')
    protected environment: { [s: string]: any }
  ) {
    super(translateService);

    this.initLanguageConfig();
  }

  initLanguageConfig() {
    return this.hlDisplayPortalFeaturesService.getPublicPortalFeatureGroup('languageSettingsGroup').pipe(
      tap((languageGroup: IFeatureGroup) => {
        let langGroup = languageGroup as LanguageSettingsGroup;
        this.languageListSubject.next(langGroup.portalLanguages);
        this.portalLanguages = langGroup.portalLanguages;
        this.languageDefaultSubject.next(langGroup.defaultLanguage);
        this.defaultLanguage = langGroup.defaultLanguage;

        this.selectDefaultLanguageIfFirstTime(this.defaultLanguage);
      })
    );
  }

  portalLanguages: string[];

  private selectDefaultLanguageIfFirstTime(lang: string) {
    const firstVisitMarker = !localStorage.getItem('hasBeenHereBefore');
    if (firstVisitMarker) {
      localStorage.setItem('selectedLanguage', lang);
      localStorage.setItem('hasBeenHereBefore', 'true');
      this.translateService.use(lang);
    }
  }

  selectLanguage(forceRun?: boolean) {
    if (!forceRun) {
      return;
    }
    if (this.portalLanguages === undefined) {
      this.initLanguageConfig()
        .pipe(first())
        .subscribe(() => {
          const langFromOldVisit = localStorage.getItem('selectedLanguage');
          const enabledLangs = this.getLanguages().filter(lang => lang.enabled);
          if (!enabledLangs.some(lang => lang.short === langFromOldVisit) && !!enabledLangs.length) {
            const defaultLanguage = this.getDefaultLanguage();
            if (enabledLangs.some(lang => lang.short === defaultLanguage)) {
              this.translateService.use(defaultLanguage);
              localStorage.setItem('selectedLanguage', defaultLanguage);
            } else {
              this.translateService.use(enabledLangs[0].short);
              localStorage.setItem('selectedLanguage', enabledLangs[0].short);
            }
          } else {
            this.translateService.use(langFromOldVisit);
          }
        });
    }
  }

  public getLanguages(): Language[] {
    if (this.portalLanguages === undefined) {
      console.log('Portal languages not set');
      return [];
    }

    return [
      {
        language: 'finnish',
        short: 'fi',
        image: 'finland-flag-round-icon-256.png',
        enabled: this.portalLanguages.indexOf('fi') > -1
      },
      {
        language: 'french',
        short: 'fr',
        image: 'france-flag-round-icon-256.png',
        enabled: this.portalLanguages.indexOf('fr') > -1
      },
      {
        language: 'dutch',
        short: 'nl',
        image: 'germany-flag-round-icon-256.png',
        enabled: this.portalLanguages.indexOf('nl') > -1
      },
      {
        language: 'german',
        short: 'de',
        image: 'germany-flag-round-icon-256.png',
        enabled: this.portalLanguages.indexOf('de') > -1
      },
      {
        language: 'norwegian',
        short: 'no',
        image: 'norway-flag-round-icon-256.png',
        enabled: this.portalLanguages.indexOf('no') > -1
      },
      {
        language: 'portuguese',
        short: 'pt',
        image: 'portugal-flag-round-icon-256.png',
        enabled: this.portalLanguages.indexOf('pt') > -1
      },
      {
        language: 'swedish',
        short: 'se',
        image: 'sweden-flag-round-icon-256.png',
        enabled: this.portalLanguages.indexOf('se') > -1
      },
      {
        language: 'english',
        short: 'en',
        image: 'united-states-of-america-flag-round-icon-256.png',
        enabled: this.portalLanguages.indexOf('en') > -1
      },
      {
        language: 'danish',
        short: 'dk',
        image: 'denmark-flag-round-icon-256.png',
        enabled: this.portalLanguages.indexOf('dk') > -1
      }
    ];
  }

  // https://support.gung.se/a/tickets/271027
  public getDefaultLanguage(): string {
    if (this.languageDefaultSubject.value === '') {
      console.log("default language not set, using environment's default language");
      return this.environment.defaultLanguage;
    } else {
      console.log('default language set through subject', this.languageDefaultSubject.value);
      return this.languageDefaultSubject.value;
    }
  }
}
