import { ChangeDetectorRef, Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { ScannedActionsSubject } from '@ngrx/store';
import { OAuthService } from 'angular-oauth2-oidc';
import { AuthService, BaseViewConfigService, FashionLoginComponent, LoginCheckService, LoginConfigService, NavbarConfigService } from 'gung-standard';
import { HlDisplayPortalFeaturesService, LanguageSettingsGroup, LoginSettingsGroup } from '../../services/hl-display-portal-features.service';
import { first, forkJoin, map } from 'rxjs';

@Component({
  selector: 'app-hl-fashion-login',
  templateUrl: './hl-fashion-login.component.html',
  styleUrl: './hl-fashion-login.component.css'
})
export class HlFashionLoginComponent extends FashionLoginComponent implements OnInit {
  oauthLoginEnabled = false;
  showLanguageSelectionOnLogin = false;

  constructor(
    protected authService: AuthService,
    protected formBuilder: FormBuilder,
    protected route: ActivatedRoute,
    protected router: Router,
    protected actions$: ScannedActionsSubject,
    protected location: Location,
    protected loginCheckService: LoginCheckService,
    protected loginConfigService: LoginConfigService,
    protected navbarConfigService: NavbarConfigService,
    protected baseViewConfigService: BaseViewConfigService,
    @Optional()
    protected oauth2Service: OAuthService,
    @Optional()
    @Inject('environment')
    protected environment: { [s: string]: any },
    protected hlDisplayPortalFeaturesService: HlDisplayPortalFeaturesService
  ) {
    super(authService, formBuilder, route, router, actions$, location, loginCheckService, loginConfigService, navbarConfigService, baseViewConfigService, oauth2Service, environment);
  }

  ngOnInit() {
    super.ngOnInit();
    forkJoin([
      this.hlDisplayPortalFeaturesService.getPublicPortalFeatureGroup('loginSettingsGroup')
      .pipe(
        first(), 
        map(loginSettings => loginSettings as LoginSettingsGroup)
      ),
      this.hlDisplayPortalFeaturesService.getPublicPortalFeatureGroup('languageSettingsGroup')
      .pipe(
        first(), 
        map(languageSettings => languageSettings as LanguageSettingsGroup)
      ) 
    ]).subscribe(([loginSettings, languageSettings]) => {
      this.oauthLoginEnabled = loginSettings.oauthLoginEnabled || this.oauthLoginEnabled;
      this.showLanguageSelectionOnLogin = languageSettings.showLanguageSelectionOnLogin || this.showLanguageSelectionOnLogin;
    })

  }

  loginOauth() {
    if(!this.oauthLoginEnabled || !this.environment?.oauth2Enabled) {
      return;
    }
    // Initializes the login towards the oauth2 authentication provider.
    this.oauth2Service.initCodeFlow();
  }
}
