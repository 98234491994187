import { Component, ComponentFactoryResolver, ChangeDetectorRef } from '@angular/core';
import { ListLayoutSingleComponent } from 'gung-list';
import { environment } from '../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-top-product-list-layout',
  templateUrl: './top-product-list-layout.component.html',
  styleUrls: ['./top-product-list-layout.component.scss']
})
export class TopProductListLayoutComponent<T> extends ListLayoutSingleComponent<T> {

  constructor(
    componentFactoryResolver: ComponentFactoryResolver,
    changeDetectorRef: ChangeDetectorRef,
    private translateService: TranslateService
  ) {
    super(componentFactoryResolver, changeDetectorRef);
  }

  getTitle(): string {
    return this.translateService.instant('TOP_PURCHASED_TITLE');
  }
}
