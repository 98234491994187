import { Injectable } from '@angular/core';
import { HlDisplayProductListConfigService } from './hl-display-product-list-config.service';
import {
  ProductService,
  OrderService,
  SelectedCustomerService,
  Product,
  AuthService,
  ProductSortService,
  PriceService,
  AssortmentService,
  GungFlowService,
  CurrentAssortmentService,
  CloudPimFiltersService
} from 'gung-standard';
import { ProductCategoryFilterService } from './filters/product-category-filter.service';
import { ProductMerchandiseTypeFilterService } from './filters/product-merchandise-type-filter.service';
import { ProductLengthFilterService } from './filters/product-length-filter.service';
import { ProductDepthFilterService } from './filters/product-depth-filter.service';
import { ProductGondolaBrandFilterService } from './filters/product-gondola-brand-filter.service';
import { ProductEtiquetteFilterService } from './filters/product-etiquette-filter.service';
import { ActivatedRoute } from '@angular/router';
import { first, map, mergeMap, filter, debounce, switchMap } from 'rxjs';
import { of, Observable, timer } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ProductCapacityFilterService } from './filters/product-capacity-filter.service';
import { ProductColorFilterService } from './filters/product-color-filter.service';
import { ProductShelfManufacturerFilterService } from './filters/product-shelf-manufacturer-filter.service';
import { ListLayout } from 'gung-list';
import { HlDisplayProductCardListComponent } from '../components/hl-display-product-card-list/hl-display-product-card-list.component';
import { TopProductListLayoutComponent } from '../components/top-product-list-layout/top-product-list-layout.component';
import { ProductItemGroupFilterService } from 'gung-standard-jeeves';
import { ProductMousquetairesFilterService } from './filters/product-mousquetaires-filter.service';
import { MetadataService } from 'gung-standard';
import { TranslateService } from '@ngx-translate/core';
import { ProductAssortmentTypeFilterService } from './filters/product-assortment-type-filter.service';
import { HlDisplayHelperService } from './hl-display-helper.service';
import { ProductExternalCategoryFilterService } from './filters/product-external-category-filter.service';
import { ProductExternalSubCategoryFilterService } from './filters/product-external-sub-category-filter.service';
import { ProductStoreCategoryFilterService } from './filters/product-store-category-filter.service';
import { ProductMainFunctionFilterService } from './filters/product-main-function-filter.service';
import { HlProductService } from './hl-product.service';
import { HlDisplayOrderService } from './hl-display-order.service';
import { ProductProductShelfTypeFilterService } from './filters/product-product-shelf-type-filter.service';
import { ProductWidthFilterService } from './filters/product-width-filter.service';
import { HlDisplayPortalFeaturesService } from './hl-display-portal-features.service';

@Injectable({
  providedIn: 'root'
})
export class HlProductListConfigByTopProductsService extends HlDisplayProductListConfigService {
  constructor(
    public productService: HlProductService,
    protected productCategoryFilter: ProductCategoryFilterService,
    protected productMerchandiseTypeFilter: ProductMerchandiseTypeFilterService,
    protected productLengthFilter: ProductLengthFilterService,
    protected productDepthFilter: ProductDepthFilterService,
    protected productGondolaBrandFilter: ProductGondolaBrandFilterService,
    protected productEtiquetteFilter: ProductEtiquetteFilterService,
    protected productCapacityFilter: ProductCapacityFilterService,
    protected productColorFilter: ProductColorFilterService,
    protected productItemGroupFilter: ProductItemGroupFilterService,
    protected productShelfManufacturerFilter: ProductShelfManufacturerFilterService,
    productMousquetairesFilter: ProductMousquetairesFilterService,
    protected productAssortmentTypeFilter: ProductAssortmentTypeFilterService,
    protected selectedCustomerService: SelectedCustomerService,
    private httpClient: HttpClient,
    metadataService: MetadataService,
    protected authService: AuthService,
    translateService: TranslateService,
    productSortService: ProductSortService,
    protected priceService: PriceService,
    assortmentService: AssortmentService,
    protected gungFlowService: GungFlowService,
    helperService: HlDisplayHelperService,
    protected productCategoryFilterService: ProductCategoryFilterService,
    protected productExternalCategoryFilterService: ProductExternalCategoryFilterService,
    protected productExternalSubCategoryFilterService: ProductExternalSubCategoryFilterService,
    protected productMainFunctionFilterService: ProductMainFunctionFilterService,
    protected productStoreCategoryFilterService: ProductStoreCategoryFilterService,
    protected hlDisplayOrderService: HlDisplayOrderService,
    protected productProductShelfTypeFilterService: ProductProductShelfTypeFilterService,
    protected productWidthFilterService: ProductWidthFilterService,
    protected cloudPimFiltersService: CloudPimFiltersService,
    protected currentAssortmentService: CurrentAssortmentService,
    protected hlDisplayPortalFeaturesService: HlDisplayPortalFeaturesService
  ) {
    super(
      productService,
      productCategoryFilter,
      productMerchandiseTypeFilter,
      productLengthFilter,
      productDepthFilter,
      productGondolaBrandFilter,
      productEtiquetteFilter,
      productCapacityFilter,
      productColorFilter,
      productShelfManufacturerFilter,
      productItemGroupFilter,
      productMousquetairesFilter,
      productAssortmentTypeFilter,
      metadataService,
      authService,
      selectedCustomerService,
      translateService,
      productSortService,
      priceService,
      assortmentService,
      gungFlowService,
      helperService,
      productCategoryFilterService,
      productExternalCategoryFilterService,
      productExternalSubCategoryFilterService,
      productMainFunctionFilterService,
      productStoreCategoryFilterService,
      hlDisplayOrderService,
      productProductShelfTypeFilterService,
      productWidthFilterService,
      cloudPimFiltersService,
      currentAssortmentService,
      hlDisplayPortalFeaturesService
    );
  }

  getItems(): Observable<Product[]> {
    return this.selectedCustomerService.getSelectedCustomer().pipe(
      first(),
      map(selectedCustomer => selectedCustomer.id),
      switchMap(customerId => {
        return this.httpClient.get('json/hl-display/top-products/' + customerId);
      }),
      switchMap(topProductList => {
        const productIds = (topProductList as any[]).map(x => {
          return x.artnr;
        });
        return this.productService.getProductsByIds(productIds);
      })
    );
  }

  getFilters() {
    return [];
  }

  getSearchGroupCss() {
    return 'd-none';
  }

  getLayouts(): ListLayout<Product>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => HlDisplayProductCardListComponent,
        getListLayoutComponent: () => TopProductListLayoutComponent,
        getName: () => 'Product grid'
      }
    ];
  }
}
