import { Injectable, Type } from '@angular/core';
import { S3Item } from '../s3-upload/s3-upload.service';

// DOCS
// https://docs.aws.amazon.com/solutions/latest/serverless-image-handler/use-supported-thumbor-filters.html
// https://thumbor.readthedocs.io/en/latest/rotate.html

export interface GungImageOption {
  type?: GungImageType; // use default values
  format?: 'webp' | string; // image format
  size?: string; // Square XXXxYYY
  etag?: string; // #s3ETag# from image
  orientation?: 'portrait' | 'landscape'; // will replace the size with sizePortrait | sizeLandscape;
  sizePortrait?: string; // XXXxYYY
  sizeLandscape?: string; // XXXxYYY
  fill?: string; // color
  background_color?: string; // color
  quality?: string; // number 0-100
  autojpg?: any;
  blur?: string; // number 0-150
  convolution?: any;
  crop?: string; // 10x10:100x100
  equalize?: any;
  grayscale?: any;
  no_upscale?: any;
  proportion?: string; // number 0.0-1.0
  rgb?: string; // -100-100,-100-100,-100-100
  rotate?: string; // number 0-359
  sharpen?: string; // 0.0-10.0, 0.0-2.0, true/false
  stretch?: any;
  strip_exif?: any;
  strip_icc?: any;
  upscale?: any;
  watermark?: any; // bucket,key,x,y,alpha[,w_ratio[,h_ratio]]
}

export enum GungImageType {
  list = 'list',
  thumbnail = 'thumbnail',
  detail = 'detail',
  high = 'high',
  background = 'background'
}

export const GungImageBaseUrl = 'https://cdn3.gung.io';
export const GungNoImageUrl = GungImageBaseUrl+'/images/no-image.jpg';

export const GungDownloadBaseUrl = 'https://cdn2.gung.io'; // CDN2 gets the image without processing any of the filters or scaling
export function GungDownloadUrl(file: string | S3Item, mustEncode?: 'encodeURI' | 'encodeURIComponent'): string {
  const encode = (s: string) => {
    if (mustEncode) {
      switch (mustEncode) {
        case 'encodeURI':
          return encodeURI(s);
        case 'encodeURIComponent':
          return encodeURIComponent(s);
        default:
          return s;
      }
    }
    return s;
  }

  let url: string = GungDownloadBaseUrl;
  if (typeof file === 'string') {
    if (!file.startsWith('/')) {
      url += '/';
    }
    return url + encode(file);
  }
  return `${url}/${encode(file.s3Uri)}?etag=${file.s3ETag}`;
}

@Injectable({
  providedIn: 'root'
})
export class GungImageUrlService {

  baseUrl: string = GungImageBaseUrl;

  defaultFormat = 'webp';

  defaultListThumbnailOptions: GungImageOption = {
    format: this.defaultFormat,
    size: '800x800',
    sizePortrait: '800x1064',
    fill:'white',
    background_color:'white'
  }

  defaultDetailsOptions: GungImageOption = {
    format: this.defaultFormat,
    size: '1200x1200',
    sizePortrait: '1200x1596',
    fill:'white',
    background_color:'white'
  }

  defaultHighDefinitionOptions: GungImageOption = {
    format: this.defaultFormat,
    size: '2500x2500',
    sizePortrait: '2500x3325',
    quality: '95',
    fill:'white',
    background_color:'white'
  }

  defaultBackgroundOptions: GungImageOption = {
    format: this.defaultFormat,
    size: '2560x1440',
    fill:'white',
    background_color:'white'
  }

  constructor() { }

  getUrl(image: string, options: GungImageOption = {}): string {
    let url: string = '' + this.baseUrl;
    // Object.keys(options).forEach(key => options[key] === undefined && delete options[key]); // Clear all undefined
    let sizeOption: string  = options.size ? 'size' : undefined; // use custom size

    // Get default options
    switch (options.type) {
      case GungImageType.list:
      case GungImageType.thumbnail:
        options = {
          ...this.defaultListThumbnailOptions,
          ...options
        }
        break;
      case GungImageType.high:
        options = {
          ...this.defaultHighDefinitionOptions,
          ...options
        }
        break;
      case GungImageType.background:
        options = {
          ...this.defaultBackgroundOptions,
          ...options
        }
        break;
      case GungImageType.detail:
        options = {
          ...this.defaultDetailsOptions,
          ...options
        }
        break;
    }
    delete options.type;

    // Get different size by orientation if not custom size
    if (!sizeOption) {
      switch (options.orientation) {
        case 'landscape':
          if (options['sizeLandscape']) {
            sizeOption = 'sizeLandscape';
            delete options.size;
            delete options.sizePortrait;
          }
          break;
        case 'portrait':
          if (options['sizePortrait']) {
            sizeOption = 'sizePortrait';
            delete options.size;
            delete options.sizeLandscape;
          }
          break;
        default:
          sizeOption = 'size';
          delete options.sizePortrait;
          delete options.sizeLandscape;
      }
    } else {
      delete options.sizePortrait;
      delete options.sizeLandscape;
    }
    delete options.orientation;

    // Use always the same order in filter options for cache
    options = Object.keys(options).sort().reduce(
      (obj, key) => { 
        obj[key] = options[key]; 
        return obj;
      }, 
      {}
    );

    // Generate the image URL
    // crop
    if (options.crop) {
      url += `/${options.crop}`;
      delete options.crop;
    }

    // size
    if (options[sizeOption]) {
      url += `/fit-in/${options[sizeOption]}`;
      delete options[sizeOption];
    }

    // Add filter options
    if (Object.keys(options).length > 0) {
      url += '/filters';

      for (const [key, value] of Object.entries(options)) {
        if (key === 'etag' && !value) {
          delete options[key];
          continue;
        }
        url += `:${key}(${value})`;
        delete options[key]; // this could break the for?
      }
    }

    // add image url
    url += '/' + image;

    return url;
  }

  getPipeUrl(value: string, args: string[]): string {
    const options = args.reduce((prev: {[s: string]: string}, curr: string) => {
      if (curr.indexOf(':') > -1) {
        // const fields = curr.split(':');
        // const prop = fields[0];
        // const value = fields[1];
        const prop = curr.substring(0, curr.indexOf(':'));
        const value = curr.substring(curr.indexOf(':') + 1);
        return {
          ...prev,
          [prop]: value
        }
      }
      console.info('GungImageUrlPipe - transform()', curr);
      return {
        ...prev,
        [curr]: ''
      }
    }, {});
    return this.getUrl(value, options);
  }

  encodeUrl(str: string): string {
    if (str.includes('%')) {
      return str
        .split('%')
        .map((part, index) => {
          return index === 0 ? encodeURIComponent(part) : '%' + part;
        })
        .join('');
    } else {
      return encodeURIComponent(str);
    }
  }

  getDownloadUrl(file: string | S3Item, mustEncode?: 'encodeURI' | 'encodeURIComponent'): string {
    return GungDownloadUrl(file, mustEncode);
  }

  getPipeDownloadUrl(value: string | S3Item, args: string[]): string {
    return this.getDownloadUrl(value, args?.[0] as any);
  }
}
