<div class="checkout-car-list">
  <div class="loading" *ngIf="loading">
    <div class='sk-wandering-cubes'>
      <div class='sk-cube bg-primary sk-cube-1'></div>
      <div class='sk-cube bg-primary sk-cube-2'></div>
    </div>
  </div>
  <div *ngIf="!isMultiDimension; else multiDimention">
    <div class="row pb-3 d-none d-md-flex" *ngIf="!!mappedData && cartListCheckoutConfig.enableGlobalDeliveryDate">
      <div class="col-9 text-right">
        <div class="row">
          <div class="col">
            <span translate>REQUEST_DELIVERY_DATE</span>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <button type="button" class="btn btn-link p-0" (click)="restoreMinDate()">
              (<span class="small" translate>RESTORE_AVAILABLE_DATES</span>)
            </button>
          </div>
        </div>
      </div>
      <div class="col-2">
        <lib-delivery-date-picker
          class="request-delivery-date"
          label=""
          [initialDate]="deliveryDate"
          (selectedDate)="dateSelected($event)"
        ></lib-delivery-date-picker>
      </div>
      <div class="col-1"></div>
    </div>
    <div *ngFor="let mappedDataRow of (!sortByOptions ? mappedData : mappedData | orderBy: 'asc': sortByFn); trackBy: trackByFn; let i = index">
      <div class="d-flex" *ngIf="i === 0">
        <div *ngIf="i === 0 && (isSales && columns.image.show) || (!isSales && columns.image.showToUser)" class="cart-head" [ngStyle]="columns.image.styles">
          <b translate>{{ columns.image.name }}</b>
        </div>
        <div *ngIf="i === 0 && (isSales && columns.name.show) || (!isSales && columns.name.showToUser)" class="cart-head" [ngStyle]="columns.name.styles">
          <b translate>{{ columns.name.name }}</b>
        </div>
        <div *ngIf="i === 0 && (isSales && columns.qty.show) || (!isSales && columns.qty.showToUser)" class="cart-head" [ngStyle]="columns.qty.styles">
          <b translate>{{ columns.qty.name }}</b>
        </div>
        <div *ngIf="i === 0 && (isSales && columns.unit.show) || (!isSales && columns.unit.showToUser)" class="cart-head" [ngStyle]="columns.unit.styles">
          <b translate>{{ columns.unit.name }}</b>
        </div>
        <div *ngIf="i === 0 && (isSales && columns.unitPrice.show) || (!isSales && columns.unitPrice.showToUser)" class="cart-head" [ngStyle]="columns.unitPrice.styles">
          <b translate>{{ columns.unitPrice.name }}</b>
        </div>
        <div *ngIf="i === 0 && (isSales && columns.discount.show) || (!isSales && columns.discount.showToUser)" class="cart-head" [ngStyle]="columns.discount.styles">
          <b translate>{{ columns.discount.name }}</b>
        </div>
        <div *ngIf="i === 0 && (isSales && columns.total.show) || (!isSales && columns.total.showToUser)" class="cart-head" [ngStyle]="columns.total.styles">
          <b translate>{{ columns.total.name }}</b>
        </div>
        <div *ngIf="i === 0 && (isSales && columns.deliveryDate.show) || (!isSales && columns.deliveryDate.showToUser)" class="cart-head" [ngStyle]="columns.deliveryDate.styles">
          <b translate>{{ columns.deliveryDate.name }}</b>
        </div>
        <div *ngIf="i === 0 && (isSales && columns.actions.show) || (!isSales && columns.actions.showToUser)" class="cart-head" [ngStyle]="columns.actions.styles">
          <b *ngIf="!sortByOptions; else sortIcon" translate>{{ columns.actions.name }}</b>
          <ng-template #sortIcon>
            <div ngbDropdown class="d-inline-block">
              <button type="button" class="btn btn-link p-0" id="dropdownBasic1" title="{{'SORT' | translate}}" ngbDropdownToggle>
                  <i class="fa-solid fa-arrow-down-short-wide"></i>
              </button>
              <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                  <button *ngFor="let option of sortByOptions" (click)="sortByFn = option.value" [class.font-weight-bold]="sortByFn === option.value" ngbDropdownItem>{{option.name | translate}}</button>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
      <div class="d-flex pt-1 pb-1 align-items-center cartrow-line">
        <div
          *ngIf="(isSales && columns.image.show) || (!isSales && columns.image.showToUser)"
          class="navigate-to-product-details cart-col product-image"
          [routerLink]="['/product/', mappedDataRow.product.id]"
          [ngStyle]="columns.image.styles"
        >
          <img
            class="img-fluid" style="max-width: 64px;"
            *ngIf="mappedDataRow.product.extra.images && mappedDataRow.product.extra.images.length > 0"
            [src]="mappedDataRow.product.extra.images[0].s3Uri
              | gungImageUrl
                :'type:thumbnail'
                :'etag:'+mappedDataRow.product.extra.images[0].s3ETag
            "
            alt=""
          />
        </div>

        <div
          *ngIf="(isSales && columns.name.show) || (!isSales && columns.name.showToUser)"
          class="navigate-to-product-details cart-col"
          [ngStyle]="columns.name.styles"
          [routerLink]="['/product/', mappedDataRow.product.id]"
        >
          <div>
            <b>{{ mappedDataRow.product.id }}</b> - {{ mappedDataRow.product.name }}
          </div>
        </div>

        <div class="col-request-quantity cart-col" *ngIf="(isSales && columns.qty.show) || (!isSales && columns.qty.showToUser)" [ngStyle]="columns.qty.styles">
          <div class="row pt-1">
            <div class="col-4 d-flex d-xl-none align-items-center">
              <b translate>QUANTITY</b>
            </div>
            <div class="col-8 col-xl-12 d-flex">
              <!--<lib-buy-btn
                [id]="mappedDataRow.product.id"
                [product]="mappedDataRow.product"
                [checkoutCart]="true"
              ></lib-buy-btn>-->
              <lib-buy-button-wrapper
                [id]="mappedDataRow.product.id"
                [product]="mappedDataRow.product"
                [checkoutCart]="true"
                [targetStockId]="mappedDataRow.cartRow?.targetStockId"
                [productPartialId]="mappedDataRow.cartRow?.productPartialId"
              ></lib-buy-button-wrapper>
            </div>
          </div>
        </div>

        <div *ngIf="(isSales && columns.unit.show) || (!isSales && columns.unit.showToUser)" class="cart-col" [ngStyle]="columns.unit.styles">
          <div class="row pt-1">
            <div class="col-4 d-flex d-xl-none align-items-center">
              <b translate>UNIT</b>
            </div>
            <div class="col-8 col-xl-12 d-flex">
              {{ mappedDataRow.product.extra._unit }}
            </div>
          </div>

        </div>
        <div *ngIf="(isSales && columns.unitPrice.show) || (!isSales && columns.unitPrice.showToUser)" class="cart-col" [ngStyle]="columns.unitPrice.styles">
          <div class="row pt-1">
            <div class="col-6 d-flex d-xl-none">
              <b translate>UNIT_PRICE</b>
            </div>
            <div class="col-6 col-xl-12 d-flex">
              <div *ngIf="isSales && cartListCheckoutConfig.enableSalesEditPrice; else showOnlyPrice">
                <input
                  type="number"
                  class="form-control"
                  [class.small-number-input]="!currentFlow.requireAvailability && !currentFlow.useAvailabilities"
                  [ngModel]="getFormattedPrice(mappedDataRow.price.cartRowUnitPrice.value)"
                  (change)="setPrice($event, mappedDataRow.cartRow)"
                  (click)="$event.preventDefault(); $event.stopPropagation()"
                />
              </div>
              <ng-template #showOnlyPrice>
                <b>
                  <lib-price [price]="mappedDataRow.price" type="cartRowUnitPrice"></lib-price>
                  <span *ngIf="mappedDataRow.price.cartRowDiscountPercent !== 0"
                    >(-{{ mappedDataRow.price.cartRowDiscountPercent }}%)</span
                  >
                </b>
              </ng-template>
            </div>
          </div>
        </div>

        <div *ngIf="(isSales && columns.discount.show) || (!isSales && columns.discount.showToUser)" class="cart-col" [ngStyle]="columns.discount.styles">
          <div class="row pt-1">
            <div class="col-6 d-flex d-xl-none">
              <b translate>DISCOUNT</b>
            </div>
            <div class="col-6 col-xl-12 d-flex">
              <div *ngIf="isSales && cartListCheckoutConfig.enableSalesEditDiscount; else showOnlydiscount">
                <input
                  type="number"
                  class="form-control"
                  [class.small-number-input]="!currentFlow.requireAvailability && !currentFlow.useAvailabilities"
                  [placeholder]="mappedDataRow.price.cartRowDiscountPercent"
                  [value]="mappedDataRow.price.cartRowDiscountPercent"
                  (change)="setDiscount($event, mappedDataRow.cartRow)"
                  (click)="$event.preventDefault(); $event.stopPropagation()"
                />
              </div>
              <ng-template #showOnlydiscount>
                <lib-price [price]="mappedDataRow.price" type="cartRowDiscountAmountPerUnit"></lib-price>
              </ng-template>
            </div>
          </div>
        </div>
        <div *ngIf="(isSales && columns.total.show) || (!isSales && columns.total.showToUser)" class="cart-col" [ngStyle]="columns.total.styles">
          <div class="row pt-1">
            <div class="col-6 d-flex d-xl-none">
              <b translate>TOTAL_PRICE</b>
            </div>
            <div class="col-6 col-xl-12 d-flex">
              <lib-price [price]="mappedDataRow.price" type="cartRowTotalPriceInclRowDiscount"></lib-price>
            </div>
          </div>
        </div>

        <div class="cart-col" *ngIf="(isSales && columns.deliveryDate.show) || (!isSales && columns.deliveryDate.showToUser)" [ngStyle]="columns.deliveryDate.styles">
          <div class="row pt-1">
            <div class="col-4 d-flex d-xl-none">
              <b translate>DELIVERY</b>
            </div>
            <div class="col-8 col-xl-12 d-flex">
              <div class="input-group">
                <lib-delivery-date-picker
                  class="request-delivery-date"
                  label=""
                  [minInitialDate]="mappedDataRow.minDate"
                  [initialDate]="
                    mappedDataRow.deliveryDate ? mappedDataRow.deliveryDate : formatNgbDate(mappedDataRow.minDate)
                  "
                  (selectedDate)="updateDeliveryDate(mappedDataRow, $event)"
                  [currentselectedDate]="mappedDataRow.deliveryDate"
                ></lib-delivery-date-picker>
              </div>
            </div>
          </div>
        </div>

        <div class="text-right cart-col" *ngIf="(isSales && columns.actions.show) || (!isSales && columns.actions.showToUser)" [ngStyle]="columns.actions.styles">
          <button
            *ngIf="cartListCheckoutConfig.userCanAddDescription"
            type="button"
            [title]=""
            class="btn btn-sm btn-outline-secondary mr-1"
            (click)="addDescription(mappedDataRow.cartRow)"
            translate
          >
            <i class="fas fa-comment"></i>
          </button>
          <button
            *ngIf="cartListCheckoutConfig.enableCopyRow"
            type="button"
            [title]=""
            class="btn btn-sm btn-outline-secondary mr-1"
            (click)="copyRow(mappedDataRow.cartRow, i)"
            translate
          >
            <i class="fas fa-copy"></i>
          </button>
          <button class="btn btn-sm btn-outline-secondary delete-button mr-1" (click)="removeRow(mappedDataRow.cartRow)">
            <i class="fal fa-minus-circle"></i>
          </button>
        </div>
      </div>

      <div class="row hr"></div>
    </div>
  </div>
  <ng-template #multiDimention>
    <div class="row pb-3 d-none d-md-flex" *ngIf="!!(cartDataContext.length > 0) && cartListCheckoutConfig.enableGlobalDeliveryDate">
      <div class="col-9 text-right">
        <div class="row">
          <div class="col">
            <span translate>REQUEST_DELIVERY_DATE</span>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <button type="button" class="btn btn-link p-0" (click)="restoreMinDateMultidimension()">
              (<span class="small" translate>RESTORE_AVAILABLE_DATES</span>)
            </button>
          </div>
        </div>
      </div>
      <div class="col-3">
        <lib-delivery-date-picker
          class="request-delivery-date"
          label=""
          [initialDate]="deliveryDate"
          (selectedDate)="dateSelectedMultidimension($event)"
        ></lib-delivery-date-picker>
      </div>
      <div class="col-1"></div>
    </div>
    <div *ngIf="cartDataContext.length > 0">
      <div
        class="row cart-row-item border-bottom"
        *ngFor="let data of cartDataContext; index as rowIndex; trackBy: trackByCartDataContext"
      >
        <div class="col-md-2">
          <div class="image-wrapper">
            <a routerLink="/product/{{ data.id }}">
              <img
                [src]="data.image.s3Uri
                  | gungImageUrl
                    :'type:thumbnail'
                    :'etag:'+data.image.s3ETag
                "
                alt="{{ data.image.description }}"
                class="img-fluid"
              />
            </a>
          </div>
        </div>

        <div class="col-md-10">
          <div class="row" style="margin-bottom: 20px">
            <div class="col-md-4">
              <a routerLink="/product/{{ data.id }}">
                <h3 class="item-title">{{ data.related[0].product.name }}</h3>
                <p class="item-info">
                  <span>{{ data.id }}</span>
                  <br />
                  <span *ngIf="data.isMultiDimension">{{ data.related[0].product.primaryDimension[0].name }} </span>
                </p>
              </a>
            </div>

            <div class="col-md-2 col-6">
              <p class="item-info-center">
                <span>
                  {{ 'QUANTITY' | translate }}
                  <br />
                  {{ data.totals.qty }}
                  <br />
                </span>
              </p>
            </div>

            <div class="col-md-2 col-6">
              <p class="item-info-center">
                <span>
                  {{ 'PRICE' | translate }}
                  <br />
                  <lib-price
                    [ngClass]="{
                      'line-through': !!data.totals.discount && data.totals.discount !== data.totals.price.value
                    }"
                    [price]="data.totals.price.value"
                    [currency]="data.totals.price.currencyCode"
                  ></lib-price>
                  <lib-price
                    class="d-block"
                    *ngIf="!!data.totals.discount && data.totals.discount !== data.totals.price.value"
                    [price]="data.totals.discount"
                  ></lib-price>
                </span>
              </p>
            </div>

            <div class="col-md-2 col-6 text-right">
              <button
                [id]="'show_btn_' + data.id"
                class="btn-link btn-icon show-hide btn-expand-text"
                (click)="toogleSizes(data.id, 'show')"
                [class.hidden]="sizesVisible[data.id]"
              >
                <i class="fas fa-angle-down"></i>
              </button>

              <button
                [id]="'hide_btn_' + data.id"
                class="btn-icon show-hide btn-expand-text"
                [class.hidden]="!sizesVisible[data.id]"
                (click)="toogleSizes(data.id, 'hide')"
              >
                <i class="fas fa-angle-up"></i>
              </button>
            </div>

            <div class="col-md-2 col-6 text-right" *ngIf="isSales && (cartListCheckoutConfig.enableSalesEditPrice || cartListCheckoutConfig.enableSalesEditDiscount)">
              <button
                type="button"
                class="btn btn-secondary"
                (click)="openCartDiscountModal(data)"
                translate
              >
                EDIT
              </button>
            </div>
          </div>

          <div [id]="data.id" *ngIf="sizesVisible[data.id]">
            <div class="item" *ngFor="let related of data.related; index as relatedIndex; trackBy: trackByRelated">
              <div class="item-details">
                <div class="row justify-content-around">
                  <div class="col-2 col-xl-2 text-center item-row-title">
                    {{ data.isMultiDimension ? related.product.secondaryDimension[0].name : related.product.id }}
                    <span *ngIf="related.cartRow.productPartialId">
                      {{ ' - ' + related.cartRow.productPartialId }}
                    </span>
                  </div>

                  <div class="col-3 col-xl-2">
                    <span class="item-price">
                      <lib-price [price]="related.price.value" [currency]="related.price.currencyCode"></lib-price>
                      <small
                        class="text-muted"
                        *ngIf="related.cartRowPrice && related.cartRowPrice.cartRowDiscountPercent !== 0"
                      >
                        {{ -1 * related.cartRowPrice.cartRowDiscountPercent | number: '1.0-2' }} %
                      </small>
                    </span>
                  </div>

                  <div class="col-4 col-xl-2 wrapper-input">
                    <lib-product-matrix-wrapper-input-element
                      [id]="related.product.id"
                      [availability]="related.availability"

                      [productPartialId]="related.cartRow.productPartialId"
                      [product]="related.product"
                    >
                    </lib-product-matrix-wrapper-input-element>
                  </div>

                  <div class="col-3 col-xl-3" *ngIf="cartListCheckoutConfig.userCanChangeDeliveryDates">
                    <div class="input-group">
                      <input
                        class="form-control input-date"
                        placeholder="yyyy-mm-dd"
                        name="dp"
                        ngbDatepicker
                        #d="ngbDatepicker"
                        readonly
                        container="body"
                        [value]="related.deliveryDate ? related.deliveryDate : formatNgbDate(related.minDate)"
                        [minDate]="related.minDate"
                        (dateSelect)="updateDeliveryDate(related, $event, true)"
                      />
                      <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button">
                          <i class="fal fa-calendar-alt"></i>
                        </button>
                      </div>
                    </div>
                  </div>

                  <div class="col-1 col-auto text-center">
                    <button
                      class="btn-link btn-icon btn-text-expand"
                      title="{{'REMOVE' | translate}}"
                      (click)="removeRow(related.cartRow)"
                    >
                      <i class="fas fa-times"></i>
                    </button>
                  </div>
                </div>
                <hr class="item-separator" *ngIf="relatedIndex < data.related.length - 1" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <div class="add-row mt-2" *ngIf="!loading && cartListCheckoutConfig.enableAddRow">
    <button class="btn btn-primary btn-circle" type="button" (click)="addNewOrderLine()" translate>
      ADD_ROWS_TO_ORDER
    </button>
  </div>
</div>
