<div *ngIf="!isMobile">
  <app-index-page *ngIf="loginRequired || (!isLoading && !errorOccured); else noApp"></app-index-page>

  <ng-template #noApp>
    <div class="wrapper d-flex align-items-center justify-content-center">
      <div *ngIf="isLoading && !errorOccured">
        <i class="fa fa-spinner fa-spin fa-7x"></i>
      </div>
      <div *ngIf="errorOccured">
        <h1 class="text-center">An error occured, please log out and contact your supplier</h1>
        <div class="d-flex justify-content-center">
          <button class="btn btn-primary" (click)="logout()" translate>LOGOUT</button>
        </div>
      </div>
    </div>
  </ng-template>
</div>

<div *ngIf="isMobile">
  <app-hl-display-navbar></app-hl-display-navbar>
  <div class="navbar-top-image logged-out"></div>
  <div class="desktop-only-container d-flex align-items-center justify-content-center flex-column">
    <div class="message-container d-flex align-items-center justify-content-center flex-column">
      <div class="desktop-only-image"></div>
      <h1 class="m-0 text-center" translate="DESKTOP_ONLY"></h1>
    </div>
  </div>
</div>
