import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FilterListService, RenderFilter } from 'gung-list';

@Injectable({
  providedIn: 'root'
})
export class HlFilterServiceService<T> extends FilterListService<T> {
  constructor(route: ActivatedRoute) {
    super(route);
  }

  protected getFilteredRenderFilters(renderFilters: RenderFilter[]): RenderFilter[] {
    const filters = super.getFilteredRenderFilters(renderFilters);

    filters.forEach(renderFilter => {
      if (this.hasDuplicatedValueNames(renderFilter)) {
        const newValueList: {
          valueId: string;
          valueName: string;
          itemsIncluded: string[];
          selected: boolean;
          itemCountTotal: number;
          itemCountAfterFilter: number;
          hide: boolean;
        }[] = [];
        renderFilter.valueList.forEach(value => {
          const existingValue = newValueList.find(newValue => newValue.valueName === value.valueName);
          if (!existingValue) {
            newValueList.push(value);
          } else {
            existingValue.valueId += `;${value.valueId}`;
            existingValue.itemsIncluded = Array.from(
              new Set<string>([...existingValue.itemsIncluded, ...value.itemsIncluded])
            );
          }
        });

        renderFilter.valueList = newValueList;
      }
    });

    return filters;
  }

  private hasDuplicatedValueNames(renderFilter: RenderFilter): boolean {
    const allValueNames = renderFilter.valueList.map(v => v.valueName);
    const allUniqueValuesNames = Array.from(new Set<string>(allValueNames));

    return allValueNames.length > allUniqueValuesNames.length;
  }
}
