import { Injectable, Type } from '@angular/core';
import { JeevesBaseViewConfigService } from 'gung-standard-jeeves';
import { HlFashionLoginComponent } from '../components/hl-fashion-login/hl-fashion-login.component';
import { LoginComponent } from 'gung-standard';

@Injectable({
  providedIn: 'root'
})
export class HlBaseViewConfigService extends JeevesBaseViewConfigService {
  loginType = 'FashionLogin';

  getLoginComponent(loginType = this.loginType): Type<LoginComponent> {
    switch (loginType) {
      case 'FashionLogin':
        return HlFashionLoginComponent;
      default:
        return super.getLoginComponent(loginType);
    }
  }
}
