import { Component, OnInit } from '@angular/core';
import { CartService, CartRow, Product } from 'gung-standard';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

@Component({
  selector: 'app-hl-validation-action-modal',
  templateUrl: './hl-validation-action-modal.component.html',
  styleUrls: ['./hl-validation-action-modal.component.css']
})
export class HlValidationActionModalComponent implements OnInit {
  private orderLineItemNotInJeevesProductId = '155035';

  public cartRows: CartRow[];
  public validationData: any[];
  public products: Product[];
  public rowsToHandle: { cartRow: CartRow; validationData: any; product: Product }[];

  constructor(
    protected cartService: CartService,
    public activeModal: NgbActiveModal,
    protected router: Router
  ) {}

  ngOnInit() {
    this.rowsToHandle = this.cartRows
      .filter(cartRow => cartRow.productId !== this.orderLineItemNotInJeevesProductId)
      .map(row => {
        return {
          cartRow: row,
          validationData: this.validationData.find(data => data.productId === row.productId),
          product: this.products.find(data => data.id === row.productId)
        };
      });
  }

  lowerQuantityOnRow(row: CartRow, validationData: any, index: number) {
    this.cartService.setProductQuantity(
      row.productId,
      validationData.availabilityData.maxFutureStock,
      row.targetStockId,
      row.productPartialId
    );
    this.updateRowsToHandle(index);
  }

  removeRow(row: CartRow, index: number) {
    this.cartService.removeRow(row);
    this.updateRowsToHandle(index);
  }

  updateRowsToHandle(index: number) {
    this.rowsToHandle.splice(index, 1);
    if (this.rowsToHandle.length === 0) {
      this.activeModal.close();
    }
  }

  redirect() {
    this.activeModal.close();
    this.router.navigate(['/products'], { queryParams: { filters: 'ASSORTMENT_TYPE__:__C_____S' } });
  }
}
