import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Product } from '../../models';
import { isMultiDimensionProduct } from '../../utils/product-utils';
import { BaseGridCardV2Component } from '../base-grid-card-v2/base-grid-card-v2.component';
import { LocationConfigService } from './../../services/location-config/location-config.service';

@Component({
  selector: 'lib-product-grid-card-v2',
  templateUrl: './product-grid-card-v2.component.html',
  styleUrls: ['./product-grid-card-v2.component.scss']
})
export class ProductGridCardV2Component extends BaseGridCardV2Component implements OnInit, AfterViewInit {
  adjustedScroll = false;

  constructor(protected router: Router, protected locationConfigService: LocationConfigService) {
    super();
  }
  ngOnInit(): void {
    super.ngOnInit();
  }

  ngAfterViewInit() {
    // Removed because this functionality is now done on the filter-list component itself //
    /*if (!this.adjustedScroll) {
      setTimeout(() => {
        this.adjustScroll();
      }, 100);
      this.adjustedScroll = true;
    }*/
  }

  getIsMultiDimensionProduct(product: Product): boolean {
    return isMultiDimensionProduct(product);
  }

  goToDetail(product: Product): void {
    // Removed because this functionality is now done on the filter-list component itself //
    //this.storeScrollPosition();

    if (this.data.product?.productType === 'concept') {
      this.router.navigate(['/concept/', product.id]);
    } else {
      this.router.navigate(['/product/', product.id]);
    }
  }

  // Removed because this functionality is now done on the filter-list component itself //
  /*storeScrollPosition(): void {
    this.locationConfigService.add({
      path: 'products',
      scrollX: window.scrollX,
      scrollY: window.scrollY
    });
  }*/

  // Removed because this functionality is now done on the filter-list component itself //
  /*adjustScroll() {
    const locationPosition = this.locationConfigService.get('products');
    if (!!locationPosition) {
      window.scrollTo({ top: locationPosition.scrollY, behavior: 'smooth' });
      this.locationConfigService.remove(locationPosition);
    }
  }*/
}
