import { Component, OnInit } from '@angular/core';
import { AssignJeevesDefaultsComponent, JeevesDeliveryAddressesService } from 'gung-standard-jeeves';
import {
  CustomerContactService,
  SelectedCustomerService,
  Customer,
  CustomerService,
  GungFlowService,
  AuthService,
  CartService,
  AvailabilityService,
  GungFlow,
  CustomerContact,
  CartRow
} from 'gung-standard';
import { DateUtilService } from 'gung-common';
import { environment } from '../../../../environments/environment';
import { merge } from 'lodash-es';
import {
  CheckoutSettingsGroup,
  HlDisplayPortalFeaturesService
} from '../../../services/hl-display-portal-features.service';
import { first } from 'rxjs';

@Component({
  selector: 'app-hl-assign-defaults',
  templateUrl: './hl-assign-defaults.component.html',
  styleUrls: ['./hl-assign-defaults.component.css']
})
export class HlAssignDefaultsComponent extends AssignJeevesDefaultsComponent implements OnInit {
  useCustomerDefaultEmailForConfirmation = false;
  constructor(
    protected selectedCustomerService: SelectedCustomerService,
    protected customerService: CustomerService,
    protected customerContactService: CustomerContactService,
    protected gungFlowService: GungFlowService,
    protected authService: AuthService,
    protected cartService: CartService,
    protected availabilityService: AvailabilityService,
    protected dateUtilService: DateUtilService,
    protected jeevesDeliveryAddressesService: JeevesDeliveryAddressesService,
    protected hlDisplayPortalFeaturesService: HlDisplayPortalFeaturesService
  ) {
    super(
      selectedCustomerService,
      customerService,
      customerContactService,
      gungFlowService,
      authService,
      cartService,
      availabilityService,
      dateUtilService,
      jeevesDeliveryAddressesService
    );
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.hlDisplayPortalFeaturesService
      .getPortalFeatureGroup('orderCheckoutSettingsGroup')
      .pipe(first())
      .subscribe(group => {
        let settings = group as CheckoutSettingsGroup;

        this.useCustomerDefaultEmailForConfirmation = settings.useCustomerDefaultEmailForConfirmation;
      });
  }

  protected setValues(
    customer: Customer,
    flow: GungFlow,
    user,
    customerContacts: CustomerContact[],
    billingCustomer?: Customer,
    cartRows?: CartRow[]
  ) {
    super.setValues(customer, flow, user, customerContacts, billingCustomer);

    // only prepopulate if not sales personel
    if (!user.roles.includes('ADMIN') && !user.roles.includes('SALES')) {
      this.checkout.extra.oh.kundref2 = user.email;
      this.checkout.extra._msFlowOrderConfirmEmail = user.email;
    }

    if (!this.checkout.extra.kus) {
      this.checkout.extra.kus = {};
    }
    this.checkout.extra.kus.q_hl_emailtt = customer.extra.kus.q_hl_emailtt || '';

    if (this.useCustomerDefaultEmailForConfirmation) {
      this.checkout.extra._msFlowOrderConfirmEmail = customer.extra.kus.q_hl_default_email || '';
    }

    /*
      This should be done in all checkouts, but unsure of consequences right now
    */
    if (user.extra.orderParams) {
      merge(this.checkout.extra, user.extra.orderParams);
    }

    if (user.roles.includes('ADMIN') || user.roles.includes('SALES')) {
      this.checkout.extra.kus.q_hl_default_email = customer.extra.kus.q_hl_default_email || '';
      this.checkout.extra.kus.q_hl_mail_invoicecc = customer.extra.kus.q_hl_mail_invoicecc || '';
    }

    // Added this field to ensure that we are sending the information from the correct cloudfront distribution when we are deploying the new portal
    // for portal alignment. - Adam
    this.checkout.extra._hlPortalAlignmentVersion = '1.0.0';
  }
}
