import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { filter, first, Subject, takeUntil } from 'rxjs';
import { AuthService } from '../../../services/auth/auth.service';
import { GungNavbarService } from '../../../services/gung-navbar/gung-navbar.service';
import { NavbarCartModel, NavbarCartService } from '../../../services/navbar-cart/navbar-cart.service';

@Component({
  selector: 'lib-gung-navbar',
  templateUrl: './gung-navbar.component.html',
  styleUrls: ['./gung-navbar.component.scss']
})
export class GungNavbarComponent implements OnInit, OnDestroy {
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: Event) {
    const clickedInsideMenu = (event.target as HTMLElement).closest('.main-menu, .btn-open-menu');
    if (this.activeMainMenu && !clickedInsideMenu) {
      this.activeMainMenu = false;
    }
  }

  private unsubscribe: Subject<void> = new Subject<void>();

  public logoUrl; /* = this.gungNavbar.getLogoUrl(); */
  isSales = false;
  activeMainMenu = false;
  isClosingShowMenu = false;
  shopMenu;
  customerString: string;
  isAnonymous = true;
  authenticated: boolean;
  isGlobalSearchOpen = false;
  cartContents: NavbarCartModel;

  constructor(
    protected gungNavbar: GungNavbarService,
    protected authService: AuthService,
    protected navbarCartService: NavbarCartService
  ) {}

  ngOnInit(): void {
    this.authService
      .getIsAuthenticated()
      .pipe(
        filter(authenticated => !!authenticated),
        takeUntil(this.unsubscribe)
      )
      .subscribe(auth => (this.authenticated = auth));

    this.gungNavbar
      .getLogoUrlObservable()
      .pipe(first())
      .subscribe(logoUrl => {
        this.logoUrl = logoUrl;
      });

    this.authService
      .getRoles()
      .pipe(
        filter(roles => !!roles),
        first()
      )
      .subscribe(roles => {
        if (roles.indexOf('SALES') > -1 || roles.indexOf('ADMIN') > -1) {
          this.isSales = true;
        }
        if (roles.indexOf('ANONYMOUS') < 0) {
          this.isAnonymous = false;
        }
      });

    this.gungNavbar
      .getCustomerInfo()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(customerString => (this.customerString = customerString));

    this.gungNavbar
      .getShopMenu()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(shopMenu => {
        this.shopMenu = shopMenu;
      });

    this.navbarCartService.getContents().subscribe(contents => {
      this.cartContents = contents;
    });
  }

  toggleMainMenu() {
    this.activeMainMenu = !this.activeMainMenu;
  }

  menuClickEvent(event) {
    this.activeMainMenu = !this.activeMainMenu;
  }

  closeExpandMenu(event) {
    this.isClosingShowMenu = event;
    setTimeout(() => {
      this.isClosingShowMenu = false;
    }, 100);
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
