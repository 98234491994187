<div class="card mb-2">
  <div class="card-body">
    <div class="card-title row align-content-center">
      <div class="col">
        <h5>{{ data.id }}</h5>
      </div>
      <div class="col-auto ml-auto d-flex align-items-center">
        <lib-action-btn [item]="data" [config]="configService"></lib-action-btn>
      </div>
    </div>
    <!--     <h5 class="card-title">{{data.id}} </h5> -->
    <div class="card-text">
      <div class="row">
        <div class="col-6 col-md-4">
          <div class="pb-1">
            <lib-gung-text-input [gungTranslate]="'ORDER'" [renderStatic]="true" [(ngModel)]="data.extra.fh.ordernr">
            </lib-gung-text-input>
          </div>
          <div class="pb-1">
            <lib-gung-text-input [gungTranslate]="'FH_FAKTSTKOD'" [renderStatic]="true"
              [ngModel]="getStatus('FH_FAKTSTKOD_', data.extra.fh.faktstkod) | translate"></lib-gung-text-input>
          </div>
          <div class="pb-1">
            <lib-gung-text-input [gungTranslate]="'FH_FTGRN'" [renderStatic]="true"
              [ngModel]="data.extra.fh.ftgnr"></lib-gung-text-input>
          </div>
          <div class="pb-1">
            <div class="form-group">
              <label class="mb-0 font-weight-bold" translate>LEFT_TO_PAY</label>
              <lib-price [price]="data.extra.fh.vbfaktrest" [currency]="data.extra.fh.valkod"
                class="form-control-plaintext p-0"></lib-price>
            </div>
          </div>
        </div>
        <div class="col-6 col-md-4">
          <div class="pb-1">
            <lib-gung-date-output [gungTranslate]="'INVOICE_DATE'" [value]="data.extra.fh.faktdat"
              [dateFormat]="dateUtilService.dateFormat">
            </lib-gung-date-output>
          </div>
          <div class="pb-1">
            <lib-gung-date-output [gungTranslate]="'DUE_DATE'" [value]="data.extra.fh.faktffdat"
              [dateFormat]="dateUtilService.dateFormat"></lib-gung-date-output>
          </div>
          <div class="pb-1">
            <lib-gung-text-input [gungTranslate]="'FH_FTGNAMN'" [renderStatic]="true"
              [ngModel]="data.extra._customer?._deliveryCustomerName"></lib-gung-text-input>
          </div>
          <div class="pb-1">
            <div class="form-group">
              <label class="mb-0 font-weight-bold" translate>TOTAL_VALUE</label>
              <lib-price [price]="data.extra.fh.vb_faktsum" [currency]="data.extra.fh.valkod"
                class="form-control-plaintext p-0"></lib-price>
            </div>
          </div>
        </div>
        <div class="col-6 col-md-4">
          <div class="pb-1">
            <lib-gung-text-input [gungTranslate]="'CUSTOMER_REFERENCE'" [(ngModel)]="data.extra.fh.kundref2"
              [renderStatic]="true"></lib-gung-text-input>
          </div>
          <div class="pb-1">
            <lib-gung-text-input [gungTranslate]="'FH_KUNDBETALARENR'" [(ngModel)]="data.extra.fh.kundbetalarenr"
              [renderStatic]="true"></lib-gung-text-input>
          </div>
          <div class="pb-1">
            <lib-gung-text-input [gungTranslate]="'PAY_CLIENT_NAME'" [renderStatic]="true"
              [ngModel]="data.extra._customer?._billingCustomerName"></lib-gung-text-input>
          </div>
          <div class="pb-1">
            <lib-gung-text-input [gungTranslate]="'OUR_REFERENCE'" [(ngModel)]="data.extra.fh.vref"
              [renderStatic]="true">
            </lib-gung-text-input>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>