import { Component } from '@angular/core';

@Component({
  selector: 'app-hl-home-user',
  templateUrl: './hl-home-user.component.html',
  styleUrl: './hl-home-user.component.scss'
})
export class HlHomeUserComponent {

}
