<div class="gungNotificationBox">
  <div
    class="gungNotification"
    *ngFor="let notification of notifications | keyvalue"
    [ngClass]="notification.value.type"
    [attr.data-id]="notification.key"
    (mouseenter)="onMouseEnter(notification.key)"
    (mouseleave)="onMouseLeave(notification.key)"
  >
    <i class="fa-solid fa-xmark" (click)="close(notification.key)"></i>
    <div class="title">{{ notification.value.title }}</div>
    <div class="message" *ngIf="!notification.value.link">{{ notification.value.message }}</div>
    <div class="message" *ngIf="notification.value.link">
      <a [routerLink]="notification.value.link.routerLink" [queryParams]="notification.value.link.queryParams">
      {{ notification.value.message }}
      </a>
    </div>
  </div>
</div>
