{
  "PIMITEM_DEPTH_MM": "Artikel Tiefe",
  "PIMITEM_WIDTH_MM": "Artikel Breite",
  "0": "Nein",
  "1": "Ja",
  "#": "#",
  "$CTRL_PRODUCT.I18N.EN.DESCRIPTION": "$CTRL_PRODUCT.I18N.EN.DESCRIPTION",
  "$CTRL_PRODUCT.I18N.SE.DESCRIPTION": "$CTRL_PRODUCT.I18N.SE.DESCRIPTION",
  "$CTRL_PRODUCT.I18N.SE.DESCRIPTION1": "$CTRL_PRODUCT.I18N.SE.DESCRIPTION1",
  "$CTRL_PRODUCT.I18N.SE.DESCRIPTION2": "$CTRL_PRODUCT.I18N.SE.DESCRIPTION2",
  "$CTRL_SELECTEDORDERID": "$CTRL_SELECTEDORDERID",
  "1_YEAR": "1_YEAR",
  "10%_DISCOUNT_WILL_BE_APPLIED": "10%_DISCOUNT_WILL_BE_APPLIED",
  "12_MONTH_TOTAL": "12_MONTH_TOTAL",
  "12_MONTH_TOTAL_LINE": "12_MONTH_TOTAL_LINE",
  "2_YEARS": "2_YEARS",
  "ABOUT": "Impressum",
  "ACCEPT_CONDITIONS": "Geschäftsbedingungen akzeptieren",
  "ACCEPT_CONDITIONS_FIRST": "Zunächst Geschäftsbedingungen akzeptieren",
  "ACCEPT_FUTURE_DATE": "Späteren Lieferzeitpunkt akzeptieren",
  "ACCEPT_PART_DELIVERIES": "Mit Teillieferungen einverstanden?",
  "ACCEPT_PART_DELIVERIES_NO": "Keine Teillieferung",
  "ACCEPT_PART_DELIVERIES_SELECTION_REQUIRED": "Geben Sie zum Abrunden der Bestellung an, ob Sie Teillieferungen wünschen oder nicht",
  "ACCEPT_PART_DELIVERIES_YES": "Teillieferung",
  "ACCESSORIES": "Accessoires",
  "ACCOUNT": "Konto",
  "ACCOUNT_DETAILS": "Kontoinformationen",
  "ACCOUNT_MANAGER": "ACCOUNT_MANAGER",
  "ACCOUNT_REQUEST": "Kontoantrag",
  "ACCOUNT_REQUEST_FAIL": "Wir können Ihren Antrag nicht erstellen. Bitte versuchen Sie es noch einmal oder kontaktieren Sie direkt das Unternehmen.",
  "ACCOUNT_REQUEST_SUCCESS": "Wir haben Ihren Antrag erhalten und Sie werden benachrichtigt, wenn er geprüft worden ist.",
  "ACCOUNTS": "ACCOUNTS",
  "ACTION": "Aktion",
  "ACTIONS": "Aktionen",
  "ACTIVE_CUSTOMERS": "ACTIVE_CUSTOMERS",
  "ACTIVE_FILTERS": "Aktive Filter",
  "ACTIVE_PRODUCTS": "ACTIVE_PRODUCTS",
  "ACTIVE_WITHING_THE_LAST": "ACTIVE_WITHING_THE_LAST",
  "ACTIVITIES": "Aktivitäten",
  "ACTIVITY": "Aktivität",
  "ACTIVITY_DETAILS": "Aktivitätsdetails",
  "ACTIVITY_SEARCH": "Suche nach Typ und Datum",
  "ACTIVITY_TYPE": "Aktivitätstyp",
  "ACTUAL_DELIVERY_DATE": "ACTUAL_DELIVERY_DATE",
  "ADD": "Hinzufügen",
  "ADD_ACTIVITY": "Aktivität hinzufügen",
  "ADD_ALL_QUANTITIES_FIRST": "Zuerst alle Mengen hinzufügen. Wenn Sie neue Zeilen einfügen, Update erneut durchführen.",
  "ADD_CONTACT": "Kontakt hinzufügen",
  "ADD_EMAIL_ADDRESS": "E-Mail-Adresse hinzufügen",
  "ADD_FILE": "ADD_FILE",
  "ADD_IMAGES_DESCRIPTION": "ADD_IMAGES_DESCRIPTION",
  "ADD_MODEL_DISCOUNT": "Rabattmodell hinzufügen",
  "ADD_MODEL_PRICE": "Preismodell hinzufügen",
  "ADD_MORE": "Mehr hinzufügen",
  "ADD_NEW_CUSTOMER": "ADD_NEW_CUSTOMER",
  "ADD_ORDER_ROW": "ADD_ORDER_ROW",
  "ADD_PHONE_CALL": "Ein Telefongespräch hinzufügen",
  "ADD_PRODUCTS_AND_OPTIONS": "Produkte und Optionen hinzufügen",
  "ADD_ROW": "ADD_ROW",
  "ADD_TO_ALL_OF_SAME_MODEL": "Zu allem mit der gleichen Modellnummer hinzufügen",
  "ADD_TO_BASKET": "ADD_TO_BASKET",
  "ADD_TO_CART": "Hinzufügen",
  "ADD_TO_CURRENT_ORDER": "Zur aktuellen Bestellung hinzufügen",
  "ADD_TO_EXISTING": "ADD_TO_EXISTING",
  "ADD_TO_FAVORITE_LIST": "Zur Favoritenliste hinzufügen",
  "ADD_TO_ORDER": "ADD_TO_ORDER",
  "ADD_USER": "ADD_USER",
  "ADDED_TO_BASKET": "ADDED_TO_BASKET",
  "ADDITIONAL_COSTS": "ADDITIONAL_COSTS",
  "ADDITIONAL_INFO": "Zusätzliche Info",
  "ADDITIONAL_INFORMATION": "Zusatzinformation",
  "ADDON": "Zusatz",
  "ADDON_PRODUCT_PRICE": "Preis des Ausgangsprodukts",
  "ADDRESS": "Anschrift",
  "ADDRESS_INFO": "Adressdaten",
  "ADDRESS1": "Straße",
  "ADDRESS2": "Anschrift",
  "ADDRESS3": "Anschrift",
  "ADDRESS4": "Adresse 4",
  "ADMIN": "Admin",
  "ADVANCE_DELIVERY_INFORMATION": "ADVANCE_DELIVERY_INFORMATION",
  "ADVANCED_SETTINGS": "Erweiterte Einstellungen",
  "AGA_LKD": "AGA_LKD",
  "AGA_SES": "AGA_SES",
  "AGE_GROUP": "AGE_GROUP",
  "AL_ARTHUVUDAVT_MISSING": "AL_ARTHUVUDAVT_MISSING",
  "ALIAS": "ALIAS",
  "ALIASES_SHORTHANDS": "ALIASES_SHORTHANDS",
  "ALL": "Alle",
  "ALL_PRICES": "Alle Preise",
  "ALL_PRODUCTS": "Alle Produkte",
  "ALSO_SUITABLE_FOR": "Auch für folgende Autos geeignet",
  "AMAZON_AGERANGE": "AMAZON_AGERANGE",
  "AMAZON_BRANDNAME": "AMAZON_BRANDNAME",
  "AMAZON_CATEGORIES": "AMAZON_CATEGORIES",
  "AMAZON_COLOR": "AMAZON_COLOR",
  "AMAZON_DEMANDTYPES": "AMAZON_DEMANDTYPES",
  "AMAZON_DEPARTMENTS": "AMAZON_DEPARTMENTS",
  "AMAZON_EXTERNALIDTYPE": "AMAZON_EXTERNALIDTYPE",
  "AMAZON_GENDER": "AMAZON_GENDER",
  "AMAZON_ITEMTYPEKEYWORD": "AMAZON_ITEMTYPEKEYWORD",
  "AMAZON_MANUFACTURERTYPE": "AMAZON_MANUFACTURERTYPE",
  "AMAZON_MSRP": "AMAZON_MSRP",
  "AMAZON_ORDERINGMETHODS": "AMAZON_ORDERINGMETHODS",
  "AMAZON_SELLINGSEASON": "AMAZON_SELLINGSEASON",
  "AMAZON_SELLINGSEASONDURATION": "AMAZON_SELLINGSEASONDURATION",
  "AMAZON_SPORTTYPE": "AMAZON_SPORTTYPE",
  "AMAZON_SUBCATEGORIES": "AMAZON_SUBCATEGORIES",
  "AMAZON_SUPPLYTYPES": "AMAZON_SUPPLYTYPES",
  "AMAZON_VENDORCODES": "AMAZON_VENDORCODES",
  "AMOUNT": "Menge",
  "AMOUNT_OF_INVOICES": "AMOUNT_OF_INVOICES",
  "AMOUNT_PRODUCTS": "AMOUNT_PRODUCTS",
  "AMOUNT_SOLD_THIS_MONTH": "AMOUNT_SOLD_THIS_MONTH",
  "AMOUNTS": "Betrag",
  "APD_BEN": "APD_BEN",
  "APPLY_TO_COLOR": "Für die Farbe anwenden",
  "APPLY_TO_MODEL": "Für das Modell anwenden",
  "APPLY_TO_ROW": "APPLY_TO_ROW",
  "April": "April",
  "AR_ARTBESKR": "AR_ARTBESKR",
  "AR_ARTBESKR2": "AR_ARTBESKR2",
  "AR_ARTFSGFORP": "AR_ARTFSGFORP",
  "AR_ARTNR": "AR_ARTNR",
  "AR_ARTNREAN": "AR_ARTNREAN",
  "AR_BALANCE": "Forderung",
  "AR_EXTRA3": "AR_EXTRA3",
  "AR_ITEMTYPECD2": "Artikelfilter 1",
  "AR_ITEMTYPECD3": "Artikelfilter 2",
  "AR_ITEMTYPECD4": "Artikelfilter 3",
  "AR_Q_KOLLEKTION": "AR_Q_KOLLEKTION",
  "AR_VARUGRUPPKOD": "Produktkategorie",
  "ARTICLE_CLASS": "ARTICLE_CLASS",
  "ARTICLE_DESCRIPTION": "Artikelbeschreibung",
  "ARTICLE_DISCOUNT_TIER": "ARTICLE_DISCOUNT_TIER",
  "ARTICLE_NUMBER": "ARTICLE_NUMBER",
  "ASSORTMENT": "Sortiment",
  "ASSORTMENT_GROUP": "Sortimentsgruppe",
  "ASSORTMENT_HOME": "Sortiment Startseite",
  "ASSORTMENT_TYPE": "Sortiment Typ",
  "PRODUCT_ASSORTMENT_TYPE_FILTER_CUSTOMER_PRICE_LIST": "Kunden PL",
  "PRODUCT_ASSORTMENT_TYPE_FILTER_STANDARD_PRICE_LIST": "Standard PL",
  "PRODUCT_ASSORTMENT_TYPE_FILTER_WAREHOUSE": "Lager",
  "August": "August",
  "AUTHOR": "AUTHOR",
  "AVAILABILITY": "Verfügbarkeit",
  "AVAILABLE": "Verfügbar",
  "AVAILABLE_DATE": "AVAILABLE_DATE",
  "AVAILABLE_LATER": "Später verfügbar",
  "AVAILABLE_NOW": "AVAILABLE_NOW",
  "AVAILABLE_SHORT": "Ver.",
  "AVAILABLE_STOCK": "Verfügbarer Bestand",
  "B24_PRICAT_TEMPLATES": "B24_PRICAT_TEMPLATES",
  "BACK_IN_STOCK": "Wieder verfügbar",
  "BACK_TO_PRODUCTS": "Weiter einkaufen",
  "BACKEND_DOWN": "Fehler bei der Kommunikation mit dem Server",
  "BAD_EMAIL": "Ungültige E-Mail",
  "BALANCE": "Kontostand",
  "BASIC_DATA": "BASIC_DATA",
  "BASIC_INFO": "BASIC_INFO",
  "BASIC_SETTINGS": "Grundeinstellungen",
  "BEST_BEFORE": "Mindestens haltbar bis",
  "BEST_BEFORE_ASC": "Mindestens haltbar bis (aufsteigend)",
  "BEST_BEFORE_DESC": "Mindestens haltbar bis (absteigend)",
  "BILLING": "BILLING",
  "BILLING_ADDRESS": "Rechnungsanschrift",
  "BILLING_CUSTOMER": "Rechnungsempfänger",
  "BILLING_INFORMATION": "Abrechnungsdaten",
  "BLANK": "Fehlende Daten",
  "BONUS": "Mit Bonusberechtigung",
  "BONUS_POINTS": "Bonusbetrag",
  "BRAND": "MARKE",
  "BRAND_NAME": "Gung - Extend your ERP!",
  "BRAND_TITLE": "Gung - Extend your ERP!",
  "BREAKDOWN": "Störung",
  "BUY": "Kaufen",
  "BUY_ATLEAST": "BUY_ATLEAST",
  "BY_ITEM_CATEGORY": "Nach Artikelkategorie",
  "BY_ITEM_ID": "Nach Artikel-ID",
  "BY_MONTH": "Nach Monat",
  "BY_QUARTER": "Nach Quartal",
  "CACHES": "Caches",
  "CALC_DELIVERY_TIME": "voraussichtliche Lieferzeit",
  "CALC_IN_STOCK": "Wird im Lager berechnet",
  "CALL_FOR_PRICE": "CALL_FOR_PRICE",
  "CAMPAIGN": "Kampagne",
  "CAMPAIGN_CODE": "Welkomstcode",
  "CAMPAIGN_FILTER_OPTION": "Kampagnenprodukte anzeigen",
  "CAMPAIGN_LABEL": "Kampagne",
  "CAMPAIGN_PRICE": "CAMPAIGN_PRICE",
  "CAMPAIGN_PRODUCTS": "Kampagnenprodukte",
  "CANCEL": "Stornieren",
  "CANCEL_QUOTE": "Bestellung stornieren",
  "CANCEL_ORDER": "Bestellung stornieren",
  "CANCEL_REMAINING_QUANTITIES": "Restmenge stornieren und Bestellung als abgeschlossen markieren",
  "CANCELLATION_CODE": "CANCELLATION_CODE",
  "CANCELLED": "Storniert",
  "CAPACITY": "CAPACITY",
  "CART": "Warenkorb",
  "CART_ARTICLE_SKU_TOTAL": "CART_ARTICLE_SKU_TOTAL",
  "CART_CREATIONDATE": "Der Warenkorb wurde angelegt",
  "CART_HAS_BEEN_LOADED": "Der Warenkorb wurde abgerufen.",
  "CART_ID": "ID",
  "CART_ID_PLACEHOLDER": "ID wurde auf Save generiert",
  "CART_LOADED": "Der Warenkorb wurde abgerufen",
  "CART_LOADING": "Wird in den Warenkorb geladen ...",
  "CART_NAME": "Name",
  "CART_NAME_PLACEHOLDER": "Name – Damit können Sie diesen Warenkorb besser erkennen",
  "CART_SUBTOTAL": "Warenkorb-Zwischensumme",
  "CART_SUMMARY": "Warenkorb-Übersicht",
  "CART_WAS_SAVED_WITH_NAME": "Der Warenkorb wurde unter folgendem Namen gespeichert:",
  "CART_WILL_BE_DELETED": "CART_WILL_BE_DELETED",
  "CARTS": "Warenkörbe",
  "CARTS_FILTER": "Filter",
  "CARTS_FILTER_PLACEHOLDER": "Name, Tagging, Datum",
  "CATEGORIES": "Kategorien",
  "CATEGORY": "Kategorie",
  "CATEGORY_DESC": "Kategoriebeschreibung",
  "CATEGORY_ID": "Kategorie-ID",
  "CATEGORY_IMAGE_UPLOAD": "Upload von Kategoriebildern",
  "CATEGORY_MAPPING": "Kategoriezuordnung",
  "CATEGORY_VALUE": "Kategoriewert",
  "CHANGE_DATE_ON_ALL_ROWS": "Datum für alle Zeilen auswählen",
  "CHANGE_FROM_BEFORE": "CHANGE_FROM_BEFORE",
  "CHANGE_LANGUAGE": "Sprache ändern",
  "CHANGE_PRODUCT_INFORMATION": "Produktinformationen ändern",
  "CHANGE_SAVED_FAIL": "Beim Versuch, Ihre Änderungen zu speichern, ist ein Fehler aufgetreten",
  "CHANGE_SAVED_SUCCESS": "Änderung mit Erfolg gespeichert",
  "CHARACTERS": "CHARACTERS",
  "CHECK_CREDENTIALS": "Überprüfen Sie Ihre Anmeldedaten",
  "CHECKOUT": "Kasse",
  "CHECKOUT__DELIVERYDATE_DATE": "CHECKOUT__DELIVERYDATE_DATE",
  "CHECKOUT_CONDITIONS": "CHECKOUT_CONDITIONS",
  "CHECKOUT_CONDITIONS_LINK": "CHECKOUT_CONDITIONS_LINK",
  "CHECKOUT_NO_PRODUCTS": "CHECKOUT_NO_PRODUCTS",
  "CHECKOUT_NO_PRODUCTS_LINK": "CHECKOUT_NO_PRODUCTS_LINK",
  "CHECKOUT_ORDER": "Auftrag versenden",
  "CHOOSE_CONTACT": "CHOOSE_CONTACT",
  "CHOOSE_CUSTOMER": "CHOOSE_CUSTOMER",
  "CITY": "Stadt",
  "CLEAR": "Löschen",
  "CLEAR_ALL": "CLEAR_ALL",
  "CLEAR_ALL_ERROR_LOGS": "Alle Fehlerprotokolle löschen",
  "CLEAR_CART": "Warenkorb löschen",
  "CLEAR_CART_CONFIRM": "CLEAR_CART_CONFIRM",
  "CLEAR_CART_QUESTION": "Sind Sie sicher, dass Sie den Warenkorb löschen wollen?",
  "CLEAR_SELECTION": "CLEAR_SELECTION",
  "CLICK": "CLICK",
  "CLICK_OR_DRAG": "CLICK_OR_DRAG",
  "CLICK_TO_CLOSE": "Zum Schließen anklicken",
  "CLICK_TO_SELECT": "CLICK_TO_SELECT",
  "CLOSE": "Schließen",
  "CODE_FOR_RESET": "Reset-Code",
  "COLOR": "Farbe",
  "COLOR_DESCRIPTION": "COLOR_DESCRIPTION",
  "COLOR_NAME": "COLOR_NAME",
  "COLOR_SIZE": "Farbe/Größe",
  "COLORS": "Farben",
  "COLOUR": "COLOUR",
  "COLUMN_FORMAT": "Spaltenformat",
  "COMMENT": "Kommentar",
  "COMPACT": "Kompakt",
  "COMPACT_PRODUCT_INFO": "Kompakte Produktinfo",
  "COMPANY": "Unternehmen",
  "COMPANY_INFORMATION": "Firmeninformation",
  "COMPANY_NAME": "Firmenname",
  "COMPLETE": "Vollständig",
  "COMPLETE_FORM_BEFORE_SUBMIT": "Vor dem Bezahlen Formular ausfüllen",
  "COMPLETED": "Abgeschlossen",
  "COMPLETED_DATE": "Fertigstellungstermin",
  "CONDITIONS": "Bedingungen",
  "CONFIRM": "Bestätigen",
  "CONFIRM_DELETION": "Sind Sie sicher, dass Sie löschen möchten {{value}}",
  "CONFIRM_ORDER": "Bestellung bestätigen",
  "CONFIRM_USER_DELETION": "CONFIRM_USER_DELETION",
  "CONFIRMATION_EMAIL": "Bestätigungs-E-Mail(s)",
  "CONTACT": "Kontakt",
  "CONTACT_DETAILS": "Kontaktangaben",
  "CONTACT_FOR_PRICE": "Preise nennen wir Ihnen gern auf Anfrage",
  "CONTACT_OR_RESPONSIBLE": "Kontakt oder Verantwortlicher",
  "CONTACT_PERSON_ASSORTMENT": "Sortiment",
  "CONTACT_PERSON_DEPARTMENT": "Abteilung",
  "CONTACT_PERSON_NAME": "Kontaktperson Name",
  "CONTACT_PERSON_PHONE_MOBILE": "Mobiltelefon",
  "CONTACT_PERSON_PHONE1": "Telefon 1",
  "CONTACT_PERSON_PHONE2": "Telefon 2",
  "CONTACT_PERSON_PHONE3": "Telefon 3",
  "CONTACT_PERSON_PHONE4": "Telefon 4",
  "CONTACT_PERSON_PHONE5": "Telefon 5",
  "CONTACT_PERSON_POSITION": "Position",
  "CONTACT_PERSONS": "Kontaktpersonen",
  "CONTACT_SEARCH": "Suche nach Namen",
  "CONTACT_US": "Kontaktieren Sie uns",
  "CONTACTS": "Kontakte",
  "CONTAINS_PRODUCT": "Enthält Produkt",
  "CONTEXT_PARAMS_PREORDERTODATE": "CONTEXT_PARAMS_PREORDERTODATE",
  "CONTEXT_PARAMS_VALIDFROMDATE": "CONTEXT_PARAMS_VALIDFROMDATE",
  "CONTEXT_PARAMS_VALIDTODATE": "CONTEXT_PARAMS_VALIDTODATE",
  "CONTEXT_RECIPIENT": "CONTEXT_RECIPIENT",
  "CONTINUE_SHOPPING": "CONTINUE_SHOPPING",
  "CONTINUE_TO_BILLING": "CONTINUE_TO_BILLING",
  "CONTINUE_TO_SHIPPING": "CONTINUE_TO_SHIPPING",
  "CONVERT": "Umwandeln",
  "CONVERT_TO_ORDER": "In Auftrag umwandeln",
  "COPIED_TO_CLIPBOARD": "Ein Link wurde auf die Zwischenablage kopiert.",
  "COPY_TO_CART": "In den Warenkorb legen",
  "COPY_USER": "Kopiera användare",
  "CORPORATE_RESPONSIBILITY": "CORPORATE_RESPONSIBILITY",
  "COUNT": "Zählen",
  "COUNTRY": "Land",
  "COVER": "Startseite",
  "CREATE": "Anlegen",
  "CREATE_COPY": "CREATE_COPY",
  "CREATE_CUSTOMER": "Kunden anlegen",
  "CREATE_LINESHEET": "Verkaufsseite anlegen",
  "CREATE_NEW": "Neu erstellen",
  "CREATE_NEW_CONCEPT": "CREATE_NEW_CONCEPT",
  "CREATE_NEW_CUSTOMER": "CREATE_NEW_CUSTOMER",
  "CREATE_NEW_GUNG_FLOW": "CREATE_NEW_GUNG_FLOW",
  "CREATE_ORDER": "CREATE_ORDER",
  "CREATE_PICKLIST": "Kommissionierliste anlegen",
  "CREATE_PRODUCT_MASTER": "CREATE_PRODUCT_MASTER",
  "CREATE_QUOTATION": "CREATE_QUOTATION",
  "CREATE_QUOTE": "Angebot erstellen",
  "CREATE_ROW_WITH_QUANTITY": "CREATE_ROW_WITH_QUANTITY",
  "CREATE_USER": "CREATE_USER",
  "CREATED_BY": "Erstellt von",
  "CREATED_DATE": "Erstellungsdatum",
  "CREATEMODELCONTEXT_PREFIX": "CREATEMODELCONTEXT_PREFIX",
  "CREATEUSER_ASSORTMENT": "Sortiment",
  "CREATEUSER_EMAIL": "E-Mail",
  "CREATEUSER_MANAGEDCOMPANYIDS[0]": "Kunden-ID",
  "CREATEUSER_NAME": "Name",
  "CREATEUSER_PASSWORD": "CREATEUSER_PASSWORD",
  "CREATEUSER_USERNAME": "Benutzername",
  "CREATION_DATE": "CREATION_DATE",
  "CREATION_DATE_FILTER": "CREATION_DATE_FILTER",
  "CREDIT_BLOCKED": "CREDIT_BLOCKED",
  "CREDIT_CHECK_OK": "Bonitätsprüfung OK",
  "CREDIT_CKECK_NOT_OK": "Bonitätsprüfung nicht OK",
  "CREDIT_LIMIT": "CREDIT_LIMIT",
  "CRON": "Cron",
  "CS0_COMNR": "CS0_COMNR",
  "CS1_COMNR": "Telefon",
  "CS2_COMNR": "CS2_COMNR",
  "CS3_COMNR": "CS3_COMNR",
  "CS4_COMNR": "Handynummer",
  "CS5_COMNR": "CS5_COMNR",
  "CS6_COMNR": "CS6_COMNR",
  "CS7_COMNR": "CS7_COMNR",
  "CS8_COMNR": "E-Mail",
  "CURRENCY": "Währung",
  "CURRENT_LIST": "Derzeit",
  "CURRENT_YEAR": "CURRENT_YEAR",
  "CURRENTLY": "Aktuelle Aufstellung",
  "CUSTOM_DELIVERY_ADDRESS": "CUSTOM_DELIVERY_ADDRESS",
  "CUSTOM_EXPORTS": "Benutzerdefinierte Exporte",
  "CUSTOM_PAGES_MENU_TITLE": "CUSTOM_PAGES_MENU_TITLE",
  "CUSTOMER": "Kunde",
  "CUSTOMER_ACCOUNT": "CUSTOMER_ACCOUNT",
  "CUSTOMER_ACTIVITY_CONTACT": "Kontakt",
  "CUSTOMER_ACTIVITY_RESPONIBLE": "Verantwortlicher",
  "CUSTOMER_ACTIVITY_STARTDATE": "Startdatum",
  "CUSTOMER_ACTIVITY_STARTTIME": "Startzeit",
  "CUSTOMER_ACTIVITY_TEXT": "Text",
  "CUSTOMER_CHANGE": "Kunden wechseln",
  "CUSTOMER_CONTACT": "Kundenkontakt",
  "CUSTOMER_CONTACT_EMAIL": "CUSTOMER_CONTACT_EMAIL",
  "CUSTOMER_CONTACTS": "CUSTOMER_CONTACTS",
  "CUSTOMER_CONTINUE": "Bleiben Sie auf dem Laufenden",
  "CUSTOMER_DETAILS": "Kundendaten",
  "CUSTOMER_DISCOUNT": "Kundenrabatt",
  "CUSTOMER_ID": "Kunden-ID",
  "CUSTOMER_INFO": "Kundeninformationen",
  "CUSTOMER_NAME": "Kundenname",
  "CUSTOMER_NET_PRICE": "CUSTOMER_NET_PRICE",
  "CUSTOMER_ORDER_DETAILS": "Kundenauftragsdaten",
  "CUSTOMER_REFERENCE": "Kundenreferenz",
  "CUSTOMER_SEARCH": "Suche nach Namen, ID, Adresse",
  "CUSTOMER_SERVICE": "CUSTOMER_SERVICE",
  "CUSTOMER_SERVICE_EMAIL": "CUSTOMER_SERVICE_EMAIL",
  "CUSTOMER_TRACKING_EMAIL_UPDATE_ERROR": "CUSTOMER_TRACKING_EMAIL_UPDATE_ERROR",
  "CUSTOMER_TRACKING_EMAIL_UPDATE_SUCCESS": "CUSTOMER_TRACKING_EMAIL_UPDATE_SUCCESS",
  "CUSTOMER_TYPE": "CUSTOMER_TYPE",
  "CUSTOMERS": "Kunden",
  "CUSTOMERS_TOTAL": "CUSTOMERS_TOTAL",
  "DATA": "Daten",
  "DATE": "Datum",
  "DAYS": "Tage",
  "DE_TEXT": "Deutscher Text",
  "December": "December",
  "DEFAULT": "Normal",
  "DEFAULT_HOME": "Standard-Startseite",
  "DEFAULT_LANGUAGE": "Eingestellte Standardsprache",
  "DEFAULT_PRICAT": "DEFAULT_PRICAT",
  "DELETE": "Löschen",
  "DELETE_CART": "Warenkorb löschen",
  "DELETE_USER": "DELETE_USER",
  "DELIVER": "Liefern",
  "DELIVER_ALL_AND_FINISH": "Alle Zeilen liefern und beenden",
  "DELIVER_ALL_AND_FINISH_FINAL": "Alle Zeilen liefern, kein Auftragsrückstand",
  "DELIVER_NOW": "Jetzt liefern",
  "DELIVERED": "Geliefert",
  "DELIVERED_0-12": "Geliefert 0–12 Monate",
  "DELIVERED_12-24": "Geliefert 12–24 Monate",
  "DELIVERED_QUANTITY": "Liefermenge",
  "DELIVERED_VALUE": "Lieferwert",
  "DELIVERIES": "Lieferungen",
  "DELIVERY": "Lieferung",
  "DELIVERY_ADDRESS": "Lieferadresse",
  "DELIVERY_CUSTOMER": "Kunde",
  "DELIVERY_DATE": "Lieferdatum",
  "DELIVERY_ID": "Liefernummer",
  "DELIVERY_INFORMATION": "Lieferungsinformationen",
  "DELIVERY_LOCATION": "DELIVERY_LOCATION",
  "DELIVERY_METHOD": "Lieferungsmethode",
  "DELIVERY_NOTE": "Lieferschein",
  "DELIVERY_OPTIONS": "Lieferoptionen",
  "DELIVERY_RETURNS": "DELIVERY_RETURNS",
  "DELIVERY_STATUS": "Lieferstatus",
  "DELIVERY_TERM": "Lieferfrist",
  "DELIVERY_TERMS": "Lieferbedingung",
  "DELIVERY_TRACKING": "Sendungsverfolgung",
  "DELIVERY_TRACKING_LINK": "Link zur Sendungsverfolgung",
  "DELIVERY_WEEK": "Lieferwoche",
  "DELIVERYPERIOD": "DELIVERYPERIOD",
  "DELIVERYPERIOD_DELIVERYSTARTDATE": "DELIVERYPERIOD_DELIVERYSTARTDATE",
  "DELIVERYPERIOD_NAME": "DELIVERYPERIOD_NAME",
  "DELIVERYROWS": "DELIVERYROWS",
  "DEPTH": "DEPTH",
  "DESCRIPTION": "Beschreibung",
  "DESELECT": "DESELECT",
  "DESELECT_ALL": "Alles zurücksetzen",
  "DESELECT_ALL_FILTERED_ITEMS": "DESELECT_ALL_FILTERED_ITEMS",
  "DESELECT_ALL_ITEMS": "DESELECT_ALL_ITEMS",
  "DESIRED_PASSWORD": "Wunschkennwort",
  "DESKTOP_ONLY": "DESKTOP_ONLY",
  "DETAILED_CHECKOUT": "Detaillierter Kassenstand",
  "DETAILS": "Details",
  "DETAILS_AND_CONDITIONS": "Details und Konditionen",
  "DIMENSION": "Größe",
  "DIMENSIONS": "Größen",
  "DISCOUNT": "Rabatt",
  "DISCOUNT_1": "DISCOUNT_1",
  "DISCOUNT_2": "DISCOUNT_2",
  "DISCOUNT_CODE": "Rabatt-Code",
  "DISCOUNT_CODE_APPLY": "Anwenden",
  "DISCOUNT_CODE_PLACEHOLDER": "Rabatt-Code eingeben",
  "DISCOUNT_CRITERIA": "DISCOUNT_CRITERIA",
  "DISCOUNT_DEAL": "DISCOUNT_DEAL",
  "DISCOUNT_TERM": "Skontofrist",
  "DISCOUNT_TIER": "DISCOUNT_TIER",
  "DISPATCH_METHOD": "Lieferart",
  "DISPATCH_MODE": "Lieferart",
  "DISPATCH_TERMS": "Lieferbedingungen",
  "DISPLAY_BATCH_PREFIX": "Show",
  "DISTRICT": "Kreis",
  "DK_TEXT": "Dänischer Text",
  "DOCUMENT_TEMPLATE_EDITOR": "DOCUMENT_TEMPLATE_EDITOR",
  "DOCUMENTS": "Dokumente",
  "DOCUMENTS_ARCHIVE": "DOCUMENTS_ARCHIVE",
  "DONATION": "DONATION",
  "DONE": "Fertig",
  "DOWNLOAD": "Herunterladen",
  "DOWNLOAD_DEFAULT_COVER": "Laden Sie die Standardabdeckung herunter",
  "DOWNLOAD_DELIVERY": "DOWNLOAD_DELIVERY",
  "DOWNLOAD_DELIVERY_NOTE": "Lieferungs-PDF herunterladen",
  "DOWNLOAD_DOCUMENT": "Dokument herunterladen",
  "DOWNLOAD_ORDER_PDF": "DOWNLOAD_ORDER_PDF",
  "DOWNLOAD_PICKLIST": "Kommissionierliste herunterladen",
  "DOWNLOAD_QUOTE_PDF": "DOWNLOAD_QUOTE_PDF",
  "DOWNLOADS": "Downloads",
  "DUE_DATE": "Ablaufdatum",
  "E_MAIL_RECIPIENTS": "E-Mail-Empfänger",
  "EAN": "EAN-Code",
  "EAST_SECTOR": "EAST_SECTOR",
  "ECONOMY": "Finanzierung",
  "EDIT": "Bearbeiten",
  "EDIT_ACTIVITY": "Aktivität bearbeiten",
  "EDIT_CART": "Warenkorb bearbeiten",
  "EDIT_CONCEPT": "EDIT_CONCEPT",
  "EDIT_CONTACT": "Kontakt bearbeiten",
  "EDIT_DIMENSION": "Maße bearbeiten",
  "EDIT_LOCATION_PROPERTY": "Lagerinformationen bearbeiten",
  "EDIT_ORDER_ROW": "Auftragszeile bearbeiten",
  "EDIT_ORDERROW": "EDIT_ORDERROW",
  "EDIT_USER": "Redigera användare",
  "EDIT_USER_GROUPS_DESCRIPTION": "EDIT_USER_GROUPS_DESCRIPTION",
  "EDITOR_FOR_ADMIN": "Der Editor unten kann nur von einem Administrator benutzt werden",
  "EDUCATION": "Ausbildung",
  "ELEMENT_EDITOR": "Elementeditor",
  "EMAIL": "E-Mail",
  "EMAIL_BITS": "EMAIL_BITS",
  "EMAIL_CONFIRMATION": "E-Mail-Bestätigung",
  "EMAIL_NOT_FOUND": "Ihre E-Mail kann nicht gefunden werden, überprüfen Sie bitte die Schreibweise und versuchen Sie es erneut.",
  "EMAIL_PLACEHOLDER": "Ihr.Name@Unternehmen.com",
  "EMAIL_RECIPIENT": "Mailadresse Empfänger",
  "EMAIL_RECIPIENT_PLACEHOLDER": "Empfänger@domain.com",
  "EMAIL_SEND": "Senden",
  "EMAIL_USERNAME": "Mailadresse (Verwendung als Benutzername)",
  "EMAIL_TEXT": "E-Mail-Text",
  "EMPLOYEES": "Mitarbeiter",
  "EMPTY_CART": "Warenkorb leeren",
  "EMPTY_SEARCH_MESSAGE": "Leider konnten wir keine Einträge finden, die Ihren Suchkriterien entsprechen",
  "EN_DESCRIPTION": "Englische Beschreibung",
  "ENABLED": "Aktiviert",
  "END_OF_LIFE": "END_OF_LIFE",
  "ENTER_ADDRESS": "Anschrift eingeben",
  "ENTER_ADDRESS1": "Adresse eingeben",
  "ENTER_ADDRESS2": "Adresse 2 eingeben",
  "ENTER_CITY": "Ort eingeben",
  "ENTER_COMPANY_ID": "Kunden-ID eingeben",
  "ENTER_COMPANY_NAME": "Firmennamen eingeben",
  "ENTER_CONDITION_TO_MATCH": "ENTER_CONDITION_TO_MATCH",
  "ENTER_CONTACT": "Kontakt eingeben",
  "ENTER_COUNTRY": "Land eingeben",
  "ENTER_EMAIL": "E-Mail eingeben",
  "ENTER_EMAIL_TO_SEND_EXPORT": "ENTER_EMAIL_TO_SEND_EXPORT",
  "ENTER_MESSAGE": "Schreiben Sie uns eine Nachricht",
  "ENTER_NAME": "Namen eingeben",
  "ENTER_NAME_OR_ID": "Name oder ID als Filter",
  "ENTER_ORG_NO": "USt-ID eingeben",
  "ENTER_PASSWORD": "Kennwort eingeben",
  "ENTER_PHONE": "Telefonnr. eingeben",
  "ENTER_PHONE_NUMBER": "Telefonnummer eingeben",
  "ENTER_REGION": "Region eingeben",
  "ENTER_RESET_CODE": "Reset-Code eingeben",
  "ENTER_RESET_TOKEN": "Reset-Token eingeben",
  "ENTER_USERNAME": "Benutzernamen eingeben",
  "ENTER_VAT": "MwSt. eingeben",
  "ENTER_YOUR_EMAIL": "Ihre E-Mail eingeben",
  "ENTER_YOUR_NAME": "ENTER_YOUR_NAME",
  "ENTER_YOUR_PO": "ENTER_YOUR_PO",
  "ENTER_ZIP": "Postleitzahl eingeben",
  "EOK": "EOK",
  "ERROR": "ERROR",
  "ERROR_DELIVERY_DATE_UNDEFINED": "ERROR_DELIVERY_DATE_UNDEFINED",
  "ERROR_SET_PASSWORD": "ERROR_SET_PASSWORD",
  "EST_DISPATCH_DATE": "Voraussichtliches Versanddatum",
  "ESTIMATED_DELIVERY_DATE": "Voraussichtliches Lieferdatum",
  "ETIQUETTE_TYPE": "Label Typ",
  "EXCEL_TO_ORDER": "Excel zu bestellen",
  "EXECUTING": "Executing",
  "EXECUTIONS": "Hinrichtungen",
  "EXPAND_FILTERED_RESULT_1": "EXPAND_FILTERED_RESULT_1",
  "EXPAND_FILTERED_RESULT_2": "EXPAND_FILTERED_RESULT_2",
  "EXPAND_FILTERED_RESULT_3": "EXPAND_FILTERED_RESULT_3",
  "EXPAND_FILTERED_RESULT_4": "EXPAND_FILTERED_RESULT_4",
  "EXPAND_FILTERED_RESULT_5": "EXPAND_FILTERED_RESULT_5",
  "EXPECTED_DATE": "EXPECTED_DATE",
  "EXPECTED_DELIVERY_DATE": "Erwartetes Lieferdatum",
  "EXPECTED_DELIVERY_DAYS": "Voraussichtliche Tage bis zur Lieferung",
  "EXPECTED_VALUE": "EXPECTED_VALUE",
  "EXPORT_CSV": "Export CSV",
  "EXPORT_DATA_FEEDBACK": "EXPORT_DATA_FEEDBACK",
  "EXPORT_GARP_AGA": "EXPORT_GARP_AGA",
  "EXPORT_GARP_AGT": "EXPORT_GARP_AGT",
  "EXPORT_IMG": "Export Bilde",
  "EXPORT_LINESHEET": "Export Verkaufsseite",
  "EXPORT_LINESHEET_PDF_READ_OR_EXCEL_EDIT": "Linesheet als PDF (schreibgeschützt, druckfreundlich) oder Excel (bearbeitbar, Import möglich) exportieren",
  "EXPORT_PIM_TEXT_AND_DESCRIPTIONS": "EXPORT_PIM_TEXT_AND_DESCRIPTIONS",
  "EXPORT_PLACEHOLDER_IDS_ONLY": "EXPORT_PLACEHOLDER_IDS_ONLY",
  "EXPORT_PRICAT": "EXPORT_PRICAT",
  "EXPORT_PRODUCT_INFO": "Export Produkten data",
  "EXPORT_PRODUCT_STOCK": "Export bestand",
  "EXPORT_TEMPLATES": "EXPORT_TEMPLATES",
  "EXPORT_XLSX": "Export XLSX",
  "EXPRESSION": "EXPRESSION",
  "EXTERNAL_TEXT": "Externer Text",
  "EXTRA_DISCOUNT": "EXTRA_DISCOUNT",
  "EXTRA_FREIGHT_ADDED": "Umfasst der Auftrag Bestellware, werden Versandkosten berechnet.",
  "FAILED": "Gescheitert",
  "FAILED_UPLOADS": "FAILED_UPLOADS",
  "FALSE": "Nein",
  "FAQ": "FAQ",
  "FAQ_ACCESS_PORTAL_QUERY": "FAQ_ACCESS_PORTAL_QUERY",
  "FAQ_ACCESS_PORTAL_RESPONSE": "FAQ_ACCESS_PORTAL_RESPONSE",
  "FAQ_ACCOUNT_INFO_QUERY": "FAQ_ACCOUNT_INFO_QUERY",
  "FAQ_ACCOUNT_INFO_RESPONSE": "FAQ_ACCOUNT_INFO_RESPONSE",
  "FAQ_CATALOUGE_QUERY": "FAQ_CATALOUGE_QUERY",
  "FAQ_CATALOUGE_RESPONSE": "FAQ_CATALOUGE_RESPONSE",
  "FAQ_FORGOT_PASSWORD_QUERY": "FAQ_FORGOT_PASSWORD_QUERY",
  "FAQ_FORGOT_PASSWORD_RESPONSE": "FAQ_FORGOT_PASSWORD_RESPONSE",
  "FAQ_INVOICE_QUERY": "FAQ_INVOICE_QUERY",
  "FAQ_INVOICE_RESPONSE": "FAQ_INVOICE_RESPONSE",
  "FAQ_MES_DONNEES": "FAQ_MES_DONNEES",
  "FAQ_ORDER_HISTORY_QUERY": "FAQ_ORDER_HISTORY_QUERY",
  "FAQ_ORDER_HISTORY_RESPONSE": "FAQ_ORDER_HISTORY_RESPONSE",
  "FAQ_ORDER_MODIFICATION_QUERY": "FAQ_ORDER_MODIFICATION_QUERY",
  "FAQ_ORDER_MODIFICATION_RESPONSE": "FAQ_ORDER_MODIFICATION_RESPONSE",
  "FAQ_ORDER_PLACEMENT_QUERY": "FAQ_ORDER_PLACEMENT_QUERY",
  "FAQ_ORDER_PLACEMENT_RESPONSE": "FAQ_ORDER_PLACEMENT_RESPONSE",
  "FAQ_ORDER_TRACKING_QUERY": "FAQ_ORDER_TRACKING_QUERY",
  "FAQ_ORDER_TRACKING_RESPONSE": "FAQ_ORDER_TRACKING_RESPONSE",
  "FAQ_PAYMENT_POLICY_QUERY": "FAQ_PAYMENT_POLICY_QUERY",
  "FAQ_PAYMENT_POLICY_RESPONSE": "FAQ_PAYMENT_POLICY_RESPONSE",
  "FAQ_PAYMENT_SAMPLE_QUERY": "FAQ_PAYMENT_SAMPLE_QUERY",
  "FAQ_PAYMENT_SAMPLE_RESPONSE": "FAQ_PAYMENT_SAMPLE_RESPONSE",
  "FAQ_PAYMENT_TERMS_QUERY": "FAQ_PAYMENT_TERMS_QUERY",
  "FAQ_PAYMENT_TERMS_RESPONSE": "FAQ_PAYMENT_TERMS_RESPONSE",
  "FAQ_RETURNS_QUERY": "FAQ_RETURNS_QUERY",
  "FAQ_RETURNS_RESPONSE": "FAQ_RETURNS_RESPONSE",
  "FAQ_TECH_FITTING_QUERY": "FAQ_TECH_FITTING_QUERY",
  "FAQ_TECH_FITTING_RESPONSE": "FAQ_TECH_FITTING_RESPONSE",
  "FAQ_TECH_MEASUREMENT_QUERY": "FAQ_TECH_MEASUREMENT_QUERY",
  "FAQ_TECH_MEASUREMENT_RESPONSE": "FAQ_TECH_MEASUREMENT_RESPONSE",
  "FAQ_TIME_EST_QUERY": "FAQ_TIME_EST_QUERY",
  "FAQ_TIME_EST_RESPONSE": "FAQ_TIME_EST_RESPONSE",
  "FAST_SEARCH_HOME": "Schnelle Suche Startseite",
  "FAST_SEARCH_PRODUCT": "Schnelle Suchprodukte",
  "FASTSEARCH": "FASTSEARCH",
  "FAVORITE_LIST": "Favoritenliste",
  "FAVORITE_LIST_NOT_FOUND": "Es wurde keine Favoritenliste gefunden.",
  "FAVORITE_LISTS": "Favoritenlisten",
  "FAVOURITES": "Favoriten",
  "FAX": "Fax",
  "FEATURES": "FEATURES",
  "February": "February",
  "FEW_LEFT": "Nur noch wenige verfügbar",
  "FH_BETKOD": "Zahlungsbedingungen",
  "FH_BETKOD_10": "10 Tage netto",
  "FH_BETKOD_12": "120 Tage netto",
  "FH_BETKOD_14": "14 Tage netto",
  "FH_BETKOD_15": "15 Tage netto",
  "FH_BETKOD_20": "20 Tage netto",
  "FH_BETKOD_21": "21 Tage netto",
  "FH_BETKOD_30": "30 Tage netto",
  "FH_BETKOD_45": "45 Tage netto",
  "FH_BETKOD_75": "75 Tage netto",
  "FH_BETKOD_90": "90 Tage netto",
  "FH_FAKTDAT": "Rechnungsdatum",
  "FH_FAKTDAT_FILTER_PLACEHOLDER": "Rechnungsdatum",
  "FH_FAKTFFDAT": "Fälligkeitsdatum",
  "FH_FAKTFFDAT_FILTER_PLACEHOLDER": "Fälligkeitsdatum",
  "FH_FAKTSTKOD": "Rechnungsstatus",
  "FH_FAKTSTKOD_-1": "Vorl. Rechn. (in Bearbeitung)",
  "FH_FAKTSTKOD_-2": "Vorschau (nicht in Rechn. gestellt)",
  "FH_FAKTSTKOD_0": "In Rechnung gestellt",
  "FH_FAKTSTKOD_3": "Wird geprüft",
  "FH_FAKTSTKOD_5": "Bezahlt",
  "FH_FAKTSTKOD_9": "Getilgt",
  "FH_FAKTURATYP": "FH_FAKTURATYP",
  "FH_KUNDREF2": "Ihr Zeichen",
  "FH_ORDERNR": "Auftragsnr.",
  "FH_VALKOD": "Währungskürzel",
  "FH_VB_FAKTSUM": "Betrag, einschl. MwSt.",
  "FH_VBFAKTINBBEL": "Eingezahlt",
  "FH_VBFAKTMOMS": "MwSt.",
  "FH_VBFAKTREST": "Noch ausstehender Betrag",
  "FH_VBFAKTTOTUMOMS": "Betrag, ohne MwSt.",
  "FH_VREF": "Unser Zeichen",
  "FILE_NOT_UPLOADED": "FILE_NOT_UPLOADED",
  "FILL_ALL_REQUIRED_FIELDS": "FILL_ALL_REQUIRED_FIELDS",
  "FILL_OPTION_IMAGE": "Optionsbild ausfüllen",
  "FILL_OPTION_LINK": "Optionslink ausfüllen",
  "FILL_OPTION_TEXT": "Optionstext ausfüllen",
  "FILTER_EXPLANATION": "Nach ID oder Teil einer Beschreibung filtern",
  "FILTERABLE_SALES_INFORMATION": "FILTERABLE_SALES_INFORMATION",
  "FILTERS": "Filter",
  "FINAL_FREIGHT_CONDITIONS": "FINAL_FREIGHT_CONDITIONS",
  "FINANCIAL_GROUP": "Finanzgruppe",
  "FINISH_WITHOUT_DELIVERING_REST": "Beenden ohne Liefern muss noch ausgeführt werden",
  "FIRST_AVAILABLE_DATE": "FIRST_AVAILABLE_DATE",
  "FIRST_NAME": "FIRST_NAME",
  "FIT": "FIT",
  "FIVE_MONTHS": "FIVE_MONTHS",
  "FLOW": "FLOW",
  "FLOWKEY": "FLOWKEY",
  "FOLLOW_US": "FOLLOW_US",
  "FOR": "für",
  "FORGOT_PASSWORD": "Passwort vergessen",
  "FORM_CONTAINS_ERROR": "FORM_CONTAINS_ERROR",
  "FORM_ERROR_REQUIRED": "FORM_ERROR_REQUIRED",
  "FORM_SUBMIT_ERROR_CONTACT_GUNG": "FORM_SUBMIT_ERROR_CONTACT_GUNG",
  "FR_DISTRKOD": "Gebiet",
  "FR_EUMOMSNR": "USt-ID",
  "FR_FTGNAMN": "Unternehmen",
  "FR_FTGNR": "Lieferadresse",
  "FR_FTGPOSTADR1": "c/o-Anschrift",
  "FR_FTGPOSTADR2": "Adresse",
  "FR_FTGPOSTADR3": "Ort",
  "FR_FTGPOSTADR4": "Büroanschrift",
  "FR_FTGPOSTADR5": "Lieferadresse",
  "FR_FTGPOSTLEVADR3": "Ort (Lieferung)",
  "FR_FTGPOSTNR": "PLZ",
  "FR_LANDSKOD": "Ländercode",
  "FR_ORGNR": "Org. Nr.",
  "FREIGHT": "Fracht",
  "FREIGHT_ADDED": "FREIGHT_ADDED",
  "FREIGHT_CHARGE": "Versandkosten",
  "FREIGHT_EMAIL": "FREIGHT_EMAIL",
  "FREIGHT_FREE": "FREIGHT_FREE",
  "FREIGHT_INFORMATION": "FREIGHT_INFORMATION",
  "FREIGHT_UNKNOWN": "FREIGHT_UNKNOWN",
  "FREIGHT_WILL_BE_ADDED": "Zzgl. Versandkosten",
  "FROZEN_PRODUCTS": "Tiefkühlprodukte",
  "FT_ARTBESKR": "Name",
  "FT_ARTNR": "Art.-Nr.",
  "FT_FAKTLEVANTAL": "Anzahl",
  "FT_FAKTRADNR": "Zeile",
  "FT_VALKOD": "Währung",
  "FT_VB_FAKTRADSUMMA": "Gesamt",
  "FT_VB_PRIS": "Preis, Währung",
  "FT_VBFAKTMOMS": "MwSt.",
  "FTGNAMN": "Kundenname",
  "FTGPOSTADR2": "Adresse",
  "FTGPOSTADR3": "Stadt",
  "FTGPOSTNR": "Postleitzahl",
  "FULL_PRODUCT_INFO": "Vollständige Produktinfo",
  "FUTURE": "Künftig",
  "FUTURE_STOCK": "Zukünftiger Bestand",
  "GALLERY": "Galerie",
  "GARP_OGF_TX1": "Text 1",
  "GARP_OGF_TX2": "Text 2",
  "GARP_OGF_TX3": "Text 3",
  "GENDER": "GENDER",
  "GENERATE": "Generieren",
  "GENERATE_B24_PRICAT": "GENERATE_B24_PRICAT",
  "GENERIC_LIST_TEMPLATES": "GENERIC_LIST_TEMPLATES",
  "GENRE": "GENRE",
  "GENRE_GROUP": "GENRE_GROUP",
  "GET_ACCOUNT": "Bitte beachten Sie, dass bei uns nur zugelassene Zahnärzte/Dentalhygieniker Einkäufe tätigen können. An Privatpersonen verkaufen wir leider keine Produkte.",
  "GO_TO_ALL_PRODUCTS": "GO_TO_ALL_PRODUCTS",
  "GO_TO_CHECKOUT": "Zur Kasse gehen",
  "GODSMARKE": "GODSMARKE",
  "GOODS_MARK": "GOODS_MARK",
  "GOODSMARK": "Bewertung",
  "GROSS_PRICE": "GROSS_PRICE",
  "GROSS_WEIGHT": "GROSS_WEIGHT",
  "GROUPS": "GROUPS",
  "GUNG_ADD_ACTIVITY": "GUNG_ADD_ACTIVITY",
  "GUNG_ADD_CONTACT": "GUNG_ADD_CONTACT",
  "GUNG_ADD_PHONE_CALL": "GUNG_ADD_PHONE_CALL",
  "GUNG_COOKIE_LINK": "/#/p/cookies-de",
  "GUNG_COOKIE_LINK_TEXT": "Weiterlesen",
  "GUNG_COOKIE_OK": "OK",
  "GUNG_COOKIE_TEXT": "Wir verwenden Cookies, um Ihnen beim Besuch unserer Website das optimale Nutzererlebnis bieten zu können. Durch weitere Nutzung unserer Website erklären Sie sich mit den Nutzerbedingungen einverstanden.",
  "GUNG_EDIT_ACTIVITY": "GUNG_EDIT_ACTIVITY",
  "GUNG_EDIT_CONTACT": "GUNG_EDIT_CONTACT",
  "GUNG_FLOW": "Flow",
  "GUNG_FLOWS": "Flows",
  "GUNG_ITEM_LIST_LOAD_MORE": "Zum Anzeigen {{limit}} der {{total}} Artikel, klicken, um mehr {{batchSize}} anzuzeigen",
  "GUNG_MISSING_QUANTITIES_LATER": "Die Bestellung enthält Artikel, die derzeit nicht vorrätig sind. Die Bestellung wird verschickt, wenn alle Artikel verfügbar sind.",
  "GUNG_MISSING_QUANTITIES_LIMIT": "Wenn Sie fortfahren, werden die Mengenangaben an die verfügbaren Mengen angepasst.",
  "GUNG_PRODUCT_ISAVAILABLE": "GUNG_PRODUCT_ISAVAILABLE",
  "GUNG_PRODUCT_LIST_LOAD_MORE": "{{limit}} von {{total}} angezeigt. Klicken, um mehr anzuzeigen",
  "GUNG_USER": "Gong-Nutzer",
  "GUNGDATA__TEXT": "GUNGDATA__TEXT",
  "GUNGDATA_AKTIVITET": "Aktivität",
  "GUNGDATA_ANSVARIG": "Verantwortlicher",
  "GUNGDATA_BEFATTNING": "Position",
  "GUNGDATA_EMAIL": "E-Mail",
  "GUNGDATA_END": "Ende",
  "GUNGDATA_KONTAKTPERSON": "Kontaktperson",
  "GUNGDATA_MOBILTFN": "Mobiltelefon",
  "GUNGDATA_POSITION": "Position",
  "GUNGDATA_PRIVATTFN": "Private Telefonnr.",
  "GUNGDATA_START": "Start",
  "GUNGDATA_STATUS": "Status",
  "GUNGDATA_WWW": "Homepage",
  "GUNGFLOW_DELIVERYENDDATE": "GUNGFLOW_DELIVERYENDDATE",
  "GUNGFLOW_DELIVERYSTARTDATE": "Frühestmöglicher Liefertermin",
  "GUNGFLOW_DISPLAY_ONLY": "GUNGFLOW_DISPLAY_ONLY",
  "GUNGFLOW_ID": "Flow-ID",
  "GUNGFLOW_INVENTORY": "GUNGFLOW_INVENTORY",
  "GUNGFLOW_IS_DEFAULT": "Vorwahl-Flow",
  "GUNGFLOW_IS_PREORDER": "Vorbestellungs-Flow",
  "GUNGFLOW_IS_RETURN": "Retouren-Flow",
  "GUNGFLOW_ISACTIVE_GROUPS": "GUNGFLOW_ISACTIVE_GROUPS",
  "GUNGFLOW_ISACTIVE_ROLES": "Flow ist aktiv bis:",
  "GUNGFLOW_NAME": "Flow-Name",
  "GUNGFLOW_ORDERENDDATE": "GUNGFLOW_ORDERENDDATE",
  "GUNGFLOW_ORDERSTARTDATE": "GUNGFLOW_ORDERSTARTDATE",
  "GUNGFLOW_REQUIRE_AVAILABILITY": "GUNGFLOW_REQUIRE_AVAILABILITY",
  "GUNGFLOW_USE_AVAILABILITIES": "GUNGFLOW_USE_AVAILABILITIES",
  "HAS_CONTENT": "Hat Artikel im Warenkorb",
  "HAS_DESCRIPTION_EN": "HAS_DESCRIPTION_EN",
  "HAS_DESCRIPTION_SE": "HAS_DESCRIPTION_SE",
  "HAS_IMAGE": "HAS_IMAGE",
  "HEADER": "HEADER",
  "HEADER_TEXT": "HEADER_TEXT",
  "HEIGHT": "HEIGHT",
  "HERE": "HERE",
  "HEX": "HEX",
  "HEXDEFAULT": "HEXDEFAULT",
  "HI_RES_IMAGES": "Abbildungen",
  "HIDE": "Ausblenden",
  "HIDE_CART": "HIDE_CART",
  "HIDE_CATEGORIES": "Kategorien verbergen",
  "HIDE_SIZES": "Größen verbergen",
  "HINT_MAX_LENGTH": "HINT_MAX_LENGTH",
  "HINT_MIN_LENGTH": "HINT_MIN_LENGTH",
  "HINT_REQUIRED": "HINT_REQUIRED",
  "HISTORY": "HISTORY",
  "HL_VALIDATION_MODAL_TEXT": "HL_VALIDATION_MODAL_TEXT",
  "HOME": "STARTSEITE",
  "HTML_OUTPUT": "HTML Ausgabe",
  "HYGENIST": "Dentalhygieniker",
  "ID": "ID",
  "ID_ASC": "ID_ASC",
  "ID_DATE_OR_ADDRESS": "ID, Datum oder Anschrift",
  "ID_DESC": "ID_DESC",
  "IF_ERP_SYNC": "IF_ERP_SYNC",
  "IGNORE_ERRORS": "IGNORE_ERRORS",
  "IMAGE": "Bild",
  "IMAGE[$CTRL_LANGUAGECODE]_CATEGORY": "IMAGE[$CTRL_LANGUAGECODE]_CATEGORY",
  "IMAGE[$CTRL_LANGUAGECODE]_DESCRIPTION": "IMAGE[$CTRL_LANGUAGECODE]_DESCRIPTION",
  "IMAGE[$CTRL_LANGUAGECODE]_TAG": "IMAGE[$CTRL_LANGUAGECODE]_TAG",
  "IMAGE_BANK": "IMAGE_BANK",
  "IMAGE_BANK_FILTER": "IMAGE_BANK_FILTER",
  "IMAGE_BANK_SELECTION": "IMAGE_BANK_SELECTION",
  "IMAGE_BANK_TOGGLE_ALL": "IMAGE_BANK_TOGGLE_ALL",
  "IMAGE_BINDING": "IMAGE_BINDING",
  "IMAGE_COUNT": "IMAGE_COUNT",
  "IMAGE_I18N[$CTRL_LANGUAGECODE]_CATEGORY": "IMAGE_I18N[$CTRL_LANGUAGECODE]_CATEGORY",
  "IMAGE_I18N[$CTRL_LANGUAGECODE]_DESCRIPTION": "IMAGE_I18N[$CTRL_LANGUAGECODE]_DESCRIPTION",
  "IMAGE_NOT_ENABLED": "Bild - nicht aktiviert",
  "IMAGE_UPLOAD": "IMAGE_UPLOAD",
  "IMAGE_URL": "IMAGE_URL",
  "IMAGES": "IMAGES",
  "IMPORT": "Import",
  "IMPORT_EXCEL": "IMPORT_EXCEL",
  "IMPORT_LINESHEET": "Import Verkaufsseite",
  "IMPORT_LINESHEET_PREORDER": "Import-Bestellungen (Vorbestellung)",
  "IMPORT_LINESHEET_STOCKORDER": "Import-Bestellungen (Lagerbestellung)",
  "IMPORT_USERS_FILE_NAME": "IMPORT_USERS_FILE_NAME",
  "IMPORT_USERS_FILE_TYPE": "IMPORT_USERS_FILE_TYPE",
  "IN_CART": "Im Warenkorb",
  "IN_STOCK": "Auf Lager",
  "IN_STOCK_VALUE": "IN_STOCK_VALUE",
  "INACTIVE_CUSTOMER_QUERY_PLAINTEXT": "INACTIVE_CUSTOMER_QUERY_PLAINTEXT",
  "INACTIVE_CUSTOMERS_AMOUNT": "INACTIVE_CUSTOMERS_AMOUNT",
  "INACTIVE_SHOWING_FOR_THE_LAST": "INACTIVE_SHOWING_FOR_THE_LAST",
  "INACTIVE_WITHIN_THE_LAST": "INACTIVE_WITHIN_THE_LAST",
  "INCLUDE": "INCLUDE",
  "INCOMING": "Eingang",
  "INTERNAL": "Intern",
  "INFO": "Informationen",
  "INFORMATION": "INFORMATION",
  "INITIALIZING": "Initialisiert",
  "INOVICE_DETAILS": "Rechnungsangaben",
  "INOVICE_INFORMATION": "Rechnungsinformationen",
  "INSERT_TEXT_HERE": "Fügen Sie hier Text ein",
  "INTERNAL_STATE": "Interner Zustand",
  "INTERNAL_TEXT": "Interner Text",
  "INVENTORY": "Inventar",
  "INVENTORY_STATUS": "Inventarstatus",
  "INVOICE": "Rechnung",
  "INVOICED": "Invoiced",
  "NOT_INVOICED": "Not invoiced",
  "INVOICE_ADDRESS": "Rechnungsanschrift",
  "INVOICE_AMOUNT": "INVOICE_AMOUNT",
  "INVOICE_BALANCE": "INVOICE_BALANCE",
  "INVOICE_CURRENCY": "INVOICE_CURRENCY",
  "INVOICE_DATE": "Rechnungsdatum",
  "INVOICE_DELIVERYADDRESS1": "INVOICE_DELIVERYADDRESS1",
  "INVOICE_DELIVERYADDRESS2": "INVOICE_DELIVERYADDRESS2",
  "INVOICE_DELIVERYCITY": "INVOICE_DELIVERYCITY",
  "INVOICE_DETAILS_DISCLAIMER": "Hinweis: Dies ist nicht Ihre aktuelle Rechnung. Verwenden Sie die Rechnung vom Lieferanten für Ihre aktuelle Rechnung.",
  "INVOICE_DETAILS_NO_ROWS": "Für diese Rechnung gibt es keine Lieferzeilen.",
  "INVOICE_EMAIL": "Rechnung E-Mail",
  "INVOICE_GT_1": "Vor 1–14 Tagen",
  "INVOICE_GT_14": "Vor 15–30 Tagen",
  "INVOICE_GT_30": "Vor über 30 Tagen",
  "INVOICE_HAS_NOT_PASSED": "Noch nicht überschritten",
  "INVOICE_HISTORY": "Rechnungschronik",
  "INVOICE_ID": "Rechnungsnummer",
  "INVOICE_INFORMATION": "Rechnungsinformationen",
  "INVOICE_INVOICEDATE": "INVOICE_INVOICEDATE",
  "INVOICE_INVOICETYPE": "INVOICE_INVOICETYPE",
  "INVOICE_LIST": "INVOICE_LIST",
  "INVOICE_NUMBER": "Rechnungsnummer",
  "INVOICE_OUTBOUNDDATE": "INVOICE_OUTBOUNDDATE",
  "INVOICE_REFERENCE": "Rechnungsreferenz",
  "INVOICE_ROW_SEARCH": "Suche nach Bestellnummer, Artikel-ID, Artikelname",
  "INVOICE_ROWS": "Rechnungsangaben",
  "INVOICE_SEARCH": "Suche nach Rechnungsnummer",
  "INVOICE_SUMMARY": "Übersicht",
  "INVOICE_TOTALVAT": "INVOICE_TOTALVAT",
  "INVOICEHEADER__DUEDATE": "Zahlungstermin",
  "INVOICEHEADER__INVOICEDATE": "Rechnungsdatum",
  "INVOICES": "Rechnungen",
  "IS_IN_STOCK": "Auf Lager",
  "ISBN": "ISBN",
  "ITEM": "ITEM",
  "ITEM_ASC": "Artikelnr., aufsteigend",
  "ITEM_DESC": "Artikelnr., absteigend",
  "ITEM_DESCRIPTION": "Beschreibung",
  "ITEM_GROUP": "Produktgruppe",
  "ITEM_ID": "Artikel #",
  "ITEM_INFORMATION": "Artikelinformationen",
  "ITEM_NO": "Artikelnummer",
  "ITEMS": "Artikel",
  "ITEMS_AVAILABLE": "Verfügbare Artikel",
  "ITEMS_FILTERED_FROM": "Gefilterte Artikel aus",
  "ITEMS_IN_ORDER": "Reihenfolge der Artikel",
  "January": "January",
  "JEEVES_FT_FAKTRADSUMMA": "In Rechn. gest. Zeile Bel-Basis",
  "JEEVES_FT_FAKTTB": "Deckungsbeitrag",
  "JEEVES_HRP_EDIT": "Anmerkungen",
  "JEEVES_HRP_EVENTNOTE": "Beschreibung",
  "JEEVES_HRP_EVENTRPENDDAT": "Erfassungsdatum",
  "JEEVES_HRP_EVENTRRPKOD": "Erfasste Aktivität",
  "JEEVES_HRP_EVENTTIMANT2": "Stunden",
  "JEEVES_HRP_PERSSIGN": "Zuständige Person",
  "JEEVES_HRP_PLANUTFORD": "Aktivität abgeschlossen",
  "JEEVES_OH_LEVSATTKOD": "JEEVES_OH_LEVSATTKOD",
  "JEEVES_OH_LEVVILLKKOD": "JEEVES_OH_LEVVILLKKOD",
  "JEEVES_OH_ORDLEVPLATS1": "JEEVES_OH_ORDLEVPLATS1",
  "JOB": "Job",
  "July": "July",
  "June": "June",
  "KA_AD1": "Anschrift 1",
  "KA_AD2": "Anschrift 2",
  "KA_ORT": "Postleitzahl/Stadt",
  "KA_REF": "Referenz",
  "KA_SLJ": "Händler",
  "KEY": "KEY",
  "KP_ANTALKOP": "Anzahl Einkäufe",
  "KP_AVD": "Abteilung",
  "KP_BEFATTKOD": "Position",
  "KR_FAD": "Rechnungsdatum",
  "KR_SUB": "Status, Zahlung",
  "KR_SUB_0": "KR_SUB_0",
  "KR_SUB_1": "KR_SUB_1",
  "KR_SUB_2": "KR_SUB_2",
  "KR_SUB_4": "KR_SUB_4",
  "KR_SUB_6": "KR_SUB_6",
  "KR_SUB_8": "KR_SUB_8",
  "KUNDBESTNR": "KUNDBESTNR",
  "KUNDREF2": "KUNDREF2",
  "KUS_BETKOD": "Zahlungsbedingungen",
  "KUS_KUNDBETALARENR": "Zahlender Kunde",
  "KUS_KUNDRABATT": "Kundenrabatt",
  "KUS_LEVSATTKOD": "Lieferart",
  "KUS_LEVVILKKOD": "Lieferbedingungen",
  "KUS_SALJARE": "Verkäufer",
  "KUS_VALKOD": "Währung",
  "LABEL": "Label",
  "LANGUAGE_DE": "Deutsch",
  "LANGUAGE_DK": "Dänisch",
  "LANGUAGE_EN": "Englisch",
  "LANGUAGE_ET": "Estnisch",
  "LANGUAGE_FI": "Finnisch",
  "LANGUAGE_FR": "LANGUAGE_FR",
  "LANGUAGE_NO": "Norwegisch",
  "LANGUAGE_SE": "Schwedisch",
  "LANGUAGES": "Sprachen",
  "LAST_EXECUTION_TIME": "Letzte Ausführungszeit",
  "LAST_MONTH": "LAST_MONTH",
  "LAST_STATUS": "Letzter Status",
  "LAST_THREE_MONTHS": "Letzte drei Monate",
  "LAST_TWELVE_MONTHS": "Letzte 12 Monate",
  "LAST_UODATED": "Zuletzt aktualisiert",
  "LAST_UPDATED": "LAST_UPDATED",
  "LAST_WEEK": "LAST_WEEK",
  "LAST_YEAR": "LAST_YEAR",
  "LATEST_INVOICE_DATE": "LATEST_INVOICE_DATE",
  "LATEST_PURCHASE": "Letzter Einkauf",
  "LEFT_TO_PAY": "Rechnungsbetrag",
  "LENGTH": "Länge",
  "LESS_INFO": "Weniger Informationen",
  "LINES": "LINES",
  "LINK_NOT_ENABLED": "Link - nicht aktiviert",
  "LIST": "Liste",
  "LIST_PRICE": "Listenpreis",
  "LOAD_CART": "Warenkorb laden",
  "LOAD_QUOTE": "LOAD_QUOTE",
  "LOAD_SAVED_CART_CONFIRMATION": "LOAD_SAVED_CART_CONFIRMATION",
  "LOAD_SAVED_QUOTE_CONFIRMATION": "LOAD_SAVED_QUOTE_CONFIRMATION",
  "LOADING": "Laden",
  "LOADING_CUSTOMERS": "Kunden laden",
  "LOADING_MESSAGE": "Ladevorgang, bitte warten Sie.",
  "LOADING_ORDERS": "Bestellungen werden geladen",
  "LOADING_PRODUCTS": "Produkte laden",
  "LOG_IN_AGAIN": "Erneut anmelden",
  "LOG_IN_FOR_MORE": "Zum Anzeigen von Preisen und Lagerbestand anmelden",
  "LOGGED_OUT": "Abgemeldet",
  "LOGGED_OUT_INFO": "Sie wurden wegen fehlender Aktivität abgemeldet",
  "LOGIN": "Anmelden",
  "LOGIN_FAILED": "Anmeldung fehlgeschlagen",
  "LOGIN_IN_PROGRESS": "Anmeldung läuft",
  "LOGIN_INSTEAD": "Melden Sie sich stattdessen an",
  "LOGOUT": "Abmeldung",
  "LOWER_QUANTITY": "Geringere Menge",
  "LR_FFD": "Verfallsdatum",
  "MADE_A_PURCHASE_THIS_YEAR": "MADE_A_PURCHASE_THIS_YEAR",
  "MAIN_CATEGORY": "Hauptkategorie",
  "MANAGE_CONCEPT": "MANAGE_CONCEPT",
  "MANAGE_CUSTOMERS": "Kunden verwalten",
  "MANAGE_FAVORITE_LISTS": "Favoritenlisten verwalten",
  "MANAGE_PRODUCT": "MANAGE_PRODUCT",
  "MANAGED_COMPANIES": "MANAGED_COMPANIES",
  "MANAGER_CUSTOMERS": "Kunden verwalten",
  "MAP": "MAP",
  "MAP_NEW_COVER_SHEET": "Neues Deckblatt zuordnen",
  "March": "March",
  "MARGIN": "Marge",
  "MARGIN_%": "% Marge",
  "MATERIAL": "Material",
  "MATERIAL_PERCENT": "Kompositionskomponente",
  "MATRIX_FORMAT": "Matrix-Format",
  "MATRIX_PACK_PRODUCT": "MATRIX_PACK_PRODUCT",
  "MATRIX_SINGLE_PRODUCT": "MATRIX_SINGLE_PRODUCT",
  "MAX_30_CHARACTERS": "Max. 30 Zeichen",
  "MAX_FUTURE": "Künftiges Maximum",
  "May": "May",
  "MEASUREMENTS": "MEASUREMENTS",
  "MEETING": "Treffen",
  "MEETING_SYSTEM": "System",
  "MEETING_TYPE": "Besprechungstyp",
  "MEETINGS": "Treffen",
  "MENU_CATALOG_TITLE": "MENU_CATALOG_TITLE",
  "MENU_PRODUCTS_TITLE": "MENU_PRODUCTS_TITLE",
  "MERCHANDISE_TYPE": "Warenart",
  "MESSAGE": "Nachricht",
  "MESSAGE_PLACEHOLDER": "Schreiben Sie uns eine Nachricht",
  "METADATA_XH_EVENTRPBESKR": "Erfasste Aktivität",
  "MIN_ORDER": "MIN_ORDER",
  "MIN_PACKAGE": "Mindestpackungsgröße",
  "MINIMUM_ORDER_INFORMATION": "MINIMUM_ORDER_INFORMATION",
  "MISSING_DATA": "Angaben fehlen",
  "MISSING_PRIMARY_HEX": "MISSING_PRIMARY_HEX",
  "MISSING_QUANTITIES": "Fehlender Lagerbestand",
  "MISSING_SECONDARY_HEX": "MISSING_SECONDARY_HEX",
  "MODE": "Modus",
  "MODEL": "Modell",
  "MODEL_DISCOUNT": "Rabattmodell",
  "MODEL_PRICE": "Preismodell",
  "MODELPRODUCT_ID": "MODELPRODUCT_ID",
  "MODELPRODUCT_NAME": "MODELPRODUCT_NAME",
  "MODELS": "Modelle",
  "MONTH": "Monat",
  "MONTHLY_BARS": "MONTHLY_BARS",
  "MONTHLY_COST": "Monatliche Gebühr",
  "MORE": "anzuzeigen",
  "MORE_FUNCTIONS": "Weitere Funktionen",
  "MORE_INFO": "Mehr Informationen",
  "MORE_INFORMATION": "Weitere Informationen",
  "MORE_OPTIONS": "MORE_OPTIONS",
  "MOST_PURCHASED": "MOST_PURCHASED",
  "MOUSQUETAIRES_FILTER_SELECTED": "MOUSQUETAIRES_FILTER_SELECTED",
  "MOUSQUETAIRES_SELECTION": "MOUSQUETAIRES_SELECTION",
  "MOVE": "MOVE",
  "MULTI_STOCKS": "MULTI_STOCKS",
  "MY_ACCOUNT": "Mein Konto",
  "MY_ACTIVITIES": "MY_ACTIVITIES",
  "MY_CUSTOMERS": "Meine Kunden",
  "MY_MENU": "Mein Menü",
  "MY_OFFICE": "MY_OFFICE",
  "MY_PAGE": "MY_PAGE",
  "MY_PAGES": "MY_PAGES",
  "NAME": "Name",
  "NAME_ASC": "Name, aufsteigend",
  "NAME_DESC": "Name, absteigend",
  "NET_PRICE": "NET_PRICE",
  "NET_WEIGHT": "NET_WEIGHT",
  "NEW": "Neu",
  "NEW_ORDER": "NEW_ORDER",
  "NEW_PASSWORD": "Neues Passwort",
  "NEW_PASSWORD_REPEAT": "Kennwort bestätigen",
  "NEW_PRICE": "Neuer Preis",
  "NEW_PRICE_OR_DISCOUNT": "Neuen Preis eingeben oder mit % für Rabatt abschließen",
  "NEWFLOW_ID": "NEWFLOW_ID",
  "NEWFLOW_ID_UNDEFINED": "NEWFLOW_ID_UNDEFINED",
  "NEWS": "NEWS",
  "NEXT": "Nächster",
  "NEXT_EXECUTION_TIME": "Nächste Ausführungszeit",
  "NO": "NO",
  "NO_ACCESS": "Kein Zugang zur Verfügbarkeit",
  "NO_AVAILABILITY": "Keine Verfügbarkeit",
  "NO_AVAILABLE_DATE": "Kein Datum",
  "NO_CHOICE": "Überspringen",
  "NO_CHOICE_INFO": "Keine Auswahl getroffen",
  "NO_CUSTOM_PAGE_CONTACT_GUNG": "Keine benutzerdefinierte Seite verfügbar. Wenden Sie sich zur Anpassung an Gung.",
  "NO_DESCRIPTION": "NO_DESCRIPTION",
  "NO_EXTRA_FREIGHT_ADDED": "Für später versandte Produkte einer Lieferung wird nicht erneut Versand berechnet.",
  "NO_ITEMS_FOUND": "Keine Artikel gefunden",
  "NO_PREVIOUS_ORDERS": "NO_PREVIOUS_ORDERS",
  "NO_PRICES": "Keine Preise",
  "NO_PRODUCT_RELATED_PRODUCTS_FOUND": "NO_PRODUCT_RELATED_PRODUCTS_FOUND",
  "NO_PRODUCT_SELECTED": "Kein Produkt ausgewählt",
  "NO_PRODUCTS_IN_CART": "Keine Produkte im Warenkorb, gehen Sie zu",
  "NO_RESULTS_FOUND": "Keine Ergebnisse gefunden",
  "NO_VALID_EMAIL": "Keine gültige E-Mail!",
  "NONE_SELECTED": "Nichts gewählt",
  "NORMAL": "Normal",
  "NORMAL_ORDER": "NORMAL_ORDER",
  "NORMAL_STOCK_LEVEL": "Auftragsniveau normal",
  "NORWEGIAN_DESCRIPTION": "NORWEGIAN_DESCRIPTION",
  "NORWEIGAN_TEXT": "Norwegischer Text",
  "NOT_AVAILABLE": "NOT_AVAILABLE",
  "NOT_DELIVERED": "Nicht geliefert",
  "NOT_ENOUGH_AVAILABILITY": "NOT_ENOUGH_AVAILABILITY",
  "NOT_ERP_SYNC": "NOT_ERP_SYNC",
  "NOT_GUNG_USER": "Kein Gong-Nutzer",
  "NOT_IN_STOCK": "Nicht auf Lager",
  "NOT_IN_STOCK_REC": "Berechnetes Lieferdatum",
  "NOT_ON_STOCK": "NOT_ON_STOCK",
  "NOT_PASSED": "Nicht fällig",
  "NOT_REQUIRED": "Nicht erforderlich",
  "NOTE": "Hinweis",
  "NOTES": "NOTES",
  "NOTHING_FOUND": "Nichts Gefunden",
  "NOTHING_FOUND_CONTACT_GUNG": "Nichts Gefunden Kontakt Gung",
  "NOW": "NOW",
  "November": "November",
  "NUM_IN_STOCK": "Stück auf Lager",
  "NUM_OF_ORDERS": "# Bestellungen",
  "NUMBER_INVICES_CREATED": "NUMBER_INVICES_CREATED",
  "NUMBER_OF_DOCS": "{{documentList.length | number}} Dokumente",
  "NUMBER_OF_USERS_CREATED": "NUMBER_OF_USERS_CREATED",
  "NUMBER_OF_USERS_UPDATED": "NUMBER_OF_USERS_UPDATED",
  "NUMBER_SALES": "NUMBER_SALES",
  "NUMERIC_ORDER": "NUMERIC_ORDER",
  "October": "October",
  "OF": "von",
  "OFF": "OFF",
  "OFH_BETKOD": "Zahlungsbedingungen",
  "OFH_KUNDBESTNR": "Zahlender Kunde",
  "OFH_OFFERTDAT": "Angebotsdatum",
  "OFH_OFFNR": "Angebotsnummer",
  "OFH_OFFSTATUS": "Angebotsstatus",
  "OFH_OFFSTATUS_1": "Angebot",
  "OFH_OFFSTATUS_3": "Aktiv",
  "OFH_OFFSTATUS_4": "Aktives Angebot",
  "OFH_OFFSTATUS_6": "Registriert",
  "OFH_OFFSTATUS_9": "Abgeschlossen",
  "OFH_OFFSTATUS_A": "Erstellt",
  "OFH_OFFSTATUS_E": "Abgelehnt",
  "OFH_OFFSTATUS_F": "Angebotsanfrage",
  "OFH_OFFSTATUS_G": "Vereinbarung steht aus",
  "OFH_OFFSTATUS_H": "Vereinbarung abgeschlossen",
  "OFH_OFFSTATUS_I": "Ausgeliefert",
  "OFH_OFFSTATUS_J": "In Kürze",
  "OGA_BLT": "Wunschlieferdatum",
  "OGA_BVK": "Zahlungsfrist",
  "OGA_customEmails": "OGA_customEmails",
  "OGA_externalText": "OGA_externalText",
  "OGA_internalText": "OGA_internalText",
  "OGA_LSE": "Lieferungsmethode",
  "OGA_LVK": "Lieferfrist",
  "OGA_OGF_TX1": "OGA_OGF_TX1",
  "OGA_OGF_TX2": "OGA_OGF_TX2",
  "OGA_OTY": "Auftragstyp",
  "OGA_PRL": "Preisliste",
  "OGA_RBK": "Rabatt-Code",
  "OGA_SES": "OGA_SES",
  "OGL_AD1": "OGL_AD1",
  "OGL_AD2": "OGL_AD2",
  "OGL_NAM": "OGL_NAM",
  "OGL_ORT": "OGL_ORT",
  "OGR_LVF_0": "Nicht geliefert",
  "OGR_LVF_4": "Teilweise geliefert",
  "OH_BETKOD": "Zahlungsbedingungen",
  "OH_EDITEXT": "OH_EDITEXT",
  "OH_FTGKONTAKTNR": "Kundenkontakt",
  "OH_FTGPOSTNR": "PLZ",
  "OH_GILTIGTOMDATUM": "Gilt bis einschl.",
  "OH_GODSMARKE1": "Versandmarkierungen",
  "OH_KUNDBESTNR": "Ihr Zeichen",
  "OH_KUNDBETALARENR": "Nr.Rechnungsempfänger",
  "OH_KUNDREF2": "Ihr Zeichen",
  "OH_LEVSATTKOD": "OH_LEVSATTKOD",
  "OH_LEVVILLKKOD": "Lieferbedingungen",
  "OH_ORDBEGLEVDAT": "OH_ORDBEGLEVDAT",
  "OH_ORDBERLEVDAT": "Ber. Lieferzeit",
  "OH_ORDDATUM": "Auftragsdatum",
  "OH_ORDERNR": "Auftragsnr.",
  "OH_ORDLEVADR1": "Empfänger",
  "OH_ORDLEVADR2": "Adresse",
  "OH_ORDLEVADR3": "Lieferadresse",
  "OH_ORDLEVADR4": "Ort",
  "OH_ORDLEVADRLANDSKOD": "Land",
  "OH_ORDSTAT": "Auftragsstatus",
  "OH_ORDSTAT_10": "OH_ORDSTAT_10",
  "OH_ORDSTAT_13": "Registriert",
  "OH_ORDSTAT_20": "Teils geliefert",
  "OH_ORDSTAT_40": "Teils geliefert",
  "OH_ORDSTAT_50": "Zur Abrechnung freigegeben",
  "OH_ORDSTAT_6": "Online-Auftrag wird registriert",
  "OH_ORDSTAT_70": "In Rechnung gestellt",
  "OH_ORDSTAT_90": "Abgebrochen",
  "OH_ORDTYP": "Auftragsart",
  "OH_SALESTYPE": "OH_SALESTYPE",
  "OH_UPPDRAGSKLASS": "Auftragsklasse",
  "OH_VREF": "Unser Zeichen",
  "OK": "Ok",
  "OK_CONTINUE": "Ok, weiter",
  "OLD_PRODUCT_IMAGE": "OLD_PRODUCT_IMAGE",
  "ON": "ON",
  "ON_ORDER": "ON_ORDER",
  "ON_STOCK": "ON_STOCK",
  "ONE_MONTH": "ONE_MONTH",
  "OPEN_ORDERS_QUOTE": "Öffnen O&Q",
  "OPEN_PREORDERS": "offene Vorbestellungen",
  "OPEN_REPEATORDERS": "offene Nachbestellungen",
  "OPTION": "Wahl",
  "OPTION_IMAGE": "Optionsbild",
  "OPTION_LINK": "Optionslink",
  "OPTION_REQUIRED": "Erforderliche Auswahl, bitte mindestens einen Zusatz wählen",
  "OPTION_TEXT": "Optionstext",
  "ORDER": "Bestellung",
  "ORDER_ACCORDING_TO_LEAD": "Auf Lager",
  "ORDER_ACCORDING_TO_LEADTIME": "Wird im Lager berechnet",
  "ORDER_AGAIN": "ORDER_AGAIN",
  "ORDER_ANALYTICS": "Auftragsanalytik",
  "ORDER_ANALYTICS_BOTTOM_10": "Untere 10",
  "ORDER_ANALYTICS_SALES": "Verkauf",
  "ORDER_ANALYTICS_TOP_10": "Obere 10",
  "ORDER_CHANNEL_STATUS": "ORDER_CHANNEL_STATUS",
  "ORDER_CHANNEL_STATUS_DETAILS": "ORDER_CHANNEL_STATUS_DETAILS",
  "ORDER_CHANNEL_STEP": "ORDER_CHANNEL_STEP",
  "ORDER_CHANNEL_TIMESTAMP": "ORDER_CHANNEL_TIMESTAMP",
  "ORDER_CONFIRMATION": "ORDER_CONFIRMATION",
  "ORDER_DATA": "Auftragsdaten",
  "ORDER_DATE": "Bestelldatum",
  "ORDER_DATE_FILTER_PLACEHOLDER": "Auftragsdatum",
  "ORDER_DETAILS": "Auftragsdaten",
  "ORDER_DISCOUNT": "Auftragsrabatt",
  "ORDER_FINDER": "Auftragsfinder",
  "ORDER_FINDER_SEARCH": "Nach einem Auftrag oder Produkt suchen",
  "ORDER_HISTORY": "Auftragshistorie",
  "ORDER_HISTORY_FILTER": "Filter",
  "ORDER_HISTORY_FILTER_PLACEHOLDER": "Auftragsnr., Kunde, Referenznummer",
  "ORDER_ID": "Bestellung ID",
  "ORDER_INFO": "Auftragsinfos",
  "ORDER_INFORMATION": "Auftragsinformationen",
  "ORDER_NO": "Bestellnummer",
  "ORDER_NUMBER": "Auftragsnummer",
  "ORDER_PARAMS": "Auftragsparameter",
  "ORDER_POINT": "Bestellpunkt",
  "ORDER_POINT_TOOLTIP": "Unterschreitet der Lagerbestand den Bestellpunkt, erfolgt mit der nächsten Anlieferung eine Nachbestellung bis zum Nachfüllniveau.",
  "ORDER_PROCESSING_INFO": "Ihre Bestellung ist eingegangen und wird derzeit von unserem System bearbeitet. Sie können die Bestellung in Ihrer Auftragshistorie ansehen.",
  "ORDER_PRODUCT": "Bestellware",
  "ORDER_REF": "ORDER_REF",
  "ORDER_ROWS": "Auftragszeilen",
  "ORDER_ROWS_TO_PICK": "Kommissionierauftragszeilen",
  "ORDER_SEARCH": "Nach ID, Datum, Anschrift suchen",
  "ORDER_SEASONS": "Auftrags-Saisons",
  "ORDER_SETTINGS": "Auftragseinstellungen",
  "ORDER_SLASH_ROW": "Bestellung/Zeile",
  "ORDER_STATUS": "Bestellstatus",
  "ORDER_STATUS_WARNING_CANCELLED": "Storniert",
  "ORDER_STATUS_WARNING_ENTERED": "Eingegeben",
  "ORDER_STATUS_WARNING_ENTRY_IN_PROGRESS": "Eingabe wird bearbeitet",
  "ORDER_STATUS_WARNING_QUOTE": "Angebot",
  "ORDER_SUM": "Auftragswert",
  "ORDER_SUMMARY": "Auftragsübersicht",
  "ORDER_TYPE": "Auftragstyp",
  "ORDER_TYPE_1": "ORDER_TYPE_1",
  "ORDER_TYPES": "Auftragstypen",
  "ORDER_VALUE": "Bestellwert",
  "ORDER_WILL_DELIVER_IN_FULL": "Der Auftrag wird im Komplettversand geliefert",
  "ORDERABLE_ITEM": "ORDERABLE_ITEM",
  "orderCreationDate": "orderCreationDate",
  "orderDeliveryDate": "orderDeliveryDate",
  "ORDERED": "Bestellt",
  "ORDERROWS": "Auftragszeilen",
  "ORDERS": "Bestellungen",
  "ORDERS_TO_PICK": "Kommissionieraufträge",
  "ORG_NO": "USt-ID",
  "ORG_NR": "ORG_NR",
  "ORGANISATION_NO": "Org no",
  "ORGID": "Org. Nr.",
  "ORIGIN_COUNTRY": "ORIGIN_COUNTRY",
  "ORIGIN_SUPPLIERID": "ORIGIN_SUPPLIERID",
  "ORIGINAL_PRICE": "Auftr. Preis",
  "OTHER": "Sonstiges",
  "OTHER_COLORS": "OTHER_COLORS",
  "OTHER_INFORMATION": "Weitere Informationen",
  "OUR_HISTORY": "OUR_HISTORY",
  "OUR_REFERENCE": "Unsere Referenz",
  "OUT_OF_STOCK": "Nicht vorrätig",
  "OUTER_PACKAGE": "Größe der Außenverpackung",
  "OUTLET": "Outlet",
  "OUTPUT_FILE_NAME": "Name der Ausgabedatei",
  "OUTPUT_FORMAT": "Ausgabeformat",
  "OUTSTANDING": "Ausgezeichnet",
  "OVER_THREE_MONTHS": "Über drei Monate",
  "OVERVIEW": "Übersicht",
  "PACK_SIZE": "Packungsgrösse",
  "PACKAGE_HEIGHT": "PACKAGE_HEIGHT",
  "PACKAGE_LENGTH": "PACKAGE_LENGTH",
  "PACKAGE_SIZE": "Packungsgröße",
  "PACKAGE_WIDTH": "PACKAGE_WIDTH",
  "PACKAGING": "Verpackung",
  "PALLET": "PALLET",
  "PANTONE": "PANTONE",
  "PANTONEDEFAULT": "PANTONEDEFAULT",
  "PARIS_NORTH_SECTOR": "PARIS_NORTH_SECTOR",
  "PART_DELIVERED": "Teillieferung",
  "PARTIAL": "Teilweise",
  "PARTIALLY_DELIVERED": "Teilweise geliefert",
  "PASSED": "Fällig",
  "PASSED_DUE": "Zahlungstermin überschritten",
  "PASSWORD": "Passwort",
  "PASSWORD_CONFIRM": "PASSWORD_CONFIRM",
  "PASSWORD_HAS_BEEN_UPDATED": "Das Passwort wurde aktualisiert",
  "PASSWORD_REQUIRED": "PASSWORD_REQUIRED",
  "PASSWORD_RESET_CHANGED": "PASSWORD_RESET_CHANGED",
  "PASSWORD_RESET_ERROR_DEFAULT": "PASSWORD_RESET_ERROR_DEFAULT",
  "PASSWORD_RESET_ERROR_USERS_MULTIPLE": "PASSWORD_RESET_ERROR_USERS_MULTIPLE",
  "PASSWORD_RESET_ERROR_USERS_NONE": "PASSWORD_RESET_ERROR_USERS_NONE",
  "PASSWORD_RESET_MIN_LENGTH": "PASSWORD_RESET_MIN_LENGTH",
  "PASSWORD_RESET_SENT": "PASSWORD_RESET_SENT",
  "PASSWORD_TOO_SHORT": "Das Passwort ist zu kurz. Mindestens 10 Zeichen.",
  "PASSWORDS_DO_NOT_MATCH": "PASSWORDS_DO_NOT_MATCH",
  "PAST_DUE": "Fällig",
  "Rechnungsempfänge": "Zahlen",
  "PAYMENT": "PAYMENT",
  "PAYMENT_CUSTOMER": "Kundenzahlung",
  "PAYMENT_TERM": "Zahlungsfrist",
  "PAYMENT_TERMS": "Zahlungsbedingungen",
  "PCS": "Stck.",
  "PDF_CATALOG": "PDF-Katalog",
  "PDF_CUSTOMER_SPECIFIC": "PDF - Kundenspezifisch",
  "PDMKAT2KOD_10": "PDMKAT2KOD_10",
  "PDMKAT2KOD_20": "PDMKAT2KOD_20",
  "PDMKAT2KOD_30": "PDMKAT2KOD_30",
  "PENDING_ORDER_HEADER": "VIELEN DANK FÜR IHREN AUFTRAG!",
  "PENDING_ORDER_INFO": "Ihr Auftrag wird derzeit vom System verarbeitet und in wenigen Minuten in Ihrer Auftragshistorie angezeigt.",
  "PER_PIECE": "pro Einheit",
  "PERIOD": "Zeitraum",
  "PERSONAL_INFORMATION": "Persönliche Daten",
  "PHONE": "Telefon",
  "PHONE1": "Telefon 1",
  "PHONE2": "Telefon 2",
  "PHONE3": "Telefon 3",
  "PICKED_QUANTITY": "Kommissioniermenge",
  "PICKLIST": "Kommissionierliste",
  "PICKLISTS": "Kommissionierlisten",
  "PIECES": "Stück",
  "PIM": "PIM",
  "PIM_COLORS": "Farben",
  "PIM_FLOWKEY": "PIM_FLOWKEY",
  "PIM_PRODUCTS": "PIM_PRODUCTS",
  "PLACE_CREDIT": "PLACE_CREDIT",
  "PLACE_ORDER": "Bestellung aufgeben",
  "PLACE_RETURN": "PLACE_RETURN",
  "PLEASE_LOGIN": "Sie haben bereits ein Konto? Dann melden Sie sich bitte an.",
  "PO_NO": "Bestellnummer",
  "POPULAR": "Populär",
  "POSITION_IN_EXPORT": "Position im Export",
  "POST_OFFICE": "P/O",
  "PREORDER": "Vorbestellung",
  "PREV": "Bisherige",
  "PREVIOUS": "PREVIOUS",
  "PREVIOUS_ORDER_TITLE": "PREVIOUS_ORDER_TITLE",
  "PREVIOUS_ORDERS": "PREVIOUS_ORDERS",
  "PREVIOUS_PAGE": "Föregående sida",
  "PRH_PRISLISTABESKR": "PRH_PRISLISTABESKR",
  "PRICE": "Preis",
  "PRICE_ADJUSTMENT": "Preisanpassung",
  "PRICE_ASC": "Preis, aufsteigend",
  "PRICE_DESC": "Preis, absteigend",
  "PRICE_LIST": "Preisliste",
  "PRICE_MODEL": "Preismodell",
  "PRICE_PER_ITEM": "Preis pro Artikel",
  "PRICE_REQUEST": "PRICE_REQUEST",
  "PRICELIST": "Preisliste",
  "PRIMARYDIMENSION": "FARBE",
  "PRINT": "Drucken",
  "PRINTED": "Gedruckt",
  "PRINTED_NOT_COMPLETED": "Gedruckt, nicht vollständig",
  "PRIORITY": "Priorität",
  "PROCARGS_LEVSATTKOD": "Lieferart",
  "PROCARGS_LEVVILLKKOD": "Lieferbedingungen",
  "PROCARGS_ORDTYP": "PROCARGS_ORDTYP",
  "PROCARGS_SALJARE": "Verkäufer",
  "PRODUCT": "Produkt",
  "PRODUCT_ACCOUNT": "PRODUCT_ACCOUNT",
  "PRODUCT_ADDED_TO_CART": "Das Produkt wurde in den Warenkorb gelegt.",
  "PRODUCT_ALTERNATIVES": "Alternativen anzeigen",
  "PRODUCT_CATALOG": "Produktkatalog",
  "PRODUCT_CATEGORIES": "PRODUCT_CATEGORIES",
  "PRODUCT_CATEGORY": "Produktkategorie",
  "PRODUCT_DATELASTREADFROMERP": "ERP->PIM",
  "PRODUCT_DATELASTWRITETOERP": "PIM->ERP",
  "PRODUCT_DESCRIPTION": "Produktbeschreibung",
  "PRODUCT_DETAILS": "Produktangaben",
  "PRODUCT_ERP_PRODUCTID": "ERP Produkt-ID",
  "PRODUCT_EXPORT_CATEGORY_MAPPING": "Zuordnung der Produktexportkategorien",
  "PRODUCT_EXPORT_CSV": "PRODUCT_EXPORT_CSV",
  "PRODUCT_EXPORT_IMG": "PRODUCT_EXPORT_IMG",
  "PRODUCT_EXPORT_LINESHEET": "PRODUCT_EXPORT_LINESHEET",
  "PRODUCT_EXPORT_NAV_BAR": "PRODUCT_EXPORT_NAV_BAR",
  "PRODUCT_EXPORT_PRICAT": "PRODUCT_EXPORT_PRICAT",
  "PRODUCT_EXPORT_PRODUCT_INFO": "PRODUCT_EXPORT_PRODUCT_INFO",
  "PRODUCT_EXPORT_PRODUCT_STOCK": "PRODUCT_EXPORT_PRODUCT_STOCK",
  "PRODUCT_EXPORT_XLSX": "PRODUCT_EXPORT_XLSX",
  "PRODUCT_FAST_SEARCH": "Schnelle Suche nach Produkten",
  "PRODUCT_FILTER_PLACEHOLDER": "Nach Art-Nr., Name usw. suchen",
  "PRODUCT_GROUP": "Produktgruppe",
  "PRODUCT_I18N[$CTRL_LANGUAGECODE]_DESCRIPTION": "PRODUCT_I18N[$CTRL_LANGUAGECODE]_DESCRIPTION",
  "PRODUCT_I18N[$CTRL_LANGUAGECODE]_SECTION1": "PRODUCT_I18N[$CTRL_LANGUAGECODE]_SECTION1",
  "PRODUCT_I18N[$CTRL_LANGUAGECODE]_SECTION2": "PRODUCT_I18N[$CTRL_LANGUAGECODE]_SECTION2",
  "PRODUCT_I18N[$CTRL_LANGUAGECODE]_SECTION3": "PRODUCT_I18N[$CTRL_LANGUAGECODE]_SECTION3",
  "PRODUCT_I18N[$CTRL_LANGUAGECODE]_SUBTITLE": "PRODUCT_I18N[$CTRL_LANGUAGECODE]_SUBTITLE",
  "PRODUCT_I18N[$CTRL_LANGUAGECODE]_TITLE": "PRODUCT_I18N[$CTRL_LANGUAGECODE]_TITLE",
  "PRODUCT_I18N[LANGUAGEKEY]_DESCRIPTION": "PRODUCT_I18N[LANGUAGEKEY]_DESCRIPTION",
  "PRODUCT_I18N[LANGUAGEKEY]_NAME": "PRODUCT_I18N[LANGUAGEKEY]_NAME",
  "PRODUCT_ID": "Artikel-ID",
  "PRODUCT_INFO": "Produktinfos",
  "PRODUCT_INFO_SECONDARY": "Weitere Infos",
  "PRODUCT_INFO_TECH": "Technische Infos",
  "PRODUCT_INFORMATION": "PRODUCT_INFORMATION",
  "PRODUCT_LIST_FILTER_TOTAL": "Zeige {{render}} von {{filter}}, gefiltert nach {{total}} Ergebnissen",
  "PRODUCT_LIST_TOTAL": "Zeige {{render}} der {{total}} Ergebnisse",
  "PRODUCT_NAME": "Name",
  "PRODUCT_NAME_OR_ID": "SKU-Name oder -ID",
  "PRODUCT_NO_REPLACEMENT": "PRODUCT_NO_REPLACEMENT",
  "PRODUCT_QUANTITY": "PRODUCT_QUANTITY",
  "PRODUCT_RELATED_PRODUCTS": "Verwandte Produkte",
  "PRODUCT_REPLACED_BY": "PRODUCT_REPLACED_BY",
  "PRODUCT_SAVED": "PRODUCT_SAVED",
  "PRODUCT_SEARCH_PLACEHOLDER": "Nach Produktname oder Produkt-ID suchen",
  "PRODUCT_SETTINGS": "Produkteinstellungen",
  "PRODUCT_TYPE": "PRODUCT_TYPE",
  "PRODUCT_VIDEO": "Video",
  "PRODUCTCATEGORYCODE": "PRODUCTCATEGORYCODE",
  "PRODUCTS": "Produkte",
  "PRODUCTS_MISSING_QUANTITIES": "Die folgenden Produkte sind nur noch in geringer Menge vorrätig",
  "PRODUCTS_NOT_ALLOWED_FOR_FLOW": "Folgende Produkte sind für den Auftragstyp nicht zugelassen:",
  "PRODUCTS_TOTAL": "PRODUCTS_TOTAL",
  "PROGRESS": "PROGRESS",
  "PROPERTY": "Eigentum",
  "PROPERTY_1": "PROPERTY_1",
  "PROPERTY_2": "PROPERTY_2",
  "PROPERTY_3": "PROPERTY_3",
  "PUBLISHED_GROUP": "Zielgruppe",
  "PURCHASE_ORDER": "Bestellung",
  "PURCHASE_ORDER_DETAILS": "Angaben zur Bestellung",
  "PURCHASE_STATUS_1": "Bestellung abgeschickt",
  "PURCHASE_STATUS_2": "Bestellung eingegangen",
  "PURCHASE_STATUS_5": "Bestellung bestätigt",
  "PURCHASE_STATUS_6": "Versandbereit",
  "PURCHASE_STATUS_7": "Versendet",
  "PURCHASED_THIS_YEAR": "PURCHASED_THIS_YEAR",
  "PUSH_DELIVERY_DATE": "Lieferdatum verschieben",
  "Q_BUDGETKOD2": "Q_BUDGETKOD2",
  "Q_PRODUCT_FAMILY": "Q_PRODUCT_FAMILY",
  "QTY": "QTY",
  "QUALITY": "QUALITY",
  "QUALITY_100_1000": "Qualität (100-1000)",
  "QUANTITY": "Menge",
  "QUANTITY_AND_PRICE": "Anzahl & Preis pro Einheit",
  "QUANTITY_AVAILABLE": "QUANTITY_AVAILABLE",
  "QUANTITY_IN_ORDER": "QUANTITY_IN_ORDER",
  "QUANTITY_IN_STOCK": "Aktueller Lagerbestand",
  "QUANTITY_PER_UNITY": "QUANTITY_PER_UNITY",
  "QUANTITY_REMAINING": "Restliche Menge",
  "QUARTER": "Quartal",
  "QUERY_CONDITION": "QUERY_CONDITION",
  "QUICK_ORDER": "Schnellbestellung",
  "QUICK_SEARCH": "QUICK_SEARCH",
  "QUICKORDER": "Eilauftrag",
  "QUOTE": "Angebot",
  "QUOTE_DATE": "Angebotsdatum",
  "QUOTE_DETAILS": "Angebotsangaben",
  "QUOTE_HISTORY": "Angebotshistorie",
  "QUOTE_ID": "Angebotsnummer",
  "QUOTE_INFO": "Angebotsinformationen",
  "QUOTE_STATUS": "Angebotsstatus",
  "QUOTE_SUM": "Übersicht",
  "QUOTE_TO_ORDER": "In bestellung umwandeln ",
  "QUOTES": "Angebote",
  "READ_MORE": "Weiterlesen",
  "RECOMMENDED_PRICE": "RECOMMENDED_PRICE",
  "REDUCE_AMOUNT_IN_ORDER": "Stückzahl im Auftrag reduzieren",
  "REDUCE_QUANTITY": "REDUCE_QUANTITY",
  "REF_NO": "REF_NO",
  "REFERENCE": "Referenz",
  "REFERENCE_NO": "Referenznr.",
  "REFRESH": "REFRESH",
  "REGION": "Region",
  "REGISTERED": "Eingetragen",
  "REGULAR_PRICE": "REGULAR_PRICE",
  "RELATED_PRODUCTS": "Verwandte Produkte",
  "RELEASE_DATE": "RELEASE_DATE",
  "RELEVANT_CUSTOMER": "RELEVANT_CUSTOMER",
  "REMAINING": "Rest",
  "REMEMBER_TO_BUY": "Kauferinnerung",
  "REMEMBER_TO_SAVE_CART": "Denken Sie daran, Ihren Warenkorb abzuspeichern, wenn Sie ihn behalten möchten, bevor Sie einen neuen Warenkorb laden.",
  "REMINDERS": "REMINDERS",
  "REMOVE": "Entfernen",
  "REMOVE_AND_CONTINUE_CHECKOUT": "Alles entfernen und weiter zur Kasse",
  "REMOVE_BEFORE_CONTINUING": "Alle Produkte in der unten stehenden Liste müssen entfernt werden, bevor Sie zur Kasse gehen",
  "REMOVE_FROM_ORDER": "Aus dem Auftrag löschen",
  "REMOVE_MODEL_DISCOUNT": "Rabattmodell entfernen",
  "REMOVE_MODEL_PRICE": "Kundenspezifischen Preis aus dem Modell entfernen",
  "REMOVE_PRODUCT": "Produkt entfernen",
  "REMOVE_ROW": "REMOVE_ROW",
  "RENT": "Mieten",
  "REPEATORDER": "Nachbestellung",
  "REPLACE_CART": "REPLACE_CART",
  "REPLACE_CART_INFO": "REPLACE_CART_INFO",
  "REPLACE_CURRENT_ORDER": "Aktuelle Bestellung ersetzen",
  "REQUEST_ACCOUNT": "Kontoerstellung beantragen",
  "REQUEST_ACCOUNT_INFO": "Sie sind bereits Kunde bei uns? Dann können Sie hier Artikel aus unserem Online-Shop bestellen. Als Neukunde wenden Sie sich bitte über +46-346-73 24 00 oder info@unident.se an uns. Wir helfen Ihnen gern!",
  "REQUEST_INVALID_FORM": "Alle Felder müssen ausgefüllt werden",
  "REQUEST_LOGIN": "Konto registrieren/Anmelden",
  "REQUEST_PROCESSED": "REQUEST_PROCESSED",
  "REQUESTED_DELIVERY_DATE": "Wunschlieferdatum",
  "REQUESTED_DELIVERYDATE": "Wunschlieferdatum",
  "REQUESTED_QUANTITY": "REQUESTED_QUANTITY",
  "REQUESTING": "Anforderung ...",
  "REQUIRED": "Angefordert",
  "RESET": "Zurücksetzen",
  "RESET_PASSWORD": "Passwort zurücksetzen",
  "RESET_PASSWORD_REQUEST_SENT": "RESET_PASSWORD_REQUEST_SENT",
  "RESET_TOKEN": "Reset-Token",
  "RESET_TOKEN_FROM_EMAIL": "Wiederherstellungscode per E-Mail",
  "RESIDUAL_AMOUNT": "RESIDUAL_AMOUNT",
  "RESPONSIBLE": "Verantwortlicher",
  "RESTOCK": "Nachfüllniveau",
  "RESTOCK_TOOLTIP": "Unterschreitet der Bestand den Bestellpunkt, erfolgt eine Nachbestellung bis zum Nachfüllniveau",
  "RESULT": "RESULT",
  "RETAIL_PRICES_ONLY": "Nur Einzelhandelspreise",
  "RETURN": "Retoure",
  "RETURN_INFORMATION": "RETURN_INFORMATION",
  "RETURN_ORDER": "Retourenauftrag",
  "REVENUE_LAST_YEAR": "REVENUE_LAST_YEAR",
  "REVENUE_THIS_YEAR": "REVENUE_THIS_YEAR",
  "REVERT_ALL_AND_FINISH": "Alle Zeilen zurücksetzen und beenden",
  "REVERT_PICKLIST": "Kommissionierliste rückgängig machen",
  "ROLE": "ROLE",
  "ROLES": "Rolle",
  "ROW": "Zeile",
  "ROW_INDEX": "Zeilennummer",
  "ROWS": "Zeilen",
  "RRP": "RRP",
  "SALES": "Vertrieb",
  "SALES_ACTUAL_YR": "Verkauf laufendes Jahr",
  "SALES_AMOUNT": "SALES_AMOUNT",
  "SALES_CALCULATION": "Vertriebskalkulation",
  "SALES_CONDITIONS": "Geschäftsbedingungen",
  "SALES_CYCLE": "Verkaufszyklus",
  "SALES_DASHBOARD": "Verkaufs-Dashboard",
  "SALES_PREV_YR": "Verkauf Vorjahr",
  "SALES_SUMMARY": "SALES_SUMMARY",
  "SALES_TERMS": "Geschäftsbedingungen",
  "SALES_THIS_MONTH": "SALES_THIS_MONTH",
  "SALESPERSON": "Händler",
  "SALJ_SALJARENAMN": "Verkäufer",
  "SAVE": "Speichern",
  "SAVE_BACK": "SAVE_BACK",
  "SAVE_CART": "Warenkorb speichern",
  "SAVE_CART_MODAL_INFO_TEXT": "Änderungen speichern",
  "SAVE_CHANGES": "Gespeicherte Warenkörbe",
  "SAVE_CUSTOMER": "SAVE_CUSTOMER",
  "SAVE_QUOTATION": "SAVE_QUOTATION",
  "SAVE_TEMPLATE": "SAVE_TEMPLATE",
  "SAVED_CART": "Gespeicherter Warenkorb",
  "SAVED_CARTS": "Kontaktpersonen finden",
  "SAVED_QUOTES": "SAVED_QUOTES",
  "SCAN_EAN": "SCAN_EAN",
  "SCHEDULERS": "Planer",
  "SE_DESCRIPTION": "Schwedische Beschreibung",
  "SEARCH": "Nach Bestellnummer suchen",
  "SEARCH_BY_HEADER": "SEARCH_BY_HEADER",
  "SEARCH_COLOR": "SEARCH_COLOR",
  "SEARCH_CONTACTS_PLACEHOLDER": "Bestellzeile suchen",
  "SEARCH_CUSTOMER_PLACEHOLDER": "SEARCH_CUSTOMER_PLACEHOLDER",
  "SEARCH_DELIVERY_LOCATIONS_PLACEHOLDER": "SEARCH_DELIVERY_LOCATIONS_PLACEHOLDER",
  "SEARCH_DELIVERY_ROWS_PLACEHOLDER": "SEARCH_DELIVERY_ROWS_PLACEHOLDER",
  "SEARCH_ID_NAME_EAN": "SEARCH_ID_NAME_EAN",
  "SEARCH_ORDER": "Zuerst Produkt suchen",
  "SEARCH_ORDER_NO": "SEARCH_ORDER_NO",
  "SEARCH_ORDER_ROW": "Zuerst Produkt suchen",
  "SEARCH_PRODUCT": "Nach Produktname oder Produkt-ID suchen",
  "SEARCH_PRODUCT_FIRST": "Produkte suchen",
  "SEARCH_PRODUCT_NAME_OR_ID": "Händler suchen",
  "SEARCH_PRODUCTS": "Nach Bestell-ID, Kunden-ID, Kundennamen oder Lieferdatum suchen",
  "SEARCH_PRODUCTS_PLACEHOLDER": "10.000 Produkten durchsuchen. Suchen Sie z. B. nach Artikelnummern, Artikelbezeichnungen, Lieferanten oder Artikelnummern eines Lieferanten.",
  "SEARCH_SIZES": "SEARCH_SIZES",
  "SEARCH_STORE_NAME_NUMBER": "SEARCH_STORE_NAME_NUMBER",
  "SEARCH_SUPPLIER": "Saison",
  "SEARCH_WAREHOUSE_ORDER_PLACEHOLDER": "SEARCH_WAREHOUSE_ORDER_PLACEHOLDER",
  "SEASON": "Saison (Bestellung)",
  "SEASON_ORDER": "Saison (Produkt)",
  "SEASON_PRODUCT": "Auswählen",
  "SECONDARYDIMENSION": "GRÖẞE",
  "SECONDS": "Sekunden",
  "SELECT": "SELECT",
  "SELECT_ACTION": "SELECT_ACTION",
  "SELECT_ALL": "Alle auswählen",
  "SELECT_ALL_FILTERED_ITEMS": "SELECT_ALL_FILTERED_ITEMS",
  "SELECT_ALL_ITEMS": "SELECT_ALL_ITEMS",
  "SELECT_ALL_REMAINING": "SELECT_ALL_REMAINING",
  "SELECT_CATEGORY": "SELECT_CATEGORY",
  "SELECT_COLORS": "SELECT_COLORS",
  "SELECT_CONTACT": "Kontakt wählen",
  "SELECT_CUSTOMER": "Kunden auswählen",
  "SELECT_CUSTOMER_IN_THE_DROPDOWN_BELOW": "SELECT_CUSTOMER_IN_THE_DROPDOWN_BELOW",
  "SELECT_CUSTOMER_MODAL_TITLE": "Kundeninformation",
  "SELECT_DELIVERY_ADDRESS": "Lieferadresse wählen",
  "SELECT_DELIVERY_COMPANY": "SELECT_DELIVERY_COMPANY",
  "SELECT_FILE": "Datei auswählen",
  "SELECT_FILES": "Dateien auswählen",
  "SELECT_FLOW": "Auftragstyp auswählen",
  "SELECT_ITEM": "SELECT_ITEM",
  "SELECT_OPTION": "Wählen",
  "SELECT_OPTIONS": "Zusatz wählen",
  "SELECT_ORDER": "SELECT_ORDER",
  "SELECT_ORDER_ROWS_TO_PICK": "SELECT_ORDER_ROWS_TO_PICK",
  "SELECT_ORDER_STATUS": "Kommissionierauftragszeilen auswählen",
  "SELECT_ORDERS_TO_PICK": "SELECT_ORDERS_TO_PICK",
  "SELECT_SALESPERSON": "Auftragsstatus auswählen",
  "SELECT_SIZES": "SELECT_SIZES",
  "SELECT_TEMPLATE": "SELECT_TEMPLATE",
  "SELECT_WORKFLOW": "Kommissionieraufträge auswählen",
  "SELECTED": "Händler auswählen",
  "SELECTED_CUSTOMER": "Gewählter Kunde",
  "SELECTED_CUSTOMER_CONTINUE": "Möchten Sie fortfahren oder den Kunden wechseln zu",
  "SELECTED_CUSTOMER_OPTION": "Der aktuell ausgewählte Kunde ist",
  "SELECTED_DELIVERY_METHOD_FREIGHT_FREE": "Die gewählte Lieferart ist versandkostenfrei",
  "SELECTED_FILTERS": "Gewählte Filter",
  "SELECTED_ITEM_COUNT": "SELECTED_ITEM_COUNT",
  "SELECTED_PRODUCT": "Gewähltes Produkt",
  "SELECTEDBRAND": "Markierung wählen",
  "SELECTEDMODEL": "Modell wählen",
  "SELECTEDTEMPLATE_ACTIVE": "SELECTEDTEMPLATE_ACTIVE",
  "SELECTEDTEMPLATE_ID": "SELECTEDTEMPLATE_ID",
  "SELECTEDTEMPLATE_ORDER": "SELECTEDTEMPLATE_ORDER",
  "SEND": "Auftragstyp auswählen",
  "SEND_LINK_TO_RESET": "Ausgewählt",
  "SEND_PASSWORD_RESET": "SEND_PASSWORD_RESET",
  "September": "September",
  "SERVICE_CLIENT": "SERVICE_CLIENT",
  "SET_DEFAULT": "Senden",
  "SET_PASSWORD": "SET_PASSWORD",
  "SHIPPING": "Versand",
  "SHIPPING_INFORMATION": "Versandinformationen",
  "SHOP_BY_CATEGORY": "SHOP_BY_CATEGORY",
  "SHOP_BY_PRODUCT_LIST": "Produktliste",
  "SHOPPING_CART_TEXT": "SHOPPING_CART_TEXT",
  "SHORT_DESCRIPTION": "Link zum Zurücksetzen senden",
  "SHOW": "Standard festlegen",
  "SHOW_ACCOUNT_FORM": "Bestandskunde – Hier Konto erstellen",
  "SHOW_ADDRESS": "Lieferadresse anzeigen",
  "SHOW_CART": "SHOW_CART",
  "SHOW_CATEGORIES": "Kurze Beschreibung",
  "SHOW_COLORS": "SHOW_COLORS",
  "SHOW_CUSTOMER": "Anzeigen",
  "SHOW_DETAILS": "Kategorien anzeigen",
  "SHOW_INACTIVE": "SHOW_INACTIVE",
  "SHOW_INVOICE": "Kunden anzeigen",
  "SHOW_MODELS": "SHOW_MODELS",
  "SHOW_MORE": "Zeig mehr",
  "SHOW_MORE_PRODUCTS": "SHOW_MORE_PRODUCTS",
  "SHOW_MORE_PRODUCTS_XS": "SHOW_MORE_PRODUCTS_XS",
  "SHOW_ORDER": "Einzelheiten anzeigen",
  "SHOW_PRICE": "Preis anzeigen",
  "SHOW_QUOTE": "Angebot anzeigen",
  "SHOW_SIZES": "Größen anzeigen",
  "SHOW_STOCK": "Verfügbarkeit anzeigen",
  "SHOWING": "Anzeigen",
  "SHOWING_FOR_THE_LAST": "SHOWING_FOR_THE_LAST",
  "SHOWING_X_RESULTS": "Ergebnis {{resultCount}} anzeigen.",
  "SHOWN_COLORS": "SHOWN_COLORS",
  "SIGN_IN": "SIGN_IN",
  "SIGN_OUT": "Abmelden",
  "SIMPLE": "Einfach",
  "SIZE": "Größe",
  "SIZES": "SIZES",
  "SKU_VIEW": "SKU_VIEW",
  "SOLD_OUT": "SOLD_OUT",
  "SORT": "Sortierung",
  "SORT_BY": "Sortieren nach",
  "SORTING": "Sortieren",
  "SORTING_COMPACT": "SORTING_COMPACT",
  "SORTING_GALLERY": "SORTING_GALLERY",
  "SORTING_LIST": "SORTING_LIST",
  "SOUTH_EAST_SECTOR": "SOUTH_EAST_SECTOR",
  "SOUTH_WEST_SECTOR": "SOUTH_WEST_SECTOR",
  "SPECIFICATION": "Spezifikation",
  "START_DATE": "Startdatum",
  "START_PICKING": "Kommissionierung starten",
  "START_TIME": "Startzeit",
  "STATE": "Stat.",
  "STATISTICS": "Statistik",
  "STATISTICS_DASHBOARD": "STATISTICS_DASHBOARD",
  "STATUS": "Status",
  "STEP": "Stufe",
  "STJERNA_EXISTING_COLORS": "Gibt es in folgenden Farben",
  "STJERNA_EXISTING_DIMENSIONS": "Gibt es in folgenden Größen",
  "STOCK": "Lager",
  "STOCK_ID": "Lager-ID",
  "STOCK_LOCATIONS": "STOCK_LOCATIONS",
  "STOCK_REPLENISHMENT": "STOCK_REPLENISHMENT",
  "STOCKLOCATION": "STOCKLOCATION",
  "STOCKORDER": "Lagerbestellung",
  "STOCKS": "Lager",
  "STORE_PERIOD": "STORE_PERIOD",
  "STYLE": "Ausführung",
  "SUB": "Zwischensumme",
  "SUB_CATEGORY_1": "Zwischenkategorie",
  "SUB_CATEGORY_2": "Unterkategorie",
  "SUBMIT": "Abschicken",
  "SUBMIT_ORDER": "Bestellung abschicken",
  "SUBMIT_QUOTE": "Angebot speichern",
  "SUBMIT_ROWS": "SUBMIT_ROWS",
  "SUBMITTING": "Hinzufügen",
  "SUBMITTING_ORDER": "Gesendeter Auftrag",
  "SUBSCRIBE": "SUBSCRIBE",
  "SUBSCRIBE_TO_NEWS": "Newsletter abonnieren",
  "SUBTOTAL": "Zwischensumme",
  "SUCCESS": "SUCCESS",
  "SUCCESSFUL_UPLOADS": "SUCCESSFUL_UPLOADS",
  "SUGGESTED_PASSWORD": "Passwortvorschlag",
  "SUMMARY": "Übersicht",
  "SUMMER": "Sommer",
  "SUPER_GROUP": "Super Gruppe",
  "SUPPLIER": "Händler",
  "SUPPLIER_ADDRESS": "Händleranschrift",
  "SUPPLIERNAME": "SUPPLIERNAME",
  "SYNC_OUTLOOK": "SYNC_OUTLOOK",
  "SYNC_TO_ERP": "SYNC_TO_ERP",
  "SYNC_WITH_ERP": "SYNC_WITH_ERP",
  "SYSTEM": "System",
  "TA01_TX1": "TA01_TX1",
  "TA02_TX1": "TA02_TX1",
  "TA03_TX1": "TA03_TX1",
  "TA04_TX1": "TA04_TX1",
  "TA06_TX1": "TA06_TX1",
  "TA11_TX1": "TA11_TX1",
  "TAG": "Kennzeichnung",
  "TAG_PLACEHOLDER": "Tagging",
  "TARGET_LINK": "TARGET_LINK",
  "TARIC_CODE": "TARIC_CODE",
  "TECH_ADVICE": "TECH_ADVICE",
  "TECHNICAL_DATA_IMPORT": "Technischer Datenimport",
  "TECHNICAL_ERROR": "Technischer Fehler",
  "TECHNICAL_INFORMATION": "Technische Informationen",
  "TEMPLATES": "Vorlagen",
  "TERMS": "Bedingungen",
  "TERMS_AND_CONDITIONS": "TERMS_AND_CONDITIONS",
  "TERMS_HEADER": "TERMS_HEADER",
  "TERMS_READ_MORE": "TERMS_READ_MORE",
  "TEXT": "TEXT",
  "TEXT_NOT_ENABLED": "Text - nicht aktiviert",
  "TEXTS": "Texte",
  "THANK_YOU_EMAIL_CONFIRMATION": "Er is zojuist een bevestigingsmail verzonden naar het adres dat is opgegeven bij het afrekenen",
  "THANK_YOU_FOR_YOUR_ORDER": "Vielen Dank für Ihre Bestellung!",
  "THANK_YOU_HEADER": "Bedankt voor je bestelling!",
  "THANK_YOU_ORDER_SUMMARY": "Overzicht van de bestelling",
  "THANK_YOU_QUESTIONS": "Voor al uw vragen, algemeen, specificiteiten, materialen of op maat gemaakt kunt u contact opnemen",
  "THE_COMPANY": "Das Unternehmen",
  "THIS_CART_WILL_BE_DELETED": "Der Warenkorb wird gelöscht.",
  "THIS_MONTH": "Dieser Monat",
  "THIS_WEEK": "Diese Woche",
  "THIS_YEAR": "THIS_YEAR",
  "THREE_MONTHS": "THREE_MONTHS",
  "THREE_MONTHS_OR_MORE": "> Drei Monate",
  "TILLBAKA": "Zurück",
  "TIME": "Zeit",
  "TIP": "TIP",
  "TITLE": "Titel",
  "TO_BE_ORDERED": "Muss bestellt werden",
  "TO_WEBSHOP": "TO_WEBSHOP",
  "TODAY": "Heute",
  "TOGGLE_SELECTION": "TOGGLE_SELECTION",
  "TOKEN_SENT": "Der Code wird Ihnen per E-Mail zugesendet. Sollten Sie den Code nicht innerhalb von 2 Minuten erhalten, sehen Sie bitte in Ihrem Spam-Ordner nach. Sobald Sie den Code erhalten haben, geben Sie ihn bitte auf dieser Seite ein. Wenn Sie diese Seite vorher schließen, müssen Sie einen neuen Wiederherstellungscode anfordern",
  "TOKEN_SENT_ALERT": "BLEIBEN SIE BITTE AUF DIESER SEITE!",
  "TOOLS": "Instrumente",
  "TOP_PURCHASED_TITLE": "TOP_PURCHASED_TITLE",
  "TOTAL": "Insgesamt",
  "TOTAL_EXCL_VAT": "Betrag, ohne MwSt.",
  "TOTAL_IMAGES": "TOTAL_IMAGES",
  "TOTAL_INC_VAT": "Betrag, einschl. MwSt.",
  "TOTAL_INCL_EXTRAS": "Insgesamt einschl. Extras",
  "TOTAL_INCLUDING_VAT": "TOTAL_INCLUDING_VAT",
  "TOTAL_PRICE": "Gesamtpreis",
  "TOTAL_QUANTITY": "Gesamtmenge",
  "TOTAL_UNITS": "Gesamteinheiten",
  "TOTAL_VALUE": "Gesamtwert",
  "TOTAL_VALUE_SOLD_FOR": "TOTAL_VALUE_SOLD_FOR",
  "TOTALS_CURRENCYCODE": "Währungscode",
  "TOTALS_ORDERVALUEAFTERDISCOUNTS": "Insgesamt ohne MwSt",
  "TOTALS_TOTALDISCOUNT": "Rabatt",
  "TOTALS_TOTALEXCLVAT": "Insgesamt ohne MwSt",
  "TOTALS_TOTALFREIGHT": "Frachtkosten",
  "TOTALS_TOTALHEADERDISCOUNT": "Bestellwertrabatt",
  "TOTALS_TOTALHEADERDISCOUNTPERCENT": "% Skonto",
  "TOTALS_TOTALINCLVAT": "Insgesamt mit MwSt",
  "TOTALS_TOTALINCLVATINCLFREIGHT": "Insgesamt mit MwSt. und Extras",
  "TOTALS_TOTALITEMS": "Gesamtartikel",
  "TOTALS_TOTALROWDISCOUNT": "Artikelrabatt",
  "TOTALS_TOTALROWVALUE": "Zwischensumme",
  "TOTALS_TOTALVAT": "MwSt-Betrag",
  "TOTALS_TOTALVATINCLFREIGHT": "Insgesamt einschl. Extras",
  "TRACK_ORDER": "TRACK_ORDER",
  "TRACKING_EMAIL": "E-Mail verfolgen",
  "TRACKING_EMAIL_TEMP": "E-Mail vorübergehend verfolgen",
  "TRACKING_LINK_TEMP_EMAIL": "Verwenden Sie diese Option, wenn der Kunde möchte, dass der Tracking-Link an eine temporäre E-Mail gesendet wird",
  "TRIGGER_NOW": "Jetzt auslösen",
  "TRIGGERS": "Trigger",
  "TRUE": "Ja",
  "TRY_AGAIN": "TRY_AGAIN",
  "TWO_MONTHS": "TWO_MONTHS",
  "UNABLE_TO_COMPLETE_IMPORT": "Import kann nicht abgeschlossen werden",
  "UNABLE_TO_DELIVER_ROWS": "UNABLE_TO_DELIVER_ROWS",
  "UNDEFINED": "UNDEFINED",
  "UNDETERMINED_DELIVERY": "Unbestimmtes Lieferdatum",
  "UNIT": "Einheit",
  "UNIT_PRICE": "Einheitspreis",
  "UNSAVED": "Nicht gespeichert",
  "UNSUBSCRIBE": "UNSUBSCRIBE",
  "UPDATE": "Update",
  "UPDATE_EVERY": "Alle aktualisiert",
  "UPDATE_MEETING": "UPDATE_MEETING",
  "UPDATE_PURCHASE_STATUS_1": "Bestellung ausdrucken",
  "UPDATE_PURCHASE_STATUS_2": "Bestellung bestätigen",
  "UPDATE_PURCHASE_STATUS_5": "Auf „versandbereit“ einstellen",
  "UPDATE_PURCHASE_STATUS_6": "Auf „versendet“ einstellen",
  "UPLOAD": "Hochladen",
  "UPLOAD_EXCEL_ORDERSHEET": "Laden Sie das Excel-Auftragsblatt hoch",
  "UPLOAD_FORMAT": "Format hochladen",
  "UPLOAD_IMAGES": "UPLOAD_IMAGES",
  "UPLOAD_MULTIPLE_DOCUMENTS": "UPLOAD_MULTIPLE_DOCUMENTS",
  "UPLOAD_MULTIPLE_IMAGES": "UPLOAD_MULTIPLE_IMAGES",
  "UPLOAD_ORDER_EXCEL_COLUMN_INFO": "UPLOAD_ORDER_EXCEL_COLUMN_INFO",
  "UPLOAD_ORDER_EXCEL_MATRIX_INFO": "UPLOAD_ORDER_EXCEL_MATRIX_INFO",
  "UPLOADED_BY": "Hochgeladen durch",
  "USE": "Verwendung",
  "USER": "Benutzer",
  "USERNAME": "Benutzername",
  "USERNAME_ALREADY_EXISTS_SELECT_ANOTHER": "USERNAME_ALREADY_EXISTS_SELECT_ANOTHER",
  "USERNAME_REQUIRED": "USERNAME_REQUIRED",
  "USERNAME_YOUR_EMAIL": "Benutzername (Ihre Mailadresse)",
  "USERS": "Benutzer",
  "WAITING": "Warten",
  "VALIDATE_BILLING_INFORMATION": "VALIDATE_BILLING_INFORMATION",
  "VALIDATE_FORM_BEFORE_SUBMIT": "Formular bestätigen und dann abschicken",
  "VALIDATE_SHIPPING_INFORMATION": "VALIDATE_SHIPPING_INFORMATION",
  "VALIDATE_STOCK": "VALIDATE_STOCK",
  "VALIDATING_USERNAME": "Benutzernamen bestätigen",
  "VALIDATION": "VALIDATION",
  "VALUE": "Wert",
  "VALUE_IN_STOCK": "Lagerwert",
  "VARIANT": "Variante",
  "VARIANTS": "VARIANTS",
  "WARNING": "WARNING",
  "WARNING_PRODUCTS_IN_CART": "Es befinden sich Produkte in Ihrem Warenkorb. Wenn Sie fortfahren, werden diese ersetzt.",
  "VAT": "MwSt",
  "VAT_NO": "Unternehmensidentität/MwSt-Nummer",
  "WEB_DISCOUNT": "Online-Rabatt",
  "WEBORDER": "Online-Auftrag wird registriert",
  "WEBORDER_UNDER_REGISTRATION": "Registrierung läuft",
  "WEEK": "Woche",
  "WELCOME": "Willkommen",
  "WELCOME_START": "Willkommen bei uns",
  "WEST_SECTOR": "WEST_SECTOR",
  "WHOLESALE_PRICES_ONLY": "Nur Großhandelspreise",
  "WIDTH": "WIDTH",
  "VIEW": "VIEW",
  "WINTER": "Winter",
  "VISIBLE_IN_MENU": "Sichtbar im Menü",
  "VOLUME": "VOLUME",
  "X2_BETVILLBESKR": "Zahlungsbedingungen",
  "X2E_LEVVILLKBESKR": "Lieferbedingungen",
  "X2F_LEVSATTBESKR": "Versandart",
  "X2T_TRANSPORTORSNAMN": "Transporter",
  "X6_ORDTYPBESKR": "X6_ORDTYPBESKR",
  "XDL_DELLEVTILLATENBESKR": "Teillieferung erlaubt",
  "XR_LAND": "Land",
  "YES": "Ja",
  "YOUR_CART_HAS_BEEN_SAVED": "Der Warenkorb wurde mit folgender ID gespeichert:",
  "YOUR_COMMERCIAL_CONTACT": "YOUR_COMMERCIAL_CONTACT",
  "YOUR_EMAIL": "Sie@E-Mail.com",
  "YOUR_ORDER": "Ihre Bestellung",
  "YOUR_PO_NUMBER": "Ihre Bestellnummer",
  "YOUR_PRICAT_IS_BEING_GENERATED_AND_WILL_BE_EMAILED_WHEN_READY": "YOUR_PRICAT_IS_BEING_GENERATED_AND_WILL_BE_EMAILED_WHEN_READY",
  "YOUR_PRICE": "Ihr Preis",
  "YOUR_REFERENCE": "Ihre Referenz",
  "YOUR_REFERENCE_NO": "Ihr Zeichen",
  "Z_OTHERS": "Sonstiges",
  "ZIP": "Postleitzahl",
  "ZIP_CITY": "Postleitzahl/Stadt",
  "GOODS_LABEL": "Warenetikett",
  "AVAILABLE_QUANTITY": "Verfügbare anzahl",
  "CARRIER_CODE": "Trägercode",
  "EMAIL_INVOICE": "Rechnung per E-Mail",
  "EMAIL_QUOTE": "E-Mail-Angebot",
  "EMAIL_QUOTE_TEMPORARY": "E-Mail senden temporäres Angebot *",
  "MESSAGE_EMAIL_QUOTE_TEMP": "Die in diesem Feld eingegebene E-Mail-Adresse wird für den Versand des Angebots verwendet. Wenn dieses Feld leer ist, wird die Adresse verwendet, die in \"Kostenvoranschlag per E-Mail senden\" eingegeben wurde.",
  "SEND_QUOTE": "Angebot senden",
  "OUTSIDE_ASSORTMENT": "Außensortiment",
  "BLOCKED": "Verstopft",
  "OFFERS": "Offers",
  "ADD_MEETING": "Add meeting",
  "MEETING_DATE": "Meeting date",
  "PRODUCT_SYSTEM": "Produktgruppe",
  "MERCHANDISE_BRAND": "Handelsmarke",
  "FRONT": "Artikel Front",
  "LABEL_TYPE": "Label Typ",
  "SPRING": "Pusherstärke",
  "SHEFT_TYPE": "Regaltyp",
  "SHELF_MANUFACTURER": "Regal Hersteller",
  "SHELF_LENGHT": "Regalboden Länge",
  "SHELF_DEPTH": "Regalboden Tiefe",
  "EXTERNAL_CATEGORY": "Category",
  "EXTERNAL_SUB_CATEGORY": "Subcategory",
  "MAIN_FUNCTION": "Main function",
  "STORE_CATEGORY": "Store department",
  "SAMPLE_ORDER": "Send sample order",
  "SAMPLE": "Muster",
  "OFFER": "Angebot",
  "PURCHASED": "Purchased",
  "DURATION": "Dauer",
  "HOURS": "Std",
  "PORTE_ETIQUETTE": "Label Holder",
  "IMPULSE_PROMOTION": "Impulse & Promotion",
  "CONTACT_NAME": "Name",
  "EMAIL_QUOTE_ORDER_CONFIRMATION": "E-Mail-Angebot/Bestellbestätigung",
  "EMAIL_QUOTE_ORDER_CONFIRMATION_TEMPORARY": "E-Mail-Angebot / Auftragsbestätigung - temporär *",
  "EMAIL_QUOTE_TEMPORARY_REQUIRED": "E-Mail-Angebot/Bestellbestätigung - temporäres Feld erforderlich",
  "CREDIT_BLOCKED_MODAL_HTML": "Das Guthaben des Kunden <b>{{customerName}}</b> ist gesperrt, daher können keine Bestellungen aufgegeben werden.",
  "CREDIT_SOFT_BLOCKED_MODAL_HTML": "Der Kunde <b>{{customerName}}</b> kann nur Angebote abgeben, weil der aktuelle Warenkorbwert {{total}} {{currency}} beträgt und sein verbleibendes Guthaben {{credit}} {{currency}}.",
  "CREDIT_SOFT_BLOCKED": "Nur Angebote verfügbar",
  "SHELF_AUTOMATION": "Regalautomatisierung",
  "EMAIL_QUOTE_TEMPORARY_INVALID": "Ungültige E-Mail-Adresse angegeben!",
  "ORDER_TITLE": "Bestellung",
  "EMAIL_ORDER_CONFIRMATION_TEMPORARY": "Temporäre Bestellbestätigungs-E-Mail",
  "MESSAGE_EMAIL_ORDER_CONFIRMATION_TEMP": "Die in diesem Feld hinzugefügten Informationen sind die E-Mail-Adresse, an die die Bestellbestätigung gesendet wird. Wenn Sie dieses Feld leer lassen, wird die Bestellbestätigung an die Standard-E-Mail-Adresse gesendet.",
  "CREDIT_BLOCKED_MODAL_HTML_1210_FI": "Leider kann die Bestellung nicht gespeichert werden. Bitte wenden Sie sich an den Kundendienst: info.fi@hl-display.com / +358 9 562 9180",
  "CREDIT_BLOCKED_MODAL_HTML_1210_SE": "Leider kann die Bestellung nicht gespeichert werden. Bitte wenden Sie sich an den Kundendienst: info.se@hl-display.com / +46 8 683 7363",
  "CREDIT_BLOCKED_MODAL_HTML_1210_NO": "Leider kann die Bestellung nicht gespeichert werden. Bitte wenden Sie sich an den Kundendienst: info.no@hl-display.com / +47 66 983 600",
  "CREDIT_BLOCKED_MODAL_HTML_1810_DE": "Leider kann die Bestellung nicht gespeichert werden. Bitte wenden Sie sich an den Kundendienst: xxx@hl-display.com / xxx",
  "CREDIT_BLOCKED_MODAL_HTML_2400_CH": "Leider kann die Bestellung nicht gespeichert werden. Bitte wenden Sie sich an den Kundendienst: xxx@hl-display.com / xxx",
  "CREDIT_BLOCKED_MODAL_HTML_1600_FR_OR_NL": "Leider konnten wir Ihre Bestellung nicht bestätigen. Könnten Sie unseren Kundenservice unter 02 47 48 85 00 kontaktieren? Dank im Voraus.",
  "CREDIT_BLOCKED_MODAL_HTML_1600_BE": "Leider konnten wir Ihre Bestellung nicht bestätigen. Könnten Sie unseren Kundenservice unter 02 47 48 85 00 kontaktieren? Dank im Voraus.",
  "INTERNAL_CREDIT_BLOCKED_MODAL_HTML": "Leider kann die Bestellung nicht gespeichert werden, wenden Sie sich bitte an den Kundendienst.",
  "CREDIT_BLOCKED_MODAL_HTML_CUSTOMER_SERVICE": "Leider kann die Bestellung nicht gespeichert werden. Bitte wenden Sie sich an den Kundendienst: {customerServiceEmail} / {customerServicePhoneNumber}",
  "BASECOST_S_U": "Basecost S / U",
  "PRODUCT_LIST": "Product list",
  "SEE_YOUR_AGREED_ASSORTMENT_AND_PRICES": "See your agreed assortment and prices",
  "FIND_YOUR_PREVIOUS_ORDERS_TWO_YEAR_BACK": "Find your previous orders two year back",
  "GO_TO_YOUR_FAVOURITE_PRODUCTS": "Go to your favourite products",
  "FIND_OUR_CONTECT_INFORMATION_BY_CLICKING_HERE": "Find our contect information by clicking here",
  "HELP_US_IMPROVE": "Help us improve",
  "IF_YOU_WANT_TO_SEND_US_FEEDBACK_OR_HAVE_IDEAS_FOR_IMPROVEMENTS": "If you want to send us feedback or have ideas for improvements on the portal, please use this form.",
  "NEW_FREIGHT_INFORMATION": "<span class='highlight'>Frachtkosten:</span> {{value}} {{currency}}, wenn der Bestellwert unter {{min}} {{currency}} liegt",
  "NEW_MINIMUM_ORDER_INFORMATION": "<span class='highlight'>Mindestbestellwert:</span> {{value}} {{currency}}",
  "NEW_ADMIN_FEE_INFORMATION": "<span class='highlight'>Verwaltungskosten:</span> {{value}} {{currency}} für jede Bestellung unter {{min}} {{currency}}"
}
