import { Injectable } from '@angular/core';
import {
  OpenOrdersConfigService,
  OrderService,
  SelectedCustomerService,
  MetadataService,
  CustomerService,
  Order
} from 'gung-standard';
import { TranslateService } from '@ngx-translate/core';
import { ListLayout, ListLayoutMultipleComponent, SimpleConfigBaseFilter, ConfigBaseFilter } from 'gung-list';
import { switchMap, mergeMap, map, first } from 'rxjs';
import { forkJoin, of } from 'rxjs';
import { differenceInDays } from 'date-fns';
import { JeevesOpenOrderTableListComponent } from '../../components/jeeves-open-order-table-list/jeeves-open-order-table-list.component';

export class OrderDateStringFilter extends SimpleConfigBaseFilter<Order> {
  constructor(protected translateService: TranslateService) {
    super();
  }

  getName(): string {
    return 'ORDER_DATE';
  }

  getOptionIds(item: Order): string[] {
    const dayDifferense = differenceInDays(new Date(), new Date(item.extra.oh.orddatum));

    if (dayDifferense <= 1) {
      return ['TODAY'];
    }
    if (dayDifferense <= 7) {
      return ['THIS_WEEK'];
    }
    if (dayDifferense <= 30) {
      return ['THIS_MONTH'];
    }
    if (dayDifferense <= 90) {
      return ['LAST_THREE_MONTHS'];
    }
    return ['OVER_THREE_MONTHS'];
  }

  getOptionName(key: string): string {
    return this.translateService.instant(key);
  }
}

class OrderExpectedDeliveryFilter extends SimpleConfigBaseFilter<Order> {
  constructor(protected translateService: TranslateService) {
    super();
  }

  getName(): string {
    return 'EXPECTED_DELIVERY_DATE';
  }

  getOptionIds(item: Order): string[] {
    const dayDifferense = differenceInDays(new Date(), new Date(item.extra.oh.ordberlevdat));

    if (dayDifferense <= 1) {
      return ['TODAY'];
    }
    if (dayDifferense <= 7) {
      return ['THIS_WEEK'];
    }
    if (dayDifferense <= 30) {
      return ['THIS_MONTH'];
    }
    if (dayDifferense <= 90) {
      return ['LAST_THREE_MONTHS'];
    }
    return ['OVER_THREE_MONTHS'];
  }

  getOptionName(key: string): string {
    return this.translateService.instant(key);
  }
}

class OrderStatusFilter extends SimpleConfigBaseFilter<Order> {
  constructor(protected translateService: TranslateService, protected metadataService: MetadataService) {
    super();
  }

  getName(): string {
    return 'ORDER_STATUS';
  }
  getOptionIds(item: Order): string[] {
    if (item.extra.oh.ordstat === 13) {
      return ['REGISTERED'];
    }

    if (item.extra.oh.ordstat === 10) {
      return ['ORDER_STATUS_WARNING_ENTRY_IN_PROGRESS'];
    }

    if (item.extra.oh.ordstat === 5) {
      return ['WEBORDER'];
    }

    if (item.extra.oh.ordstat === 11) {
      return ['QUOTE'];
    }

    return ['UNDEFINED'];
  }
  getOptionName(key: string): string {
    const value = this.metadataService.getMetadataValue('x7', 'ordstatbeskr', key);
    return this.translateService.instant(key);
  }
}

@Injectable({
  providedIn: 'root'
})
export class JeevesOpenOrdersListTableConfigService extends OpenOrdersConfigService {
  constructor(
    protected translationService: TranslateService,
    protected orderService: OrderService,
    protected selectedCustomerService: SelectedCustomerService,
    protected metadataService: MetadataService,
    protected customerService: CustomerService
  ) {
    super(translationService, orderService, selectedCustomerService, metadataService, customerService);
  }

  getLayouts(): ListLayout<Order>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => JeevesOpenOrderTableListComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'Product grid'
      }
    ];
  }

  getSearchTerms(item: Order): string[] {
    const result = [item.id];

    if (item.extra.customerName) {
      result.push(item.extra.customerName);
    }

    return result;
  }

  getItems() {
    return this.orderService.getOpenOrders().pipe(
      mergeMap(items => forkJoin([of(items), this.customerService.getCustomers().pipe(first())])),
      map(([orders, customers]) => {
        const result = orders.map(order => {
          return {
            ...order,
            extra: {
              ...order.extra
            }
          };
        });

        result.map(resOrder => {
          const cust = customers.find(customer => customer.id === resOrder.deliveryCustomerId);
          resOrder.extra.customerName = cust ? cust.name : '';
          return resOrder;
        });

        const sorted = result.sort((a, b) => {
          if (a.extra.oh.orddatum > b.extra.oh.orddatum) {
            return 1;
          }
          if (a.extra.oh.orddatum < b.extra.oh.orddatum) {
            return -1;
          }
          if (a.id > b.id) {
            return 1;
          }
          if (a.id < b.id) {
            return -1;
          }
          return 0;
        });

        return sorted.reverse();
      })
    );
  }

  getFilters(): ConfigBaseFilter<Order>[] {
    return [
      new OrderDateStringFilter(this.translationService),
      new OrderExpectedDeliveryFilter(this.translationService),
      new OrderStatusFilter(this.translationService, this.metadataService)
    ];
  }
}
