import { Component, OnInit } from '@angular/core';
import { BackendFeatureService, CheckoutStepComponent, SelectedCustomerService } from 'gung-standard';
import { HlDisplayAdditionalCostsService } from 'projects/hl-display/src/app/services/hl-display-additional-costs.service';
import {
  HlDisplayPortalFeaturesService,
  CheckoutSettingsGroup
} from 'projects/hl-display/src/app/services/hl-display-portal-features.service';
import { first, forkJoin, of, switchMap } from 'rxjs';

@Component({
  selector: 'app-hl-display-additional-costs-step',
  templateUrl: './hl-display-additional-costs-step.component.html',
  styleUrls: ['./hl-display-additional-costs-step.component.css']
})
export class HlDisplayAdditionalCostsStepComponent extends CheckoutStepComponent implements OnInit {
  private additionalCostsFeatureFlagId = 'trade-policy';

  constructor(
    private portalFeatureService: HlDisplayPortalFeaturesService,
    private additionalCostsService: HlDisplayAdditionalCostsService,
    private selectedCustomerService: SelectedCustomerService
  ) {
    super();
  }

  isVisible(): boolean {
    return false;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.onNextBtnClicked.subscribe(_ => {
      forkJoin({
        checkoutSettingsGroup: this.portalFeatureService.getPortalFeatureGroup('orderCheckoutSettingsGroup').pipe(first()),
        selectedCustomer: this.selectedCustomerService.getSelectedCustomer().pipe(first())
      })
        .pipe(
          switchMap(({ checkoutSettingsGroup, selectedCustomer }) => {
            return forkJoin({
              checkoutSettingsGroup: of(checkoutSettingsGroup),
              selectedCustomer: of(selectedCustomer),
              additionalCostsTermsObject: this.additionalCostsService.getAdditionalCostTerms(selectedCustomer)
            });
          })
        )
        .subscribe(({ checkoutSettingsGroup, selectedCustomer, additionalCostsTermsObject }) => {
          // When we don't have the feature active, we don't need to do anything
          let settings = checkoutSettingsGroup as CheckoutSettingsGroup;

          if (!settings.useTradePolicy) {
            this.stepDone.emit(this.checkout)
          } else {
            let currencyCode = '';
            if (!!selectedCustomer.extra && selectedCustomer.extra.kus) {
              currencyCode = selectedCustomer.extra.kus.valkod || '';
            }
            this.additionalCostsService.addFreightChargeToCheckout(
              this.checkout,
              additionalCostsTermsObject,
              currencyCode
            );
            this.additionalCostsService.addAdminFeeToCheckout(this.checkout, additionalCostsTermsObject, currencyCode);
            this.additionalCostsService.addMinOrderToCheckout(this.checkout, additionalCostsTermsObject, currencyCode);
            this.stepDone.emit(this.checkout)
          }
        });

    })
  }
}
