import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { DeliveryDatePickerComponent, DeliveryDateService, CalendarDate, GungModalService, CartService } from 'gung-standard';
import { DateUtilService } from 'gung-common';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { isDate, isSameDay } from 'date-fns';
import { HlDeliveryDateService } from '../../services/hl-delivery-date.service';
import { first, takeUntil } from 'rxjs';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-hl-delivery-date-picker',
  templateUrl: './hl-delivery-date-picker.component.html',
  styleUrls: ['./hl-delivery-date-picker.component.css']
})
export class HlDeliveryDatePickerComponent extends DeliveryDatePickerComponent implements OnInit, OnDestroy {
  @Input()
  protected deliveryMethod: string;

  @Output()
  protected selectedDate = new EventEmitter<CalendarDate>();

  @Input()
  protected productId?: string;

  @Input()
  protected partialId?: string;

  constructor(
    protected deliveryDateService: HlDeliveryDateService,
    public dateUtilService: DateUtilService,
    protected gungModalService: GungModalService,
    protected cartService: CartService
  ) {
    super(deliveryDateService, dateUtilService, gungModalService);
  }

  ngOnInit() {
    this.deliveryDateService
      .getDeliveryDates(this.deliveryMethod)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(dates => {
        if (!dates) {
          return;
        }

        this.dates = dates;
        const newFirstDateAvailable = this.dates.find(date => date.valid);

        if (!!this.firstDateAvailable && newFirstDateAvailable) {
          if (isSameDay(newFirstDateAvailable.date, this.firstDateAvailable.date)) {
            return;
          }
        }

        if (!!newFirstDateAvailable) {
          this.firstDateAvailable = newFirstDateAvailable;
        }

        this.firstDatePickerDate = new NgbDate(
          this.firstDateAvailable.date.getFullYear(),
          this.firstDateAvailable.date.getMonth() + 1,
          this.firstDateAvailable.date.getDate()
        );

        if (!this.ngModel) {
          this.ngModel = this.firstDatePickerDate;
        }

        this.loaded = true;
      });

    if (!!this.productId) {
      this.cartService.getProductExtra(this.productId, this.partialId).pipe(takeUntil(this.unsubscribe)).subscribe(x => {
        const newDateString: string = x.orp.ordberlevdat;

        const newDate = new Date(newDateString);

        const selectedDate = new NgbDate(
          newDate.getFullYear(),
          newDate.getMonth() + 1,
          newDate.getDate()
        )

        this.ngModel = selectedDate;

        this.loaded = true;
      });
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
