import { Injectable } from '@angular/core';
import { SimpleConfigBaseFilter } from 'gung-list';
import { AuthService, Product } from 'gung-standard';
import { environment } from '../../../environments/environment';
import { first } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProductLengthFilterService extends SimpleConfigBaseFilter<Product> {
  isManager = false;

  constructor(private authService: AuthService) {
    super();

    this.authService.getHasRoles('MANAGERS').pipe(first()).subscribe(isManager => {
      this.isManager = isManager
    });
  }

  getName(): string {
    return 'LENGTH';
  }
  getOptionIds(item: Product): string[] {
    if (this.isManager) {
      return [item.extra.hlPimItem?.Widthmm];
    }
    return [item.extra.hlPimItem.ItemShelfLength];
  }
  getOptionName(key: string): string {
    return key + ' mm';
  }
}
