import { Injectable } from '@angular/core';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class HlMultilangDatepickerService extends NgbDatepickerI18n {
  I18N_VALUES = {
    fr: {
      weekdays: ['Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa', 'Di'],
      months: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Aou', 'Sep', 'Oct', 'Nov', 'Déc']
    },
    en: {
      weekdays: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
      months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    },
    sv: {
      weekdays: ['Må', 'Ti', 'On', 'To', 'Fr', 'Lö', 'Sö'],
      months: ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec']
    },
    se: {
      weekdays: ['Må', 'Ti', 'On', 'To', 'Fr', 'Lö', 'Sö'],
      months: ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec']
    },
    no: {
      weekdays: ['Ma', 'Ti', 'On', 'To', 'Fr', 'Lø', 'Sø'],
      months: ['Jan', 'Feb', 'Mar', 'Apr', 'Kan', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Des']
    },
    de: {
      weekdays: ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'],
      months: ['Jan', 'Feb', 'Mär', 'Apr', 'Kan', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez']
    },
    nl: {
      weekdays: ['Ma', 'Di', 'Wo', 'Do', 'Vr', 'Za', 'Zo'],
      months: ['Jan', 'Feb', 'Mrt', 'Apr', 'Mei', 'Juni', 'Juli', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec']
    }
  };

  constructor(private languageService: TranslateService) {
    super();
  }

  getWeekdayShortName(weekday: number): string {
    return this.I18N_VALUES?.[this.languageService.currentLang]?.weekdays?.[weekday - 1] || this.I18N_VALUES?.['en']?.weekdays?.[weekday - 1];
  }

  getMonthShortName(month: number): string {
    return this.I18N_VALUES?.[this.languageService.currentLang]?.months?.[month - 1] || this.I18N_VALUES?.['en']?.months?.[month - 1];
  }

  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }

  getWeekdayLabel(weekday: number) {
    return this.I18N_VALUES?.[this.languageService.currentLang]?.weekdays?.[weekday - 1] || this.I18N_VALUES?.['en']?.weekdays?.[weekday - 1];
  }
}
