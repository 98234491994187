import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CheckoutStepComponent, Availability, ProductService } from 'gung-standard';
import { forkJoin, of } from 'rxjs';
import { first, mergeMap } from 'rxjs';
import { HlDisplayCartService } from '../../../../services/hl-display-cart.service';
import { HlValidationActionModalComponent } from '../../../hl-validation-action-modal/hl-validation-action-modal.component';

@Component({
  selector: 'app-hl-cart-prevalidation-step',
  templateUrl: './hl-cart-prevalidation-step.component.html',
  styleUrls: ['./hl-cart-prevalidation-step.component.css']
})
export class HlCartPrevalidationStepComponent extends CheckoutStepComponent implements OnInit {
  private orderLineItemNotInJeevesProductId = '155035';

  constructor(
    private cartService: HlDisplayCartService,
    private modalService: NgbModal,
    private productService: ProductService
  ) {
    super();
  }

  ngOnInit() {
    this.onNextBtnClicked.subscribe(_ => this.handleNextButtonClicked());
  }

  private handleNextButtonClicked() {
    this.cartService
      .getCurrentCart()
      .pipe(
        first(),
        mergeMap(rows =>
          forkJoin([
            of(rows),
            this.cartService.getCartRowsValidationData(rows).pipe(first()),
            this.productService.getProductsByIds(rows.map(r => r.productId))
          ])
        )
      )
      .subscribe(([cartRows, validationData, products]) => {
        const rowsToHandle = cartRows
          .filter(cartRow => cartRow.productId !== this.orderLineItemNotInJeevesProductId)
          .filter(cartRow => {
            const validation = validationData.find(data => data.productId === cartRow.productId);
            if (!validation) {
              console.log('Row did not contain validation data', cartRow);
              return false;
            }
            const availabilityData = validation.availabilityData as Availability;
            return cartRow.qty > availabilityData.maxFutureStock;
          });

        if (rowsToHandle.length > 0) {
          const modalReference = this.modalService.open(HlValidationActionModalComponent, {
            backdrop: 'static',
            size: 'lg',
            keyboard: false
          });

          modalReference.componentInstance.cartRows = rowsToHandle;
          modalReference.componentInstance.validationData = validationData;
          modalReference.componentInstance.products = products;

          modalReference.result.then(
            () => {
              this.stepDone.emit(this.checkout);
            },
            error => {
              this.stepError.emit({
                error,
                message: 'Not all undeliverable rows were handled',
                step: 'HlCartPrevalidationStepComponent'
              });
            }
          );
        } else {
          this.stepDone.emit(this.checkout);
        }
      });
  }

  isVisible() {
    return false;
  }
}
