import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonModalService } from 'gung-common';
import { GungModalService, SupplierPurchaseOrder } from 'gung-standard';
import { JeevesSupplierPoSplitModalComponent } from '../../components/supplier/jeeves-supplier-po-split-modal/jeeves-supplier-po-split-modal.component';

@Injectable({
  providedIn: 'root'
})
export class JeevesGungModalService extends GungModalService {

  constructor(protected modalService: NgbModal) {
    super(modalService);
  }

  openSupplierPurchaseOrderSplitModal(supplierPurchaseOrder: SupplierPurchaseOrder) {
    const id = JeevesSupplierPoSplitModalComponent.name;

    const ref = this.modalService.open(JeevesSupplierPoSplitModalComponent, {
      size: 'xl'
    });
    ref.componentInstance.delegate = this;
    ref.componentInstance.supplierPurchaseOrder = supplierPurchaseOrder;
    this.modalRef.set(id, ref);
    return ref.result;
  }
}
