import { Component, OnInit } from '@angular/core';
import { ProductListConfigService } from 'gung-standard';
import { HlProductListConfigByTopProductsService } from '../../services/hl-product-list-config-by-top-products.service';

@Component({
  selector: 'app-product-list-by-top-products',
  template: '<lib-filter-list #productListByTopProducts [configService]="productListConfigService"></lib-filter-list>',
  styleUrls: ['./product-list-by-top-products.component.css'],
  providers: [
    {
      provide: ProductListConfigService,
      useClass: HlProductListConfigByTopProductsService
    }
  ]
})
export class ProductListByTopProductsComponent implements OnInit {
  constructor(public productListConfigService: ProductListConfigService) {}

  ngOnInit() {}
}
