import { Inject, Injectable, OnInit, Optional } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CommonNavigationConfig, DateUtilService, GungLanguageConfigService } from 'gung-common';
import { first, map, Observable, tap } from 'rxjs';
import { HlDisplayPortalFeaturesService, LanguageSettingsGroup } from './hl-display-portal-features.service';

@Injectable({
  providedIn: 'root'
})
export class HlDisplayDateUtilService extends DateUtilService implements OnInit {
  constructor(
    protected translateService: TranslateService,
    protected languageConfigService: GungLanguageConfigService,
    protected hlDisplayPortalFeaturesService: HlDisplayPortalFeaturesService,
    @Optional()
    @Inject('environment')
    protected environment: CommonNavigationConfig
  ) {
    super(translateService, languageConfigService, environment);
  }
  private langSettingsObservable: Observable<LanguageSettingsGroup> = undefined;

  ngOnInit(): void {
  }

  public getLangSettingsObservable(): Observable<LanguageSettingsGroup> {
    if(!this.langSettingsObservable){
      this.langSettingsObservable = this.hlDisplayPortalFeaturesService.getPortalFeatureGroup('languageSettingsGroup').pipe(
        first(),
        map(languageSettingsGroup => languageSettingsGroup as LanguageSettingsGroup),
        tap(langSettings => this.dateFormat = langSettings.dateFormat)
      )
    }
    return this.langSettingsObservable;
  }
}
