import { Injectable, OnInit } from '@angular/core';
import { MetadataService, OrderListConfigService, OrderService, SelectedCustomerService } from 'gung-standard';
import { Order } from 'gung-standard';
import { ConfigBaseFilter, ListLayout, ListLayoutComponent, ListLayoutSingleComponent } from 'gung-list';
import { Observable, first, forkJoin, map, of, switchMap } from 'rxjs';
import {
  HlDisplayPortalFeaturesService,
  OpenOrdersSettingsGroup,
  OrderSearchSettingsGroup
} from './hl-display-portal-features.service';
import { HlDisplayJeevesOrderCardListComponent } from '../components/hl-display-open-orders-quotes/hl-display-jeeves-order-card-list/hl-display-jeeves-order-card-list.component';
import { OrderDateFilter, OrderExpectedDeliveryFilter, OrderStatusFilter } from 'gung-standard-jeeves';
import { HlDisplayOrderPODFilter } from './hl-display-jeeves-order-list-config.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class HlDisplayOrderListConfigService extends OrderListConfigService {
  filterOrdersOnPOD: boolean = false;

  constructor(
    orderService: OrderService,
    selectedCustomerService: SelectedCustomerService,
    protected hlDisplayPortalFeaturesService: HlDisplayPortalFeaturesService,
    protected translationService: TranslateService,
    protected metadataService: MetadataService
  ) {
    super(orderService, selectedCustomerService);
  }

  getFilters(): ConfigBaseFilter<Order>[] {
    const filters = [
      new OrderDateFilter(),
      new OrderExpectedDeliveryFilter(),
      new OrderStatusFilter(this.metadataService)
    ];
    this.hlDisplayPortalFeaturesService
      .getPortalFeatureGroup('openOrdersSettingsGroup')
      .pipe(first())
      .subscribe(openOrdersSettingsGroup => {
        let openOrdersGroup = openOrdersSettingsGroup as OpenOrdersSettingsGroup;
        this.filterOrdersOnPOD = openOrdersGroup.filterOrdersOnPOD;
        if (openOrdersGroup.filterOrdersOnPOD) {
          filters.push(new HlDisplayOrderPODFilter(this.translationService));
        }
      });
    return filters;
  }

  getLayouts(): ListLayout<Order>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => HlDisplayJeevesOrderCardListComponent,
        getListLayoutComponent: () => ListLayoutSingleComponent,
        getName: () => 'Product grid'
      }
    ];
  }

  getLoadMoreCss(): string {
    return 'mt-1 btn-primary col-10';
  }

  getSearchTerms(item: Order): string[] {
    const res = [...super.getSearchTerms(item)];

    if (item.extra.oh.ordlevplats1 && item.extra.deliveryCustomer.extra.lp) {
      const lp = item.extra.deliveryCustomer.extra.lp.find(l => l.ordlevplats1 === item.extra.oh.ordlevplats1);
      if (lp && lp._fr.ftgnamn) {
        res.push(lp._fr.ftgnamn);
      }
    }

    return res;
  }

  getSearchPlaceholderTranslate(): string {
    return 'SEARCH';
  }

  getItems(): Observable<Order[]> {
    return forkJoin([
      super.getItems().pipe(first()),
      this.hlDisplayPortalFeaturesService.getPortalFeatureGroup('orderSearchSettingsGroup').pipe(
        first(),
        map(orderSettings => orderSettings as OrderSearchSettingsGroup)
      )
    ]).pipe(
      switchMap(([orders, orderSettings]) => {
        if (!orderSettings.showCancelledOrders) {
          orders = orders.filter(
            o =>
              !(
                o.extra?.oh?.ordstat === 90 ||
                o.extra?.oh?.ordtyp === 101 ||
                o.extra?.oh?.ordtyp === 201 ||
                o.extra?.oh?.q_hl_edi_cancel === true
              )
          );
        }
        return of(orders);
      })
    );
  }
}
