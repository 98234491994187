<div class="container bg-white">
    <div class="row pt-4 mb-5">
        @for(breadcrumb of breadcrumbs; track breadcrumb.url; let isFirst = $first) {
          @if(!isFirst) {
            <div class="col-auto p-0"> 
                <h3>></h3>
            </div>
          }
          <div class="col-auto">
            <a routerLink="{{breadcrumb.url}}">
                <h3>{{breadcrumb.label}}</h3>
            </a>
          </div>
        }
    </div>
    <div class="row mb-2 justify-content-center" *ngIf="breadcrumbs?.length < 2">
        @for(portal of portals; track portal) {
            <div class="col-12 col-sm-3 mb-2">
                <a class="card border h-100" routerLink="{{portal}}/features">
                    <div
                        class="card-body"
                        title="Here you can browse your EDI customers and change their settings"
                        >
                        <h5 class="card-title text-center">{{portal}}</h5>
                    </div>
                </a>
            </div>
        }
    </div>
    <router-outlet></router-outlet>    
</div>
