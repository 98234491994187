// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  availability: {
    // Default for legacy implementation outside nordic
    useConfigTransform: false,
    bufferDays: null,
    showTransportTime: true
  },
  getArticlesOutsidePriceLists: undefined,
  copyToCartExcludedProductIds: ['527859', '900000', '900001', '900002', '900003', '900004', '900006', '900009'],
  requestPricelistAssortmentWithWebCondition: false,
  tagManagerId: '',
  showCreateSampleOrder: false,
  managers: false,
  mainCustomer: '',
  subCustomer: '',
  showPreviousOrderFilter: false,
  oauth2Enabled: false,
  oauth2SkipRedirectUrls: []
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
