import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Inject, Optional } from '@angular/core';
import { NgbDate, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { format } from 'date-fns';
import { DateUtilService, gungComparatorHelper, gungGetPropertyOfObject, gungSetPropertyOfObject } from 'gung-common';
import {
  AuthService,
  AvailabilityService,
  CalendarDate,
  CartListCheckoutConfigService,
  CartListRow,
  CartRow,
  CartService,
  CartTotalsService,
  CheckoutCartListComponent,
  Customer,
  GungFlowService,
  GungModalService,
  MetadataService,
  PriceService,
  ProductService,
  SelectedCustomerService
} from 'gung-standard';
import { JeevesSalesCartListRow } from 'gung-standard-jeeves';
import { catchError, debounceTime, first, forkJoin, map, of, takeUntil } from 'rxjs';
import {
  CheckoutSettingsGroup,
  HlDisplayPortalFeaturesService,
  LanguageSettingsGroup
} from '../../../services/hl-display-portal-features.service';
import { HlDisplayDateUtilService } from '../../../services/hl-display-date-util.service';
import { HlDisplayCheckoutAddRowModalComponent } from '../../hl-display-checkout-add-row-modal/hl-display-checkout-add-row-modal.component';

@Component({
  selector: 'app-hl-cart-list',
  templateUrl: './hl-cart-list.component.html',
  styleUrl: './hl-cart-list.component.scss'
})
export class HlCartListComponent extends CheckoutCartListComponent {
  private orderLineItemNotInJeevesProductId = '155035'; //Check this?

  isSales = false;
  isProjectManager = false;
  stockId: string;
  dateFormat: string;
  shouldAddInstallationChargeAutomatically = false;
  shouldAddInstallationChargeManually = false;
  protected installationChargeProductId: string;
  protected hasChanged: boolean = false;
  changeToAnyQty: boolean = false;
  showDates: boolean = false;
  selectedCustomer: Customer;

  constructor(
    protected cartService: CartService,
    protected gungFlowService: GungFlowService,
    protected productService: ProductService,
    protected priceService: PriceService,
    protected availabilityService: AvailabilityService,
    public dateUtilService: HlDisplayDateUtilService,
    protected authService: AuthService,
    public cartListCheckoutConfig: CartListCheckoutConfigService,
    protected gungModalService: GungModalService,
    protected featuresService: HlDisplayPortalFeaturesService,
    protected translateService: TranslateService,
    protected selectedCustomerService: SelectedCustomerService,
    protected cartTotalsService: CartTotalsService,
    protected metadataService: MetadataService,
    protected ngbModal: NgbModal,
    protected selectedCustomerSerivce: SelectedCustomerService,
    @Optional()
    @Inject('environment')
    protected environment: { [s: string]: any }
  ) {
    super(
      cartService,
      gungFlowService,
      productService,
      priceService,
      availabilityService,
      dateUtilService,
      authService,
      cartListCheckoutConfig,
      gungModalService
    );
  }

  ngOnInit() {
    this.onNextBtnClicked.subscribe(_ => this.nextButtonClick());

    forkJoin({
      selectedCustomer: this.selectedCustomerService.getSelectedCustomer().pipe(first()),
      roles: this.authService.getRoles().pipe(first()),
      settings: this.featuresService.getPortalFeatureGroup('orderCheckoutSettingsGroup').pipe(
        first(),
        map(checkoutSettingsGroup => checkoutSettingsGroup as CheckoutSettingsGroup)
      ),
      languageSettings: this.featuresService.getPortalFeatureGroup('languageSettingsGroup').pipe(
        first(),
        map(res => res as LanguageSettingsGroup)
      )
    })
      .pipe(first())
      .subscribe(({ selectedCustomer, roles, settings, languageSettings }) => {
        this.isSales = roles.filter(role => role.toUpperCase() === 'SALES').length > 0;
        this.isProjectManager = roles.indexOf('MANAGER') > -1;
        this.changeToAnyQty = this.isSales || this.isProjectManager || settings.changeToAnyQtyAsUser;
        this.selectedCustomer = selectedCustomer;
        //Fallback if the setting is not present in DBconfig in backend.
        this.showDates = settings.showDateInCheckout;

        if (this.showDates === undefined) {
          this.showDates = true;
        }

        this.shouldAddInstallationChargeAutomatically = settings.addInstallationChargeAutomatically;
        this.shouldAddInstallationChargeManually = settings.addInstallationChargeManually;
        this.installationChargeProductId = settings.installationChargeProductId;
        this.dateFormat = languageSettings.dateFormat;
      });
    this.authService
      .getCurrentUser()
      .pipe(first())
      .subscribe(user => {
        const multiStockIds = user.managedMultistockIds;
        this.stockId = multiStockIds[0];
      });

    this.featuresService
      .getPortalFeatureGroup('priceSettingsGroup')
      .pipe(
        first(),
        catchError(_ => of({}))
      )
      .subscribe(res => {
        this.getCurrentCartInfo();
        this.columns = this.cartListCheckoutConfig.cartListColumns;
      });
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.mappedData, event.previousIndex, event.currentIndex);

    this.cartService.reorderCart(
      this.mappedData.map(row => ({
        productId: row.cartRow.productId,
        targetStockId: row.cartRow.targetStockId,
        productPartialId: row.cartRow.productPartialId
      }))
    );
  }

  getCurrentCartInfo() {
    this.cartService
      .getCurrentCart()
      .pipe(debounceTime(500), takeUntil(this.unsubscribe))
      .subscribe(cart => {
        this.loading = true;

        // These sorts below might seem weird, but they are necessary to avoid a bug in the cart where items got flipped
        // or changed order when increasing the items in the cart. The problem before was that we had a single sort on
        // position, but when we had no position the items could get in a state where we received different order from
        // `getCurrentCart` and therefore the items got flipped each time.
        // The solution is to sort first on productId, then on timeAdded and lastly on position. This way we get the
        // intended behaviour without the bug.
        // It is not enough to only sort on time added, since that can be the same for all items, for example when copying
        // from a quote / order.
        cart = cart.sort((a, b) => gungComparatorHelper(a.productId, b.productId, 1));
        cart = cart.sort((a, b) => gungComparatorHelper(a.timeAdded, b.timeAdded, 1));
        cart = cart.sort((a, b) => gungComparatorHelper(a.extra._position, b.extra._position, 1));

        this.checkout = {
          ...this.checkout,
          rows: cart.map(r => ({
            id: r.productId,
            partialId: r.productPartialId,
            targetStockId: r.targetStockId,
            quantity: r.qty,
            extra: r.extra || {},
            rownr: r.rownr
          }))
        };
        this.cartRows = cart;
        this.getProductsInfo();
      });
  }

  createSingleDimentionCart(): void {
    this.mappedData = this.cartRows.map(cr => {
      const product = this.products.find(p => p.id === cr.productId);
      const price = this.prices.find(p => p.productId === cr.productId && p.productPartialId === cr.productPartialId);
      const av = this.availabilities.find(a => a.productId === cr.productId);
      const minDateJs: Date = this.getMinDate(av);
      let deliveryDate;
      const dateField = this.cartListCheckoutConfig.deliveryDateField;
      if (gungGetPropertyOfObject(dateField, cr.extra)) {
        const setDate = this.dateUtilService.parseDate(gungGetPropertyOfObject(dateField, cr.extra));
        deliveryDate = format(setDate, 'yyyy-MM-dd');
      }
      this.setFieldTodisplay(product);
      return {
        cartRow: cr,
        product: {
          ...product,
          extra: {
            ...product.extra,
            minDateJs
          }
        },
        price,
        availability: av,
        minDate: new NgbDate(minDateJs.getFullYear(), minDateJs.getMonth() + 1, minDateJs.getDate()),
        deliveryDate
      };
    });
    if (this.shouldAddInstallationChargeAutomatically) {
      this.addInstallationChargeFixed(0.1);
    }
  }

  setPrice(event: any, row: CartRow): void {
    // This informs the price service that the price has been manually modified, and therefore removes the disocunt.
    // If not, the discount would be applied on top of the manually modified price. - Adam
    if (event.target.value) {
      row = {
        ...row,
        extra: {
          ...row.extra,
          modifiedPrice: parseFloat(event.target.value)
        }
      };
    }

    super.setPrice(event, row);
  }

  openCommentsModal(row: JeevesSalesCartListRow) {
    this.gungModalService.openEditNoteModal((row.cartRow.extra.orp && row.cartRow.extra.orp.Editext) || '').then(
      result => {
        if (!result) {
          return;
        }
        if (result.note.trim() !== '') {
          this.cartService.setExtra(
            row.cartRow.productId,
            {
              orp: {
                ...row.cartRow.extra.orp,
                Editext: result.note
              }
            },
            row.cartRow.targetStockId,
            row.cartRow.productPartialId
          );
        } else {
          this.cartService.removeExtraField(
            row.cartRow.productId,
            'orp.Editext',
            row.cartRow.targetStockId,
            row.cartRow.productPartialId
          );
        }
      },
      dissmissReason => {
        console.log(dissmissReason);
      }
    );
  }

  addInstallationChargeFixed(value: number) {
    const productId = this.installationChargeProductId;

    if (productId == null || productId == '') {
      return;
    }

    const installationRow = this.mappedData?.find(d => d.product.id === productId);

    forkJoin([
      this.selectedCustomerService.getSelectedCustomer().pipe(first()),
      this.cartTotalsService.getTotals().pipe(first())
    ]).subscribe(([customer, totals]) => {
      /*const customerFinancialGroup = customer.extra.kus.q_financialgrpcode;
      const discountFactor = environment.discountCustomerFinancialGroup[customerFinancialGroup]
        ? environment.discountCustomerFinancialGroup[customerFinancialGroup]
        : 0;*/
      const discountFactor = 0;

      let price: string;
      if (installationRow) {
        price = ((totals.totalValue - installationRow.price.cartRowTotalPriceInclRowDiscount.value) * value).toFixed(2);
      } else {
        price = (totals.totalValue * value).toFixed(2);
      }

      const deliveryDate =
        (this.mappedData.length > 0 && this.mappedData[0].deliveryDate) || format(new Date(), 'yyyy-MM-dd');

      const theOrp = {
        vb_pris: price,
        ordantal: 1,
        rabatt1: undefined,
        artbeskr: this.translateService.instant('INSTALLATION_COST'),
        ordberlevdat: deliveryDate
      };

      if (!installationRow && this.mappedData.length > 0) {
        this.cartService.addToCart(productId, 1);
        this.cartService.setExtra(productId, {
          orp: theOrp,
          _discountCustomerFinancialGroupInfo: {
            appliedFactor: discountFactor,
            appliedTo: price,
            appliedFrom: 'HlDisplaySalesCartListComponent - addInstallationCharge()'
          }
        });
      }
      if (installationRow && this.mappedData.length > 1) {
        const currentPrice = installationRow.cartRow?.extra?.orp?.vb_pris;
        if (price && currentPrice !== price) {
          this.hasChanged = true;
          this.cartService.setExtra(productId, {
            orp: theOrp,
            _discountCustomerFinancialGroupInfo: {
              appliedFactor: discountFactor,
              appliedTo: price,
              appliedFrom: 'HlDisplaySalesCartListComponent - addInstallationCharge()'
            }
          });
        }
      }

      if (installationRow && this.mappedData.length === 1) {
        this.cartService.removeRow(installationRow.cartRow);
      }
    });
  }

  public setSelectedDeliveryDate(date: CalendarDate) {
    if (!this.checkout.extra.oh) {
      this.checkout.extra.oh = {};
    }
    this.checkout.extra.oh.q_ordberlevdat_cust = date.date;
    /* if (environment.bolag !== '1210') {
      this.checkout.extra.oh.q_ordbeglevdat_cust = date.date;
    }*/

    if ((date as any).estimatedShippingDate) {
      this.checkout.extra.oh.ordberlevdat = (date as any).estimatedShippingDate;
      /*if (environment.bolag !== '1210') {
        this.checkout.extra.oh.ordbeglevdat = (date as any).estimatedShippingDate;
      }*/

      this.cartService
        .getCurrentCart()
        .pipe(first())
        .subscribe(rows => {
          rows.forEach(row => {
            this.setDeliveryDate(row, this.checkout.extra.oh.ordberlevdat);
          });
        });
    }
  }

  public setDeliveryDate(cartRow: CartRow, deliveryDate: Date): void {
    // Clone the extra object if it exists, or create a new one if it doesn't
    const extra = cartRow.extra ? { ...cartRow.extra } : {};
    // Clone the orp object if it exists, or create a new one if it doesn't
    extra.orp = extra.orp ? { ...extra.orp } : {};

    // Set the ordberlevdat property on the cloned orp object
    extra.orp.ordberlevdat = this.getFormattedDateString(deliveryDate);
    // Pass the cloned and modified extra object to the cart service
    this.cartService.setExtra(cartRow.productId, extra, cartRow.targetStockId, cartRow.productPartialId);
  }

  public getFormattedDateString(date: Date): string {
    let dateString = '';
    dateString = dateString + date.getFullYear();

    const month = '' + (date.getMonth() + 1);
    if (month.length === 1) {
      dateString = dateString + '-' + '0' + month;
    } else {
      dateString = dateString + '-' + month;
    }

    const day = '' + date.getDate();
    if (day.length === 1) {
      dateString = dateString + '-' + '0' + day;
    } else {
      dateString = dateString + '-' + day;
    }
    return dateString;
  }

  addNewOrderLine(): void {
    this.ngbModal.open(HlDisplayCheckoutAddRowModalComponent, { size: 'lg', backdrop: 'static' });
  }

  getDescription(dataRow: CartListRow): string {
    if (dataRow.product.id === this.orderLineItemNotInJeevesProductId) {
      return `(${dataRow.cartRow.extra.orp.artbeskr})`;
    }

    return `(${dataRow.product.extra.ar.artbeskr})`;
  }
}
