import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs';

import { CalendarDate, DeliveryDateService, GungFlowService } from 'gung-standard';

@Injectable({
  providedIn: 'root'
})
export class HlDisplayDeliveryDateService extends DeliveryDateService {
  constructor(http: HttpClient, protected gungFlowService: GungFlowService) {
    super(http, gungFlowService);
  }

  public getDeliveryDates(deliveryMethod: string): Observable<CalendarDate[]> {
    const url = 'json/hl-display/calendar-dates';
    return this.http
      .get<CalendarDate[]>(url, {
        params: {
          deliveryMethodCode: deliveryMethod
        }
      })
      .pipe(
        map(datesResp =>
          datesResp.map(date => ({
            valid: date.valid,
            invalidReason: date.invalidReason,
            date: new Date(date.date)
          }))
        )
      );
  }
}
