<div *ngIf="products; else loading">
  <input
    id="typeahead-http"
    type="text"
    class="form-control"
    (selectItem)="onItemSelect($event)"
    [inputFormatter]="formatter"
    [resultFormatter]="formatter"
    [class.is-invalid]="searchFailed"
    [(ngModel)]="model"
    [ngbTypeahead]="search"
    placeholder="search term..."
  />
  <span *ngIf="searching">searching..</span>
  <div class="invalid-feedback" *ngIf="searchFailed">Sorry, suggestions could not be loaded.</div>
</div>

<ng-template #loading>
  <i class="fa fa-spinner fa-spin fa-lg"></i>
</ng-template>
