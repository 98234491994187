<div class="container" *ngIf="currentLang === 'fr'">
  <div class="row justify-content-center">
    <div class="col-12 col-lg-8 col-xl-7">
      <div class="mb-4">
        <h1 class="primary-header">Responsabilité d’entreprise</h1>
        <p>
          HL Display dispose une longue expérience dans le développement durable. Nous acceptons et respectons notre
          responsabilité envers toutes nos parties prenantes. Nous suivons une politique et des directives claires pour
          continuellement assurer la création de valeur.
        </p>
        <div class="mb-4">
          <img
            src="https://www.hl-display.com/imagevault/publishedmedia/ta2g8027tln8s93khn95/HL-Sustainable-Choice-003.jpg"
            class="img-fluid"
            alt=""
          />
        </div>
      </div>
      <div class="mb-5">
        <h1>Système de gestion</h1>
        <p>
          Une production responsable signifie exploiter les usines de manière efficace et respectueuse de
          l’environnement. HL Display s'efforce d'améliorer continuellement ses performances environnementales en se
          conformant aux politiques et aux normes, en réduisant l'impact environnemental de sa production et en
          travaillant avec des fournisseurs qui partagent les mêmes valeurs éthiques. Les systèmes de gestion et les
          politiques de la société en matière de qualité, de travail et de gestion garantissent une production de déchet
          minimale et le respect des normes de sécurité les plus strictes dans le processus de production.
        </p>
      </div>
      <div class="row flex-row justify-content-between">
        <div class="col-12 col-md-4 text-column">
          <div class="text-column-heading">
            <h5>Certification ISO</h5>
          </div>
          <p class="">
            HL Display opère en conformité avec les normes environnementales SS-EN ISO 9001 et SS-EN ISO 14001, 75% des
            installations de production et de stockage étant certifiées. Les sites de production de Sundsvall et Suzhou
            ainsi que le RDC Ouest (Region Distribution Center) remplissent également les exigences de la norme OHSAS
            18001 et nos usines de Gliwice (Pologne) et Harlow (Royaume-Uni) sont en cours de certification.
          </p>
        </div>
        <div class="col-12 col-md-4 text-column">
          <div class="text-column-heading">
            <h5>REACH</h5>
          </div>
          <p class="">
            Lorsqu’il s’agit de s’approvisionner et de produire de nouveaux matériaux, HL Display répond aux exigences
            de la norme REACH, règlement Européen sur les produits chimiques. Les fournisseurs doivent également
            garantir le respect de l’ensemble des lois environnementales applicables et signer un engagement écrit sur
            le respect de la réglementation REACH.
          </p>
        </div>
        <div class="col-12 col-md-4 text-column">
          <div class="text-column-heading">
            <h5>Consommation d’énergie</h5>
          </div>
          <p class="">
            L’utilisation de l’eau fait partie intégrante du processus de production lors du refroidissement des
            produits plastiques extrudés ou moulés. L’usine HL Display de Gliwice en Pologne, utilise un système de
            refroidissement fermé pour réduire la consommation d’eau. Dans le même temps, des travaux préparatoires sont
            en cours dans notre usine de Sundsvall en Suède pour réduire de manière significative la consommation d’eau.
          </p>
        </div>
      </div>

      <div class="mb-4">
        <h1>HL Sustainable Choice – le choix du développement durable</h1>
      </div>
      <div class="mb-5">
        <p>
          En 2017, 90,2% des déchets plastiques produits par la société ont été recyclés. L’objectif d’HL Display est
          d’utiliser les déchets plastiques de sa propre production mais également des déchets plastiques de sources
          externes pour produire de nouveaux produits. Pour aller plus loin, nous proposons maintenant des produits
          fabriqués en bioplastique, matière permettant d’éviter l’utilisation de pétrole. Notre ambition est de
          proposer une alternative durable pour 1/3 de notre assortiment d’ici la fin 2019.
        </p>
        <p>
          Ces nouvelles avancées en matière de production ont permis à l'entreprise de faire un pas de plus vers la
          réduction de l'impact environnemental et des déchets plastiques. En savoir plus sur HL Sustainable choice.
        </p>
      </div>

      <div class="mb-4">
        <h1>Responsabilité sociale</h1>
      </div>
      <div class="mb-5">
        <p>
          HL Display respecte ses partenaires commerciaux aux quatre coins du monde et s'efforce de promouvoir les
          normes d'éthique les plus strictes. Au cours de son développement, l’entreprise a commencé à opérer en Asie,
          en Europe et sur d'autres continents, nos normes de conduite des affaires ont ainsi été relevées afin de
          s’adapter à chaque région.
        </p>
        <p>
          Depuis 2010, HL Display est devenu membre des United Nations Global Compact (Pacte Mondial). Les valeurs
          fondamentales du Pacte Mondial et les 10 principes sur les droits de l’Homme, le droit du travail, les droits
          environnementaux et les normes anti-corruption font maintenant partie intégrante de la stratégie de
          l’entreprise, de sa culture et des décisions quotidiennes.
        </p>
        <p>
          Le rapport d'engagement publié annuellement par HL Display est un des éléments clés de l’engagement de la
          société au Pacte Global. En tant que leader sur le marché, nous nous efforçons non seulement de nous conformer
          à la législation en vigueur sur la Global Reporting Initiative (GRI), mais également de définir la norme en
          tant que partenaire de confiance pour les acteurs du marché.
        </p>
      </div>

      <div class="mb-4">
        <h1>Responsabilité financière</h1>
      </div>
      <div class="mb-5">
        <p>
          HL Display s’engage à atteindre la rentabilité et la croissance à long terme en appliquant des pratiques
          commerciales éthiques et en négociant de manière responsable. La majeure partie de la valeur économique
          générée par HL Display est distribuée aux partenaires de l'entreprise, notamment les fournisseurs, les
          employés, les propriétaires et les investisseurs. La société se conforme aux lois fiscales locales de chaque
          pays dans lequel nous opérons
        </p>
      </div>

      <div class="mb-4">
        <h1>Code de conduite</h1>
      </div>
      <div class="mb-5">
        <p>
          Le code de conduite d’HL Display témoigne de la position ferme de l'entreprise contre la corruption, en
          s’assurant que les employés et les parties prenantes fassent preuve d'honnêteté, d'intégrité et de
          responsabilité. Avec une position de non-tolérance vis-à-vis de la corruption ou de l'extorsion, un strict
          respect des exigences réglementaires et un service de dénonciation par tierce partie, le code de conduite
          définit clairement les principes fondamentaux que chaque représentant de l'entreprise devrait respecter.
        </p>
        <p>
          HL Display est un employeur qui garantit l'égalité des chances et adhère à sa propre politique de
          responsabilité d'entreprise (politique CR) définissant le mode de fonctionnement de l'entreprise. La société
          offre aux employés un lieu de travail sûr et propice au développement, en respectant le droit des employés à
          la négociation collective et en encourageant la participation à des syndicats.
        </p>
        <p>
          Une adhésion stricte à la politique de responsabilité de l’entreprise est attendue de la part des fournisseurs
          qui sont tenus de signer une politique d’achat contenant des exigences et des informations relatives aux
          droits de l’Homme, au droit du travail et à la lutte contre la corruption. Les représentants de HL Display
          rendent régulièrement visite à leurs fournisseurs pour surveiller les problèmes de conformité et d’adéquation
          aux normes environnementales.
        </p>
      </div>
    </div>
  </div>
</div>

<div class="container" *ngIf="currentLang === 'en'">
  <div class="row justify-content-center">
    <div class="col-12 col-lg-8 col-xl-7">
      <div class="mb-4">
        <h1 class="primary-header">Corporate responsibility</h1>
        <p>
          HL Display has a long track record in sustainable development. We accept and respect our responsibility to all
          of our stakeholders and follow clear policies and guidelines to ensure continued value creation.
        </p>
        <div class="mb-4">
          <img
            src="https://www.hl-display.com/imagevault/publishedmedia/ta2g8027tln8s93khn95/HL-Sustainable-Choice-003.jpg"
            class="img-fluid"
            alt=""
          />
        </div>
      </div>

      <div class="row flex-row justify-content-between">
        <div class="col-12 col-md-4 text-column">
          <div class="text-column-heading">
            <h5>Helping customers to reduce waste</h5>
          </div>
          <p class="">
            We offer products that are resource-efficient, not only in manufacturing but also in transportation and
            installation. We commit to give sufficient information for end-of-life treatment of products. We aim to
            offer a Sustainable Choice solution on one third of the assortment by the end of 2019.
          </p>
        </div>
        <div class="col-12 col-md-4 text-column">
          <div class="text-column-heading">
            <h5>Driving continuous improvement in operations with a focus on efficient use of resources</h5>
          </div>
          <p class="">
            We aim to recycle 100 percent of our production waste. We strive to use electricity from renewable sources.
            We minimise our water consumption. We aim to have all our factories and distribution centres ISO 14001 and
            OHSAS 18001 certified by 2020. We actively pursue projects with our partners in order to reduce the carbon
            footprint of our transports.
          </p>
        </div>
        <div class="col-12 col-md-4 text-column">
          <div class="text-column-heading">
            <h5>Ensuring responsibility throughout the supply chain</h5>
          </div>
          <p class="">
            We aim for all suppliers serving HL to sign our Purchasing Policy. We secure freedom of association,
            anti-discrimination, anti-corruption and workplace safety through a continuous pursuit of the objectives set
            out in our policies. We ensure all our employees sign and adhere to our Code of Conduct.
          </p>
        </div>
      </div>

      <div class="mb-4">
        <h1>HL Sustainable Choice</h1>
      </div>
      <div class="mb-5">
        <p>
          In 2018, 97.1 percent of the total plastic waste of the company's production facilities was recycled. HL
          Display's aim is to use plastic waste from its own production as well as recycled plastic from external
          sources to produce new products. Further, we are now offering products made with bio-based plastics instead of
          oil-based and it is our ambition to offer a sustainable alternative for 1/3 of our portfolio by the end of
          2019.
        </p>
        <p>
          These new advances in production have taken the company one step closer towards reducing the environmental
          impact of plastic waste.
        </p>
      </div>

      <div class="mb-4">
        <h1>Social responsibility</h1>
      </div>
      <div class="mb-5">
        <p>
          HL Display values and respects our trading partners in every corner of the globe and continually strive to
          promote the highest ethical standards. As the company expanded and began operating in Asia, Europe and other
          continents, our standards for conducting business were raised in order to adapt to each unique region.
        </p>
      </div>

      <div class="mb-4">
        <h1>Financial responsibility</h1>
      </div>
      <div class="mb-5">
        <p>
          HL Display are committed to achieving sustainability, profitability and long-term growth by applying ethical
          business practices and trading responsibly. The majority of economic value generated by HL Display is
          distributed to company stakeholders, which include suppliers, employees, owners and lenders. The company
          complies to local taxation laws in each country we are operating in.
        </p>
      </div>

      <div class="mb-4">
        <h1>Code of conduct</h1>
      </div>
      <div class="mb-5">
        <p>
          HL Display's Code of Conduct demonstrates the company's strong position against corruption, ensuring employees
          and stakeholders practice honesty, integrity and responsibility. With a no tolerance stance on bribery,
          extortion or corruption and strict adherence to regulatory requirements, the Code of Conduct clearly defines
          the fundamental principles that every company representative should follow.
        </p>
      </div>
    </div>
  </div>
</div>

<div class="container" *ngIf="currentLang === 'no'">
  <div class="row justify-content-center">
    <div class="col-12 col-lg-8 col-xl-7">
      <div class="mb-4">
        <h1 class="primary-header">Bedriftens samfunnsansvar</h1>
        <p>
          HL Display har en lang historie når det gjelder bærekraftig utvikling. Vi aksepterer og respekterer ansvaret
          vi har overfor alle våre interessenter og følger tydelige regler og retningslinjer for å sikre fortsatt
          verdiskaping.
        </p>
        <div class="mb-4">
          <img
            src="https://www.hl-display.com/imagevault/publishedmedia/ta2g8027tln8s93khn95/HL-Sustainable-Choice-003.jpg"
            class="img-fluid"
            alt=""
          />
        </div>
      </div>

      <div class="row flex-row justify-content-between">
        <div class="col-12 col-md-4 text-column">
          <div class="text-column-heading">
            <h5>Vi hjelper kundene å redusere svinn</h5>
          </div>
          <p class="">
            Vi tilbyr produkter som er ressurseffektive – både i produksjonsleddet, under transport og ved installasjon
            i butikk. Vi gir utfyllende informasjon om hvordan produktene skal kasseres etter bruk. Vi tar sikte på å
            tilby minimum en tredjedel av våre produkter som bærekraftige alternativer innen utgangen av 2019.
          </p>
        </div>
        <div class="col-12 col-md-4 text-column">
          <div class="text-column-heading">
            <h5>Vi driver kontinuerlige virsomhetsforbedringer med fokus på effektiv ressursutnyttelse</h5>
          </div>
          <p class="">
            Vi tar sikte på å resirkulere 100 prosent av produksjonsavfallet vårt.  Vi etterstreber å bruke energi fra
            fornybare kilder. Vi reduserer vannforbruket til et minimum. Vi jobber mot å få alle fabrikkene og
            distribusjonssentrene våre ISO 14001- og OHSAS 18001-sertifisert i løpet av 2020. Vi jobber aktivt med
            partnere om prosjekter der vi reduserer karbonavtrykket fra transporten.
          </p>
        </div>
        <div class="col-12 col-md-4 text-column">
          <div class="text-column-heading">
            <h5>Vi sikrer ansvarlighet gjennom hele verdikjeden</h5>
          </div>
          <p class="">
            Alle våre leverandører skal underskrive retningslinjene våre for innkjøp. Vi garanterer for
            organisasjonsfrihet og sikkerhet på arbeidsplassen og jobber aktivt for å bekjempe diskriminering og
            korrupsjon i henhold til målene som er fastsatt i retningslinjene våre. Vi påser at alle ansatte
            underskriver og opptrer i samsvar med de etiske retningslinjene våre.
          </p>
        </div>
      </div>

      <div class="mb-4">
        <h1>HL Bærekraftig valg</h1>
      </div>
      <div class="mb-5">
        <p>
          I 2018 ble 97,1 % av alt plastavfall fra selskapets produksjonsanlegg resirkulert. HL Displays mål er å bruke
          plast fra egen produksjon i tillegg til resirkulert plast fra eksterne kilder til å produsere nye produkter. I
          tillegg tilbyr vi nå også produkter laget med biobasert plast i stedet for oljebasert plast. Vi har ambisjoner
          om å tilby et bærekraftig alternativ for 1/3 av porteføljen vår innen slutten av 2019.
        </p>
        <p>
          Disse nye fremskrittene i produksjonen har tatt selskapet et skritt nærmere å redusere miljøet innvirkning på
          plastavfall.
        </p>
      </div>

      <div class="mb-4">
        <h1>Samfunnsansvar</h1>
      </div>
      <div class="mb-5">
        <p>
          HL Display verdsetter og respekterer de globale handelspartnerne våre, og jobber uavbrutt for å fremme strenge
          etiske standarder. Etter hvert som selskapet har ekspandert og etablert virksomheter i Asia, Europa og i
          resten av verden, har vi intensivert og spisset virksomhetsstandardene våre for å tilpasse oss til hver enkelt
          region.
        </p>
      </div>

      <div class="mb-4">
        <h1>Økonomisk ansvar</h1>
      </div>
      <div class="mb-5">
        <p>
          HL Display har et sterkt fokus på oppnå bærekraft, lønnsomhet og langsiktig vekst via etisk forretningspraksis
          og ansvarlig handel. Brorparten av den økonomiske verdien som genereres av HL Display distribueres til
          selskapets interessenter, herunder leverandører, ansatte, eiere og långivere. Selskapet overholder den lokale
          skattelovgivningen i alle land vi er virksomme i.
        </p>
      </div>

      <div class="mb-4">
        <h1>Etiske retningslinjer</h1>
      </div>
      <div class="mb-5">
        <p>
          HL Displays etiske retningslinjer utgjør grunnstenen i selskapets solide standpunkt mot korrupsjon, og skal
          sikre at ansatte og interessenter til enhver tid opptrer ærlig og med integritet og ansvarlighet. De etiske
          retningslinjene understreker viktigheten av nulltoleranse for bestikkelser, utpressing og korrupsjon samt
          streng overholdelse av myndighetskrav, og definerer tydelig de grunnleggende prinsippene som alle
          representanter for selskapet skal følge.
        </p>
      </div>
    </div>
  </div>
</div>
