<footer class="footer" style="min-height: 40vh">
  <div class="row">
    <div class="col-12 col-lg-10 offset-lg-1">
      <div class="row">
        <div class="col-12 col-sm-6 col-lg-3">
        </div>
        <div class="col-12 col-sm-6 col-lg-3">
        </div>
        <div class="col-12 col-sm-6 col-lg-3">

        </div>
        <div class="col-12 col-sm-6 col-lg-3">
          <div class="logo-wrapper">
            <img class="img-fluid" src="assets/logo-white.png" alt="" />
          </div>
          <a href="https://www.linkedin.com/company/{{linkedInSuffix}}" target="_blank">
            <div class="round-link">
              <span class="fab fa-linkedin-in"></span>
            </div>
          </a>
          <a href="https://vimeo.com/user33178545" target="_blank">
            <div class="round-link">
              <span class="fab fa-vimeo-v"></span>
            </div>
          </a>
          <a href="https://www.youtube.com/channel/UC_agBKH9fzMENa1iqGjLazQ/featured" target="_blank">
            <div class="round-link">
              <span class="fab fa-youtube"></span>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="row pt-4" *ngIf="cardPaymentEnabled && isCardPaymentCustomer">
    <div class="col-12 col-lg-10 offset-lg-1">
      <div class="row">
        <div class="col-12 col-sm-6 col-lg-4">
          <img class="img-fluid card-payment-logos" src="./assets/card-payment-logos-footer_v1.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</footer>