import { Injectable } from '@angular/core';
import { Customer, CustomerService } from 'gung-standard';
import { first, forkJoin, Observable, of, switchMap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { HlDisplayCustomerKuarService } from './hl-display-customer-kuar.service';

@Injectable({
  providedIn: 'root'
})
export class HlDisplayCustomerService extends CustomerService {
  constructor(
    protected http: HttpClient,
    private customerKuarService: HlDisplayCustomerKuarService
  ) {
    super(http);
  }

  getCustomer(customerId: string, noCache?: boolean): Observable<Customer> {
    // We bind KUAR to the customer here in order to be able to show the customer specific product ids and names.
    // Previously we have had in a couple of ways that caused large performance issues:
    // 1. All KUARs was bound to the product (for all customers), causing a large amount of data to be loaded.
    //    Removing that made the product list data about 10x smaller.
    // 2. We tried to bind KUAR to the customer entity in the backend. This caused the customer cache to skyrocket on
    //    some companies (specifically 1710) and caused the backend to crash.
    // 3. Latest version is the one below, we load the KUAR data on demand when the customer is loaded.
    //    This is a good compromise between performance and functionality, especially since we only need the KUAR data
    //    for the selected customer.
    return super.getCustomer(customerId, noCache).pipe(
      switchMap(customer =>
        forkJoin([of(customer), this.customerKuarService.getCustomerKuar(customerId).pipe(first())])
      ),
      switchMap(([customer, kuar]) => {
        if (!customer || !customer.extra) {
          return of(customer);
        }

        const alreadyHasKuarMapped = !!customer.extra.kuarMapped && Object.keys(customer.extra.kuarMapped).length > 0;
        const kuarFromEndpointIsEmpty = Object.keys(kuar).length === 0;
        if (alreadyHasKuarMapped && kuarFromEndpointIsEmpty) {
          // If we already have KUAR mapped and the new KUAR is empty, we don't need to do anything.
          // This should only happen now in the transition period where we are moving from the old KUAR system to the new.
          return of(customer);
        }

        // Important that we generate a new object here, otherwise we will get issues with the change detection causing
        // customer change to break.
        return of({
          ...customer,
          extra: {
            ...customer.extra,
            kuarMapped: kuar
          }
        });
      })
    );
  }
}
