<div class="" style="overflow: hidden">
  <div class="row">
    <div class="col-12">
      <app-hl-display-slider-promotion />
    </div>
  </div>
  <div class="row my-5">
    <div class="col-12 d-flex justify-content-center">
      <div class="hr-line"></div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-card col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
            <a [routerLink]="'/products'">
              <div class="card my-2 py-3 border rounded">
                <div class="row g-0">
                  <div class="col-sm-2 d-flex justify-content-center align-items-center">
                    <i class="fa-solid fa-boxes-stacked fa-3x py-3"></i>
                  </div>
                  <div class="col-sm-10">
                    <div class="card-body">
                      <h5 class="card-title mt-2" translate>PRODUCT_LIST</h5>
                      <p class="card-text" translate>SEE_YOUR_AGREED_ASSORTMENT_AND_PRICES</p>
                      <!-- <p class="card-text"><small class="text-body-secondary">Last updated 3 mins ago</small></p> -->
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div class="col-card col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
            <a [routerLink]="'/orders'">
              <div class="card my-2 py-3 border rounded">
                <div class="row g-0">
                  <div class="col-sm-2 d-flex justify-content-center align-items-center">
                    <i class="fa-solid fa-clock-rotate-left fa-3x py-3"></i>
                  </div>
                  <div class="col-sm-10">
                    <div class="card-body">
                      <h5 class="card-title mt-2" translate>ORDER_HISTORY</h5>
                      <p class="card-text" translate>FIND_YOUR_PREVIOUS_ORDERS_TWO_YEAR_BACK</p>
                      <!-- <p class="card-text"><small class="text-body-secondary">Last updated 3 mins ago</small></p> -->
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div class="col-card col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
            <a [routerLink]="'/favourites'">
              <div class="card my-2 py-3 border rounded">
                <div class="row g-0">
                  <div class="col-sm-2 d-flex justify-content-center align-items-center">
                    <i class="fa-regular fa-heart fa-3x py-3"></i>
                  </div>
                  <div class="col-sm-10">
                    <div class="card-body">
                      <h5 class="card-title mt-2" translate>FAVORITE_LIST</h5>
                      <p class="card-text" translate>GO_TO_YOUR_FAVOURITE_PRODUCTS</p>
                      <!-- <p class="card-text"><small class="text-body-secondary">Last updated 3 mins ago</small></p> -->
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div class="col-card col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
            <a [routerLink]="'/contact'">
              <div class="card my-2 py-3 border rounded">
                <div class="row g-0">
                  <div class="col-sm-2 d-flex justify-content-center align-items-center">
                    <i class="fa-solid fa-headset fa-3x py-3"></i>
                  </div>
                  <div class="col-sm-10">
                    <div class="card-body">
                      <h5 class="card-title mt-2" translate>CONTACT_US</h5>
                      <p class="card-text" translate>FIND_OUR_CONTECT_INFORMATION_BY_CLICKING_HERE</p>
                      <!-- <p class="card-text"><small class="text-body-secondary">Last updated 3 mins ago</small></p> -->
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row border-top mt-4">
    <div class="col-12 contact-us">
      <div class="container">
        <div class="card mb-3 py-3 mx-auto" style="max-width: 900px">
          <a href="https://emea.dcv.ms/TXYJSOG5Fm" target="_blank">
            <div class="row g-0">
              <div class="col-2 d-flex justify-content-center align-items-center">
                <i class="fa-regular fa-comments fa-3x py-3"></i>
              </div>
              <div class="col-10">
                <div class="card-body">
                  <h5 class="card-title" translate>HELP_US_IMPROVE</h5>
                  <p class="card-text" translate>IF_YOU_WANT_TO_SEND_US_FEEDBACK_OR_HAVE_IDEAS_FOR_IMPROVEMENTS</p>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
