<div *ngIf="data; else loading">
  <div class="row" *ngFor="let schedule of data">
    <div class="col-12 border-bottom">
      <!-- CARD -->
      <div class="">
        <mat-card class="example-card">
          <mat-card-header class="w-100 align-items-center">
            <div
              mat-card-avatar
              class="rounded-circle d-flex justify-content-center align-items-center border"
              style="border-width: 3px"
              (click)="showLastExceptionTrace(schedule, content)"
              [ngClass]="{
                'border-success': schedule.jobdatamap?.exceptionCounter == 0,
                'border-danger': !(schedule.jobdatamap?.exceptionCounter === 0),
                btn: schedule.jobdatamap?.lastExceptionDate
              }"
            >
              {{ schedule.jobdatamap?.exceptionCounter || 0 }}
            </div>
            <mat-card-title class="h5 text-break m-0">{{ schedule.jobName }}</mat-card-title>
            <mat-card-subtitle>
              <label translate>LAST_STATUS</label>: <b>{{ schedule.jobdatamap?.lastStatus }}</b>
              <button
                *ngIf="schedule.jobdatamap?.lastExceptionDate"
                type="button"
                class="btn btn-link"
                (click)="showLastExceptionTrace(schedule, content)"
              >
                <i class="fas fa-question-circle"></i>
              </button>
            </mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
            <div class="row border-bottom">
              <div class="col-6">
                <div class="row">
                  <div class="col-6">
                    <label translate>CRON</label>
                  </div>
                  <div class="col-6 text-right text-break">
                    <b>{{ schedule.cronExpression }}</b>
                  </div>
                </div>
                <div class="row pl-2">
                  <p class="cron-desc">{{ parseCronExpression(schedule.cronExpression) }}</p>
                </div>
              </div>
              <ng-container *ngIf="schedule.hasDescription">
                <div class="col-3 border-left" translate>DESCRIPTION</div>
                <div class="col-3 text-right text-break" *ngIf="!schedule.descriptionEn; else descriptionEn">
                  {{ schedule.jobName + '_DESCRIPTION' | translate }}
                </div>
                <ng-template #descriptionEn>
                  <div class="col-3 text-right text-break">
                    {{ schedule.descriptionEn }}
                  </div>
                </ng-template>
              </ng-container>
            </div>
            <mat-accordion>
              <mat-expansion-panel
                [expanded]="panelOpenState(schedule.jobName)"
                (opened)="panelOpenState(schedule.jobName, true)"
                (closed)="panelOpenState(schedule.jobName, false)"
              >
                <mat-expansion-panel-header class="p-0">
                  <mat-panel-title>
                    <label translate>LAST_EXECUTION_TIME</label>
                  </mat-panel-title>
                  <mat-panel-description class="text-right">
                    {{ schedule.jobdatamap?.lastExecutionTime / 1000 }} s
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <!-- Expanded data -->
                <div class="row mt-2 border-bottom">
                  <div class="col-4">
                    <label translate>TRIGGERS</label>
                  </div>
                  <div class="col-8 text-right text-break">
                    {{ schedule.triggerCount }}
                  </div>
                </div>
                <div class="row mt-2 border-bottom">
                  <div class="col-4">
                    <label translate>EXECUTIONS</label>
                  </div>
                  <div class="col-8 text-right text-break">
                    {{ schedule.jobdatamap?.executionCounter }}
                  </div>
                </div>
                <div class="row mt-2 border-bottom">
                  <div class="col-4">
                    <label translate>FAILED</label>
                  </div>
                  <div class="col-8 text-right text-break">
                    {{ schedule.jobdatamap?.exceptionCounter }}
                  </div>
                </div>
                <div class="row mt-2 border-bottom">
                  <div class="col-4">
                    <label translate>NEXT_EXECUTION_TIME</label>
                  </div>
                  <div class="col-8 text-right text-break">
                    {{ schedule.nextFireTime | date : dateFormat + ' HH:mm:ss' }}
                  </div>
                </div>
                <!-- <div class="row mt-2 border-bottom">
                    <div class="col-4">
                      <label translate>LAST_EXECUTION_TIME</label>
                    </div>
                    <div class="col-8 text-right text-break">
                      {{schedule.jobdatamap?.lastExecutionTime /1000 }} s
                    </div>
                  </div> -->
                <!-- <div class="row mt-2 border-bottom">
                    <div class="col-4">
                      <label translate>CRON</label>
                    </div>
                    <div class="col-8 text-right text-break">
                      <b>{{schedule.cronExpression}}</b>
                    </div>
                  </div> -->
                <div class="row mt-2 border-bottom">
                  <div class="col-4">
                    <label translate>INTERNAL_STATE</label>
                  </div>
                  <div class="col-8 text-right text-break">
                    {{ schedule.jobdatamap?.statusDetails.statusString }}
                  </div>
                </div>
                <div
                  class="row mt-2 border-bottom"
                  *ngIf="schedule.jobdatamap?.running && schedule.jobdatamap?.lastStartTime"
                >
                  <div class="col-4">
                    <label translate>STARTED</label>
                  </div>
                  <div class="col-8 text-right text-break">
                    {{ schedule.jobdatamap?.lastStartTime | date : 'longTime' }}
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </mat-card-content>
          <mat-card-actions>
            <button
              (click)="triggerJob(schedule.jobKey)"
              type="button"
              class="btn btn-primary"
              [disabled]="schedule.jobdatamap?.running ? true : undefined"
            >
              <span translate>
                {{ schedule.jobdatamap?.running ? 'EXECUTING' : 'TRIGGER_NOW' }}
                <i *ngIf="schedule.jobdatamap?.running" class="fa fa-spinner fa-spin"></i>
              </span>
            </button>
            <!-- <span class="btn btn-link p-0" (click)="triggerJob(schedule.jobKey)" translate>TRIGGER_NOW</span> -->
          </mat-card-actions>
        </mat-card>
      </div>
      <!-- OLD -->
      <div class="mb-3 p-2 h-100 d-none">
        <div class="row border-bottom">
          <div class="col-8 px-0 h5 text-break">
            {{ schedule.jobName }}
          </div>
          <div class="col-4 px-0 text-right">
            <span translate>{{ schedule.jobdatamap?.running ? 'EXECUTING' : 'WAITING' }}</span>
          </div>
        </div>
        <!-- JOB DETAILS -->
        <div class="row mt-2 border-bottom">
          <div class="col-4"><label tranlsate>JOB</label>:</div>
          <div class="col-8 text-right text-break">
            <span class="btn btn-link p-0" (click)="triggerJob(schedule.jobKey)" translate>TRIGGER_NOW</span>
          </div>
        </div>
        <div class="row mt-2 border-bottom">
          <div class="col-4">
            <label translate>LAST_STATUS</label>
          </div>
          <div class="col-8 text-right font-weight-bold text-break">
            {{ schedule.jobdatamap?.lastStatus }}
          </div>
        </div>
        <div class="row mt-2 border-bottom">
          <div class="col-4">
            <label translate>TRIGGERS</label>
          </div>
          <div class="col-8 text-right text-break">
            {{ schedule.triggerCount }}
          </div>
        </div>
        <div class="row mt-2 border-bottom">
          <div class="col-4">
            <label translate>EXECUTIONS</label>
          </div>
          <div class="col-8 text-right text-break">
            {{ schedule.jobdatamap?.executionCounter }}
          </div>
        </div>
        <div class="row mt-2 border-bottom">
          <div class="col-4">
            <label translate>FAILED</label>
          </div>
          <div class="col-8 text-right text-break">
            {{ schedule.jobdatamap?.exceptionCounter }}
          </div>
        </div>
        <div class="row mt-2 border-bottom">
          <div class="col-4">
            <label translate>NEXT_EXECUTION_TIME</label>
          </div>
          <div class="col-8 text-right text-break">
            {{ schedule.nextFireTime | date : dateFormat + ' HH:mm:ss' }}
          </div>
        </div>
        <div class="row mt-2 border-bottom">
          <div class="col-4">
            <label translate>LAST_EXECUTION_TIME</label>
          </div>
          <div class="col-8 text-right text-break">{{ schedule.jobdatamap?.lastExecutionTime / 1000 }} s</div>
        </div>
        <div class="row mt-2 border-bottom">
          <div class="col-4">
            <label translate>CRON</label>
          </div>
          <div class="col-8 text-right text-break">
            <b>{{ schedule.cronExpression }}</b>
          </div>
        </div>
        <div class="row mt-2 border-bottom">
          <div class="col-4">
            <label translate>INTERNAL_STATE</label>
          </div>
          <div class="col-8 text-right text-break">
            {{ schedule.jobdatamap?.statusDetails.statusString }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #loading>
  <div class="d-flex align-items-center justify-content-center m-5">
    <div>
      <i class="fa fa-spinner fa-spin fa-7x"></i>
    </div>
  </div>
</ng-template>

<ng-template #content let-modal>
  <div class="modal-header">
    <div>
      <h4 class="modal-title" id="modal-basic-title">{{ lastException?.jobName }} - Exception details</h4>
      <span>{{ lastException?.lastExceptionDate }}</span>
    </div>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <!-- <h4 class="text-center">{{lastException?.lastExceptionDate}}</h4> -->
    <pre>
        {{ lastException?.lastExceptionTrace }}
      </pre
    >
  </div>
  <div class="modal-footer">
    <!-- <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Save</button> -->
  </div>
</ng-template>
