import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-history-page',
  templateUrl: './history-page.component.html',
  styleUrls: ['./history-page.component.scss']
})
export class HistoryPageComponent implements OnInit {
  currentLang: string;

  constructor(protected translate: TranslateService) {}

  ngOnInit() {
    if (!['en', 'fr', 'no', 'nl'].includes(this.translate.currentLang)) {
      this.currentLang = 'en';
    } else {
      this.currentLang = this.translate.currentLang;
    }
  }
}
