<div *ngIf="!isLoading" class="table-responsive gung-responsive">
  <table class="table table-borderless table-striped table-hover">
    <thead>
      <tr>
        <th scope="col">{{ 'ORDERSTATUS' | translate }}</th>
        <th scope="col">{{ 'ORDER_ID' | translate }}</th>
        <th scope="col">{{ 'YOUR_ORDERNO' | translate }}</th>
        <th scope="col">{{ 'PRODUCT' | translate }}</th>
        <th scope="col">{{ 'NAME' | translate }}</th>
        <th scope="col">{{ 'QUANTITY' | translate }}</th>
        <th scope="col">{{ 'ORP_RESTANT' | translate }}</th>
        <th scope="col">{{ 'UNIT_PRICE' | translate }}</th>
        <th scope="col">{{ 'TOTAL' | translate }}</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let element of data">
        <td [attr.data-label]="'ORDERSTATUS' | translate">
          <lib-gung-meta-output [value]="element.extra.orp.ordradst" [showOnlyText]="true" metadataTable="x7"
            metaOptionName="ordstatbeskr"></lib-gung-meta-output>
        </td>
        <td [attr.data-label]="'ORDER_ID' | translate">
          <a [href]="'orders/' + element.orderId"> {{ element.orderRowId ? element.orderRowId : element.orderId }} </a>
        </td>
        <td [attr.data-label]="'YOUR_ORDERNO' | translate">
          {{ element.extra.orp.kundbestnr }}
        </td>
        <td [attr.data-label]="'PRODUCT' | translate">
          <a [href]="'product/' + element.productId"> {{ element.productId }} </a>
        </td>
        <td [attr.data-label]="'NAME' | translate">
          {{ element.extra.orp.artbeskr }}
        </td>
        <td [attr.data-label]="'QUANTITY' | translate">
          {{ element.quantity }}
        </td>
        <td [attr.data-label]="'ORP_RESTANT' | translate">
          <lib-price [price]="element.extra.orp.ordrestant" [currency]="currency"></lib-price>
        </td>
        <td [attr.data-label]="'UNIT_PRICE' | translate">
          <lib-price [price]="element.extra.orp.vb_nettopris" [currency]="currency"></lib-price>
        </td>
        <td [attr.data-label]="'TOTAL' | translate">
          <lib-price [price]="element.extra.orp.vb_nettopris * element.quantity" [currency]="currency"></lib-price>
        </td>
        <td>
          <div ngbDropdown class="d-inline-block mr-2 dropdown"
            *ngIf="element.extra?.shipmentIds && element.extra.shipmentIds.length > 0">
            <button type="button" class="dropdown-toggle btn btn-outline-primary" id="dropdownTrack" ngbDropdownToggle>
              TRACKING
              <i class="fa ml-2 fas fa-truck"></i>
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownTrack">
              <a ngbDropdownItem *ngFor="let item of element.extra.shipmentIds; let i = index"
                href="https://distribution.logtrade.info/ControlTower/status.aspx?u=LTD8103495&p=89258714&s={{ item }}"
                target='_blank'>
                <span translate>TRACKING</span>&nbsp;{{ i + 1 }}
              </a>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>