<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Edit orderrow - Product: {{ editedOrderRow.id }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <lib-gung-accordion #acc="gungAccordion">
      <lib-gung-accordion-item [collapsed]="false">
        <ng-template libGungAccordionHeader>{{ 'AMOUNTS' | translate }}</ng-template>
        <ng-template libGungAccordionBody>
          <div class="form-group row">
            <label for="quantity" class="col-form-label text-right-sm text-muted col-xs-12 col-sm-4"
              translate>QUANTITY</label>
            <div class="col">
              <input type="number" class="form-control" [(ngModel)]="editedOrderRow.extra.orp.ordantal" />
            </div>
          </div>
          <div class="form-group row">
            <label for="quantity" class="col-form-label text-right-sm text-muted col-xs-12 col-sm-4"><span
                translate>PRICE</span> ({{ orderRow.extra.orp.valkod }})</label>
            <div class="col">
              <input type="number" class="form-control" [(ngModel)]="editedOrderRow.extra.orp.vb_pris"
                [disabled]="readOnlyPrice" />
            </div>
          </div>
          <div class="form-group row">
            <label for="quantity" class="col-form-label text-right-sm text-muted col-xs-12 col-sm-4"><span
                translate>DISCOUNT</span> (%)</label>
            <div class="col">
              <input type="number" class="form-control" [(ngModel)]="editedOrderRow.extra.orp.kundrabatt"
                [disabled]="readOnlyPrice" min="0" max="100" step="0.01" />
            </div>
          </div>

          <div class="form-group row">
            <label for="orpradtext" class="col-form-label text-right-sm text-muted col-xs-12 col-sm-4"
              translate>INTERNAL_TEXT</label>
            <div class="col">
              <textarea class="form-control" type="text" name="orpradtext" id="orpradtext"
                [(ngModel)]="editedOrderRow.extra.orp.orpradtext"></textarea>
            </div>
          </div>
        </ng-template>
      </lib-gung-accordion-item>
    </lib-gung-accordion>
  </div>
  <div *ngIf="isUpdating; then updating; else available"></div>

  <ng-template #updating>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" disabled="true">
        <i class="fa fa-spinner fa-spin"></i>
      </button>
    </div>
  </ng-template>

  <ng-template #available>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="updateRow()">Save</button>
    </div>
  </ng-template>
</ng-template>

<i *ngIf="orderRow.extra.orp.ordradst !== 90" class="far fa-edit clickable" (click)="open(content)"></i>
<i *ngIf="orderRow.extra.orp.ordradst !== 90" class="fas fa-trash-alt clickable" (click)="deleteRow()"></i>