import { Injectable } from '@angular/core';
import { SimpleConfigBaseFilter } from 'gung-list';
import { Product } from 'gung-standard';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ProductAssortmentTypeFilterService extends SimpleConfigBaseFilter<Product> {
  constructor(private translateService: TranslateService) {
    super();
  }

  getName(): string {
    return 'ASSORTMENT_TYPE';
  }

  getOptionIds(item: Product): string[] {
    return [item.extra.assortmentType];
  }

  getOptionName(key: string): string {
    if (key === 'C') {
      return this.translateService.instant('PRODUCT_ASSORTMENT_TYPE_FILTER_CUSTOMER_PRICE_LIST');
    }
    if (key === 'S') {
      return this.translateService.instant('PRODUCT_ASSORTMENT_TYPE_FILTER_STANDARD_PRICE_LIST');
    }
    if (key === 'W') {
      return this.translateService.instant('PRODUCT_ASSORTMENT_TYPE_FILTER_WAREHOUSE');
    }
    return key;
  }
}
