import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModalOptions, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { first } from 'rxjs';
import { Customer, CustomerSelectDropdownComponent, CustomerService, GungModalService, SelectedCustomerService } from 'gung-standard';
import { HlDisplayCreditBlockedService } from '../../services/hl-display-credit-blocked.service';

@Component({
  selector: 'app-hl-display-customer-select-dropdown',
  templateUrl: './hl-display-customer-select-dropdown.component.html',
  styleUrls: ['./hl-display-customer-select-dropdown.component.css']
})
export class HlDisplayCustomerSelectDropdownComponent extends CustomerSelectDropdownComponent {
  constructor(
    protected customerService: CustomerService,
    protected selectedCustomerService: SelectedCustomerService,
    protected creditBlockedService: HlDisplayCreditBlockedService,
    protected gungModalService: GungModalService,
    protected router: Router
  ) {
    super(customerService, selectedCustomerService);
  }

  onItemSelect(event: NgbTypeaheadSelectItemEvent) {
    const cust = event.item as Customer;
    this.creditBlockedService.checkCreditBlocked(cust).pipe(first()).subscribe(result => {
      if (result.status === 'CREDIT_HARD_BLOCK') {
        const options: NgbModalOptions = {
          backdrop: 'static',
          size: 'sm',
          keyboard: true
        };
        const translation = this.creditBlockedService.getPortalTranslationCustomer(cust);
        this.gungModalService.openBasicModal(translation.title, translation.body, options).then(
          result => {},
          reason => {}
        );
      }
    });
    super.onItemSelect(event);
  }

  selectCustomer() {
    this.selectedCustomerService
      .getSelectedCustomer()
      .pipe(first())
      .subscribe(customer => {
        this.creditBlockedService
          .checkCreditBlocked(customer)
          .pipe(first())
          .subscribe(result => {
            if (result.status === 'CREDIT_HARD_BLOCK') {
              const options: NgbModalOptions = {
                backdrop: 'static',
                size: 'sm',
                keyboard: true
              };
              const translation = this.creditBlockedService.getPortalTranslationCustomer(customer);
              this.gungModalService.openBasicModal(translation.title, translation.body, options).then(
                result => {},
                reason => {}
              );
            }
          });
        this.router.navigate(['/customers', customer.id]);
      });
  }
}
