import { environment as defaultEnv } from './default-environment';

export const environment = {
  ...defaultEnv,
  backendUri: 'https://hl-portal-api.gung.io/',
  production: true,
  bolag: 'DEFAULT',
  allowedPriceLists: [],
  hideMobile: false,
  discountCustomerFinancialGroup: {
    ICA: 0.038
  },
  sales: false,
  analyticsId: '',
  sentryDsn: '',
  quoteConfirmationEmailAddress: 'support@gung.se',
  orderOurReference: '',
  externalOrderType: null,
  mainCountry: undefined,
  stripeApiKey: undefined,
  requestPricelistAssortmentWithWebCondition: false,
  showApprovalQuote: false,
  showFavourites: false,
  //Only for testing on HL alignment portals. Using Kesko information
  oauth2Enabled: false,
  oauth2SkipRedirectUrls: [],
  defaultLanguage: 'en',
  displayPriceLevels: true // to enable staffed prices shown, controlled by feature if shown or not
};
