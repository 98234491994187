<h2 class="mb-4" translate>SHIPPING_INFORMATION</h2>
<div class="row">
  <div class="col-12 col-md-6">
    <lib-gung-input-multi-select
      *ngIf="deliveryLocations"
      label="DELIVERY_LOCATION"
      [optionsList]="deliveryLocations"
      (selectedValues)="deliveryLocationId = $event?.length > 0 ? $event[0] : undefined"
      [showId]="false"
      [previewField]="['name', ' - ', 'extra.fr.ftgpostadr1', ', ', 'extra.fr.ftgpostnr', ' ', 'extra.fr.ftgpostadr3']"
      singleSelection="true"
    ></lib-gung-input-multi-select>
    <div class="form-group">
      <label for="ordlevadr2" class="control-label" translate>ADDRESS1</label>
      <input
        type="text"
        class="form-control"
        id="ordlevadr2"
        [value]="checkout.extra.procargs.ordlevadr2 || ''"
        disabled
      />
    </div>
    <div class="form-group">
      <label for="ordlevadr3" class="control-label" translate>ADDRESS2</label>
      <input
        type="text"
        class="form-control"
        id="ordlevadr3"
        [value]="checkout.extra.procargs.ordlevadr3 || ''"
        disabled
      />
    </div>
    <div class="row">
      <div class="col-12 col-md-4">
        <div class="form-group">
          <label for="zip" class="control-label" translate>ZIP</label>
          <input type="text" class="form-control" id="zip" [value]="checkout.extra.procargs.ftgpostnr || ''" disabled />
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="form-group">
          <label for="city" class="control-label" translate>CITY</label>
          <input
            type="text"
            class="form-control"
            id="city"
            [value]="checkout.extra.procargs.ordlevadr4 || ''"
            disabled
          />
        </div>
      </div>
      <div class="col-12 col-md-4">
        <lib-meta-select-input
          table="xr"
          metaId="landskod"
          metaDisplay="land"
          [disabled]="true"
          [(ngModel)]="checkout.extra.procargs.ordlevadrlandskod"
        ></lib-meta-select-input>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="form-group">
          <label for="oh.editext" class="control-label" translate>MESSAGE</label>
          <textarea
            rows="10"
            class="form-control"
            type="text"
            name="oh.edit"
            id="oh.edit"
            [(ngModel)]="checkout.extra.oh.edit"
          ></textarea>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <lib-gung-text-input gungTranslate="STORE_NO" [(ngModel)]="checkout.extra.oh.storeNo"> </lib-gung-text-input>
        <lib-gung-text-input gungTranslate="CUSTOMER_REFERENCE" [(ngModel)]="checkout.extra.oh.kundref2">
        </lib-gung-text-input>
        <lib-gung-text-input gungTranslate="CUSTOMER_REFERENCE_NO_PO" [(ngModel)]="checkout.extra.oh.kundbestnr">
        </lib-gung-text-input>
        <lib-gung-text-input
          gungTranslate="GOODS_LABEL"
          [(ngModel)]="checkout.extra.oh.godsmarke2"
        ></lib-gung-text-input>
      </div>
    </div>
  </div>

  <div class="col-12 col-md-6">
    <!-- <lib-gung-text-input gungTranslate="EMAIL" [(ngModel)]="checkout.extra.confirmationEmails[0]" *ngIf="!getDisableEmail()"></lib-gung-text-input> -->
    <div class="form-group">
      <lib-gung-text-input
        gungTranslate="DELIVERY_TRACKING_EMAIL"
        [(ngModel)]="checkout.extra.oh.q_hl_emailtt"
        (change)="emailttChange('oh', $event.target.value)"
      ></lib-gung-text-input>
    </div>
    <div class="form-group">
      <label for="orderConfirmEmail" class="control-label" translate>QUOTE_OR_CONFIRMATION_EMAIL</label>&nbsp;
      <input
        type="text"
        class="form-control"
        name="orderConfirmEmail"
        id="orderConfirmEmail"
        [(ngModel)]="checkout.extra._msFlowOrderConfirmEmail"
      />
      <span class="text-error" *ngIf="orderConfirmError">{{ 'BAD_EMAIL' | translate }}</span>
    </div>
  </div>
</div>
