<div class="container faq-container">
  <div class="row justify-content-center">
    <div class="col-12 col-lg-8 col-xl-7">
      <div class="mb-4">
        <h1>FAQ</h1>
      </div>

      <h3 class="faq-header" translate>CUSTOMER_ACCOUNT</h3>
      <!-- CUSTOMER ACCOUNT -->
      <lib-gung-accordion>
        <lib-gung-accordion-item id="1">
          <ng-template libGungAccordionHeader>{{ 'FAQ_ACCESS_PORTAL_QUERY' | translate }}</ng-template>
          <ng-template libGungAccordionBody>
            <ul>
              <li [innerHTML]="'FAQ_ACCESS_PORTAL_RESPONSE' | translate"></li>
            </ul>
          </ng-template>
        </lib-gung-accordion-item>
        <lib-gung-accordion-item id="2">
          <ng-template libGungAccordionHeader>{{ 'FAQ_ACCOUNT_INFO_QUERY' | translate }}</ng-template>
          <ng-template libGungAccordionBody>
            <ul>
              <li>
                {{ 'FAQ_ACCOUNT_INFO_RESPONSE' | translate }}
                <a [routerLink]="['/customers/', customerId]">{{ 'FAQ_MES_DONNEES' | translate }}</a>
              </li>
            </ul>
          </ng-template>
        </lib-gung-accordion-item>
        <lib-gung-accordion-item id="3">
          <ng-template libGungAccordionHeader>{{ 'FAQ_FORGOT_PASSWORD_QUERY' | translate }}</ng-template>
          <ng-template libGungAccordionBody>
            <ul>
              <li [innerHTML]="'FAQ_FORGOT_PASSWORD_RESPONSE' | translate"></li>
            </ul>
          </ng-template>
        </lib-gung-accordion-item>
      </lib-gung-accordion>
      <!-- ORDER INFOMRATION -->
      <h3 class="faq-header" translate>ORDER_INFO</h3>
      <lib-gung-accordion>
        <lib-gung-accordion-item id="1">
          <ng-template libGungAccordionHeader>{{ 'FAQ_ORDER_PLACEMENT_QUERY' | translate }}</ng-template>
          <ng-template libGungAccordionBody>
            <ul>
              <li [innerHTML]="'FAQ_ORDER_PLACEMENT_RESPONSE' | translate"></li>
            </ul>
          </ng-template>
        </lib-gung-accordion-item>

        <lib-gung-accordion-item id="2">
          <ng-template libGungAccordionHeader>{{ 'FAQ_ORDER_TRACKING_QUERY' | translate }}</ng-template>
          <ng-template libGungAccordionBody>
            <ul>
              <li>
                {{ 'FAQ_ORDER_TRACKING_RESPONSE' | translate }}
                <a [routerLink]="['/customers/', customerId]">{{ 'FAQ_MES_DONNEES' | translate }}</a>
              </li>
            </ul>
          </ng-template>
        </lib-gung-accordion-item>

        <lib-gung-accordion-item id="3">
          <ng-template libGungAccordionHeader>{{ 'FAQ_ORDER_MODIFICATION_QUERY' | translate }}</ng-template>
          <ng-template libGungAccordionBody>
            <ul>
              <li [innerHTML]="'FAQ_ORDER_MODIFICATION_RESPONSE' | translate"></li>
            </ul>
          </ng-template>
        </lib-gung-accordion-item>

        <lib-gung-accordion-item id="4">
          <ng-template libGungAccordionHeader>{{ 'FAQ_ORDER_HISTORY_QUERY' | translate }}</ng-template>
          <ng-template libGungAccordionBody>
            <ul>
              <li>
                {{ 'FAQ_ORDER_HISTORY_RESPONSE' | translate }}
                <a [routerLink]="['/customers/', customerId]">{{ 'FAQ_MES_DONNEES' | translate }}</a>
              </li>
            </ul>
          </ng-template>
        </lib-gung-accordion-item>
        <lib-gung-accordion-item id="5">
          <ng-template libGungAccordionHeader>{{ 'FAQ_PRINT_ORDER_CONFIRMATION_QUERY' | translate }}</ng-template>
          <ng-template libGungAccordionBody>
            <ul>
              <li [innerHTML]="'FAQ_PRINT_ORDER_CONFIRMATION_RESPONSE' | translate"></li>
            </ul>
          </ng-template>
        </lib-gung-accordion-item>
      </lib-gung-accordion>
      <!-- DELIVERY / RETURNS -->
      <h3 class="faq-header" translate>DELIVERY_RETURNS</h3>
      <lib-gung-accordion>
        <lib-gung-accordion-item id="1">
          <ng-template libGungAccordionHeader>{{ 'FAQ_TIME_EST_QUERY' | translate }}</ng-template>
          <ng-template libGungAccordionBody>
            <ul>
              <li [innerHTML]="'FAQ_TIME_EST_RESPONSE' | translate"></li>
            </ul>
          </ng-template>
        </lib-gung-accordion-item>
        <lib-gung-accordion-item id="2">
          <ng-template libGungAccordionHeader>{{ 'FAQ_RETURNS_QUERY' | translate }}</ng-template>
          <ng-template libGungAccordionBody>
            <ul>
              <li [innerHTML]="'FAQ_RETURNS_RESPONSE' | translate"></li>
            </ul>
          </ng-template>
        </lib-gung-accordion-item>
        <lib-gung-accordion-item id="3">
          <ng-template libGungAccordionHeader>{{ 'FAQ_INVOICE_QUERY' | translate }}</ng-template>
          <ng-template libGungAccordionBody>
            <ul>
              <li [innerHTML]="'FAQ_INVOICE_RESPONSE' | translate"></li>
            </ul>
          </ng-template>
        </lib-gung-accordion-item>
        <lib-gung-accordion-item id="4">
          <ng-template libGungAccordionHeader>{{ 'FAQ_CATALOUGE_QUERY' | translate }}</ng-template>
          <ng-template libGungAccordionBody>
            <ul>
              <li [innerHTML]="'FAQ_CATALOUGE_RESPONSE' | translate"></li>
            </ul>
          </ng-template>
        </lib-gung-accordion-item>
      </lib-gung-accordion>
      <!-- TECH ADVICE -->
      <h3 class="faq-header" translate>TECH_ADVICE</h3>
      <lib-gung-accordion>
        <lib-gung-accordion-item id="1">
          <ng-template libGungAccordionHeader>
            <ng-container>
              {{ 'FAQ_TECH_FITTING_QUERY' | translate }}
            </ng-container>
          </ng-template>
          <ng-template libGungAccordionBody>
            <ul>
              <li [innerHTML]="'FAQ_TECH_FITTING_RESPONSE' | translate"></li>
            </ul>
          </ng-template>
        </lib-gung-accordion-item>
        <lib-gung-accordion-item id="2" *ngIf="isSampleEnabled">
          <ng-template libGungAccordionHeader>{{ 'FAQ_PAYMENT_SAMPLE_QUERY' | translate }}</ng-template>
          <ng-template libGungAccordionBody>
            <ul>
              <li [innerHTML]="'FAQ_PAYMENT_SAMPLE_RESPONSE' | translate"></li>
            </ul>
          </ng-template>
        </lib-gung-accordion-item>
        <!-- <ngb-panel id="2">
            <ng-template ngbPanelTitle>
              <span translate>FAQ_TECH_MEASUREMENT_QUERY</span>
            </ng-template>
            <ng-template ngbPanelContent>
              <ul>
                <li translate>
                  FAQ_TECH_MEASUREMENT_RESPONSE
                </li>
              </ul>
            </ng-template>
          </ngb-panel> -->
      </lib-gung-accordion>
      <!-- PAYMENT -->
      <h3 class="faq-header" translate>PAYMENT</h3>
      <lib-gung-accordion>
        <lib-gung-accordion-item id="1">
          <ng-template libGungAccordionHeader>{{ 'FAQ_PAYMENT_TERMS_QUERY' | translate }}</ng-template>
          <ng-template libGungAccordionBody>
            <ul>
              <li [innerHTML]="'FAQ_PAYMENT_TERMS_RESPONSE' | translate"></li>
            </ul>
          </ng-template>
        </lib-gung-accordion-item>
        <lib-gung-accordion-item id="2">
          <ng-template libGungAccordionHeader>{{ 'FAQ_PAYMENT_POLICY_QUERY' | translate }}</ng-template>
          <ng-template libGungAccordionBody>
            <ul>
              <li [innerHTML]="'FAQ_PAYMENT_POLICY_RESPONSE' | translate"></li>
            </ul>
          </ng-template>
        </lib-gung-accordion-item>
        <lib-gung-accordion-item id="3">
          <ng-template libGungAccordionHeader>{{ 'FAQ_PAYMENT_SAMPLE_QUERY' | translate }}</ng-template>
          <ng-template libGungAccordionBody>
            <ul>
              <li [innerHTML]="'FAQ_PAYMENT_SAMPLE_RESPONSE' | translate"></li>
            </ul>
          </ng-template>
        </lib-gung-accordion-item>
      </lib-gung-accordion>
    </div>
  </div>
</div>