import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Product } from 'gung-standard';
import { Order, OrderRow, PriceService, OrderService, CartRowPrice, AuthService } from 'gung-standard';
import { HlDisplayOrderService } from '../../services/hl-display-order.service';
import { forkJoin } from 'rxjs';
import { Route, Router } from '@angular/router';
import { CartRow } from 'gung-standard';
import { first, switchMap } from 'rxjs';
import { User } from 'gung-standard/lib/state/auth/types';

@Component({
  selector: 'app-hl-display-order-add-row-modal',
  templateUrl: './hl-display-order-add-row-modal.component.html',
  styleUrls: ['./hl-display-order-add-row-modal.component.css']
})
export class HlDisplayOrderAddRowModalComponent implements OnInit {
  editRows: CartRow[] = [];
  prices: CartRowPrice[] = [];
  user: User;

  /**
   * added as modalref data from order details component
   */
  order: Order;

  constructor(
    public activeModal: NgbActiveModal,
    private orderService: HlDisplayOrderService,
    protected priceService: PriceService,
    private router: Router,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.authService
      .getCurrentUser()
      .pipe(first())
      .subscribe(user => (this.user = user));
  }

  selectProduct(product: Product, row: CartRow, rowIndex: number) {
    row.productId = product.id;

    const orderCustomer = this.order.deliveryCustomerId;

    this.priceService.getCustomerPrice(product.id, orderCustomer).subscribe(price => {
      this.prices[rowIndex] = this.priceService.getCartRowPrice(price, row);
    });
  }

  addNewRow() {
    this.editRows.push({
      productId: '',
      extra: { orp: {} },
      targetStockId: this.user.managedMultistockIds[0],
      timeAdded: new Date(),
      timeUpdated: new Date(),
      qty: 1
    });
  }

  removeRow(index: number) {
    this.editRows.splice(index, 1);
    this.prices.splice(index, 1);
  }

  submitRows() {
    const copiedOrder = JSON.parse(JSON.stringify(this.order)) as any;
    const submitRows = this.editRows
      .filter(row => !!row.productId)
      .map(row => {
        const submitRow = {
          id: row.productId,
          quantity: row.qty,
          extra: row.extra
        };
        if (row.targetStockId) {
          submitRow.extra.orp = submitRow.extra.orp || {};
          submitRow.extra.orp.lagstalle = row.targetStockId;
        }
        return submitRow;
      });

    copiedOrder.rows = submitRows;
    this.orderService.postAppendToOrder(copiedOrder).subscribe(orders => {
      this.activeModal.dismiss();
      this.router.navigate([`/orders/${orders[0].id}`]);
      window.location.reload();
    });
  }
}
