import { Component, OnInit } from '@angular/core';
import { AbstractSelectionService } from 'gung-list';
import { ProductSelectionService, ProductListConfigService } from 'gung-standard';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-hl-product-list-wrapper',
  template: ` <app-hl-product-list [configService]="productListConfigService"></app-hl-product-list> `,
  providers: [
    {
      provide: AbstractSelectionService,
      useClass: ProductSelectionService
    }
  ]
})
export class HlProductListWrapperComponent implements OnInit {
  constructor(public productListConfigService: ProductListConfigService, public route: ActivatedRoute) {}

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      const assortmentId = params.get('assortmentId');

      if (assortmentId != null) {
        this.productListConfigService.assortmentSubject.next(assortmentId);
      } else {
        this.productListConfigService.assortmentSubject.next(null);
      }
    });
  }
}
