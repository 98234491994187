import { Injectable, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Customer, SelectedCustomerService } from 'gung-standard';
import { CreditCheckAmout, JeevesCheckCreditService } from 'gung-standard-jeeves';
import { Observable, first, forkJoin, of, switchMap } from 'rxjs';
import { environment } from '../../environments/environment';
import { CustomerServiceSettingsGroup, HlDisplayPortalFeaturesService } from './hl-display-portal-features.service';

@Injectable({
  providedIn: 'root'
})
export class HlDisplayCreditBlockedService {
  customerServiceEmail: string = '';
  customerServicePhoneNumber: string = '';
  translationTags: string[] = ['NO_PORTAL_SPECIFIC_TRANSLATION_FOUND', 'CREDIT_BLOCKED_MODAL_HTML_CUSTOMER_SERVICE'];

  constructor(
    protected selectCustomerService: SelectedCustomerService,
    protected jeevesCheckCreditService: JeevesCheckCreditService,
    protected translateService: TranslateService,
    protected hlDisplayPortalFeaturesService: HlDisplayPortalFeaturesService
  ) {
    this.hlDisplayPortalFeaturesService
      .getPortalFeatureGroup('customerServiceSettingsGroup')
      .subscribe(customerServiceSettingsGroup => {
        let customerServiceGroup = customerServiceSettingsGroup as CustomerServiceSettingsGroup;
        this.customerServiceEmail = customerServiceGroup.email;
        this.customerServicePhoneNumber = customerServiceGroup.phoneNumber;
      });
  }

  checkCreditBlockedSelectedCustomer(): Observable<{ status: string; customer: Customer; credit: CreditCheckAmout }> {
    return this.selectCustomerService.getSelectedCustomer().pipe(
      first(),
      switchMap(customer => {
        const result: { status: string; customer: Customer; credit: CreditCheckAmout } = {
          status: '',
          customer: customer,
          credit: undefined
        };

        if (customer.extra?.kus?.kreditsparr == '1') {
          result.status = 'CREDIT_HARD_BLOCK';
        }

        const billingCustomerId: string = customer?.extra?._billingCustomer?.id;
        if (billingCustomerId && customer.extra?.kus?.kreditsparrtyp == 1) {
          result.status = 'CREDIT_SOFT_BLOCK';
        }

        return forkJoin({
          status: of(result.status),
          customer: of(result.customer),
          credit:
            result.status === 'CREDIT_SOFT_BLOCK'
              ? this.jeevesCheckCreditService.getAmountOfCredit(billingCustomerId).pipe(first())
              : of(result.credit)
        });
      })
    );
  }

  checkCreditBlocked(customer: Customer): Observable<{ status: string; customer: Customer; credit: CreditCheckAmout }> {
    const result: { status: string; customer: Customer; credit: CreditCheckAmout } = {
      status: '',
      customer: customer,
      credit: undefined
    };

    if (customer.extra?.kus?.kreditsparr == '1') {
      result.status = 'CREDIT_HARD_BLOCK';
    }

    const billingCustomerId: string = customer?.extra?._billingCustomer?.id;
    if (billingCustomerId && customer.extra?.kus?.kreditsparrtyp == 1) {
      result.status = 'CREDIT_SOFT_BLOCK';
    }

    return forkJoin({
      status: of(result.status),
      customer: of(result.customer),
      credit:
        result.status === 'CREDIT_SOFT_BLOCK'
          ? this.jeevesCheckCreditService.getAmountOfCredit(billingCustomerId).pipe(first())
          : of(result.credit)
    });
  }

  getPortalTranslationCart(
    customer: Customer,
    creditLeft: number,
    totalValue: number,
    displayDefault: boolean = false
  ): { title: string; body: string } {
    const translatedTitle = this.translateService.instant('CREDIT_BLOCKED');
    let translatedBody = 'NO_PORTAL_SPECIFIC_TRANSLATION_FOUND';
    translatedBody = this.getPortalSpecificTranslation();

    // If there is no specific translation available in current language for specific portal, then use default translation
    if (this.translationTags.includes(translatedBody) || displayDefault) {
      translatedBody = this.translateService.instant('CREDIT_SOFT_BLOCKED_MODAL_HTML', {
        customerName: customer.name,
        credit: Number(creditLeft).toFixed(2),
        total: Number(totalValue).toFixed(2),
        currency: customer?.extra?.kus?.valkod
      });
    }

    return { title: translatedTitle, body: translatedBody };
  }

  getPortalTranslationCustomer(customer: Customer): { title: string; body: string } {
    const translatedTitle = this.translateService.instant('CREDIT_BLOCKED');
    let translatedBody = 'NO_PORTAL_SPECIFIC_TRANSLATION_FOUND';
    translatedBody = this.getPortalSpecificTranslation();

    // If there is no specific translation available in current language for specific portal, then use default translation
    if (this.translationTags.includes(translatedBody)) {
      translatedBody = this.translateService.instant('CREDIT_BLOCKED_MODAL_HTML', {
        customerName: customer.name
      });
    }

    return { title: translatedTitle, body: translatedBody };
  }

  private getPortalSpecificTranslation(): string {
    return this.translateService.instant('CREDIT_BLOCKED_MODAL_HTML_CUSTOMER_SERVICE', {
      customerServiceEmail: this.customerServiceEmail,
      customerServicePhoneNumber: this.customerServicePhoneNumber
    });
  }
}
