import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SimpleConfigBaseFilter } from 'gung-list';
import { MetadataService, Product } from 'gung-standard';

@Injectable({
  providedIn: 'root'
})
export class ProductProductShelfTypeFilterService extends SimpleConfigBaseFilter<Product> {
  currentLang = this.translate.currentLang;

  constructor(protected translate: TranslateService, protected metadataService: MetadataService) {
    super();
  }
  getName(): string {
    return 'PRODUCT_SHELF_TYPE';
  }
  getOptionIds(item: Product): string[] {
    if(item.extra.hlPimProduct.ShelfType?.length > 0) {
      const itemName = this.metadataService.getMetadataTableList('ShelfType');
      const itemNameList: string[] = item.extra.hlPimProduct.ShelfType.map(
        itemShelfName => itemName.find(c => c.Id === itemShelfName)?.Values[this.currentLang] || itemShelfName
      );
      if (itemNameList?.length > 0) {
        return itemNameList;
      }
    }
    return [item.extra.hlPimProduct.ShelfType];
  }
  getOptionName(key: string): string {
    return key;
  }
}
