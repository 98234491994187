import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';
import { Observable } from 'rxjs';
import { tap } from 'rxjs';
import { PdfDocument } from '../models/pdf-document';
import { PdfItem } from '../models/pdf-item';

@Injectable({
  providedIn: 'root'
})
export class DynamicPdfService {
  baseUrl = 'json/exports/dynamic-pdf';

  protected pdfListInitialized = false;
  protected pdfListSubject: Subject<PdfDocument[]> = new ReplaySubject<PdfDocument[]>();
  protected pdfListObservable: Observable<PdfDocument[]> = this.pdfListSubject.asObservable();

  constructor(protected http: HttpClient) {}

  exportPdf(documentBody: PdfDocument): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/generate`, documentBody);
  }

  getRenderedItemHtml(pdfItem: PdfItem): Observable<string> {
    return this.http.post(`${this.baseUrl}/generate/render-item`, pdfItem, { responseType: 'text' });
  }

  savePdf(documentBody: PdfDocument): Observable<any> {
    console.log('test save', documentBody);
    return this.http.post<any>(`${this.baseUrl}`, documentBody).pipe(tap(() => this.updatePdfListSubject()));
  }

  getPdfs(): Observable<PdfDocument[]> {
    let headers = new HttpHeaders();
    headers = headers.set('maxAge', '-1');
    return this.http.get<any>(`${this.baseUrl}`, { headers });
  }

  getPdf(id): Observable<PdfDocument> {
    let headers = new HttpHeaders();
    headers = headers.set('maxAge', '-1');
    return this.http.get<any>(`${this.baseUrl}/${id}`, { headers });
  }

  deletePdf(id): Observable<PdfDocument> {
    return this.http.delete<any>(`${this.baseUrl}/${id}`).pipe(tap(() => this.updatePdfListSubject()));
  }

  getAllPdfs(): Observable<PdfDocument[]> {
    if (!this.pdfListInitialized) {
      this.updatePdfListSubject();
    }

    return this.pdfListObservable;
  }

  protected updatePdfListSubject() {
    const headers: { [s: string]: string | string[] } = {};
    headers.maxAge = '-1';
    this.http.get<any>(`${this.baseUrl}`, { headers }).subscribe(dynamicPdfs => {
      this.pdfListSubject.next(dynamicPdfs);
      this.pdfListInitialized = true;
    });
  }

  getProductTemplates(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/templates`);
  }
}
