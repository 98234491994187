<div class="row" *ngIf="!loading">
  <div class="col-12 col-md-2 mt-4 mb-2">
    <lib-discount-input
      label="DISCOUNT_CODE"
      class="d-block"
      [ngModel]="discountCode ? campaignCode : discountCode"
      (ngModelChange)="discountCode ? campaignCode : (discountCode = $event)"
      (discountDescription)="discountDescription = $event"
      (discountCode)="setDiscountCode($event)"
    ></lib-discount-input>
    <div
      class="alert alert-success position-absolute discount-description"
      *ngIf="discountDescription && discountDescription.length > 0"
      style="z-index: 1"
    >
      {{ discountDescription | translate }}
    </div>
  </div>
  <div class="col-12 col-md-3 offset-md-7 d-flex align-items-end">
    <div class="w-100">
      <div class="row pt-1">
        <div class="col-7 text-truncate"><span class="font-weight-bold" translate>ORDER_VALUE</span>:</div>
        <div class="col-5 font-weight-bold item-info text-primary text-right">
          <lib-price [price]="cartTotalValue" [currency]="cartCurrency"></lib-price>
        </div>
      </div>
      <div class="row pt-1" *ngIf="adminCharge > 0 && cartTotalValue < adminChargeMin">
        <div class="col-7 text-truncate"><span class="font-weight-bold" translate>ADMIN_CHARGE</span>:</div>
        <div class="col-5 font-weight-bold item-info text-primary text-right">
          <lib-price [price]="adminCharge" [currency]="cartCurrency"></lib-price>
        </div>
      </div>
      <div class="row pt-1" *ngIf="freightCost > 0 && cartTotalValue < freightCostMin">
        <div class="col-7 text-truncate"><span class="font-weight-bold" translate>FREIGHT</span>:</div>
        <div class="col-5 font-weight-bold item-info text-primary text-right">
          <lib-price [price]="freightCost" [currency]="cartCurrency"></lib-price>
        </div>
      </div>
      <!--<div class="row pt-1 pb-1">
                <div class="col-7 text-truncate">
                    <span class="font-weight-bold" translate>FREIGHT</span>:
                </div>
                <div class="col-5 font-weight-bold item-info text-primary text-right">
                    <lib-price [price]="0" [currency]="cartCurrency"></lib-price>
                </div>
            </div>-->
    </div>
  </div>
  <div class="col-12 col-md-2"></div>
  <div class="col-12">
    <div class="border-bottom"></div>
  </div>
</div>
<div class="row" *ngIf="!loading">
  <div class="col-12 col-md-3 offset-md-9">
    <div class="row pt-2 row-total">
      <div class="col-7 text-truncate"><span class="font-weight-bold" translate>TOTAL</span>:</div>
      <div class="col-5 font-weight-bold item-info text-primary text-right">
        <lib-price [price]="orderTotalValue" [currency]="cartCurrency"></lib-price>
      </div>
    </div>
  </div>
</div>
<div class="row mt-4">
  <div class="col-12 col-md-2 offset-md-10 text-right">
    @if (!isLastStep) {
      @if (cartTotalValue > minOrderValue || isSales) {
        <button
          class="btn btn-primary w-100 btn-sm mt-1 checkout-btn-next checkout-btn-text"
          type="button"
          (click)="nextClicked.emit()"
          *ngIf="!isExternalPaymentStep"
          [disabled]="disableNextButton"
        >
          <span translate>{{ nextBtnText }}</span>
        </button>
      } @else {
        <button
          type="button"
          class="btn btn-primary w-100 btn-sm mt-1 checkout-btn-next checkout-btn-text"
          disabled
          translate
        >
          LOW_VALUE
        </button>
      }
    } @else {
      @if (showCreateOrder) {
        <div class="col-12 mb-2">
          <button
            class="btn btn-primary w-100 btn-sm mt-1 checkout-btn-next checkout-btn-text"
            type="button"
            (click)="createOrder()"
            *ngIf="!isExternalPaymentStep"
            [disabled]="disableNextButton"
          >
            <span translate>ORDER</span>
          </button>
        </div>
      }
      @if (showApprovalQuote) {
        <div class="col-12 mb-2">
          <button
            class="btn btn-primary w-100 btn-sm mt-1 checkout-btn-next checkout-btn-text"
            type="button"
            (click)="sendApprovalQuote()"
            *ngIf="!isExternalPaymentStep"
            [disabled]="disableNextButton"
          >
            <span translate>OFFER</span>
          </button>
        </div>
      }
      @if (showCreateSampleOrder) {
        <div class="col-12 mb-2">
          <button
            class="btn btn-primary w-100 btn-sm mt-1 checkout-btn-next checkout-btn-text"
            type="button"
            (click)="createSampleOrder()"
            *ngIf="!isExternalPaymentStep"
            [disabled]="disableNextButton"
          >
            <span translate>SAMPLE</span>
          </button>
        </div>
      }
    }

    <!--  Always preselect the Assortment type filter with the values C and S. This is by request from Magnus and Juliette - Adam -->
    @if (isFirstStep) {
      <a
        class="btn btn-secondary w-100 mt-1 btn-sm border-0 checkout-btn-text"
        href="#"
        role="button"
        translate
        [routerLink]="['/products']"
        [queryParams]="{filters: 'ASSORTMENT_TYPE__:__C_____S'}"
        >CONTINUE_SHOPPING</a
      >
    } @else {
      <button
        type="button"
        class="btn btn-outline-primary btn-sm checkout-btn-previous w-100 mt-1 checkout-btn-text"
        (click)="isFirstStep ? emptyCart() : prevClicked.emit()"
        translate
      >
        {{ isFirstStep ? 'EMPTY_CART' : prevBtnText }}
      </button>
    }

    @if (isLastStep) {
      <div class="col-12 mt-2">
        <div class="ml-auto text-start font-weight-bold" style="max-width: 150px">
          <span>{{ 'NO_FREIGHT_NO_VAT' | translate }}</span>
        </div>
      </div>
    }
  </div>
</div>
