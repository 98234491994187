import { Component, Input, OnChanges, OnInit, OnDestroy } from '@angular/core';
import { RenderFilter } from 'gung-list';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

const imageDataMappings = {
  default: {
    image: 'DEFAULT_2024',
    imagePharma: 'DEFAULT_PHARMA',
    title: ''
  },
  'Concept Vrac': {
    image: 'CONCEPT_VRAC',
    title: 'Concept Vrac'
  },
  'Facing automatique': {
    image: 'FACING_AUTOMATIQUE',
    title: 'Facing Automatique'
  },
  'Porte-étiquettes': {
    image: 'PORTES_ETIQUETTES',
    title: 'Porte-étiquettes'
  },
  'Présentoirs & Paniers': {
    image: 'FRUIT',
    title: 'Présentoirs & Paniers'
  },
  'Séparateurs & Accessoires': {
    image: 'SEPARATORS',
    title: 'Séparateurs & Accessoires'
  },
  'Protection et sécurité': {
    image: 'PROTECTION',
    title: 'Protection et sécurité'
  },
  GravityScoopBins: {
    image: 'CONCEPT_VRAC',
    title: null // when title is null then the title is obtained from the filter (which contains the i18n value name of the category filter)
  },
  ShelfManagementAutomatic: {
    image: 'FACING_AUTOMATIQUE',
    title: null
  },
  PriceCommunication: {
    image: 'PORTES_ETIQUETTES',
    title: null
  },
  FreshProduceSolutions: {
    image: 'FRUIT',
    title: null
  },
  ShelfManagementManual: {
    image: 'SEPARATORS',
    imagePharma: 'SEPARATORS_PHARMA',
    title: null
  },
  ProtectionSafetyEquipment: {
    image: 'PROTECTION',
    title: null
  },
  LightingPowerInfrastructure: {
    image: 'DIGITAL_MISE_EN_LUMIERE',
    imagePharma: 'DIGITAL_MISE_EN_LUMIERE_PHARMA',
    title: null
  },
  StoreCommunicationBranding: {
    image: 'COMMUNICATION_SIGNALETIQUE',
    title: null
  },
  'LightingPowerInfrastructure;DigitalScreensAccessories': {
    // equals to LightingPowerInfrastructure
    image: 'DIGITAL_MISE_EN_LUMIERE',
    title: null
  },
  'DigitalScreensAccessories;LightingPowerInfrastructure': {
    // equals to LightingPowerInfrastructure
    image: 'DIGITAL_MISE_EN_LUMIERE',
    title: null
  },
  'FreshProduceSolutions;DisplayAccessories': {
    // equals to FreshProduceSolutions
    image: 'FRUIT',
    title: null
  },
  'DisplayAccessories;FreshProduceSolutions': {
    // equals to FreshProduceSolutions
    image: 'FRUIT',
    title: null
  },
  ShelfManagementManual_NL: {
    image: 'Header_1_(small_long)',
    title: null
  },
  ShopFittingEquipment_NL: {
    image: 'Header_7_(Small_long).png',
    title: null
  },
  PriceCommunication_NL: {
    image: 'Header_3_(small_long).png',
    title: null
  },
  FreshProduceSolutions_NL: {
    image: 'Header_4_(small_long).png',
    title: null
  }
};

const getMappingFromRenderFilters = (filters: RenderFilter[]): { image: string; title: string } | undefined => {
  const selectedCategory = filters
    .filter(filter => filter.name === 'CATEGORY')
    .map(filter => filter.valueList)
    .reduce((acc, curr) => [...curr, ...acc], [])
    .filter(value => value.selected)[0];

  // reset title of default to empty
  imageDataMappings.default.title = '';

  if (!selectedCategory) {
    return imageDataMappings.default;
  }

  let imageDataMapping = imageDataMappings[selectedCategory.valueId] || imageDataMappings.default;

  // in case title is not set then get its value from the filter
  if (!imageDataMapping.title) {
    imageDataMapping.title = selectedCategory.valueName || '';
  }
  return imageDataMapping;
};

@Component({
  selector: 'app-hl-product-list-header',
  templateUrl: './hl-product-list-header.component.html',
  styleUrls: ['./hl-product-list-header.component.scss']
})
export class HlProductListHeaderComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  renderFilters: RenderFilter[];

  private unsubscribe: Subject<boolean> = new Subject<boolean>();

  showImage = true;
  imageData: { image: string; title: string } | undefined;

  constructor(public translateService: TranslateService) {}

  ngOnInit(): void {}

  ngOnChanges() {
    const mapping = getMappingFromRenderFilters(this.renderFilters);
    if (!!mapping) {
      const title = mapping.title;
      let image = mapping.image;

      if (image.includes('.')) {
        this.imageData = { title, image: `assets/product-list-headers/${image}` };
      } else {
        this.imageData = { title, image: `assets/product-list-headers/${image}.jpg` };
      }
    } else {
      this.imageData = undefined;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }
}
