import { Injectable } from '@angular/core';
import { SimpleConfigBaseFilter } from 'gung-list';
import { Product, MetadataService } from 'gung-standard';
import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductCategoryFilterService extends SimpleConfigBaseFilter<Product> {
  private externalCategoriesCache: any = {};

  constructor(protected metadataService: MetadataService) {
    super();
  }

  getName(): string {
    return 'CATEGORY';
  }

  getOptionIds(item: Product): string[] {
    if (!!item.extra.hlPimProduct && !!item.extra.hlPimProduct.ProductExternalCategory) {
      const productExternalCategoryId = item.extra.hlPimProduct.ProductExternalCategory;
      const productExternalCategoryValue = this.getI18nProductExternalCategory(item, productExternalCategoryId);

      if (!!productExternalCategoryValue) {
        this.externalCategoriesCache[productExternalCategoryId] = productExternalCategoryValue;
        return [productExternalCategoryId];
      }
    }

    return [];
  }

  getOptionName(key: string): string {
    // customer portals
    return this.externalCategoriesCache[key];
  }

  private getI18nProductExternalCategory(item: Product, productExternalCategory: string): string {
    // in case not find a product external category for the selected language then use the default
    const selectedLanguage = localStorage.getItem('selectedLanguage') || 'en';
    if (!!item.extra.i18n) {
      if (
        !!item.extra.i18n[selectedLanguage] &&
        !!item.extra.i18n[selectedLanguage].ProductExternalCategory &&
        !!item.extra.i18n[selectedLanguage].ProductExternalCategory[productExternalCategory]
      ) {
        return item.extra.i18n[selectedLanguage].ProductExternalCategory[productExternalCategory];
      }

      const defaultLanguage = 'en';
      if (
        !!item.extra.i18n[defaultLanguage] &&
        !!item.extra.i18n[defaultLanguage].ProductExternalCategory &&
        !!item.extra.i18n[defaultLanguage].ProductExternalCategory[productExternalCategory]
      ) {
        return item.extra.i18n[defaultLanguage].ProductExternalCategory[productExternalCategory];
      }

      return productExternalCategory;
    }

    return null;
  }
}
