import { Injectable, OnInit } from '@angular/core';
import { JeevesOrderListConfigService } from 'gung-standard-jeeves';
import {
  Order,
  OrderService,
  SelectedCustomerService,
  MetadataService,
  CustomerService,
  AuthService
} from 'gung-standard';
import { ConfigBaseFilter, ListLayout, SimpleConfigBaseFilter } from 'gung-list';
import { TranslateService } from '@ngx-translate/core';
import { HlDisplayJeevesOrderCardListComponent } from '../components/hl-display-open-orders-quotes/hl-display-jeeves-order-card-list/hl-display-jeeves-order-card-list.component';
import { Observable, of, mergeMap, map, filter, switchMap, forkJoin, first } from 'rxjs';
import { HlDisplayOrderService } from './hl-display-order.service';
import { environment } from '../../environments/environment';
import { HlDisplayPortalFeaturesService, OrderSearchSettingsGroup } from './hl-display-portal-features.service';

@Injectable({
  providedIn: 'root'
})
export class HlDisplayJeevesOrderListConfigService extends JeevesOrderListConfigService {
  isSales: boolean = false;

  private orderSearchSettings: OrderSearchSettingsGroup;

  constructor(
    protected translationService: TranslateService,
    protected orderService: OrderService,
    protected selectedCustomerService: SelectedCustomerService,
    protected metadataService: MetadataService,
    protected customerService: CustomerService,
    protected hlDisplayOrderService: HlDisplayOrderService,
    protected authService: AuthService,
    protected hlDisplayPortalFeaturesService: HlDisplayPortalFeaturesService
  ) {
    super(translationService, orderService, selectedCustomerService, metadataService, customerService);
  }

  getSearchTerms(item: Order): string[] {
    const res: string[] = [...super.getSearchTerms(item), item.extra?.oh?.godsmarke2];

    if (item.extra.pod) {
      res.push(item.extra.pod.ftgnamn);
    } else if (item.extra.customerName) {
      res.push(item.extra.customerName);
    }

    if (item.extra._productsIds && item.extra._productsIds.length > 0) {
      res.push(...item.extra._productsIds);
    }
    return res;
  }

  getLayouts(): ListLayout<Order>[] {
    return [
      {
        ...super.getLayouts()[0],
        getListItemComponent: () => HlDisplayJeevesOrderCardListComponent
      }
    ];
  }

  getItems(): Observable<Order[]> {
    this.authService.getRoles().subscribe(roles => {
      this.isSales = roles.includes('SALES');
    });

    return this.hlDisplayPortalFeaturesService.getPortalFeatureGroup('orderSearchSettingsGroup').pipe(
      first(),
      switchMap(orderSearchSettingsGroup => {
        this.orderSearchSettings = orderSearchSettingsGroup as OrderSearchSettingsGroup;

        let customerId = this.customerId;
        const request = this.customerId
          ? of(this.customerId)
          : this.selectedCustomerService.getSelectedCustomer().pipe(
            filter(customer => !!customer),
            map(customer => {
              customerId = customer.id;
              return customer.id;
            })
          );
        return request.pipe(
          switchMap(customerId => this.orderService.getOrders(customerId, false, this.maxSize)),
          mergeMap(orders =>
            forkJoin([
              of(orders),
              this.isSales && this.orderSearchSettings.orderSearchByProductId
                ? this.hlDisplayOrderService.getOrderRows(customerId).pipe(first())
                : of(undefined)
            ])
          ),
          switchMap(([orders, orderRows]) => {
            if (orderRows) {
              orders = orders.map(order => {
                const orderRowsFiltered = orderRows.filter(row => row.orderId === order.id);
                order.extra._productsIds = orderRowsFiltered.map(row => row.productId);
                return order;
              });
            }
            if (!this.orderSearchSettings.showCancelledOrders) {
              orders = orders.filter(
                o =>
                  !(
                    o.extra?.oh?.ordstat === 90 ||
                    o.extra?.oh?.ordtyp === 101 ||
                    o.extra?.oh?.ordtyp === 201 ||
                    o.extra?.oh?.q_hl_edi_cancel === true
                  )
              );
            }

            if (this.orderSearchSettings.filterOrdersOnPOD) {
              for (const order of orders) {
                const lpMap: { name: string; addr1: string; addr2: string; postnr: string; land: string }[] = [];
                const lps: any[] = order.extra?.deliveryCustomer?.extra?.lp || [];

                for (const lp of lps) {
                  lpMap.push({
                    name: lp._fr?.ftgnamn || '',
                    addr1: lp._fr?.ftgpostadr1 || '',
                    addr2: lp._fr?.ftgpostadr2 || '',
                    postnr: lp._fr?.ftgpostnr || '',
                    land: lp._fr?.landskod || ''
                  });
                }

                lpMap.push({
                  name: order.extra?.deliveryCustomer?.extra?.fr?.ftgnamn || '',
                  addr1: order.extra?.deliveryCustomer?.extra?.fr?.ftgnamn || '',
                  addr2: order.extra?.deliveryCustomer?.extra?.fr?.ftgpostadr2 || '',
                  postnr: order.extra?.deliveryCustomer?.extra?.fr?.ftgpostnr || '',
                  land: order.extra?.deliveryCustomer?.extra?.fr?.landskod || ''
                });

                order.extra._gungLpMap = lpMap;
              }
            }

            return of(orders);
          })
        );
      })
    );
  }

  getFilters(): ConfigBaseFilter<Order>[] {
    const filters = super.getFilters();

    if (this.orderSearchSettings.filterOrdersOnPOD) {
      filters.push(new HlDisplayOrderPODFilter(this.translationService));
    }

    return filters;
  }
}

export class HlDisplayOrderPODFilter extends SimpleConfigBaseFilter<Order> {
  constructor(protected translationService: TranslateService) {
    super();
  }

  getName(): string {
    return 'POD_FILTER';
  }

  getOptionIds(item: Order): string[] {
    let podDelivery: string = 'NO_POD_FOUND';
    const podFound = item.extra._gungLpMap?.find(
      (lp: { name: string; addr1: string; addr2: string; postnr: string; land: string }) =>
        lp.addr1 === item.extra?.oh?.delivaddr1 && lp.addr2 === item.extra?.oh?.delivaddr3
    );

    if (!!podFound) {
      podDelivery = `${podFound.name} - ${podFound.postnr} ${podFound.land}`;
    }

    return [podDelivery];
  }

  getOptionName(key: string): string {
    return this.translationService.instant(key || '');
  }
}
