<div class="row">
  <div class="col-12 col-md-6">
    <h4 translate>DELIVERY_ADDRESS</h4>
    <div class="form-group">
      <label for="ordlevadr1" class="control-label" translate>OH_ORDLEVADR1</label>
      <input type="text" class="form-control form-control-sm" id="ordlevadr1"
        [value]="checkout.extra.procargs.ordlevadr1 || ''" disabled />
    </div>
    <div class="form-group">
      <label for="ordlevadr2" class="control-label" translate>OH_ORDLEVADR2</label>
      <input type="text" class="form-control form-control-sm" id="ordlevadr2"
        [value]="checkout.extra.procargs.ordlevadr2 || ''" disabled />
    </div>
  </div>
  <div class="col-12 col-md-6" *ngIf="customer">
    <h4 translate>INVOICE_ADDRESS</h4>
    <div class="form-group">
      <label for="billingFtgnamn" class="control-label" translate>FTGNAMN</label>
      <input type="text" class="form-control form-control-sm" id="billingFtgnamn"
        [value]="customer.extra._billing?.ftgnamn || ''" disabled />
    </div>
    <div class="form-group">
      <label for="billingFtgpostadr2" class="control-label" translate>FTGPOSTADR2</label>
      <input type="text" class="form-control form-control-sm" id="billingFtgpostadr2"
        [value]="customer.extra._billing?.ftgpostadr2 || ''" disabled />
    </div>
    <div class="form-group">
      <label for="billingFtgpostadr2" class="control-label" translate>EMAIL_INVOICE</label>
      <input type="text" class="form-control form-control-sm" id="billingFtgpostadr2" [value]="''" disabled />
    </div>
  </div>
</div>
<hr *ngIf="!isExternalProjectManagers && !isStores" />
<div *ngIf="!isExternalProjectManagers && !isStores" class="row">
  <div class="col-12">
    <h4 class="mb-2" translate>TERMS</h4>
    <div class="row">
      <div class="col">
        <lib-meta-select-input table="x2e" metaId="levvillkkod" metaDisplay="levvillkbeskr"
          [(ngModel)]="checkout.extra.procargs.levvillkkod" [disabled]="true"></lib-meta-select-input>
        <lib-meta-select-input table="x2f" metaId="levsattkod" metaDisplay="levsattbeskr"
          [(ngModel)]="checkout.extra.procargs.levsattkod" [disabled]="true"></lib-meta-select-input>

        <div class="form-group">
          <label for="kus.kundklass" translate>CARRIER_CODE</label>
          <lib-meta-select-input [suppressLabel]="true" [optionsFilterFunction]="filterCarrierCodes"
            [(ngModel)]="checkout.extra.oh.transportorskod" table="x2t" metaId="transportorskod"
            metaDisplay="transportorsnamn" [disabled]="true"></lib-meta-select-input>
        </div>
      </div>
      <div class="col">
        <lib-meta-select-input [(ngModel)]="checkout.extra.oh.betkod" table="x2" metaId="betkod"
          metaDisplay="betvillbeskr" [disabled]="true"></lib-meta-select-input>
        <lib-meta-select-input [(ngModel)]="checkout.extra.oh.dellevtillaten" table="xdl" metaId="dellevtillaten"
          metaDisplay="dellevtillatenbeskr" [disabled]="!sales"></lib-meta-select-input>
      </div>
    </div>
  </div>
  <hr />
  <div> <!-- *ngIf="showTerms; else showTextareas" -->
    <h4 translate>CONDITIONS</h4>
    <div class="row">
      <div class="col-12 col-md-6">
        <lib-meta-select-input [(ngModel)]="checkout.extra.procargs.levsattkod" table="x2f" metaId="levsattkod"
          metaDisplay="levsattbeskr" [disabled]="true">
        </lib-meta-select-input>
        <lib-meta-select-input [(ngModel)]="checkout.extra.procargs.levvillkkod" table="x2e" metaId="levvillkkod"
          metaDisplay="levvillkbeskr" [disabled]="true">
        </lib-meta-select-input>
        <div *ngIf="sales">
          <label translate>INTERNAL_TEXT</label>
          <textarea class="form-control">{{ checkout.extra.oh.editext }}</textarea>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <lib-meta-select-input [(ngModel)]="checkout.extra.oh.betkod" table="x2" metaId="betkod"
          metaDisplay="betvillbeskr" [disabled]="true">
        </lib-meta-select-input>
        <lib-meta-select-input [(ngModel)]="checkout.extra.procargs.saljare" table="salj" metaId="saljare"
          metaDisplay="saljarenamn" [disabled]="true">
        </lib-meta-select-input>
      </div>
    </div>
  </div>
  <!-- <ng-template #showTextareas> add this to div above for sales
  <div class="row">
    <div class="col-6">
      <div class="form-group">
        <label translate>INTERNAL_TEXT</label>
        <textarea disabled class="form-control">{{ checkout.extra.oh.editext }}</textarea>
      </div>
    </div>
  </div>
</ng-template> -->

  <div *ngIf="productsMap && pricesMap">
    <h4 translate>ROWS</h4>
    <div class="table-responsive">
      <table class="table table-striped">
        <thead>
          <tr>
            <th translate *ngIf="!sales">PRODUCT_ID</th>
            <th translate>NAME</th>
            <th *ngIf="showApprovalQuote" class="d-none d-sm-table-cell"><!-- iamges --></th>
            <th translate *ngIf="!sales && showDates">ESTIMATED_DELIVERY_DATE</th>
            <th translate>QUANTITY</th>
            <th translate>UNIT_PRICE</th>
            <th translate>TOTAL</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of checkout.rows">
            <td *ngIf="!sales">{{ row.id }}</td>
            <!-- <td>{{productsMap[row.id].name}}</td> -->
            <td>{{ getName(productsMap[row.id], row) }}</td>
            <td *ngIf="showApprovalQuote" class="d-none d-sm-table-cell">
              @if(sales){
              <div class="row">
                <div class="col-auto" *ngFor="let image of productsFullMap[row.id]?.extra?.images"
                  (click)="selectImage(row, image)">
                  <img src="https://cdn1.gung.io/fit-in/250x250/filters:etag({{ image.s3ETag }})/{{ image.s3Uri }}"
                    [alt]="image.extra.PictureType" [class.border-selected]="!!image.isSelected" />
                </div>
              </div>
              }

            </td>
            <td *ngIf="!sales && showDates">
              {{ row.extra.estDeliveryDate }}
            </td>
            <td>{{ row.quantity }}</td>
            <td>
              <div *ngIf="pricesMap">
                <div *ngIf="!dummyArticleService.isDummyRow(row); else dummyPrice">
                  {{
                  pricesMap[row.id].cartRowUnitPrice.value
                  | gungCurrency
                  : pricesMap[row.id].cartRowUnitPrice.currencyCode
                  : 'symbol-narrow'
                  : '1.2-2'
                  : 'sv_SE'
                  }}
                </div>
                <ng-template #dummyPrice>
                  {{
                  row.extra.orp.vb_pris || 0
                  | gungCurrency
                  : pricesMap[row.id].cartRowUnitPrice.currencyCode
                  : 'symbol-narrow'
                  : '1.2-2'
                  : 'sv_SE'
                  }}
                </ng-template>
              </div>
            </td>
            <td>
              <div *ngIf="pricesMap">
                <div *ngIf="!dummyArticleService.isDummyRow(row); else dummyPrice">
                  {{
                  pricesMap[row.id].cartRowTotalPriceInclRowDiscount.value
                  | gungCurrency
                  : pricesMap[row.id].cartRowTotalPriceInclRowDiscount.currencyCode
                  : 'symbol-narrow'
                  : '1.2-2'
                  : 'sv_SE'
                  }}
                </div>
                <ng-template #dummyPrice>
                  {{
                  (row.extra.orp.vb_pris || 0) * (row.extra.orp.ordantal || 0)
                  | gungCurrency
                  : pricesMap[row.id].cartRowTotalPriceInclRowDiscount.currencyCode
                  : 'symbol-narrow'
                  : '1.2-2'
                  : 'sv_SE'
                  }}
                </ng-template>
              </div>
            </td>
            <!-- <td *ngIf="pricesMap">{{pricesMap[row.id].customerNetPrice.value}} {{pricesMap[row.id].customerNetPrice.currencyCode}}</td> -->
          </tr>
        </tbody>
      </table>
    </div>
  </div>