import { Injectable } from '@angular/core';
import { ConfigService, ConfigBaseFilter, ListSortOption, ListLayout, ListLayoutMultipleComponent } from 'gung-list';
import { SelectedCustomerService, ActivityService, Activity } from 'gung-standard';
import { Observable } from 'rxjs';
import { first, switchMap, map } from 'rxjs';
import { HlDisplayMeetingTableComponent } from '../components/hl-display-meeting-table/hl-display-meeting-table.component';

@Injectable({
  providedIn: 'root'
})
export class HlDisplayMeetingListService implements ConfigService<Activity> {
  customerId: string;
  setCustomerId(customerId: string) {
    this.customerId = customerId;
  }

  constructor(protected activityService: ActivityService, protected selectedCustomerService: SelectedCustomerService) {}

  getItems(): Observable<Activity[]> {
    if (this.customerId == null) {
      return this.selectedCustomerService.getSelectedCustomer().pipe(
        first(),
        switchMap(cust =>
          this.activityService.getActivitys(cust.id).pipe(
            map(data => {
              const sorted = data.sort((a, b) => {
                if (a.extra.hr.regdat > b.extra.hr.regdat) {
                  return 1;
                }
                if (a.extra.hr.regdat < b.extra.hr.regdat) {
                  return -1;
                }
                return 0;
              });
              return sorted.reverse();
            })
          )
        )
      );
    }

    return this.activityService.getActivitys(this.customerId).pipe(
      map(data => {
        const sorted = data.sort((a, b) => {
          if (a.extra.hr.regdat > b.extra.hr.regdat) {
            return 1;
          }
          if (a.extra.hr.regdat < b.extra.hr.regdat) {
            return -1;
          }
          return 0;
        });
        return sorted.reverse();
      })
    );
  }

  getFilters(): ConfigBaseFilter<Activity>[] {
    return [];
  }

  getSortOptions(): ListSortOption<Activity>[] {
    return [];
  }

  getBatchSizes(): number[] {
    return [24];
  }

  getLayouts(): ListLayout<Activity>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => HlDisplayMeetingTableComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'MeetingTable'
      }
    ];
  }

  getSearchTerms(item: Activity): string[] {
    return [item.id, item.extra.hr.editext];
  }

  getItemId(item: Activity): string {
    return item.id;
  }

  getNothingFoundTranslateTag() {
    return 'NOTHING_FOUND';
  }
}
