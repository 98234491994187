import { Injectable } from '@angular/core';
import { CheckoutObject, StripeCheckoutService } from 'gung-standard';

@Injectable({
  providedIn: 'root'
})
export class HLDisplayStripeCheckoutService extends StripeCheckoutService {
  public shouldPayWithStripe(checkout: CheckoutObject): boolean {
    // Default implementation is to never pay with Stripe. This will almost always be customer specific.
    //return true;
    return checkout.extra.oh.betkod === '99' || checkout.extra.oh.betkod === 'CC';
  }

  public decorateCheckout(checkout: CheckoutObject): void {
    super.decorateCheckout(checkout);
  }

  isEnabled(): boolean {
    // By checking the environment for the pk live we can support multiple websites where some should have stripe
    // and some should not.
    return !!this.environment.stripeApiKey;
  }
}
