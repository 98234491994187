import { Injectable } from '@angular/core';
import { GungLanguageConfigService, Language } from 'gung-common';

@Injectable({
  providedIn: 'root'
})
export class LanguageConfigService extends GungLanguageConfigService {
  public getLanguages(): Language[] {
    return [
      {
        language: 'finnish',
        short: 'fi',
        image: 'finland-flag-round-icon-256.png',
        enabled: false
      },
      {
        language: 'french',
        short: 'fr',
        image: 'france-flag-round-icon-256.png',
        enabled: false
      },
      {
        language: 'german',
        short: 'de',
        image: 'germany-flag-round-icon-256.png',
        enabled: false
      },
      {
        language: 'norwegian',
        short: 'no',
        image: 'norway-flag-round-icon-256.png',
        enabled: false
      },
      {
        language: 'portuguese',
        short: 'pt',
        image: 'portugal-flag-round-icon-256.png',
        enabled: false
      },
      {
        language: 'swedish',
        short: 'se',
        image: 'sweden-flag-round-icon-256.png',
        enabled: true
      },
      {
        language: 'english',
        short: 'en',
        image: 'united-kingdom-flag-round-icon-256.png',
        enabled: true
      },
      {
        language: 'danish',
        short: 'dk',
        image: 'denmark-flag-round-icon-256.png',
        enabled: false
      },
      {
        language: 'dutch',
        short: 'nl',
        image: 'netherlands-flag-round-icon-256.png',
        enabled: false
      },
      {
        language: 'spanish',
        short: 'es',
        image: 'spain-flag-round-icon-256.png',
        enabled: false
      },
      {
        language: 'italian',
        short: 'it',
        image: 'italy-flag-round-icon-256.png',
        enabled: false
      },
      {
        language: 'polish',
        short: 'pl',
        image: 'poland-flag-round-icon-256.png',
        enabled: false
      }
    ];
  }
}
