import { AfterViewInit, Component } from '@angular/core';
import { LocationConfigService, OrderCardListComponent } from 'gung-standard';
import { JeevesOrderActionConfigService } from '../../services/jeeves-order-action-config/jeeves-order-action-config.service';
@Component({
  selector: 'gung-jeeves-jeeves-order-card-list',
  templateUrl: './jeeves-order-card-list.component.html',
  styleUrls: ['./jeeves-order-card-list.component.css']
})
export class JeevesOrderCardListComponent extends OrderCardListComponent {
  adjustedScroll = false;

  constructor(
    public configService: JeevesOrderActionConfigService,
    protected locationConfigService: LocationConfigService
  ) {
    super();
  }
}
