<div class="row">
  <div class="col">
    <h2 translate>SERVICE_CLIENT</h2>
  </div>
</div>
<div class="grid-container mt-4">
  <div class="map">
    <img src="assets/france-map.svg" />
  </div>

  <div class="sector-west box">
    <div class="row">
      <div class="col">
        <h6 class="title" translate>WEST_SECTOR</h6>
        <p><span translate>YOUR_COMMERCIAL_CONTACT</span>: <span>Michael Dupont</span></p>
        <p><span translate>CUSTOMER_SERVICE_EMAIL</span>: <span class="link">secteur.ouest&#64;hl-display.com</span></p>
        <p><span translate>CUSTOMER_CONTACT_EMAIL</span>: <span class="link">michael.dupont&#64;hl-display.com</span></p>
      </div>
    </div>
  </div>

  <div class="sector-south-west box">
    <div class="row">
      <div class="col">
        <h6 class="title" translate>SOUTH_WEST_SECTOR</h6>
        <p><span translate>YOUR_COMMERCIAL_CONTACT</span>: <span>Christophe Guedon</span></p>
        <p><span translate>CUSTOMER_SERVICE_EMAIL</span>: <span class="link">secteur.sud-ouest&#64;hl-display.com</span></p>
        <p><span translate>CUSTOMER_CONTACT_EMAIL</span>: <span class="link">christophe.guedon&#64;hl.display.com</span></p>
      </div>
    </div>
  </div>

  <div class="sector-paris-north box">
    <div class="row">
      <div class="col">
        <h6 class="title" translate>PARIS_NORTH_SECTOR</h6>
        <p><span translate>YOUR_COMMERCIAL_CONTACT</span>: <span>Regina Goncalves</span></p>
        <p>
          <span translate>CUSTOMER_SERVICE_EMAIL</span>: <span class="link">secteur.Paris-Nord&#64;hl-display.com</span>
        </p>
        <p><span translate>CUSTOMER_CONTACT_EMAIL</span>: <span class="link">regina.goncalves&#64;hl.display.com</span></p>
      </div>
    </div>
  </div>

  <div class="sector-east box">
    <div class="row">
      <div class="col">
        <h6 class="title" translate>EAST_SECTOR</h6>
        <p><span translate>YOUR_COMMERCIAL_CONTACT</span>: <span>Julien Schneider</span></p>
        <p><span translate>CUSTOMER_SERVICE_EMAIL</span>: <span class="link">secteur.est&#64;hl-display.com</span></p>
        <p><span translate>CUSTOMER_CONTACT_EMAIL</span>: <span class="link">julien.schneider&#64;hl-display.com</span></p>
      </div>
    </div>
  </div>

  <div class="sector-south-east box">
    <div class="row">
      <div class="col">
        <h6 class="title" translate>SOUTH_EAST_SECTOR</h6>
        <p><span translate>YOUR_COMMERCIAL_CONTACT</span>: <span>Virgil Carena</span></p>
        <p><span translate>CUSTOMER_SERVICE_EMAIL</span>: <span class="link">secteur.sud-est&#64;hl-display.com</span></p>
        <p><span translate>CUSTOMER_CONTACT_EMAIL</span>: <span class="link">virgil.carena&#64;hl-display.com</span></p>
      </div>
    </div>
  </div>
</div>
