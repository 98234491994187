import { Component } from '@angular/core';
import { PriceLevelsComponent } from 'gung-standard';

@Component({
  selector: 'hl-display-price-levels',
  templateUrl: './hl-display-price-levels.component.html',
  styleUrls: ['./hl-display-price-levels.component.scss']
})
export class HlDisplayPriceLevelsComponent extends PriceLevelsComponent {
  ngOnInit(): void {
    super.ngOnInit();
    // this.priceLevels = [this.priceLevels.shift()];
  }
}
