import { Injectable } from '@angular/core';
import { SimpleConfigBaseFilter } from 'gung-list';
import { Product } from 'gung-standard';

// eslint-disable-next-line max-len
const ids = [
  '945092',
  '945082',
  '945085',
  '939797',
  '945084',
  '945086',
  '945094',
  '945098',
  '945093',
  '945087',
  '945090',
  '944962',
  '945095',
  '930537',
  '958146',
  '958147',
  '958148',
  '581336',
  '581339',
  '802071',
  '945083',
  '918647',
  '316536',
  '945100',
  '958143',
  '958144',
  '958145',
  '945097',
  '320944',
  '346061',
  '726605',
  '967308',
  '945096',
  '944960',
  '944961',
  '945088',
  '945089',
  '548815',
  '920691',
  '802517',
  '802774',
  '866970',
  '967310',
  '931923',
  '931924',
  '937632',
  '930825',
  '930826',
  '862327',
  '946059',
  '946060',
  '309139',
  '581439',
  '564261',
  '558503',
  '707418',
  '866582',
  '867101',
  '802148',
  '802154',
  '778686',
  '557200',
  '564260',
  '554233',
  '783028',
  '549084',
  '779244',
  '958140',
  '958142',
  '156338',
  '156339',
  '156342',
  '156343',
  '156344'
];

@Injectable({
  providedIn: 'root'
})
export class ProductMousquetairesFilterService extends SimpleConfigBaseFilter<Product> {
  getName(): string {
    return 'MOUSQUETAIRES_SELECTION';
  }
  getOptionIds(item: Product): string[] {
    const id = item.id;
    if (ids.includes(id)) {
      return ['MOUSQUETAIRES_FILTER_SELECTED'];
    }
    return [];
  }
  getOptionName(key: string): string {
    return `${key}`;
  }
}
