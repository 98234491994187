import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, map } from 'rxjs';
import { Product } from '../models';
import { ConfigBaseFilter, ConfigService, ListLayout, ListLayoutMultipleComponent, ListSortOption } from 'gung-list';
import { BaseViewConfigService } from './base-view-config/base-view-config.service';
import { ProductViewType } from './base-view-config/base-view-config.service';
import { ProductGridViewV2Component } from '../components/product-grid-view-v2/product-grid-view-v2.component';
import { GridViewProductCard, ProductGridViewComponent } from '../components/product-grid-view/product-grid-view.component';
import { ConceptGridCardV2Component } from '../components/concept-grid-card-v2/concept-grid-card-v2.component';
import { ProductGridCardV2Component } from '../components/product-grid-card-v2/product-grid-card-v2.component';
import { ProductListConfigService } from './product-list-config/product-list-config.service';
import { ProductListV2ConfigService } from './product-list-v2-config/product-list-v2-config.service';
import { ProductSortService } from './product-sort/product-sort.service';

@Injectable({
  providedIn: 'root'
})
export class NavbarSearchViewMoreListConfigService implements ConfigService<Product> {
  searchDisabled = true;
  productsSubject: Subject<Product[]> = new BehaviorSubject(null);
  disableViewMoreSearch: boolean = false;

  constructor(
    protected baseViewConfigService: BaseViewConfigService,
    protected productListConfigService: ProductListConfigService,
    protected productListV2ConfigService: ProductListV2ConfigService,
    protected productSortService: ProductSortService
  ) {

  }

  getItems(): Observable<Product[]> {
    return this.productsSubject.asObservable().pipe(
      map(products => {
        return this.productSortService.sortProducts(products);
      })
    );
  }

  getFilters(): ConfigBaseFilter<Product>[] {
    return [];
  }

  getSortOptions(): ListSortOption<Product>[] {
    return [];
  }

  getBatchSizes(): number[] {
    return [24];
  }

  getLayouts(): ListLayout<Product>[] {
    if (this.baseViewConfigService.productViewType === ProductViewType.productV2) {
      return this.productListV2ConfigService.getLayouts();
    } else {
      return this.productListConfigService.getLayouts();
    }
  }

  getProductGridCardComponent(item: GridViewProductCard): any {
    return this.productListV2ConfigService.getProductGridCardComponent(item);
  }

  getItemId(item: Product) {
    return item.id;
  }

  getSearchTerms(item: Product) {
    return [item.id, item.name];
  }
}
