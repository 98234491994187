import { Injectable } from '@angular/core';
import { CustomerProductPrice, PriceConfigService, BackendPriceLevel } from 'gung-standard';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HlDisplayPriceConfigService extends PriceConfigService {

  public getStaffedPrice(
    price: CustomerProductPrice,
    mode: string,
    quantity: number,
    display?: string | boolean,
    currency?: string
  ): BackendPriceLevel {
    const levels = price.backendPrice.levels.filter(l => l.price > 0);
    // Set first level as default
    let level = levels[0];

    // loop till I am at the level which is right below too much
    if (quantity) {
      for (const nextLevel of levels) {
        if (nextLevel.quantity > quantity) {
          return level;
        }
        level = nextLevel;
      }
    }
    return level;
  }

  protected createStaffedHTML(
    priceStaffedString: string,
    discountPercent: number,
    priceString: string,
    displayOnlyPrice?: boolean
  ) {
    if (displayOnlyPrice) {
      return `<span>${priceStaffedString}</span>`;
    } else if (discountPercent !== 0) {
      return `<div>${priceStaffedString} <small>(-${discountPercent.toFixed(
        2
      )}%)</small><span class='text-muted float-right'>${priceString}</span></div>`;
    } else {
      return `<span>${priceString}</span>`;
    }
  }
}
