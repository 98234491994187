import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState, Metadata, MetadataService } from 'gung-standard';
import { Observable } from 'rxjs';
import { tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HlDisplayMetadataService extends MetadataService {
  private metadataI18n: any = {};

  constructor(store: Store<AppState>) {
    super(store);
  }

  protected getMetadata(): Observable<Metadata> {
    return super.getMetadata().pipe(tap(metadata => this.mapMetadataI18n(metadata)));
  }

  mapMetadataI18n(metadata: Metadata) {
    if (!metadata || Object.keys(metadata).length === 0) {
      return;
    }
    const metadataExtra = metadata;

    const res: any = {};
    Object.keys(metadataExtra).forEach(metadataTable => {
      const metaObject = metadataExtra[metadataTable];

      if (metaObject.list && metaObject.list.length > 0) {
        if (Object.keys(metaObject.list[0]).indexOf('Values') >= 0) {
          metaObject.list.forEach(element => {
            Object.keys(element.Values).forEach(valueLanguageKey => {
              res[valueLanguageKey] = res[valueLanguageKey] || {};
              res[valueLanguageKey][metadataTable] = res[valueLanguageKey][metadataTable] || {};
              res[valueLanguageKey][metadataTable][element.Id] = element.Values[valueLanguageKey];
            });
          });
        }
      }
    });

    this.metadataI18n = res;
  }

  getMetadataI18n(): any {
    return this.metadataI18n;
  }
}
