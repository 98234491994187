<div *ngIf="isSales" class="text-right">
  <button class="btn btn-outline-primary text-nowrap mt-2" (click)="openMeeting(data)" translate>ADD_MEETING</button>
</div>
<div class="table-responsive">
  <table class="table table-borderless table-striped table-hover">
    <thead>
      <tr>
        <th scope="col" translate>DATE</th>
        <th scope="col" translate *ngIf="!isSales">MEETING_TYPE</th>
        <th scope="col" translate *ngIf="!isSales">CONTACT</th>
        <th scope="col" translate>MEETING_SYSTEM</th>
        <th scope="col" translate *ngIf="!isSales">SALES_CYCLE</th>
        <th scope="col"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let meeting of data">
        <td>
          {{ meeting.extra.hr.eventpldat | date: 'shortDate' }}
        </td>
        <td *ngIf="!isSales">
          {{ getOptionName('xa', 'eventbeskr', meeting.extra.hr.eventkod1) }}
        </td>
        <td *ngIf="!isSales">
          {{ meeting.extra.hr.editext }}
        </td>
        <td>
          <span *ngFor="let key of meeting.extra.systems; let i = index">
            {{ getOptionName('q_system', 'q_systemdescription', key) }}<br />
          </span>
        </td>
        <td *ngIf="!isSales">
          <span *ngFor="let key of meeting.extra.salesCycles; let i = index">
            {{ getOptionName('q_hl_salescycle', 'q_hl_salescyclebeskr', key) }}<br />
          </span>
        </td>
        <td class="">
          <button type="button" class="btn btn-primary btn-block" (click)="openEditMeetingModal(meeting)" translate>
            UPDATE_MEETING
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</div>
