import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, AuthGuard } from 'gung-standard';
import { first } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  constructor(
    private router: Router,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.authService
      .getRoles()
      .pipe(first())
      .subscribe(roles => {
        if (roles.indexOf('SALES') < 0 && roles.indexOf('ADMIN') < 0 && roles.indexOf('MANAGER') < 0) {
          this.router.navigateByUrl('/home-user');
        } else {
          this.router.navigateByUrl('/customers');
        }
        return;
      });
  }
}
