import { Component, Input, OnInit } from '@angular/core';
import { GungCompany } from '../../../services/gung-company/gung-company.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomPageHtmlEditorComponent } from '../../custom-page/custom-page-html-editor/custom-page-html-editor.component';
import { TemplateElement } from '../../custom-page/custom-page-interface/template-component';
import { TranslateService } from '@ngx-translate/core';

export interface MyCompanyFooter {
  enabled: boolean;
  backgroundColor: string;
  columns: MyCompanyFooterColumn[];
}

export interface MyCompanyFooterColumn {
  html: string;
  i18n: {[lang: string]: string};
  class: string;
}

@Component({
  selector: 'lib-my-company-footer',
  templateUrl: './my-company-footer.component.html',
  styleUrls: ['./my-company-footer.component.css']
})
export class MyCompanyFooterComponent implements OnInit {
  public currentLang = this.translateService.currentLang || 'se';
  loaded = false;
  @Input()
  public company: GungCompany;

  public myCompanyFooter: MyCompanyFooter;

  constructor(
    protected modalService: NgbModal,
    protected translateService: TranslateService
  ) {
  }

  ngOnInit(): void {
    if (!this.company?.extra?.footer) {
      this.company = {
        ...this.company,
        extra: {
          ...this.company.extra,
        }
      }

      this.company.extra.footer = {
        backgroundColor: '#000',
        columns: []
      };
    }
    this.myCompanyFooter = this.company.extra.footer;
    this.loaded = true;
  }

  addColumn() {
    this.myCompanyFooter.columns.push({
      html: '',
      i18n: {},
      class: ''
    });
  }

  public invertHex(hex) {
    return (Number(`0x1${hex}`) ^ 0xFFFFFF).toString(16).substr(1).toUpperCase()
  }

  public openHtmlModal(column: MyCompanyFooterColumn) {
    const modalRef = this.modalService.open(CustomPageHtmlEditorComponent, { 
      size: 'xl',
      backdrop: 'static',
      keyboard: true
    });

    const data: TemplateElement[] = [
      {
        link: undefined,
        image: undefined,
        text: {
          enabled: true,
          text: column.html,
          i18n: {},
        }
      }
    ];
    for (const [key, value] of Object.entries(column.i18n)) {
      data[0].text.i18n[key] = {
        text: value
      };
    }
    
    modalRef.componentInstance.data = JSON.parse(JSON.stringify(data));
    modalRef.result.then(
      result => {
        const newElement = JSON.parse(result);
        for (const [key, value] of Object.entries(newElement[0].text.i18n)) {
          column.i18n[key] = (value as any).text;
        }
        // column.i18n = newElement[0].text.i18n;
        column.html = newElement[0].text.text;
      },
      reason => {}
    );
  }

  public getHtmlText(htmlText: MyCompanyFooterColumn) {
    if (htmlText && (htmlText.html || htmlText?.i18n?.[this.currentLang])) {
      return htmlText?.i18n?.[this.currentLang] || htmlText.html;
    }
    return '';
  }

  public deleteColumn(i: number) {
    this.myCompanyFooter.columns.splice(i, 1);
  }
}
