import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Availability } from '../../../models/availability';
import { parse } from 'date-fns';
import { Product } from '../../../models';

@Component({
  selector: 'lib-availability-modal',
  templateUrl: './availability-modal.component.html',
  styleUrls: ['./availability-modal.component.css']
})
export class AvailabilityModalComponent {
  product: Product;
  availability: Availability;

  constructor(public activeModal: NgbActiveModal) {}

  parseDate(date: string): Date {
    return parse(date, 'yyMMdd', new Date());
  }

  parseFloat(value): number {
    return parseFloat(value);
  }
}
