import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BarcodeScanner } from 'dynamsoft-javascript-barcode';
import { BarcodeScannerConfigService } from '../../../services/barcode-scanner-config/barcode-scanner-config.service';

@Component({
  selector: 'lib-scanner',
  templateUrl: './scanner.component.html',
  styleUrls: ['./scanner.component.css']
})
export class ScannerComponent implements OnInit, OnDestroy {
  @Output()
  outputCodeScanned = new EventEmitter<string>();

  @Input()
  autoInitCamera = true;

  pScanner = null;
  scanner: BarcodeScanner;

  currentCameraIndex = 0;

  constructor(protected barcodeScannerConfigService: BarcodeScannerConfigService) {}

  async ngOnInit(): Promise<void> {
    try {
      if (!BarcodeScanner.license) {
        BarcodeScanner.license = this.barcodeScannerConfigService.apiKey;
        // BarcodeScanner.engineResourcePath = 'https://cdn.jsdelivr.net/npm/dynamsoft-javascript-barcode@9.2.13/dist/';
        BarcodeScanner.engineResourcePath = "https://cdn.jsdelivr.net/npm/dynamsoft-javascript-barcode/dist/";
      }
      this.scanner = await (this.pScanner = BarcodeScanner.createInstance());
      let settings = await this.scanner.getRuntimeSettings();
      settings.region.regionMeasuredByPercentage = 1;
      settings.region.regionLeft = 25;
      settings.region.regionTop = 25;
      settings.region.regionRight = 75;
      settings.region.regionBottom = 75;
      await this.scanner.updateRuntimeSettings(settings);
      await this.scanner.setUIElement(document.querySelector('.component-barcode-scanner') as any);

      this.scanner.onFrameRead = (results: any) => {
        for (const result of results) {
          this.outputCodeScanned.emit(result.barcodeText);
        }
      };
      await this.scanner.open();
      this.scanner.setVideoFit("cover");
    } catch (ex) {
      let errMsg;
      if (ex.message.includes('network connection error')) {
        errMsg =
          'Failed to connect to Dynamsoft License Server: network connection error. Check your Internet connection or contact Dynamsoft Support (support@dynamsoft.com) to acquire an offline license.';
      } else {
        errMsg = ex.message || ex;
      }
      console.error(errMsg);
    }
  }

  async ngOnDestroy() {
    if (this.pScanner) {
      (await this.pScanner).destroyContext();
      console.log('BarcodeScanner Component Unmount');
    }
  }

  async changeCamera(){
    this.currentCameraIndex++;
    let cameras = await this.scanner.getAllCameras();
    if(cameras.length === this.currentCameraIndex){
      this.currentCameraIndex = 0;
    }
    await this.scanner.setCurrentCamera(cameras[this.currentCameraIndex]);
  }

  cameraOnOff() {
    if (this.scanner.isOpen()) {
      this.scanner.hide();
    } else {
      this.scanner.show();
      (document.querySelector('.dce-video-container video') as any).style['object-fit'] = 'cover';
    }
  }

  /* initCamera() {
    configure(this.barcodeScannerConfigService.apiKey, {
      engineLocation: 'https://cdn.jsdelivr.net/npm/scandit-sdk/build'
    }).then(() => {
      BarcodePicker.create(
        document.getElementById('scandit-barcode-picker'),
        this.barcodeScannerConfigService.getBarcodeConfig()
      ).then(barcodePicker => {
        this.barcodePicker = barcodePicker;
        this.outputBarcodePicker.emit(barcodePicker);
        barcodePicker.applyScanSettings(this.barcodeScannerConfigService.getScanSettings());
        if (this.barcodeScannerConfigService.scanSettings?.searchArea) {
          barcodePicker.setViewfinderArea(this.barcodeScannerConfigService.scanSettings.searchArea);
        }
        barcodePicker.on('scan', scanResult => {
          this.outputCodeScanned.emit(scanResult.barcodes[0].data);
          this.barcodeScannerConfigService.pauseScanning(barcodePicker);
        });
      });
    });
  } */
}
