import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService, GungFlowService, Product, ProductService, SelectedCustomerService } from 'gung-standard';
import { forkJoin, Observable, of } from 'rxjs';
import { first, map, switchMap } from 'rxjs';
import { environment } from '../../environments/environment';
import { HlDisplayPurchasedProduct } from '../models/purchased-product';
import { HlDisplayMetadataService } from './hl-display-metadata.service';

interface PurchasedProduct {
  artnr: string;
  latestDeliveryDate: number;
  total: number;
  product: Product;
}

export const langExists =
  (lang: string | undefined) =>
    (data: Product): boolean =>
      lang && data.extra.i18n && data.extra.i18n[lang];

@Injectable({
  providedIn: 'root'
})
export class HlProductService extends ProductService {
  constructor(
    http: HttpClient,
    gungFlowService: GungFlowService,
    authService: AuthService,
    private translate: TranslateService,
    selectedCustomerService: SelectedCustomerService,
    private metadataService: HlDisplayMetadataService
  ) {
    super(http, gungFlowService, authService, selectedCustomerService);
  }

  public getProduct(productId: string): Observable<Product> {
    return super.getProduct(productId).pipe(map(product => this.transformProduct(product)));
  }

  public getProductsByIds(productIds: string[]): Observable<Product[]> {
    return super
      .getProductsByIds(productIds)
      .pipe(map(products => products.map(product => this.transformProduct(product))));
  }

  public getProductsByAssortment(assortmentId: string): Observable<Product[]> {
    return super
      .getProductsByAssortment(assortmentId)
      .pipe(map(products => products.map(product => this.transformProduct(product))));
  }

  public getComponentsByIds(productIds: string[]): Observable<Product[]> {
    const url = `json/hl-display/components`;

    return this.http.post<Product[]>(url, productIds);
  }

  public getCustomerPurchasedProducts(): Observable<HlDisplayPurchasedProduct[]> {
    return forkJoin([
      this.selectedCustomerService.getSelectedCustomer().pipe(first()),
      this.gungFlowService.getSelectedFlow().pipe(first())
    ]).pipe(
      switchMap(([selectedCustomer, flow]) => {
        const assortmentId = 'sROT';
        const url = `json/hl-display/customer-purchased-before/${assortmentId}/${selectedCustomer.id}/${flow.id}`;
        return this.http.get<PurchasedProduct[]>(url);
      }),
      map(customerPurchasedProducts => {
        return customerPurchasedProducts.map(customerPurchasedProduct => {
          const { product, ...allPropertiesButProduct } = customerPurchasedProduct;
          return {
            ...this.transformProduct(customerPurchasedProduct.product),
            ...allPropertiesButProduct
          };
        });
      })
    );
  }

  private transformProduct(productToMap: Product): Product {
    const languageCode = this.translate.currentLang;
    const product: Product = {
      ...productToMap,
      extra: {
        ...productToMap.extra,
        hlPimProduct: {
          ...productToMap.extra.hlPimProduct
        },
        hlPimItem: {
          ...productToMap.extra.hlPimItem
        },
        minimumOrderQuantity: 1,
        stepAmount: 1,
        i18n: {
          ...this.mergeI18n(productToMap.extra.i18n, this.metadataService.getMetadataI18n())
        }
      }
    };
    product.extra.minimumOrderQuantity = 1;
    product.extra.stepAmount = 1;
    try {
      product.extra.minimumOrderQuantity = parseInt(product.extra.ar.q_hl_sales_moq, 10);
    } catch (error) {
      console.error(error);
    }
    try {
      product.extra.stepAmount = parseInt(product.extra.ar.q_hl_sales_qty, 10);
    } catch (error) {
      console.error(error);
    }

    if (languageCode === product.extra._currentLanguage) {
      return product;
    }

    if (!!product.extra.images) {
      product.extra.images.map(img => {
        if (img.description === 'No Image') {
          img.s3Uri = `hldisplay/images/${languageCode}-no-image.jpg`;
        }
      });
    }

    // product.name = product.extra.ar.artbeskr2 || product.name;
    product.description = product.extra.ar.itemspecweb || product.description || '';
    if (!product.extra.i18n) {
      return product;
    }
    const i18n: { [language: string]: any } = product.extra.i18n;

    if (product.id === '796965') {
      console.log('PRODUCT', product);
    }

    if (i18n[languageCode]) {
      const languageMap = i18n[languageCode];

      product.name = languageMap.externalProductName || product.name;

      product.description = languageMap.itemspecweb || '';

      if (product.extra.hlPimItem.ItemMerchandiseType) {
        const typeList = product.extra.hlPimItem.ItemMerchandiseType as string[];
        product.extra.hlPimItem.ItemMerchandiseType = typeList.map(type => {
          if (languageMap.MerchandiseType && languageMap.MerchandiseType[type]) {
            return languageMap.MerchandiseType[type];
          } else {
            return type;
          }
        });
      }

      if (product.extra.hlPimProduct.ShelfManufacturer) {
        const typeList = product.extra.hlPimProduct.ShelfManufacturer as string[];
        product.extra.hlPimProduct.ShelfManufacturer = typeList.map(type => {
          if (languageMap.ShelfManufacturer) {
            return languageMap.ShelfManufacturer[type];
          } else {
            return type;
          }
        });
      }

      if (product.extra.hlPimProduct.ShelfManufacturer) {
        const typeList = product.extra.hlPimProduct.ShelfManufacturer as string[];
        product.extra.hlPimProduct.ShelfManufacturer = typeList.map(type => {
          if (languageMap.ShelfManufacturer) {
            return languageMap.ShelfManufacturer[type];
          } else {
            return type;
          }
        });
      }

      if (product.extra.hlPimProduct.ExtProdSys) {
        const typeList = product.extra.hlPimProduct.ExtProdSys as string[];
        product.extra.hlPimProduct.ExtProdSys = typeList.map(type => {
          if (languageMap.ExtProdSys) {
            return languageMap.ExtProdSys[type];
          } else if (languageMap.ESLManufacturer && languageMap.ESLManufacturer[type]) {
            return languageMap.ESLManufacturer[type];
          } else {
            return type;
          }
        });
      }

      if (product.extra.hlPimItem.ItemColourName) {
        let typeList = product.extra.hlPimItem.ItemColourName as string[];
        if (!Array.isArray(product.extra.hlPimItem.ItemColourName)) {
          typeList = [product.extra.hlPimItem.ItemColourName];
        }
        product.extra.hlPimProduct.ItemColourName = typeList.map(type => {
          if (languageMap.ItemColourName) {
            return languageMap.ItemColourName[type];
          } else {
            return type;
          }
        });
      }
      product.extra._currentLanguage = languageCode;
    }

    return product;
  }

  // values from i18nSecondary will be overwritten if duplicate keys exist in i18nPrimary
  private mergeI18n(i18nSecondary: any, i18nPrimary: any): any {
    const res: any = {};

    Object.keys(i18nSecondary).forEach(language => {
      res[language] = { ...i18nSecondary[language] };
    });

    Object.keys(i18nPrimary).forEach(language => {
      res[language] = {
        ...(res[language] || {}),
        ...i18nPrimary[language]
      };
    });

    return res;
  }
}
