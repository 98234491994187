import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ListItemRendererComponent } from 'gung-list';
import { Activity } from 'gung-standard';
import { environment } from '../../../../environments/environment';
import { HlDisplayCustomerMeetingModalComponent } from '../../hl-display-customer-meeting-modal/hl-display-customer-meeting-modal.component';

@Component({
  selector: 'app-hl-display-meeting-card-list',
  templateUrl: './hl-display-meeting-card-list.component.html',
  styleUrls: ['./hl-display-meeting-card-list.component.css']
})
export class HlDisplayMeetingCardListComponent extends ListItemRendererComponent<Activity[]> implements OnInit {
  constructor(private modalService: NgbModal) {
    super();
  }

  ngOnInit(): void { }

  openEditMeetingModal(meeting: Activity): void {
    const modalReference = this.modalService.open(HlDisplayCustomerMeetingModalComponent, {
      backdrop: true || 'static',
      size: 'lg',
      keyboard: true
    });

    modalReference.componentInstance.meeting = meeting;

    modalReference.result.then(
      result => {
        // console.log('result: ', result);
      },
      error => {
        // console.log('error: ', error);
      }
    );
  }
}
