import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  SimpleConfigBaseFilter,
  ListLayout,
  ListLayoutMultipleComponent,
  ConfigBaseFilter,
  SelectionAction,
  ExportSelection
} from 'gung-list';
import { Observable, mergeMap, forkJoin, of, map, first } from 'rxjs';
import { CustomerService } from '../../../services/customers/customer.service';
import { MetadataService } from '../../../services/metadata/metadata.service';
import { OrderListConfigService } from '../../../services/order-list-config/order-list-config.service';
import { OrderService } from '../../../services/orders/order.service';
import { SelectedCustomerService } from '../../../services/selected-customer/selected-customer.service';
import { Order } from '../../../models/order';
import { ProductionManagementPickableOrdersService } from './production-management-pickable-orders.service';
import { ProductionManagementOrdersToPickTableComponent } from '../components/production-management-orders-to-pick-table/production-management-orders-to-pick-table.component';
import { format } from 'date-fns';
import { ProductionManagementOrderExportService } from './production-management-order-export.service';
import { printDocument } from '../../../utils/print-document';

class FilterOrderInvoiced extends SimpleConfigBaseFilter<Order> {
  constructor(protected translateService: TranslateService) {
    super();
  }

  getName(): string {
    return 'INVOICE_STATUS';
  }

  getOptionIds(item: Order): string[] {
    return [item.extra.Order.InvoiceReference === '0' ? 'NOT_INVOICED' : 'INVOICED'];
  }

  getOptionName(key: string): string {
    return this.translateService.instant(key);
  }
}

class FilterExtraOrder extends SimpleConfigBaseFilter<Order> {
  constructor(
    protected filterTitle: string,
    protected extraOrderField: string,
    protected translateService: TranslateService
  ) {
    super();
  }

  getName(): string {
    return this.filterTitle;
  }

  getOptionIds(item: Order): string[] {
    return [item.extra.Order[this.extraOrderField] || 'UNDEFINED'];
  }

  getOptionName(key: string): string {
    return this.translateService.instant(key);
  }
}

class OrderStatusFilter extends SimpleConfigBaseFilter<Order> {
  constructor(protected translateService: TranslateService) {
    super();
  }

  getName(): string {
    return 'ORDER_STATUS';
  }

  getOptionIds(item: Order): string[] {
    return [getOrderStatusTranslationKey(item)];
  }

  getOptionName(key: string): string {
    return this.translateService.instant(key);
  }
}

class OrderTypeFilter extends SimpleConfigBaseFilter<Order> {
  constructor() {
    super();
  }

  getName(): string {
    return 'ORDER_TYPE';
  }

  getOptionIds(item: Order): string[] {
    if (item.extra.Order && item.extra.Order.OrderType) {
      return [item.extra.Order.OrderType];
    }
    return ['UNDEFINED'];
  }

  getOptionName(key: string): string {
    return key;
  }
}

class OrderDeliveryMethodFilter extends OrderTypeFilter {
  constructor(protected metadataService: MetadataService) {
    super();
  }

  getName(): string {
    return 'DELIVERY_METHOD';
  }

  getOptionIds(order: Order): string[] {
    return [order.extra?.Order?.WayOfDelivery || 'BLANK'];
  }

  getOptionName(key: string): string {
    return this.metadataService.getMetadataValue('WayOfDeliveries', 'Description', key) || key;
  }
}

class OrderLabelsFilter extends OrderTypeFilter {
  constructor(protected metadataService: MetadataService) {
    super();
  }

  getName(): string {
    return 'TAGS';
  }

  getOptionIds(order: Order): string[] {
    if (order.extra?.Order?.Labels) {
      return order.extra.Order.Labels.map(label => label.Id);
    } else {
      return ['BLANK'];
    }
  }

  getOptionName(key: string): string {
    return this.metadataService.getMetadataValue('Labels', 'Description', key) || key;
  }
}

class OrderExpectedDeliveryFilter extends SimpleConfigBaseFilter<Order> {
  type = 'dateRangeFilter';

  constructor(protected translateService: TranslateService) {
    super();
  }

  getName(): string {
    return 'EXPECTED_DELIVERY_DATE';
  }

  getOptionIds(item: Order): string[] {
    return [format(new Date(item.extra.Order.DeliveryDate || Date()), 'yyMMdd')];
  }

  getOptionName(key: string): string {
    return this.translateService.instant(key);
  }
}

class OrderDateFilter extends SimpleConfigBaseFilter<Order> {
  type = 'dateRangeFilter';

  constructor(protected translateService: TranslateService) {
    super();
  }

  getName(): string {
    return 'ORDER_DATE';
  }

  getOptionIds(item: Order): string[] {
    return [format(new Date(item.extra.Order.OrderDate), 'yyMMdd')];
  }

  getOptionName(key: string): string {
    return this.translateService.instant(key);
  }
}

@Injectable({
  providedIn: 'root'
})
export class ProductionManagementOrdersToPickListConfigService extends OrderListConfigService {
  topFilter: boolean = true;

  constructor(
    protected translationService: TranslateService,
    protected orderService: OrderService,
    protected selectedCustomerService: SelectedCustomerService,
    protected metadataService: MetadataService,
    protected customerService: CustomerService,
    protected pickableOrdersService: ProductionManagementPickableOrdersService,
    protected orderExportService: ProductionManagementOrderExportService
  ) {
    super(orderService, selectedCustomerService);
  }

  getAddQueryParameterLimitToUrl(): boolean {
    return true;
  }

  getLayouts(): ListLayout<Order>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => ProductionManagementOrdersToPickTableComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'Order list'
      }
    ];
  }

  getFilters(): ConfigBaseFilter<Order>[] {
    return [
      new OrderDateFilter(this.translationService),
      new OrderExpectedDeliveryFilter(this.translationService),
      new FilterExtraOrder('OUR_REFERENCE', 'OurReference', this.translationService),
      new FilterExtraOrder('COUNTRY', 'Country', this.translationService),
      new OrderStatusFilter(this.translationService),
      new OrderDeliveryMethodFilter(this.metadataService),
      new OrderLabelsFilter(this.metadataService),
      new OrderTypeFilter()
    ];
  }

  getSearchTerms(item: Order): string[] {
    const result = super.getSearchTerms(item);

    if (item.extra?.Order?.OurReference) {
      result.push(item.extra.Order.OurReference);
    }
    if (item.extra?.Order?.WayOfDelivery) {
      result.push(
        this.metadataService.getMetadataValue('WayOfDeliveries', 'Description', item.extra.Order.WayOfDelivery) ||
          item.extra.Order.WayOfDelivery
      );
    }
    if (item.extra?.Order?.Labels) {
      result.push(
        ...item.extra.Order.Labels.map(
          label => this.metadataService.getMetadataValue('Labels', 'Description', label.Id) || label.Id
        )
      );
    }
    if (item.extra?.Order?.DeliveryDate) {
      result.push(item.extra.Order.DeliveryDate);
    }

    return result;
  }

  getItems(): Observable<Order[]> {
    return this.pickableOrdersService.getOrdersToPick().pipe(
      mergeMap(items => forkJoin([of(items)])),
      map(([orders]) => {
        const result = orders.map(order => {
          return {
            ...order,
            extra: {
              ...order.extra
            }
          };
        });
        result.map(resOrder => {
          resOrder.extra.customerName = resOrder.extra.Order.CustomerName;
          return resOrder;
        });
        const sorted = result.sort((a, b) => {
          if (a.extra.Order.OrderDate > b.extra.Order.OrderDate) {
            return -1;
          }
          if (a.extra.Order.OrderDate < b.extra.Order.OrderDate) {
            return 1;
          }
          if (a.extra.Order.OrderDate === b.extra.Order.OrderDate) {
            if (a.id > b.id) {
              return -1;
            }
            if (a.id < b.id) {
              return 1;
            }
          }
          return 0;
        });
        return sorted;
      })
    );
  }
  getSelectionActions(): Observable<SelectionAction<Order>[]> {
    return of([
      this.getPickingListExport(),
      this.getDeliveryNoteExport(),
      this.getProformaExport(),
      this.getCombinedDeliveryNoteProformaExport()
    ]);
  }

  private getPickingListExport(): SelectionAction<Order> {
    return {
      label: 'PICKING_LIST',
      performAction: (selection: ExportSelection<Order>) => {
        const itemList = Object.values(selection.selectedItems);
        this.orderExportService
          .exportOrders(itemList, 'PICKING_LIST')
          .pipe(first())
          .subscribe(x => {
            printDocument(x.body, 'picking-list.pdf');
          });
        return of();
      }
    };
  }

  private getDeliveryNoteExport(): SelectionAction<Order> {
    return {
      label: 'DELIVERY_NOTE',
      performAction: (selection: ExportSelection<Order>) => {
        const itemList = Object.values(selection.selectedItems);
        this.orderExportService
          .exportOrders(itemList, 'DELIVERY_NOTE')
          .pipe(first())
          .subscribe(x => {
            printDocument(x.body, 'delivery-note.pdf');
          });
        return of();
      }
    };
  }

  private getProformaExport(): SelectionAction<Order> {
    return {
      label: 'PROFORMA',
      performAction: (selection: ExportSelection<Order>) => {
        const itemList = Object.values(selection.selectedItems);
        this.orderExportService
          .exportOrders(itemList, 'PROFORMA')
          .pipe(first())
          .subscribe(x => {
            printDocument(x.body, 'proforma.pdf');
          });
        return of();
      }
    };
  }

  private getCombinedDeliveryNoteProformaExport(): SelectionAction<Order> {
    return {
      label: 'DELIVERY_NOTE_AND_PROFORMA',
      performAction: (selection: ExportSelection<Order>) => {
        const itemList = Object.values(selection.selectedItems);
        this.orderExportService
          .exportOrders(itemList, 'DELIVERY_NOTE_PROFORMA_COMBINED')
          .pipe(first())
          .subscribe(x => {
            printDocument(x.body, 'deliverynote_proforma.pdf');
          });
        return of();
      }
    };
  }
}

function getOrderStatusTranslationKey(order: Order): string {
  if (!!order) {
    if (order.extra.Order.Cancelled) {
      return 'CANCELLED';
    }
    if (order.extra.Order.DeliveryState === 'registration') {
      return 'OPEN_ORDER';
    }
    if (order.extra.Order.DeliveryState === 'delivery') {
      return 'DELIVERED_ORDER';
    }
    if (order.extra.Order.DeliveryState === 'reservation') {
      return 'NON_ALLOCATED_ORDER';
    }
    if (!order.extra.Order.NotCompleted) {
      return 'COMPLETED';
    }
    return 'ACTIVE';
  }

  return 'UNDEFINED';
}
