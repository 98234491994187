import { Injectable } from '@angular/core';
import { CartRow, CheckoutRow } from 'gung-standard';

@Injectable({
  providedIn: 'root'
})
export class HlDisplayDummyArticleService {
  dummyArticleId = '155035';

  constructor() {}

  isDummyRow(row: CartRow | CheckoutRow): boolean {
    if ('productId' in row) {
      return row.productId === this.dummyArticleId;
    }
    if ('id' in row) {
      return row.id === this.dummyArticleId;
    }
  }
}
