import { Injectable } from '@angular/core';
import {
  AuthService,
  BackendRerouteService,
  Customer,
  CustomerService,
  Order,
  OrderToBasketService,
  SelectedCustomerService
} from 'gung-standard';
import { ActionButton, ActionButtonConfigService } from 'gung-common';
import { first, map, Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import { HlDisplayOrderService } from './hl-display-order.service';
import { HlDisplayOrderModalComponent } from '../components/hl-display-order-modal/hl-display-order-modal.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient } from '@angular/common/http';
import { ActionType, HlDisplayMsflowArgumentService } from './hl-display-msflow-argument.service';

@Injectable({
  providedIn: 'root'
})
export class HlDisplayOrderActionConfigService implements ActionButtonConfigService<Order> {
  isSales = false

  base = [
    { title: 'XLSX', action: (item: Order) => this.backendRerouteService.reroute('/download/order-excel/' + item.id) },
    { title: 'PDF', action: (item: Order) => this.backendRerouteService.reroute('/download/order-pdf/' + item.id) },
    { title: 'IMAGES', action: (item: Order) => this.backendRerouteService.reroute('/download/order-zip/' + item.id) },
    { title: 'MORE_INFO', action: (item: Order) => this.router.navigate(['/orders/' + item.id]) },
    { title: 'COPY_TO_CART', action: (item: Order) => this.customerComparison(item) }
  ];

  sales = [
    {
      title: 'QUOTE_TO_ORDER',
      action: (item: Order) => {
        const updateOrder = JSON.parse(JSON.stringify(item));
        updateOrder.extra.oh = {
          ordstat: 10
        };

        // This is a bit ugly since we set it on the OH. It is required though since the endpoint only uses that
        // object and I don't want to have it in a separate request because of the chances something goes wrong.
        // We will handle it in the backend (strip it from the oh before posting into jeeves and then using the fields
        // in the stage after).
        updateOrder.extra.oh[this.hlDisplayMsflowDecoratorService.getArgumentKey()] =
          this.hlDisplayMsflowDecoratorService.createMsFlowArguments(ActionType.OfferToOrder, true);

        this.orderService.updateOrderHeader(updateOrder).subscribe(order => {
          this.router.navigate(['/orders/' + order.id]);
          this.orderService.getQuotes(true).pipe(first()).subscribe();
          this.orderService.getOpenOrders(true).pipe(first()).subscribe();

          //this.http
          //  .post('/json/order/send-confirmation', {
          //    emailRecipients: [environment.quoteConfirmationEmailAddress],
          //    orderId: order.id
          //  })
          //  .subscribe(() => {
          //    this.router.navigate(['/orders/' + order.id]);
          //    this.orderService.getQuotes(true).pipe(first()).subscribe();
          //    this.orderService.getOpenOrders(true).pipe(first()).subscribe();
          //  });
        });
      }
    },
    {
      title: 'CANCEL_QUOTE',
      action: (item: Order) => {
        const updateOrder = JSON.parse(JSON.stringify(item));
        updateOrder.extra.oh = {
          ordstat: 90
        };
        this.orderService.updateOrderHeader(updateOrder).subscribe(order => {
          this.orderService.updateQuotesSubject();
        });
      }
    }
  ];

  modalRef: NgbModalRef;

  constructor(
    private http: HttpClient,
    private backendRerouteService: BackendRerouteService,
    private router: Router,
    private authService: AuthService,
    private orderToBasketService: OrderToBasketService,
    private customerService: CustomerService,
    private selectedCustomerService: SelectedCustomerService,
    private modalService: NgbModal,
    private orderService: HlDisplayOrderService,
    private hlDisplayMsflowDecoratorService: HlDisplayMsflowArgumentService
  ) { }

  sendConfirmationToCustomerService() { }

  getButtons(item: Order): Observable<ActionButton<Order>[]> {
    return this.authService.getRoles().pipe(first(), map(roles => {
      const isSales = roles.includes('SALES');
      const isManager = roles.includes('MANAGER');

      if (isManager) {
        const index = this.base.findIndex(b => b.title === 'IMAGES');
        if (index > -1) {
          this.base.splice(index, 1);
        }
      }
      if (item.extra.oh.ordstat !== 0) {
        return [...this.base];
      }
      return (isSales || isManager) ? [...this.base, ...this.sales] : [...this.base];
    }))
  }

  changeCustomerDialog(currentCustomer: Customer, orderCustomer: Customer, item: Order) {
    this.modalRef = this.modalService.open(HlDisplayOrderModalComponent);
    this.modalRef.componentInstance.currentCustomer = currentCustomer;
    this.modalRef.componentInstance.nextCustomer = orderCustomer;

    this.modalRef.result
      .then(
        resolve => {
          if (resolve === 'Change') {
            this.selectedCustomerService.selectCustomer(orderCustomer.id);
          }
        },
        () => {
          throw new Error('Rejected');
        }
      )
      .then(() => this.copyToCart(item))
      .catch(error => console.log(error));
  }

  copyToCart(item: Order) {
    this.orderToBasketService
      .addOrderToBasket(item.id)
      .then(() => {
        console.log('added to cart');
      })
      .catch(e => { });
  }

  customerComparison(item: Order) {
    this.selectedCustomerService
      .getSelectedCustomer()
      .pipe(first())
      .subscribe(currentCustomer => {
        this.customerService.getCustomer(item.deliveryCustomerId).subscribe(orderCustomer => {
          currentCustomer.id?.split('@')[0] === orderCustomer.id
            ? this.copyToCart(item)
            : this.changeCustomerDialog(currentCustomer, orderCustomer, item);
        });
      });
  }
}
