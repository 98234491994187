import { Component, OnInit, Input } from '@angular/core';
import { CartService, CartRow, CheckoutObject } from 'gung-standard';
import { HlCalendarDate } from '../services/hl-delivery-date.service';
import { first } from 'rxjs';
import { format } from 'date-fns';

@Component({
  selector: 'app-hlsales-cart-delivery-date-picker',
  template:
    '<app-hl-delivery-date-picker class="mt-3" [deliveryMethod]="deliveryMethod" (selectedDate)="setAllRows($event)"></app-hl-delivery-date-picker>',
  styleUrls: ['./hlsales-cart-delivery-date-picker.component.css']
})
export class HLSalesCartDeliveryDatePickerComponent implements OnInit {
  @Input()
  public deliveryMethod: string;

  @Input()
  public checkout?: CheckoutObject;

  constructor(private cartService: CartService) {}

  ngOnInit() {}

  public setAllRows(selectedDate: HlCalendarDate) {
    if (this.checkout) {
      this.checkout.extra.oh = this.checkout.extra.oh || {};
      this.checkout.extra.oh.ordberlevdat = format(selectedDate.estimatedShippingDate, 'yyyy-MM-dd');
      this.checkout.extra.oh.ordlovlevdat = this.checkout.extra.oh.ordberlevdat;
      // this.checkout.extra.oh.q_ordlovlevdat_cust = format(selectedDate.date, 'yyyy-MM-dd');
      // this.checkout.extra.oh.q_ordberlevdat_cust = this.checkout.extra.oh.q_ordlovlevdat_cust;
    }

    this.cartService
      .getCurrentCart()
      .pipe(first())
      .subscribe((cartRows: CartRow[]) => {
        cartRows.forEach(row => this.onDeliveryDateSelect(row, selectedDate));
      });
  }

  private onDeliveryDateSelect(cartRow: CartRow, selectedDate: HlCalendarDate) {
    if (
      cartRow?.extra.estDeliveryDate === format(selectedDate.date, 'yyyy-MM-dd')
      && cartRow?.extra.orp?.ordberlevdat === format(selectedDate.estimatedShippingDate, 'yyyy-MM-dd')
      && cartRow?.extra.orp?.ordlovlevdat === format(selectedDate.estimatedShippingDate, 'yyyy-MM-dd')
    ) {
      // Don't change if it is the same date
      return;
    }
    this.cartService.setExtra(
      cartRow.productId,
      {
        estDeliveryDate: format(selectedDate.date, 'yyyy-MM-dd'),
        orp: {
          ordberlevdat: format(selectedDate.estimatedShippingDate, 'yyyy-MM-dd'),
          ordlovlevdat: format(selectedDate.estimatedShippingDate, 'yyyy-MM-dd')
        }
      },
      cartRow.targetStockId,
      cartRow.productPartialId
    );
  }
}
