<div class="container">
  <div class="row">
    <div class="col-12 col-md-3">
      <div class="row">
        <div class="col-12">
          <h5 translate>SETTINGS</h5>
        </div>
      </div>
      <div class="row settings py-3 mb-2">
        @for (settingsGroup of features | keyvalue; track settingsGroup.key) {
          <div class="col-12 py-1">
            <div
              class="setting p-2 d-block rounded w-100"
              (click)="selectedSettingsGroup = settingsGroup.key"
              [class.active]="settingsGroup.key === selectedSettingsGroup"
            >
              <span>
                <i class="fa-solid fa-icons fa-xl mr-4"></i><span translate>{{ settingsGroup.key }}</span>
              </span>
            </div>
          </div>
        }
      </div>
    </div>
    @if (selectedSettingsGroup) {
      <div class="col-12 offset-md-1 col-md-8">
        <div class="row">
          <div class="col-12">
            <h5 id="FEATURES" translate>FEATURES</h5>
          </div>
        </div>
        <div class="row features py-3 mb-5">
          @for (feature of features[selectedSettingsGroup] | keyvalue; track feature.key) {
            @if (feature.key !== 'name' && feature.key !== '@class') {
              <div class="col-12 py-1">
                <div class="features p-3 border rounded w-100 d-flex justify-content-between">
                  <span class="">{{ feature.key }}</span>
                  @switch (feature.value.constructor.name) {
                    @case ('Boolean') {
                      <div class="form-check form-switch">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="flexSwitchCheckDefault"
                          [(ngModel)]="features[selectedSettingsGroup][feature.key]"
                        />
                      </div>
                    }
                    @case ('Number') {
                      <lib-gung-number-input
                        suppressLabel="true"
                        [(ngModel)]="features[selectedSettingsGroup][feature.key]"
                        extraFormClass="m-0"
                      />
                    }
                    @case ('Array') {
                      <lib-gung-text-input
                        suppressLabel="true"
                        [ngModel]="features[selectedSettingsGroup][feature.key]"
                        (ngModelChange)="updateArrayValues($event, feature.key)"
                      />
                    }
                    @case ('String') {
                      <lib-gung-text-input
                        suppressLabel="true"
                        [(ngModel)]="features[selectedSettingsGroup][feature.key]"
                      />
                    }
                    @default {
                      <lib-gung-text-input
                        suppressLabel="true"
                        [(ngModel)]="features[selectedSettingsGroup][feature.key]"
                      />
                    }
                  }
                </div>
              </div>
            }
          }
        </div>
      </div>
    }
  </div>
  <div class="row pb-5 justify-content-end">
    <div class="col-auto">
      <button (click)="save($event)" type="button" class="btn btn-secondary p-2 px-4" translate>SAVE</button>
    </div>
  </div>
</div>
