<div class="striped row p-md-4 pt-4 pb-4">
  <div class="col">
    <div class="row">
      <div class="col">
        <h5>
          <a [routerLink]="['/customers/' + data.id]" (click)="selectCustomer(data)">{{ data.name }} </a>
          <small>({{ data.id }})</small>
          <small *ngIf="isManager && data.extra?.fr?.q_siren" class="text-muted"
            >&nbsp;(NISA: {{ data.extra.fr.q_siren }})</small
          >
        </h5>
        <span translate>ORGANISATION_NO</span>: {{ data.extra.fr.orgnr }}<span class="d-none d-sm-inline">, </span>
        <br class="d-sm-none" /><span translate>FR_FTGPOSTADR2</span>: {{ data.extra.fr.ftgpostadr2 }},
        <span translate>FR_FTGPOSTNR</span>: {{ data.extra.fr.ftgpostnr }}({{ data.extra.fr.ftgpostadr3 }})
      </div>
      <div class="col-12 col-md-2 mt-xs-2">
        <a
          class="btn btn-primary text-nowrap w-100"
          [routerLink]="'/products'"
          [queryParams]="{ filters: 'ASSORTMENT_TYPE__:__C_____S' }"
          (click)="selectCustomer(data)"
          translate
          >ORDER</a
        >
        <button
          *ngIf="isSales"
          class="btn btn-outline-primary text-nowrap mt-2 w-100"
          (click)="openMeeting(data)"
          translate
        >
          MEETING
        </button>
      </div>
    </div>
  </div>
</div>
