import { Component, OnInit } from '@angular/core';
import { JeevesInvoiceActionConfigService, JeevesInvoiceCardListComponent } from 'gung-standard-jeeves';
import { CustomerService } from 'gung-standard';
import { first } from 'rxjs';
import { HlDisplayDateUtilService } from '../../services/hl-display-date-util.service';

@Component({
  selector: 'app-hl-display-invoice-card-list',
  templateUrl: './hl-display-invoice-card-list.component.html',
  styleUrls: ['./hl-display-invoice-card-list.component.css']
})
export class HlDisplayInvoiceCardListComponent extends JeevesInvoiceCardListComponent implements OnInit {
  constructor(public configService: JeevesInvoiceActionConfigService, protected costumerService: CustomerService, protected dateUtilService: HlDisplayDateUtilService) {
    super(configService);
  }

  ngOnInit(): void {
  }
}
