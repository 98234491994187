<div class="card product-list-card">
  <div class="card-body mt-3">
    <div class="product-image d-none d-md-block">

      <ng-template ngTemplateOutlet *ngIf="!data.extra.oldProduct; then productImg; else oldProductImg"></ng-template>
      <ng-template #productImg>
        <a routerLink="/product/{{ data.id }}">
          <img class="img-fluid" *ngIf="!!data.extra.images && !!data.extra.images[0]" src="https://cdn1.gung.io/fit-in/250x250/filters:etag({{ data.extra.images[0].s3ETag }})/{{
              data.extra.images[0].s3Uri
            }}" alt="" />
        </a>
      </ng-template>
      <ng-template #oldProductImg>
        <div class="old-product-image">
          <a routerLink="/product/{{ data.id }}">
            <img src="assets/old-product.png" class="img-fluid" />
            <div class="text text-center" translate>OLD_PRODUCT_IMAGE</div>
          </a>
        </div>
      </ng-template>
    </div>

    <div class="product-info-wrapper p-3">
      <ng-template ngTemplateOutlet *ngIf="!data.extra.oldProduct; then productTitle; else oldProductTitle">
      </ng-template>
      <ng-template #productTitle>
        <div class="product-name mb-1 h5 font-weight-bold">
          <a class="product-link" routerLink="/product/{{ data.id }}">{{ getName() }}</a>
        </div>
        <div class="product-text">
          {{ getDescription() }}
        </div>
        <div class="product-text" *ngIf="getSecondaryDescription() !== ''">
          <span style="font-weight: bold" translate>DESCRIPTION</span><span style="font-weight: bold">:</span>
          {{ getSecondaryDescription() }}
        </div>
        <div class="h5 font-weight-bold price">
          <span translate>PRICE</span>:
          <span *ngIf="price && !displayStaffedPrices; else staffedPrice">{{
            price.customerNetPrice.value
            | gungCurrency
            : price.customerNetPrice.currencyCode
            : ('symbol-narrow')
            : '1.2-2'
            : ('sv_SE')
            }}
            <span>{{ getAssortmentType(data.extra) }}</span>
          </span>
          <ng-template #staffedPrice>
            <span *ngIf="price">
              <hl-display-price [price]="price" priceLevelsDisplayType="FULL" displayOnlyPrice="true"
                type="STAFFED"></hl-display-price>
            </span>
          </ng-template>
        </div>
        <div>
          <span class="d-block">
            <span translate>ID</span>: {{ data.id }}
            <span *ngIf="isPodEnabled && data.extra._kuar">({{ data.extra._kuar }})</span>
          </span>
          <span class="d-block" *ngIf="data.extra.hlPimItem.ItemColourName"><span translate>COLOR</span>: {{
            data.extra.hlPimItem.ItemColourName.join(',') }}</span>
          <span *ngIf="productSize" class="d-block"><span translate>SIZE</span>: {{ productSize }} </span>
          <span class="d-block"><span translate>PACK_SIZE</span>: {{ data.extra.stepAmount }}</span>

          <span *ngIf="data.extra.ar.antalperpall" class="d-block"><span translate>QTY_PER_PALLET</span>: {{
            data.extra.ar.antalperpall }}</span>
          <span *ngIf="
              price &&
              getAssortmentType(data.extra) === '(W)' &&
              data.extra.ar.q_basecost_start &&
              data.extra.ar.q_basecost_unit
            " class="d-block">
            <span translate>BASECOST_S_U</span>:
            {{
            data.extra.ar.q_basecost_start
            | gungCurrency: price.customerNetPrice.currencyCode : ('symbol-narrow') : '1.2-2'
            }}
            /
            {{
            data.extra.ar.q_basecost_unit
            | gungCurrency: price.customerNetPrice.currencyCode : ('symbol-narrow') : '1.2-2'
            }}
          </span>
        </div>
        <div>
          <span translate>AVAILABILITY</span>:
          <app-hl-display-availability *ngIf="data.extra?.availabilities" [availability]="availability">
          </app-hl-display-availability>
        </div>
      </ng-template>
      <ng-template #oldProductTitle>
        <div class="product-link">
          <div class="product-name">{{ data.name }}</div>
          <div class="product-text">{{ data.description }}</div>
        </div>
      </ng-template>
    </div>
    <div class="product-btns mb-3 mt-auto">
      <app-hl-display-buy-btn [product]="data" *ngIf="!data.extra.oldProduct" [id]="data.id">
      </app-hl-display-buy-btn>
      <a class="product-link" routerLink="/product/{{ data.id }}">
        <button type="button" class="btn btn-block btn-outline-primary btn-sm mt-2 w-100" style="white-space: nowrap"
          translate>
          MORE_INFO
        </button>
      </a>
    </div>
  </div>
</div>