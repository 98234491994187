import { Component, OnInit } from '@angular/core';
import { HlDisplayInvoicesListConfigService } from '../../services/hl-display-invoices-list-config.service';

@Component({
  selector: 'app-hl-display-invoices',
  templateUrl: './hl-display-invoices.component.html',
  styleUrls: ['./hl-display-invoices.component.css']
})
export class HlDisplayInvoicesComponent implements OnInit {
  constructor(public config: HlDisplayInvoicesListConfigService) {}

  ngOnInit(): void {}
}
