import { Component, OnInit } from '@angular/core';
import { ExportDragProductService } from '../../services/export-drag-product.service';

@Component({
  selector: 'lib-add-standalone-note',
  templateUrl: './add-standalone-note.component.html',
  styleUrls: ['./add-standalone-note.component.scss']
})
export class AddStandaloneNoteComponent {
  note: string;
  heading: string;

  constructor(protected exportDragProductService: ExportDragProductService) {}

  createNote(): void {
    this.exportDragProductService.setOneNote({ heading: this.heading, note: this.note });
    this.note = '';
  }
}
