<div *ngIf="loading && (!filteredItems || filteredItems.length <= 0)" class="progress mt-10">
  <div
    class="progress-bar progress-bar-striped progress-bar-animated bg-primary"
    role="progressbar"
    aria-valuenow="75"
    aria-valuemin="0"
    aria-valuemax="100"
    style="width: 75%"
  ></div>
</div>
<div class="filter-list">
  <div class="filter-list-wrapper">
    <ng-container *ngTemplateOutlet="sideFilter"></ng-container>
    <div class="row" *ngIf="selectionActions">
      <lib-selection-bar
        [selectionActions]="selectionActions"
        [filteredItems]="filteredItems"
        [allItems]="items"
        (barHeight)="adjustBottomMargin($event)"
      ></lib-selection-bar>
    </div>
  </div>
</div>

<div (click)="toggleFilters()" [ngClass]="shouldDisplayOverlay ? 'overlay-visible' : 'overlay-hidden'"></div>

<ng-template #sideFilter>
  <div class="row" [hidden]="loading && (!filteredItems || filteredItems.length <= 0)" [ngStyle]="containerStyles">
    <div
      #gungFilters
      [ngClass]="getGungFilterClasses()"
      [class]="getFilterGroupCss()"
      class="col-12 gung-filters d-flex flex-column"
    >
      <div class="gung-filters-inner d-flex flex-column p-0">
        <app-hl-product-list-filters
          *ngIf="renderFilters"
          [renderFilters]="renderFilters"
          (selection)="setMultipleSelections($event)"
        ></app-hl-product-list-filters>
        <lib-selected-filter
          [renderFilters]="renderFiltersForSelected"
          (deselectAll)="clearSelection()"
          (deselectValue)="setSelection($event.filterName, $event.valueId, false)"
          (deselectValueRange)="setSelection($event.filterName, null, false, $event.valueIdRange)"
        >
        </lib-selected-filter>
      </div>
    </div>
    <div
      [ngClass]="{
        'col-12 col-lg-9': renderFilters && renderFilters.length > 0,
        'col-12': renderFilters && renderFilters.length === 0
      }"
      class="col-12"
    >
      <div class="product-list-search-group">
        <app-hl-product-list-header *ngIf="renderFilters" [renderFilters]="renderFilters"></app-hl-product-list-header>
        <div class="row" [ngClass]="getSearchGroupCss()">
          <div class="mb-2 col-md col-12">
            <ng-template libCustomHost name="searchFields"></ng-template>
            <!--<lib-filter-list-search name="searchFields" (searchUpdated)="setSearch($event)"></lib-filter-list-search>-->
          </div>
          <div class="col col-md-3" *ngIf="batchSizes && batchSizes.length > 1">
            <lib-filter-list-batch (batchSizeUpdated)="setBatchSize($event)" [batchSizes]="batchSizes">
            </lib-filter-list-batch>
          </div>
          <div class="col-auto" *ngIf="!isSmallScreeen && layouts && layouts.length > 1">
            <button
              class="btn {{ layout.getButtonClass && layout.getButtonClass() }}"
              *ngFor="let layout of layouts; let layoutIndex = index"
              (click)="changeLayout(layoutIndex); currentLayout = layoutIndex"
            >
              <i [ngClass]="layout.getIconClass()"></i>
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <ng-template libCustomHost></ng-template>
            <p *ngIf="filteredItems && filteredItems.length === 0" class="nothing-found">
              <span [innerHTML]="getNothingFoundTranslateTag() | translate"></span>
            </p>
          </div>
        </div>
        <div class="d-flex justify-content-center align-items-center">
          <button
            [ngClass]="getLoadMoreCss()"
            *ngIf="!!filteredItems && filteredItems.length > limit"
            class="btn"
            (click)="loadMore()"
          >
            <span translate [translateParams]="{ limit: limit, total: filteredItems.length, batchSize: batchSize }"
              >LOAD_MORE</span
            >
          </button>
        </div>
      </div>
    </div>
    <div *ngIf="filteredItems && filteredItems.length > 0 && renderFilters && renderFilters.length > 0">
      <a
        [ngClass]="{ 'hide-filter-toggle': getGungFilterClasses().open }"
        class="gung-filters-toggle"
        (click)="toggleFilters()"
      >
        <i class="fa fa-filter"></i>
      </a>

      <a
        [ngClass]="{ 'hide-filter-toggle': !getGungFilterClasses().open }"
        class="gung-filters-toggle close-filters"
        (click)="toggleFilters()"
      >
        <i class="fa fa-times"></i>
      </a>
    </div>
  </div>
</ng-template>
