<footer class="footer gung-footer" *ngIf="!myCompanyFooter; else customFooter">
  <div class="row">
    <div class="col-12 col-lg-10 offset-lg-1 footer-content-container">
      <div class="row">
        <div class="col-sm-4 d-none d-sm-block footer-left-column">
          <ng-container
            [ngTemplateOutlet]="iterateColumnItems"
            [ngTemplateOutletContext]="{ content: leftContent }"
          ></ng-container>
        </div>
        <div class="col-sm-4 d-none d-sm-block footer-center-column">
          <ng-container
            [ngTemplateOutlet]="iterateColumnItems"
            [ngTemplateOutletContext]="{ content: centerContent }"
          ></ng-container>
        </div>
        <div class="col-sm-4 d-none d-sm-block footer-right-column">
          <ng-container
            [ngTemplateOutlet]="iterateColumnItems"
            [ngTemplateOutletContext]="{ content: rightContent }"
          ></ng-container>
        </div>
      </div>

      <!--iterates through all items which is sent in-->
      <ng-template #iterateColumnItems let-content="content">
        <div *ngFor="let item of content">
          <div *ngIf="!!item.text">
            <p>{{ item.text }}</p>
          </div>
          <div *ngIf="!!item.label">
            <a href="{{ item.linkUrl }}">{{ item.label }}</a>
          </div>
          <div *ngIf="!!item.imageUrl"><img src="{{ item.imageUrl }}" /></div>
        </div>
      </ng-template>
    </div>
  </div>
</footer>

<ng-template #customFooter>
  <footer [class.border]="edit" [style]="{ backgroundColor: myCompanyFooter.backgroundColor }">
    <div class="row h-100 m-0">
        <div *ngFor="let column of myCompanyFooter.columns; let i = index" class="col h-100" (click)="openHtmlModal(column)" [class.cursor-pointer]="true">
            <button *ngIf="edit" type="button" class="btn btn-sm btn-danger position-absolute" (click)="$event.preventDefault(); $event.stopPropagation(); deleteColumn(i)"><i class="fa-solid fa-trash-can"></i></button>
            <div [innerHTML]="getHtmlText(column) | safeHtml" class="h-100" [class.border]="edit" [ngStyle]="{bordeColor:invertHex(myCompanyFooter.backgroundColor)}"></div>
        </div>
    </div>
  </footer>
</ng-template>