import { Component, OnInit } from '@angular/core';
import { OrderListConfigService } from 'gung-standard';

import { JeevesOrderListConfigService } from 'gung-standard-jeeves';

@Component({
  selector: 'app-open-orders',
  templateUrl: './open-orders.component.html',
  styleUrls: ['./open-orders.component.css']
})
export class OpenOrdersComponent implements OnInit {
  constructor(public listConfigService: JeevesOrderListConfigService) {}

  ngOnInit() {}
}
