import { Component, OnInit } from '@angular/core';
import { AuthService, OrderService } from 'gung-standard';
import { JeevesEditOrderrowModalComponent } from 'gung-standard-jeeves';
import { first, forkJoin } from 'rxjs';
import { environment } from '../../../environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HlDisplayPortalFeaturesService, OrderEditSettingsGroup } from '../../services/hl-display-portal-features.service';

@Component({
  selector: 'app-hl-display-edit-orderrow-modal',
  templateUrl: './hl-display-edit-orderrow-modal.component.html',
  styleUrls: ['./hl-display-edit-orderrow-modal.component.css']
})
export class HlDisplayEditOrderrowModalComponent extends JeevesEditOrderrowModalComponent implements OnInit {

  public readOnlyPrice: boolean = false;

  constructor(
    protected modalService: NgbModal,
    protected orderService: OrderService,
    protected authService: AuthService,
    protected hlDisplayPortalFeaturesService: HlDisplayPortalFeaturesService
  ) {
    super(modalService, orderService);
  }

  ngOnInit(): void {
    forkJoin({
      user: this.authService.getCurrentUser().pipe(first()),
      orderEditSettingsGroup: this.hlDisplayPortalFeaturesService.getPortalFeatureGroup('orderEditSettingsGroup').pipe(first())
    }).subscribe(({ user, orderEditSettingsGroup }) => {
      let orderEditGroup = orderEditSettingsGroup as OrderEditSettingsGroup;

      this.readOnlyPrice = user.extra.readOnlyPrice || !orderEditGroup.canEditOrderRow;
    });
  }
}
