import {
  Component,
  ComponentFactoryResolver,
  ChangeDetectorRef,
  Optional,
  HostListener,
  AfterViewInit,
  OnInit
} from '@angular/core';
import { Location } from '@angular/common';
import {
  FilterListComponent,
  FilterListService,
  AbstractSelectionService,
  FilterSelectionAction,
  FilterListRoutingService,
  FilterListLocationConfigService,
  GungListRouterEventsService
} from 'gung-list';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService, Product } from 'gung-standard';
import { environment } from './../../../environments/environment';
import { first } from 'rxjs';

@Component({
  selector: 'app-hl-product-list',
  templateUrl: './hl-product-list.component.html',
  styleUrls: ['./hl-product-list.component.css']
})
export class HlProductListComponent extends FilterListComponent<Product> implements AfterViewInit, OnInit {
  private smallScreeenWidth = 576;
  private currentLayout = 0;
  isSmallScreeen = false;

  isSales = false;

  constructor(
    public filterListRoutingService: FilterListRoutingService,
    public route: ActivatedRoute,
    public changeDetectorRef: ChangeDetectorRef,
    protected filterListService: FilterListService<Product>,
    protected gungListRouterEventsService: GungListRouterEventsService,
    protected filterListLocationConfigService: FilterListLocationConfigService,
    protected router: Router,
    protected authService: AuthService,
    @Optional()
    protected selectionService?: AbstractSelectionService<Product>
  ) {
    super(
      filterListRoutingService,
      route,
      changeDetectorRef,
      filterListService,
      gungListRouterEventsService,
      filterListLocationConfigService,
      router,
      selectionService
    );

  }

  ngOnInit(): void {
    super.ngOnInit();
    this.authService.getHasRoles('SALES').pipe(first())
      .subscribe((isSales) => {
        this.isSales = isSales;
      })
  }

  ngAfterViewInit(): void {
    const layouts = this.configService.getLayouts();
    if (this.isSales && window.innerWidth < this.smallScreeenWidth) {
      this.currentLayout = 1;
      this.isSmallScreeen = true;
    }
    const toRender = layouts[this.currentLayout];
    this.renderLayout(toRender.getListLayoutComponent(), toRender.getListItemComponent());
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (!this.isSales) {
      return;
    }
    const windowObject = event.target || window;
    this.isSmallScreeen = windowObject.innerWidth < this.smallScreeenWidth;
    if (this.isSmallScreeen && this.currentLayout !== 1) {
      this.changeLayout(1);
      this.currentLayout = 1;
    }
  }

  setMultipleSelections(actions: FilterSelectionAction[]) {
    super.setMultipleSelections(actions);
    this.renderFilters = JSON.parse(JSON.stringify(this.renderFilters)); // Force to refresh the headr and filters
  }
}
