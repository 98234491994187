import { Injectable } from '@angular/core';
import { CustomerService } from 'gung-standard';
import {
  DeliveryLocationsService,
  JeevesDeliveryAddressesService,
  JeevesOrderDeliveryAddress
} from 'gung-standard-jeeves';

@Injectable({
  providedIn: 'root'
})
export class HlDisplayJeevesDeliveryAddressesService extends JeevesDeliveryAddressesService {
  constructor(
    protected deliveryLocationsService: DeliveryLocationsService,
    protected customerService: CustomerService
  ) {
    super(deliveryLocationsService, customerService);
  }

  // override Jeeves
  // mapping for Hl-display are from
  // -   fr.ftgnamn
  // -   fr.ftgpostadr1
  // -   fr.ftgpostadr2
  // -   fr.ftgpostnr
  // -   fr.ftgpostadr3
  // -   fr.landskod
  // do not fr.ftgpostadr5
  protected getMappedFr(
    fr: any,
    source: 'deliveryLocation' | 'customerAddress',
    deliveryLocationId?: string
  ): JeevesOrderDeliveryAddress {
    const res: JeevesOrderDeliveryAddress = {
      addressValues: {
        ordlevadr1: fr.ftgnamn || '',
        ordlevadr2: fr.ftgpostadr1 || '',
        ordlevadr3: fr.ftgpostadr2 || '',
        ordlevadr4: fr.ftgpostadr3 || '',
        ftgpostnr: fr.ftgpostnr || '',
        ordlevadrlandskod: fr.landskod || '',
        ordlevplats1: deliveryLocationId
      },
      source
    };

    return res;
  }
}
